import React, { PureComponent, createContext } from 'react';
import MyInput from '@pagescommon/Inputs/MyInput';
import MySelect from '@pagescommon/Inputs/MySelect';
import './css/restApi.scss';
import StickyHeadTable from '../restapi/request/request_parameter/jsx/data_request_param_grid';
import DataRequestParamGrid from '../restapi/request/request_parameter/jsx/request_header';
import {
  postWebServiceAddApi,
  postWebServiceStepNLP,
  getAllGlobalVariableListReq,
  getAllLocalVariablesReq,
  getAllProjectVariableListReq,
  getStepsDataByHistoryId,
  createCurlReq,
  getAllStepGroupVariable,
  gellAllParameter,
  getSingleModuleReq,
  getScriptReq,
  importCurlToJson,
} from '@api/api_services';
import DataRequestBodyGrid from './request/request_body/jsx/request_body';
import Authorization from './request/Authorization/authorization';
import Assert from './request/assert/jsx/assert';
import ResponseBody from '../restapi/response/response_body/jsx/response_body';
import Prerequisite from '../restapi/request/prerequisite/jsx/prerequisite';
import ResponseHeaders from '../restapi/response/response_headers/jsx/response_headers';
import AssertResult from '../restapi/response/assert_results/jsx/assert_results';
import CaptureResponseData from '../restapi/response/capture_response_data/jsx/capture_response_data';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SaveAapiRreq from './Save_api/save_api_req';
import Modal from '@material-ui/core/Modal';
import { withAlertContainer } from '@pagescommon/alert_service/useAlert';
import { WEBSERVICE } from '../restapi/constants/constants';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import Button from '@mui/material/Button';
import ShowHide from '../restapi/images/show-hides.svg';
import ShowExpand from '../restapi/images/show-hide.svg';
import ShowExpandHover from '../restapi/images/show-hide-black.svg';
import ExpandIcon from '../restapi/images/expand-icon-1.svg';
import MenuItem from '@mui/material/MenuItem';
import { CommonLoader } from '@src/pages/common/common-loader';
import CapturedDataFromResponse from '../restapi/response/captured_data_from_response/jsx/captured_data_from_response';
import { ASSERT_CONSTANT } from './request/assert/jsx/constant';
import CircleIcon from '@mui/icons-material/Circle';
import { PREREQUISITE_CONSTANT } from '@pages/test-development/script/scripts/webservice/restapi/request/prerequisite/jsx/constant';
import { withStyles, Tooltip } from '@material-ui/core';
import CommonButton from '@src/pages/common/button/CommonButton';
import { colors } from '@src/css_config/colorConstants.js';
import Magnifier from '../restapi/images/magnifier.svg';
import SearchIcon from '@material-ui/icons/Search';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import WebserviceWarningModal from '../../webservice-warning-modal';
import WebserviceAuth from '@pages/test-development/script/scripts/settings/webservice-auth/webservice-auth';
import GenerateCurlModal from '@src/pages/common/generateCurlModal.js';
import { isEmptyObject, isEmptyValue } from '@src/util/common_utils';
import { REGEX } from '@src/util/validations';
import Settings from './response/settings/jsx/Settings';
import { SETTINGS } from './response/settings/jsx/constants';

const styles = (theme) => ({
  customModal: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    border: 'none',
  },
  paper: {
    width: 450,
  },
  customModalBodyContainer: {
    width: '100%',
    whiteSpace: 'normal',
    boxShadow: `0px 0px 4px ${colors.shadow_black}`,
    overflow: 'hidden',
    height: '89%',
    backgroundColor: colors.text_white,
  },
  root: {
    width: '275px',
    height: '30px',
    top: 2,
    bottom: 2,
    padding: '0 20px',
    marginLeft: 90,
    position: 'absolute',
    overflow: 'hidden',
  },
  customButton: {
    borderRadius: '5px',
    height: '34px',
  },
  customMenuItem: {
    paddingLeft: '10px',
  },
});

export const RestContext = createContext();
const {
  DISABLE_REQUEST_HEADER_INDEXES,
  VALIDATION_REQUEST_NAME,
  VALIDATION_REQUEST_TYPE,
  VALIDATION_REQUEST_URL,
  DEFAULT_EXPORT_CURL,
  CONTENT_TYPE_RESPONSE_HEADERS,
  APPLICATION_HTML_WITH_CHARSET,
  APPLICATION_HTML_WITHOUT_CHARSET,
  TEXT_HTML_WITH_CHARSET,
  TEXT_HTML_WITHOUT_CHARSET,
  APPLICATION_JSON_WITHOUT_CHARSET,
  APPLICATION_JSON_WITH_CHARSET,
  TEXT_JSON_WITHOUT_CHARSET,
  TEXT_JSON_WITH_CHARSET,
  APPLICATION_XML_WITHOUT_CHARSET,
  APPLICATION_XML_WITH_CHARSET,
  TEXT_XML_WITHOUT_CHARSET,
  TEXT_XML_WITH_CHARSET,
  VALIDATION_REQUEST_NAME_ALPHANUMERIC,
  VALIDATION_REQUEST_NAME_MAX_LENGTH,
  VALIDATION_REQUEST_NAME_WHITESPACE,
  VALIDATION_REQUEST_NAME_MIN_LENGTH,
  IMPORT,
  IMPORT_CURL_TEXT,
  EXPORT_CURL_TEXT,
  LOADING_SENDING,
  SEND_BUTTON_TEXT,
  UPDATE_AS_STEP,
  SAVE_AS_STEP,
  REQUEST_NAME_ERROR,
  IMPORT_CURL_SUCCESS,
  IMPORT_CURL_FAILURE,
  IMPORT_CURL_ERROR,
  DEFAULT_REQUEST_HEADERS,
  ONCHANGE,
  ONPASTE,
  INVALID_IMPORT_CURL,
  DEFAULT_FORM_DATA,
  DEFAULT_URL_FORM_DATA,
  DEFAULT_HEADER_ROW,
  FOR_LOOP_INITIAL_VALUE,
  FOR_LOOP_DISPLAY_VALUE,
  FOR_LOOP_VALUE,
  INITIAL_PROJECT_TYPE_LIST,
  BUTTON_STATUS,
  MS,
  NA,
  REQUEST_BODY_BUTTONS,
  REQUEST_URL_PREFIXES,
  REQUEST_URL_ERROR,
  DEFAULT_RAW_DATA,
  UNSUPPORTED_METHOD,
  CAPTURE_GET_RESPONSE_TIME,
  CAPTURE_GET_STATUS_CODE,
  API_KEY_INITIAL_VALUES,
  WEBSERVICE_TITLE,
} = WEBSERVICE;
const { REQUEST_URL_REGEX, GRAPHQL_FORMAT_REGEX } = REGEX;
const { REQUEST_TIMEOUT_MS } = SETTINGS;
const initialValues = {
  requestName: '',
  variableId: '',
  urlVariableId: '',
  tokenVariableId: '',
  requestType: 'GET',
  requestUrl: '',
  urlformData: DEFAULT_URL_FORM_DATA,
  formData: DEFAULT_FORM_DATA,
  authType: 'Inherit from Parent',
  noAuthTypeData: null,
  basicAuthData: {},
  bearerTokenData: {},
  oauth1Data: {},
  oauth2Data: {},
  digestAuthData: {},
  awsAuthData: {},
  hawkAuthData: {},
  microsoftNtlmAuthData: {},
  auth: {},
  defaultAssert: {},
  queryValue: [],
  requestBodyType: 'raw',
  rawData: DEFAULT_RAW_DATA,
  captureResponse: {},
  errors: {},
  ifFailed: '',
  statusCode: '',
  responseTimeData: '',
  responseBodyData: '',
  getHeaders: '',
  contentType: '',
  selectAssertTab: ASSERT_CONSTANT.ENABLE_DEFAULT_ASSERT,
  selectedRequestTab: '',
  setResponse: {},
  rawContentType: '',
  queryParams: INITIAL_PROJECT_TYPE_LIST,
  preHistoryId: '',
  requestHeaders: DEFAULT_REQUEST_HEADERS,
  captureResponseMetaError: {},
  settings: {
    tlsCertificateEnabled: true,
    requestTimeOut: {
      time: REQUEST_TIMEOUT_MS,
      unit: 'Ms',
    },
    maxNumberOfRedirect: 2,
    redirectEnabled: true,
    originalHttpMethodEnabled: false,
    retainAuthorizationHeaderEnabled: true,
    tlsVersions: [
      {
        name: 'TLSv1',
        isEnabled: true,
      },
      {
        name: 'TLSv1.1',
        isEnabled: true,
      },
      {
        name: 'TLSv1.2',
        isEnabled: true,
      },
      {
        name: 'TLSv1.3',
        isEnabled: true,
      },
    ],
  },
  allContextGlobalVariables: [],
  allContextProjectEnvVariables: [],
  allContextLocalVariables: [],
  isHistoryStep: false,
  action: {
    label: 'Request Header',
    value: 'requestHeader',
  },
  algorithm: { label: 'HS256', value: 'HS256', header: 'HMAC - SHA-256' },
  jwtAuth: {
    action: 'requestHeader',
    algorithm: 'HS256',
    secret: '',
    encoded: false,
    privateKey: '',
    payload: '',
    tokenPrefix: '',
    jwtHeaders: '',
    privateKeyFileName: '',
  },
  apiKey: API_KEY_INITIAL_VALUES,
};

export const RestApiProvider = ({ children }) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      requestName: Yup.string().required(VALIDATION_REQUEST_NAME),
      requestType: Yup.string().required(VALIDATION_REQUEST_TYPE),
      requestUrl: Yup.string().required(VALIDATION_REQUEST_URL),
    }),
  });

  return <RestContext.Provider value={formik}>{children}</RestContext.Provider>;
};

class Restapi extends PureComponent {
  static contextType = RestContext;
  constructor(props) {
    super(props);
    this.state = {
      value: 'request-body',
      queryParam: 'disable',
      requestHeader: 'disable',
      requestBody: 'enable',
      authorization: 'disable',
      preRequisite: 'disable',
      assert: 'disable',
      setIsUpArrowRequest: 'show-request-tab',
      setIsDownArrowRequest: 'hide-request-tab',
      setIsUpArrowResponse: '',
      setIsArrowPosition: '',
      responseBody: 'enable',
      responseHeaders: 'disable',
      assertResults: 'disable',
      settings: 'disable',
      captureResponseData: 'disable',
      activeRequestParam: '',
      activeRequestHeaders: '',
      activeRequestBody: 'activeQuery',
      activeAuthorization: '',
      activeAssert: '',
      activePreRequisite: '',
      activeResponseBody: 'activeQuery',
      activeResponseHeaders: '',
      activeAssertResult: '',
      activeCaptureResponseData: '',
      activeSettings: '',
      updateData: false,
      setSaveAsEnable: false,
      responseHeadersValue: '',
      responseValue: '',
      setRequestName: '',
      setRequestType: 'GET',
      setRequestURL: '',
      responseBodyValue: '',
      responseHeadersLenValue: 0,
      responseAssertionResultsValue: [],
      responseAssertionResultsLen: 0,
      assertionResultValue: [],
      assertionResultValueLen: 0,
      statusCode: '',
      statusCodeValue: 'NA',
      status: '',
      apiTime: 'NA',
      setParamValue: '',
      responseBodyValueWithParse: '',
      responseBodyValueWithoutParse: '',
      selInitialLoadValueofResponsebody: '',
      openModal: false,
      requestNameError: '',
      requestUrlError: 'Response Not Found',
      JsonContentTypeLen: 0,
      XMLContentTypeLen: 0,
      responseNotFoundClassName: 'responseBodyInternalServerError',
      updateResponseBody: false,
      HTMLContentTypeLen: 0,
      isLoading: false,
      isLoadingNlp: false,
      errors: {},
      setRequestNameResponse: '',
      formatObjectToString: '',
      formatValueURLToTable: '',
      onGetRequestURL: '',
      responseSelectedValue: 'Response Body',
      responseBodyValueWithoutParseError: '',
      localVariable: [],
      globalVariable: [],
      projEnvironmentVariable: [],
      selectedStepData: {},
      setAllVariableName: {},
      captureDataResponseError: false,
      isRequestUrlFocus: false,
      captureResponseDataError: {},
      isProgressLoding: false,
      activeCaptureDataFromResponse: '',
      capturedDataFromResponse: 'disable',
      capturedDataFromResponseValue: [],
      capturedDataFromResponseLenValue: 0,
      historyId: '',
      checkWebserviceData: {},
      curlData: '',
      curlModal: false,
      isCopied: false,
      selectedRequestTab: 'Request Body',
      selectedResponseTab: 'Response Body',
      isVariableModalOpen: false,
      variableName: props.data ? props.data.name : '',
      variableType: props.variableType ? props.variableType : props.type ? 'Step' : 'LOCAL',
      variableValue: props.data ? props.data.value : '',
      variableErrors: {},
      dataProviderVariables: [],
      optionalParam: {},
      changeFieldIdx: -1,
      requestUrlErr: '',
      requestNameErr: '',
      defaultAssertErrors: {},
      captureDataError: {},
      stepGroupVariable: [],
      stepGroupParam: [],
      showWebserviceModal: false,
      parentAuthData: null,
      moduleName: '',
      iconHovered: false,
      initialConditionalStepData: {},
      isValidImportCurl: false,
      dataProviderData: {},
      optionalParamValueObj: {},
      forLoopVariables: [],
      requestURLinput: '',
      metaDataofIterationStep: {},
      conditionalStepObject: {},
      cdfrTabState: {
        statusCode: '',
        respBody: '',
        respTime: '',
        getHeader: '',
        jsonPath: '',
        headerKey: '',
        contentType: '',
      },
      isSendExecuted: true,
      isCdfrResponseVariableAssigned: false,
      isCdfrResponseVariableUpdated: false,
      blobFileSize: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.modifiedCheckWebserviceData = this.state.checkWebserviceData;
  }

  moduleName = this.props.match.params.moduleName;
  search = window.location.search;
  moduleId = new URLSearchParams(this.search).get('moduleId');
  scriptId = new URLSearchParams(this.search).get('scriptId');
  stepId = new URLSearchParams(this.search).get('stepId');
  stepGroupId = new URLSearchParams(this.search).get('id');
  libraryId = new URLSearchParams(this.search).get('parentId');
  stepGroupName = new URLSearchParams(this.search).get('stepGroup');
  manualScriptId = new URLSearchParams(this.search).get('manualScriptId');
  setOutRequestUrl = '';
  isCdfrVariableApiCall = false;
  isCdfrVariableUpdated = false;

  onRequestName = (e) => {
    const {
      target: { value, variableId },
    } = e;
    const isValid = !!value;
    this.modifiedCheckWebserviceData['requestName'] = isValid;
    this.context.handleChange(e);
    this.context.setFieldValue('variableId', variableId || '');
    this.onRequestNameValidation(value);
    this.handleSaveAsSteps(false);
  };

  onRequestType = (e) => {
    const { value } = e.target;
    this.modifiedCheckWebserviceData['requestType'] = value !== 'GET';
    this.context.handleChange(e);
    this.handleSaveAsSteps(false);
  };

  onRequestUrl = (e) => {
    const { value, data, action } = e.target || 'unknown';
    const requestUrlValue = value.replace(this.state.formatObjectToString, '');
    this.context.setFieldValue('currentRequestUrl', e);
    this.modifiedCheckWebserviceData['requestUrl'] = !!value;
    this.setState({ formatValueURLToTable: requestUrlValue });
    this.onRequestUrlValidation(value);
    const requestUrl = value.split('?');
    const queryParam = requestUrl[1] ? `?${requestUrl[1]}` : requestUrl[1] === undefined ? '' : '?';
    if (requestUrl[0] === '' && requestUrl[1] === undefined) {
      if (isEmptyValue(this.state.onGetRequestURL)) this.setState({ onGetRequestURL: ' ' });
      else this.setState({ onGetRequestURL: '' });
    } else {
      this.setState({ onGetRequestURL: queryParam });
    }
    const getRequestUrlForVariable = this.getUserInputValue(value, false, data, action);
    this.setState({ requestURLinput: getRequestUrlForVariable });
    this.context.setFieldValue('urlVariableId', getRequestUrlForVariable !== value ? getRequestUrlForVariable : '');
    this.context.handleChange(e);
    this.handleSaveAsSteps(false);
  };

  onHandleDataProvider = (stringifyPayload) => {
    let dataVar = '';
    let variableObj = {};
    let columns = {};
    let tempDataProvider = '';
    let isExistIteration = false;
    let updatedOptionalParam = { ...this.state.optionalParam };
    this.state.dataProviderVariables.forEach((data) => {
      dataVar = '${DPV_' + data.dpName + ':' + data.varname + '}';
      if (data.dpName !== tempDataProvider) {
        columns = {};
      }
      if (
        !stringifyPayload.indexOf(dataVar) !== -1 &&
        updatedOptionalParam?.stepData?.values?.dataProvider &&
        !isExistIteration
      ) {
        delete updatedOptionalParam?.stepData?.values?.dataProvider;
      }
      columns[data.colName] = data.varname;
      tempDataProvider = data.dpName;
      if (stringifyPayload.indexOf(dataVar) !== -1) {
        const isExists = updatedOptionalParam.stepData.values.dataProvider?.some((obj) => obj.name === data.dpName);
        if (!isExists) {
          isExistIteration = true;
          variableObj = {
            name: data.dpName,
            fileId: data.fileId,
            sheetName: data.sheetName,
            columns: columns,
          };
          if (!updatedOptionalParam.stepData.values.dataProvider) {
            updatedOptionalParam.stepData.values.dataProvider = [];
          }
          updatedOptionalParam.stepData.values.dataProvider.push(variableObj);
        }
      }
    });
    return updatedOptionalParam;
  };

  updateForLoopName(stepData) {
    if (stepData && stepData.values && stepData.values.forLoop) {
      const forLoop = stepData.values.forLoop;
      if (forLoop.length > 0) {
        forLoop[0].name = FOR_LOOP_VALUE;
      }
    }
  }

  getUserInputValue = (value, status, data, action) => {
    let tempValue = '';
    let setAllVariableName = { ...this.state.setAllVariableName };
    if (action === 'create') {
      const variableId = '${' + data.id + '}';
      const variableName = this.onSetValueByType(data.name, data.type, data);
      setAllVariableName[variableId] = variableName;
    }
    if (Object.keys(this.state.setAllVariableName).length > 0 && value && typeof value === 'string') {
      tempValue = value;
      for (const items in setAllVariableName) {
        if (status) {
          tempValue = tempValue.replaceAll(items, setAllVariableName[items]);
        } else {
          tempValue = tempValue.replaceAll(setAllVariableName[items], items);
        }
      }
    }
    return tempValue;
  };

  updateVaribleDetailsById = (variables, updatedVariable) => {
    const index = variables.findIndex((variable) => variable.id === updatedVariable.id);
    if (index !== -1) {
      variables[index] = { ...variables[index], ...updatedVariable };
    }
    return variables;
  };

  onSetAllVariableName = (data, type, action) => {
    let variableName = '';
    if (type === 'LOCAL' && ['Webservice', 'script'].includes(this.props.type)) {
      variableName = '${LV_' + data.name + '}';
      let updateLocalVariable = '';
      if (!action) {
        updateLocalVariable = [...this.state.localVariable, data];
        this.setState({ localVariable: updateLocalVariable });
      }
      if (action === 'update') {
        updateLocalVariable = this.updateVaribleDetailsById(this.state.localVariable, data);
        this.setState({ localVariable: updateLocalVariable });
      }
    } else if (type === 'GLOBAL') {
      variableName = '${GV_' + data.name + '}';
      let updateGLobalVariable = '';
      if (!action) {
        updateGLobalVariable = [...this.state.globalVariable, data];
        this.setState({ globalVariable: updateGLobalVariable });
      }
      if (action === 'update') {
        updateGLobalVariable = this.updateVaribleDetailsById(this.state.globalVariable, data);
        this.setState({ globalVariable: updateGLobalVariable });
      }
    } else if (type === 'PROJECT_ENVIRONMENT') {
      variableName = '${PEV_' + data.name + '}';
      let updateProjEnvironmentVariable = '';
      if (!action) {
        updateProjEnvironmentVariable = [...this.state.projEnvironmentVariable, data];
        this.setState({ projEnvironmentVariable: updateProjEnvironmentVariable });
      }
      if (action === 'update') {
        updateProjEnvironmentVariable = this.updateVaribleDetailsById(this.state.projEnvironmentVariable, data);
        this.setState({ projEnvironmentVariable: updateProjEnvironmentVariable });
      }
    } else if (['LOCAL', 'Step'].includes(type) && data?.parentVariableType === 'STEPGROUP') {
      variableName = '${SGV_' + data.name + '}';
      let updateStepGroupVariable = '';
      if (!action) {
        updateStepGroupVariable = [...this.state.stepGroupVariable, data];
        this.setState({ stepGroupVariable: updateStepGroupVariable });
      }
      if (action === 'update') {
        updateStepGroupVariable = this.updateVaribleDetailsById(this.state.stepGroupVariable, data);
        this.setState({ stepGroupVariable: updateStepGroupVariable });
      }
    } else {
      variableName = '${SGP_' + data.name + '}';
      let updateStepGroupParameter = '';
      if (!action) {
        updateStepGroupParameter = [...this.state.stepGroupParam, data];
        this.setState({ stepGroupParam: updateStepGroupParameter });
      }
      if (action === 'update') {
        updateStepGroupParameter = this.updateVaribleDetailsById(this.state.stepGroupParam, data);
        this.setState({ stepGroupParam: updateStepGroupParameter });
      }
    }
    const variableId = '${' + data.id + '}';
    const updateSetAllVariableName = { ...this.state.setAllVariableName };
    updateSetAllVariableName[variableId] = variableName;
    this.setState({ setAllVariableName: updateSetAllVariableName });
    this.context.setFieldValue('variableData', updateSetAllVariableName);
  };

  handleRequestTabChange = (newValue) => {
    const requestOption = newValue;
    switch (requestOption) {
      case 'Query Parameter':
        this.setState({
          value: requestOption,
          queryParam: 'queryParamEnable',
          requestHeader: 'disable',
          requestBody: 'disable',
          authorization: 'disable',
          preRequisite: 'disable',
          settings: 'disable',
          assert: 'disable',
          captureResponseData: 'disable',
          activeRequestParam: 'activeQuery',
          activeRequestHeaders: '',
          activeRequestBody: '',
          activeAuthorization: '',
          activeAssert: '',
          activePreRequisite: '',
          activeCaptureResponseData: '',
          activeSettings: '',
          selectedRequestTab: requestOption,
        });
        break;
      case 'Request Header':
        this.setState({
          value: requestOption,
          queryParam: 'disable',
          requestHeader: 'enable',
          requestBody: 'disable',
          authorization: 'disable',
          preRequisite: 'disable',
          assert: 'disable',
          settings: 'disable',
          captureResponseData: 'disable',
          activeRequestParam: '',
          activeRequestHeaders: 'activeQuery',
          activeRequestBody: '',
          activeAuthorization: '',
          activeAssert: '',
          activePreRequisite: '',
          activeCaptureResponseData: '',
          activeSettings: '',
          selectedRequestTab: requestOption,
        });
        break;
      case 'Request Body':
        this.setState({
          value: requestOption,
          queryParam: 'disable',
          requestHeader: 'disable',
          requestBody: 'enable',
          authorization: 'disable',
          preRequisite: 'disable',
          settings: 'disable',
          assert: 'disable',
          captureResponseData: 'disable',
          activeRequestParam: '',
          activeRequestHeaders: '',
          activeRequestBody: 'activeQuery',
          activeAuthorization: '',
          activeAssert: '',
          activePreRequisite: '',
          activeCaptureResponseData: '',
          activeSettings: '',
          selectedRequestTab: requestOption,
        });
        break;
      case 'Authorization':
        this.setState({
          value: requestOption,
          queryParam: 'disable',
          requestHeader: 'disable',
          requestBody: 'disable',
          authorization: 'enable',
          preRequisite: 'disable',
          settings: 'disable',
          assert: 'disable',
          captureResponseData: 'disable',
          activeRequestParam: '',
          activeRequestHeaders: '',
          activeRequestBody: '',
          activeAuthorization: 'activeQuery',
          activeAssert: '',
          activePreRequisite: '',
          activeCaptureResponseData: '',
          activeSettings: '',
          selectedRequestTab: requestOption,
        });
        break;
      case 'Pre Requisite':
        this.setState({
          value: requestOption,
          queryParam: 'disable',
          requestHeader: 'disable',
          requestBody: 'disable',
          authorization: 'disable',
          settings: 'disable',
          preRequisite: 'enable',
          assert: 'disable',
          captureResponseData: 'disable',
          activeRequestParam: '',
          activeRequestHeaders: '',
          activeRequestBody: '',
          activeAuthorization: '',
          activeAssert: '',
          activePreRequisite: 'activeQuery',
          activeCaptureResponseData: '',
          activeSettings: '',
          selectedRequestTab: requestOption,
        });
        break;
      case 'Assert':
        this.setState({
          value: requestOption,
          queryParam: 'disable',
          requestHeader: 'disable',
          requestBody: 'disable',
          authorization: 'disable',
          preRequisite: 'disable',
          settings: 'disable',
          assert: 'enable',
          captureResponseData: 'disable',
          activeRequestParam: '',
          activeRequestHeaders: '',
          activeRequestBody: '',
          activeAuthorization: '',
          activeAssert: 'activeQuery',
          activePreRequisite: '',
          activeCaptureResponseData: '',
          activeSettings: '',
          selectedRequestTab: requestOption,
        });
        break;
      case 'Capture Data From Response':
        this.setState({
          value: requestOption,
          queryParam: 'disable',
          requestHeader: 'disable',
          requestBody: 'disable',
          authorization: 'disable',
          preRequisite: 'disable',
          settings: 'disable',
          assert: 'disable',
          activeRequestParam: '',
          activeRequestHeaders: '',
          activeRequestBody: '',
          activeAuthorization: '',
          activeAssert: '',
          activePreRequisite: '',
          captureResponseData: 'enable',
          activeSettings: '',
          activeCaptureResponseData: 'activeQuery',
          selectedRequestTab: requestOption,
        });
        break;
      case 'Settings':
        this.setState({
          value: requestOption,
          queryParam: 'disable',
          requestHeader: 'disable',
          requestBody: 'disable',
          authorization: 'disable',
          preRequisite: 'disable',
          assert: 'disable',
          captureResponseData: 'disable',
          settings: 'enable',
          activeRequestParam: '',
          activeRequestHeaders: '',
          activeRequestBody: '',
          activeAuthorization: '',
          activeAssert: '',
          activePreRequisite: '',
          activeCaptureResponseData: '',
          activeSettings: 'activeQuery',
          selectedRequestTab: requestOption,
        });
        break;
      default:
        return null;
    }
  };

  onChangeResponseOption = (newValue) => {
    const baseState = {
      responseBody: 'disable',
      responseHeaders: 'disable',
      assertResults: 'disable',
      activeResponseBody: '',
      activeResponseHeaders: '',
      activeAssertResult: '',
      activeCaptureDataFromResponse: '',
      capturedDataFromResponse: 'disable',
      responseSelectedValue: newValue,
    };

    const activeFieldsMap = {
      'Response Body': {
        responseBody: 'enable',
        activeResponseBody: 'activeQuery',
      },
      'Response Headers': {
        responseHeaders: 'enable',
        activeResponseHeaders: 'activeQuery',
      },
      'Assert Results': {
        assertResults: 'enable',
        activeAssertResult: 'activeQuery',
      },
      'Captured Data From Response': {
        capturedDataFromResponse: 'enable',
        activeCaptureDataFromResponse: 'activeQuery',
      },
      Settings: {
        activeSettings: 'activeQuery',
        settings: 'enable',
      },
    };

    const specificState = activeFieldsMap[newValue] || {};

    this.setState({
      ...baseState,
      ...specificState,
    });

    this.context.values.setResponse = {
      ...this.context.values.setResponse,
      assertResults: specificState.assertResults || 'disable',
      capturedDataFromResponse: specificState.capturedDataFromResponse || 'disable',
      responseHeaders: specificState.responseHeaders || 'disable',
      responseBody: specificState.responseBody || 'disable',
    };
  };

  handleClickOpen = () => {
    const { errors, authType } = this.context.values;
    const { captureResponseDataError, defaultAssertErrors, captureDataError } = this.state;
    const basicAssertions = this.getBasicAssertions();
    const updateVariables = this.getUpdateVariable();
    const isWebserviceError = this.onCheckPayloadValidation(
      defaultAssertErrors,
      basicAssertions,
      captureResponseDataError,
      captureDataError,
      updateVariables,
      errors,
      authType
    );
    if (!isWebserviceError) {
      this.setState({ openModal: true });
    }
  };

  handleClickClose = () => {
    this.setState({ openModal: false });
  };

  getAllLocalVariables = (isFormReset = false) => {
    if (!this.isCdfrVariableUpdated && !isFormReset) {
      this.props.resetProgress();
      this.props.startOrResumeProgress({
        stopAt: 90,
      });
    }

    getAllLocalVariablesReq(this.moduleId, this.scriptId).then((response) => {
      const localVariableResponse = response.data.responseObject;
      if (localVariableResponse && localVariableResponse.length > 0) {
        this.setState({ localVariable: localVariableResponse });
        this.context.values.allContextLocalVariables = localVariableResponse;
      }
      this.getStepsUpdate(isFormReset);
    });
  };

  getProjectEnvironmentVariable = () => {
    getAllProjectVariableListReq().then((response) => {
      const projectEnvResponse = response.data.responseObject;
      if (projectEnvResponse && projectEnvResponse.length > 0) {
        this.setState({ projEnvironmentVariable: projectEnvResponse });
        this.context.values.allContextProjectEnvVariables = projectEnvResponse;
      }
      this.getStepGroupVariable();
    });
  };

  getRequestUrlParam = (requestUrlParam) => {
    const requestUrl = requestUrlParam.split('?');
    return requestUrl[1] ? `?${requestUrl[1]}` : requestUrl[1] === undefined ? '' : '?';
  };

  getUserStepsById = (varId) => {
    getStepsDataByHistoryId(varId).then((response) => {
      const stepsDataResponse = response.data.success.request;
      this.handleRequestData(stepsDataResponse, response);
      this.props.stopProgress();
      this.props.startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      this.setState({ isProgressLoding: false });
    });
  };

  getDataProviderVariable = (stepObj) => {
    const selectedDataProvider = [];
    stepObj?.script?.dataProvider?.map((scriptData) => {
      stepObj.dataProviderVariables.map((stepsData) => {
        if (scriptData?.name === stepsData.dpName) {
          selectedDataProvider.push({
            dpName: stepsData.dpName,
            varname: stepsData.varname,
            fileId: scriptData.fileId,
            sheetName: scriptData.sheetName,
            colName: stepsData.colName,
          });
        }
      });
    });
    return selectedDataProvider;
  };

  getForLoopVariable = (forVariableArray) => {
    const selectedForLoop = [];
    if (forVariableArray) {
      selectedForLoop.push({
        varName: forVariableArray?.name,
        varValue: forVariableArray?.value,
      });
    }
    this.setState({ forLoopVariables: selectedForLoop });
    return selectedForLoop;
  };

  getSingleWebserviceAuthReq = async (parentId) => {
    try {
      let response = await getSingleModuleReq(parentId);
      response = response.data.responseObject;
      if (response?.authorizationInfo?.inherited) {
        this.getSingleWebserviceAuthReq(response?.authorizationInfo?.parentId);
      } else {
        this.setState({ moduleName: response?.name });
      }
      this.setState({ parentAuthData: response });
    } catch (error) {
      console.error('SINGLE MODULE REQUEST API FAILED!', error);
    }
  };

  getWebserviceAuth = async () => {
    try {
      let response = null;
      let parentId = null;
      const id = this.scriptId ? this.scriptId : this.manualScriptId;
      response = await getScriptReq(this.moduleId, id);
      response = response.data.responseObject;
      parentId = response?.authorizationInfo?.parentId ? response?.authorizationInfo?.parentId : response?.parentId;
      if (parentId && response?.authorizationInfo?.inherited) {
        this.getSingleWebserviceAuthReq(parentId);
      } else {
        this.setState({ moduleName: response?.name });
        this.setState({ parentAuthData: response });
      }
    } catch (error) {
      console.error('SINGLE TREE MODULE REQUEST API FAILED!', error);
    }
  };

  handleCdfrUpdate = (key, value) => {
    this.setState((prevState) => ({
      cdfrTabState: {
        ...prevState.cdfrTabState,
        [key]: value,
      },
    }));
  };

  componentDidMount() {
    const { requestBodyType, rawContentType } = this.context.values;
    if (!isEmptyValue(this.context.values.preHistoryId)) {
      const { history } = this.props;
      const path = history.location.pathname;
      const parentId = history.location.state?.parentId;
      const updatedPath = `${path}?moduleId=${this.moduleId}&scriptId=${this.scriptId}&stepId=${this.context.values.preHistoryId}`;
      if (!path.includes('&stepId') && !parentId) {
        history.push(updatedPath);
      }
    } else {
      this.context.values.preHistoryId = this.stepId;
    }
    this.setState({ isProgressLoding: true });

    const Scriptadd = localStorage.getItem('Scriptadd');
    if (Scriptadd) {
      this.props.MyAlert.success(`${Scriptadd} script updated successfully`);
      localStorage.removeItem('Scriptadd');
    }

    this.getAllLocalVariables();
    const { location } = this.props;
    const allScripts = location?.state?.steps || location?.state?.script?.steps;
    if (allScripts?.length > 0) {
      const getSeletedSteps = allScripts.find((steps) => steps.stepId === this.stepId);
      const selectedStepsId = getSeletedSteps?.stepInputs[1]?.value;
      this.context.values.ifFailed = selectedStepsId;
    }
    if (this.props?.type === 'Webservice') {
      this.getWebserviceAuth();
    }
    this.setState({
      ...this.state,
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    });

    const stepsObj = location?.state;
    this.setState({ conditionalStepObject: stepsObj });
    const {
      executionOrder,
      marginLeft,
      parentSpecialNlpId = null,
      conditionId = null,
      isIterationStep = false,
      conditionSearchKey = null,
      values = {},
    } = stepsObj || 'unknown';
    const metaDataItrStep = {
      marginLeft: marginLeft,
      executionOrder: executionOrder,
      ...(conditionId && { conditionId: conditionId }),
      ...(conditionSearchKey && { conditionSearchKey: conditionSearchKey }),
    };
    if (isIterationStep) {
      metaDataItrStep.isIterationStep = stepsObj.isIterationStep;
    }

    if (!conditionId) {
      if (parentSpecialNlpId) {
        metaDataItrStep.parentSpecialNlpId = parentSpecialNlpId;
      } else if (stepsObj?.specialNlpId) {
        metaDataItrStep.parentSpecialNlpId = stepsObj.specialNlpId;
      }
    }

    this.setState(
      {
        metaDataofIterationStep: metaDataItrStep,
      },
      () => {
        if (marginLeft >= 0) {
          this.setState({
            optionalParam: {
              stepData: {
                metadata: this.state.metaDataofIterationStep,
                values: {},
              },
            },
          });
        }
      }
    );

    if (stepsObj?.dataProviderVariables) {
      const initialDataProvider = {
        stepData: {
          metadata: this.state.metaDataofIterationStep,
          values: {},
        },
      };
      const dataProviderVariableRes = this.getDataProviderVariable(stepsObj);
      this.setState({ dataProviderVariables: dataProviderVariableRes });
      this.setState({ optionalParam: initialDataProvider });
    }
    if (stepsObj && !isEmptyObject(stepsObj) && values?.forLoop) {
      this.setState({ optionalParamValueObj: values });
      this.getForLoopVariable(values?.forLoop[0]);
      let initialConditionalStepData = { stepData: { metadata: this.state.metaDataofIterationStep, values: {} } };
      this.setState({ optionalParam: initialConditionalStepData });
    }
    if (!isEmptyValue(this.context.values.selectedRequestTab)) {
      this.handleRequestTabChange(this.context.values.selectedRequestTab);
    }
    requestBodyType === 'raw' &&
      isEmptyValue(rawContentType) &&
      sessionStorage.setItem('arrayofContenttype', 'application/json');
  }

  componentDidUpdate(prevProps, prevState) {
    const { requestBodyType, rawData, formData, urlformData, authType } = this.context.values;
    if (prevProps.variableData !== this.props.variableData) {
      this.onSetAllVariableName(this.props.variableData, this.props.variableData?.type);
    }

    if (
      prevState.localVariable !== this.state.localVariable ||
      prevState.globalVariable !== this.state.globalVariable ||
      prevState.projEnvironmentVariable !== this.state.projEnvironmentVariable ||
      prevState.stepGroupVariable !== this.state.stepGroupVariable ||
      prevState.stepGroupParam !== this.state.stepGroupParam
    ) {
      this.context.values.allContextLocalVariables = this.state.localVariable;
      this.context.values.allContextGlobalVariables = this.state.globalVariable;
      this.context.values.allContextProjectEnvVariables = this.state.projEnvironmentVariable;
      const combineAllVariable = [
        ...this.state.localVariable,
        ...this.state.globalVariable,
        ...this.state.projEnvironmentVariable,
        ...this.state.dataProviderVariables,
        ...this.state.stepGroupVariable,
        ...this.state.stepGroupParam,
      ];
      const updateAllVariableName = {};
      combineAllVariable.map((value) => {
        if (
          value.type === 'LOCAL' &&
          (this.props?.type === 'Webservice' ||
            [ASSERT_CONSTANT.MODULE, ASSERT_CONSTANT.SCRIPT].includes(this.props?.label))
        ) {
          updateAllVariableName[`\${${value.id}}`] = `\${LV_${value.name}}`;
        } else if (value.type === 'GLOBAL') {
          updateAllVariableName[`\${${value.id}}`] = `\${GV_${value.name}}`;
        } else if (value.type === 'PROJECT_ENVIRONMENT') {
          updateAllVariableName[`\${${value.id}}`] = `\${PEV_${value.name}}`;
        } else {
          if (this.props?.type === 'Step Group') {
            if (ASSERT_CONSTANT.PARAMETER_TYPE.includes(value.type)) {
              updateAllVariableName[`\${${value._id}}`] = `\${SGP_${value.name}}`;
            } else {
              updateAllVariableName[`\${${value.id}}`] = `\${SGV_${value.name}}`;
            }
          }
        }
      });
      this.setState({ setAllVariableName: updateAllVariableName });
    }
    const isWebserviceData = Object.values(this.state.checkWebserviceData).includes(true);
    if (this.props?.type === 'Step Group') {
      this.props.createVariableModal(isWebserviceData);
    }
    if (this.props?.type === 'Webservice') {
      this.props.createVariableModal(isWebserviceData);
      const { requestUrl, requestType } = this.context.values;
      const { requestHeaders } = this.context.values;
      if (
        requestUrl ||
        requestType !== 'GET' ||
        requestHeaders.length > 8 ||
        requestHeaders[7]?.name ||
        requestHeaders[7]?.value ||
        requestHeaders[7]?.isEnabled ||
        (requestBodyType === 'raw' && rawData) ||
        (requestBodyType === 'form-data' &&
          (formData.length > 1 || formData[0]?.name || formData[0]?.value || formData[0]?.isEnabled)) ||
        (requestBodyType === 'x-www-form-urlencoded' &&
          (urlformData.length > 1 || urlformData[0]?.name || urlformData[0]?.value || urlformData[0]?.isEnabled)) ||
        (authType && authType !== 'No Auth')
      ) {
        this.props.handleCurlModal(false);
      } else {
        this.props.handleCurlModal(true);
      }
    }
    if (prevProps.isExportCurlOpen !== this.props.isExportCurlOpen) {
      if (this.props.isExportCurlOpen) {
        this.setState({ curlData: DEFAULT_EXPORT_CURL });
        this.CreateWebService();
      }
    }
    if (prevProps.recentVariableData !== this.props.recentVariableData) {
      if (Object.keys(this.props.recentVariableData).length > 0) {
        const { data, type, action } = this.props.recentVariableData;
        this.onSetAllVariableName(data, type, action);
      }
    }
    if (prevProps.isImportCurlOpen !== this.props.isImportCurlOpen) {
      if (this.props.isImportCurlOpen) {
        this.setState({ curlData: '' });
      }
    }
    if (prevState?.cdfrTabState !== this.state?.cdfrTabState) {
      this.onSelectRequestMethod('Capture Data From Response');
    }
  }

  getStepsUpdate = (ele = false) => {
    getAllGlobalVariableListReq(false).then((response) => {
      const globalVariableResponse = response.data.responseObject;
      if (globalVariableResponse && globalVariableResponse.length > 0) {
        this.setState({ globalVariable: globalVariableResponse });
        this.context.values.allContextGlobalVariables = globalVariableResponse;
      }
      this.getProjectEnvironmentVariable(ele);
    });
  };

  getParamValueCode = (code) => {
    let updatedCode = '';
    if (code && code.length > 0) {
      updatedCode = code.map((ele) => {
        return {
          ...ele,
          name: this.getUserInputValue(ele.name, false),
          value: this.getUserInputValue(ele.value, false),
        };
      });
    }
    this.setState({
      setParamValue: updatedCode,
    });
  };

  getParamValue = (code, data, action) => {
    let queryParamsObiValue = code.filter((rows) => {
      return rows.isEnabled === true;
    });
    let queryParamsObi = Object.values(queryParamsObiValue).map((item) => ({
      name: this.getUserInputValue(item.name, true, data, action) || '',
      value: this.getUserInputValue(item.value, true, data, action) || '',
    }));
    let modifyObject = (queryParamsObi) => {
      return queryParamsObi.reduce((acc, el) => {
        if (el.value?.length > 0) return acc + el.name + '=' + el.value + '&';
        else return acc + el.name + el.value + '&';
      }, '?');
    };
    let output = modifyObject(queryParamsObi);
    let outPrint = output.substring(0, output.length - 1);
    if (queryParamsObiValue[0]?.name === '' && queryParamsObiValue[0]?.value === '') {
      outPrint = '?';
    }
    this.setState({
      formatObjectToString: outPrint,
    });
    if (this.state.formatValueURLToTable !== '' || this.context.values.requestUrl !== '') {
      let testValue = '';
      let getRequestUrl = this.context.values.requestUrl.split('?');
      testValue = getRequestUrl[0] + outPrint;
      let testValueForUrlId =
        this.getUserInputValue(getRequestUrl[0], false) + this.getUserInputValue(outPrint, false, data, action);
      const isVariableExist = this.getUserInputValue(testValue, false, data, action);
      if (isVariableExist !== testValue) {
        this.context.setFieldValue('urlVariableId', testValueForUrlId);
      } else {
        this.context.values.urlVariableId = '';
      }
      this.context.setFieldValue('requestUrl', testValue);
    } else if (this.state.formatValueURLToTable === '') {
      let testValue = this.state.formatValueURLToTable + outPrint;
      this.context.setFieldValue('requestUrl', testValue);
    }
  };

  RequestURLappend(value) {
    this.context.setFieldValue('requestUrl', value);
  }

  getBasicAssertions = () => {
    const basicAssertionsPayload = [];

    Object.values(this.context.values.defaultAssert)
      .filter((v) => v.title)
      .map((items) => {
        items.values.map((item) => {
          item.isEnabled = items.isEnabled;
          basicAssertionsPayload.push(item);
        });
      });
    return basicAssertionsPayload;
  };

  onCheckAssertCodeSnippet = (assertCodeValue, customSnippetValue) => {
    if (ASSERT_CONSTANT.ONlOAD_CHANGE !== assertCodeValue) {
      return {
        program: {
          inputAttributes: {
            key1: 'abc',
            key2: 'efg',
          },
          codeSnippet: {
            code: assertCodeValue,
            customSnippetDefinitions: customSnippetValue,
          },
        },
      };
    } else {
      return {
        program: {
          inputAttributes: {
            key1: 'abc',
            key2: 'efg',
          },
        },
      };
    }
  };

  onCheckPreRequisiteCodeSnippet = (preRequisiteCodeValue, preCustomSnippetValue) => {
    if (PREREQUISITE_CONSTANT.ONlOAD_CHANGE !== preRequisiteCodeValue) {
      return {
        program: {
          inputAttributes: {
            key1: 'abc',
            key2: 'efg',
          },
          codeSnippet: {
            code: preRequisiteCodeValue,
            customSnippetDefinitions: preCustomSnippetValue,
          },
        },
      };
    } else {
      return {
        program: {
          inputAttributes: {
            key1: 'abc',
            key2: 'efg',
          },
        },
      };
    }
  };

  CreateWebService = async () => {
    const {
      requestUrl,
      requestName,
      requestType,
      errors,
      authType,
      urlVariableId,
      variableId,
      assertCodeValue,
      customSnippetValue,
      requestBodyType,
      preRequisiteCodeValue,
      preCustomSnippetValue,
      urlformData,
      rawData,
      requestHeaders,
    } = this.context.values;
    const { captureResponseDataError, setParamValue, defaultAssertErrors, captureDataError } = this.state;
    const basicAssertions = this.getBasicAssertions();
    const updateVariables = this.getUpdateVariable();
    const isWebserviceError = this.onCheckPayloadValidation(
      defaultAssertErrors,
      basicAssertions,
      captureResponseDataError,
      captureDataError,
      updateVariables,
      errors,
      authType
    );
    if (!isWebserviceError) {
      if (!this.props.isExportCurlOpen) {
        if (this.props?.type === 'Webservice') {
          this.props.createVariableModal(true);
        }

        if (requestUrl.includes('http') === true) {
          let value = requestUrl;
          this.RequestURLappend(value);
        }

        const webserviceErrors = await this.context.validateForm(this.context.values);
        if (webserviceErrors.requestUrl || webserviceErrors.requestName || webserviceErrors.requestType) {
          this.context.setErrors(webserviceErrors);
          this.context.setTouched(webserviceErrors);
          return;
        }
      }
      const postStep = this.onCheckAssertCodeSnippet(assertCodeValue, customSnippetValue);
      const preStep = this.onCheckPreRequisiteCodeSnippet(preRequisiteCodeValue, preCustomSnippetValue);
      const payload = {
        url: urlVariableId ? urlVariableId.trim() : requestUrl.trim(),
        name: variableId || requestName,
        method: requestType,
        authType: authType || ASSERT_CONSTANT.INHERIT_FROM_PARENT_TEXT,
        auth: this.getAuth(),
        requestBody: this.getRequestBodyData(),
        bodyType: requestBodyType,
        queryParams: Object.values(setParamValue).map((item) => ({
          name: item.name,
          value: item.value,
          isEnabled: item.isEnabled,
        })),
        headers: Object.values(requestHeaders).map((item, index) =>
          DISABLE_REQUEST_HEADER_INDEXES.includes(index)
            ? { name: item.name, value: item.value, isEnabled: !item.isEnabled }
            : { name: item.name, value: item.value, isEnabled: item.isEnabled }
        ),
        fileParamMap: {},
        urlEncoded: requestBodyType === 'x-www-form-urlencoded' ? urlformData : [],
        postStep,
        preStep,
        basicAssertions: basicAssertions,
        updateVariables: updateVariables,
        settings: this.context?.values?.settings,
      };
      let formData = new FormData();
      let FileSizeExcced = false;
      if (this.context.values.requestBodyType === 'form-data') {
        let map = new Array();
        map = this.context.values.formData;

        let fileParamMapValue = new Map();
        if (!(map.length === 1 && map.at(0).value === undefined)) {
          map.forEach(function (input) {
            let fileName = {
              isEnabled: input.isEnabled,
              isModified: false,
              name: '',
              id: '',
              folderId: '',
              value: input.value,
              type: input.type,
            };

            //set default type to text.
            if (!input?.type) {
              fileName['type'] = 'text';
            }

            let fileNames = new Array();
            if (fileParamMapValue.has(input.name)) {
              fileNames = fileParamMapValue.get(input.name);
            }

            if (input.type === 'file') {
              fileName.name = input.value?.name || input.value;
              fileName.value = '';
              fileName.id = input?.value?._id || input?.id || '';
              fileName.folderId = input?.value?.parentId || input?.folderId || '';
              fileName.isModified = input.value?.actualPath ? false : input.isModified;
              fileNames.push(fileName);
              fileParamMapValue.set(input.name, fileNames);
              const fileSize = input?.value?.size / 1024;
              if (fileSize > 1024 * 500) {
                {
                  FileSizeExcced = true;
                }
              } else {
                FileSizeExcced = false;
              }
              if (typeof input.value === 'object' && !input?.value.parentId) {
                formData.append(input.name, input.value);
              }
            } else {
              fileNames.push(fileName);
              fileParamMapValue.set(input.name, fileNames);
            }
          });

          if (FileSizeExcced === true) {
            this.props.MyAlert.alertbanner('Payload is too large.Please check the payload.');
            this.setState({
              updateData: false,
              isLoading: false,
            });
            this.context.values.setResponse = {
              ...this.context.values.setResponse,
              updateData: false,
              isLoading: false,
            };
          }

          payload.fileParamMap = Object.fromEntries(fileParamMapValue);
        }
      }
      const stringifyPayload = JSON.stringify(payload);
      if (stringifyPayload.includes(FOR_LOOP_DISPLAY_VALUE)) {
        const updatedInitialConditionalStepData = {
          stepData: {
            metadata: { ...this.state.metaDataofIterationStep },
            values: this.state.optionalParamValueObj,
          },
        };
        this.updateForLoopName(updatedInitialConditionalStepData.stepData);
        await this.setState({ optionalParam: updatedInitialConditionalStepData });
      }
      if (this.state.dataProviderVariables.length > 0 && !this.state.conditionalStepObject?.stepData?.values?.forLoop) {
        const updatedDataProviderData = this.onHandleDataProvider(stringifyPayload);
        updatedDataProviderData.stepData.metadata = this.state.metaDataofIterationStep;
        await this.setState({ optionalParam: updatedDataProviderData });
      }
      formData.append('optionalParam', JSON.stringify(this.state.optionalParam));
      formData.append('body', JSON.stringify(payload));
      const parentAndSonJson = JSON.parse(localStorage.getItem('parentAndSonJson'));
      let scriptName = parentAndSonJson[parentAndSonJson?.length - 1]?.Name;
      scriptName = scriptName.substring(scriptName.indexOf(' - ') + 3);
      const selctedProjectData = JSON.parse(localStorage.getItem('selected-project'));
      const selectedProjectId = selctedProjectData.id;
      if (FileSizeExcced === false) {
        if (this.props.isExportCurlOpen) {
          this.setState({ curlModal: true });
          try {
            const response = await createCurlReq(
              formData,
              selectedProjectId,
              this.moduleId,
              this.scriptId,
              WEBSERVICE_TITLE
            );
            if (response.data.success) {
              this.setState({ curlData: response.data.success });
            }
          } catch (err) {
            let curlError = err?.message || err;
            this.setState({ curlData: curlError });
          }
        } else {
          const isStepGroupParamExist = this.onCheckStepGroupParam(stringifyPayload);
          if (!isStepGroupParamExist) {
            if (requestUrl !== '' && requestName !== '' && Object.keys(this.context.values.errors || {}).length === 0) {
              this.setState({
                updateData: true,
                isLoading: true,
              });
              this.context.values.setResponse = {
                ...this.context.values.setResponse,
                updateData: true,
                isLoading: true,
              };
            } else if (requestUrl !== '' && requestName !== '' && authType === 'No Auth') {
              this.setState({
                updateData: true,
                isLoading: true,
              });
              this.context.values.setResponse = {
                ...this.context.values.setResponse,
                updateData: true,
                isLoading: true,
              };
            } else {
              this.setState({
                updateData: false,
                isLoading: false,
                responseHeadersLenValue: 0,
                responseAssertionResultsLen: 0,
                assertionResultValueLen: 0,
                apiTime: 0,
                statusCode: '',
                statusCodeValue: '',
                capturedDataFromResponseLenValue: 0,
              });
              this.context.values.setResponse = {
                ...this.context.values.setResponse,
                updateData: false,
                isLoading: false,
                apiTime: 0,
                statusCode: '',
                statusCodeValue: '',
              };
            }
            const headers =
              this.props?.type === 'Webservice'
                ? {
                    type: 'formdata',
                    projectId: selectedProjectId,
                    scriptId: this.scriptId,
                    moduleId: this.moduleId,
                    scriptName: scriptName,
                    Sectionname: 'Web Service',
                  }
                : {
                    type: 'formdata',
                    projectId: selectedProjectId,
                    stepGroupId: this.stepGroupId,
                    libraryId: this.libraryId,
                    stepGroupName: this.stepGroupName,
                    Sectionname: 'Web Service',
                  };
            postWebServiceAddApi(formData, headers)
              .then((resp) => {
                this.setState({
                  blobFileSize: resp.data.success.headers.find((name) => name.name === 'Content-Length')?.value,
                });

                this.setState({
                  isLoading: false,
                });
                this.context.values.setResponse = {
                  ...this.context.values.setResponse,
                  isLoading: false,
                };
                const {
                  responseAssertionResults,
                  headers,
                  assertionResult,
                  postStepResult,
                  responseBody,
                  updatedVariables,
                  metaData,
                } = resp.data.success;
                let responseAssertionResultsValue = responseAssertionResults || [];
                let responseAssertionResultsLen = responseAssertionResultsValue?.length;
                let responseHeadersLen = headers;
                let responseValue = resp.data.success;
                let status = assertionResult.status;
                let assertionResultValue = postStepResult;
                let assertionResultValueLen = assertionResultValue?.assertionResult?.assertionResults?.length || 0;
                let len2 = responseHeadersLen?.length;
                let statusCode = resp.data.success.statusCode;
                let statusCodeValue = resp.data.success.statusCodeValue;
                let apiTime = metaData.apiTime;
                let setresponseBodyValueWithoutParse = '';
                let setresponseBodyValueWithoutParseError = '';
                if ([200, 201].includes(statusCode)) {
                  setresponseBodyValueWithoutParse = responseBody;
                } else {
                  setresponseBodyValueWithoutParseError = responseBody;
                }

                if (statusCode >= 100 && this.state.updateData) {
                  this.handleSaveAsSteps(false);
                }
                this.onUpdateResponseTab(headers);
                if (
                  setresponseBodyValueWithoutParse !== '' ||
                  setresponseBodyValueWithoutParseError !== '' ||
                  !setresponseBodyValueWithoutParseError
                ) {
                  this.setState({
                    responseHeadersValue: headers,
                    responseValue,
                    responseBodyValue: setresponseBodyValueWithoutParse,
                    responseBodyValueWithoutParse: setresponseBodyValueWithoutParse,
                    responseHeadersLenValue: len2,
                    responseAssertionResultsValue,
                    responseAssertionResultsLen,
                    assertionResultValue,
                    assertionResultValueLen,
                    statusCode: statusCode,
                    statusCodeValue: statusCodeValue,
                    status: status,
                    apiTime: apiTime,
                    updateData: true,
                    updateResponseBody: true,
                    responseBodyValueWithoutParseError: setresponseBodyValueWithoutParseError,
                    capturedDataFromResponseValue: updatedVariables,
                    capturedDataFromResponseLenValue: updatedVariables.length,
                    historyId: resp.data.success.id,
                  });
                  this.context.values.setResponse = {
                    ...this.context.values.setResponse,
                    status: status,
                    apiTime: apiTime,
                    statusCodeValue: statusCodeValue,
                    responseBodyValueWithoutParse: setresponseBodyValueWithoutParse,
                    responseBodyValueWithoutParseError: setresponseBodyValueWithoutParseError,
                    responseHeadersValue: headers,
                    responseAssertionResultsValue,
                    assertionResultValue,
                    capturedDataFromResponseValue: updatedVariables,
                    updateResponseBody: true,
                    historyId: resp.data.success.id,
                  };
                } else if (setresponseBodyValueWithoutParse === '' || setresponseBodyValueWithoutParseError === '') {
                  this.setState({
                    responseHeadersValue: headers,
                    responseValue,
                    responseBodyValue: '',
                    responseBodyValueWithoutParse: '',
                    responseHeadersLenValue: len2,
                    responseAssertionResultsValue,
                    responseAssertionResultsLen,
                    assertionResultValue,
                    assertionResultValueLen,
                    statusCode: statusCode,
                    statusCodeValue: statusCodeValue,
                    status: status,
                    apiTime: apiTime,
                    updateData: true,
                    updateResponseBody: true,
                    setresponseBodyValueWithoutParseError: '',
                    capturedDataFromResponseValue: updatedVariables,
                    capturedDataFromResponseLenValue: updatedVariables.length,
                    historyId: resp.data.success.id,
                  });
                  this.context.values.setResponse = {
                    ...this.context.values.setResponse,
                    status: status,
                    apiTime: apiTime,
                    statusCodeValue: statusCodeValue,
                    responseBodyValueWithoutParse: '',
                    responseBodyValueWithoutParseError: '',
                    capturedDataFromResponseValue: updatedVariables,
                    responseHeadersValue: headers,
                    responseAssertionResultsValue,
                    assertionResultValue,
                    updateResponseBody: true,
                    historyId: resp.data.success.id,
                  };
                }
                this.isCdfrApiCall(statusCode, apiTime, CAPTURE_GET_STATUS_CODE, CAPTURE_GET_RESPONSE_TIME);
              })
              .catch((error) => {});
          } else {
            this.setState({ showWebserviceModal: true });
          }
        }
      }
      this.toggleAccordionResponse();
    }
    if (this.state.conditionalStepObject?.marginLeft) {
      await this.setState({
        optionalParam: {
          stepData: {
            metadata: { ...this.state.metaDataofIterationStep },
            values: {},
          },
        },
      });
    }

    if (typeof this.props?.setExportCurlOpen === 'function') {
      this.props?.setExportCurlOpen(false);
    }
  };

  CreateWebServiceStepNLP = (values) =>
    new Promise(async (resolve, reject) => {
      const { assertCodeValue, preRequisiteCodeValue, rawData, requestHeaders } = this.context.values;
      this.setState({
        isLoadingNlp: true,
      });
      const postStep = this.onCheckAssertCodeSnippet(
        this.context.values.assertCodeValue,
        this.context.values.customSnippetValue
      );
      const preStep = this.onCheckPreRequisiteCodeSnippet(
        this.context.values.preRequisiteCodeValue,
        this.context.values.preCustomSnippetValue
      );
      const historyId = this.context.values.setResponse?.historyId || this.state.historyId;
      const payload = {
        id: historyId,
        url: this.context.values.urlVariableId
          ? this.context.values.urlVariableId.trim()
          : this.context.values.requestUrl.trim(),
        name: this.context.values.variableId || this.context.values.requestName,
        method: this.context.values.requestType,
        authType: this.context.values.authType || ASSERT_CONSTANT.INHERIT_FROM_PARENT_TEXT,
        auth: this.getAuth(),
        requestBody: this.getRequestBodyData(),
        bodyType: this.context.values.requestBodyType,
        queryParams: Object.values(this.state.setParamValue).map((item) => ({
          name: item.name,
          value: item.value,
          isEnabled: item.isEnabled,
        })),
        headers: Object.values(requestHeaders).map((item, index) =>
          DISABLE_REQUEST_HEADER_INDEXES.includes(index)
            ? { name: item.name, value: item.value, isEnabled: !item.isEnabled }
            : { name: item.name, value: item.value, isEnabled: item.isEnabled }
        ),
        fileParamMap: {},
        urlEncoded:
          this.context.values.requestBodyType === 'x-www-form-urlencoded' ? this.context.values.urlformData : [],
        postStep,
        preStep,
        basicAssertions: this.getBasicAssertions(),
        updateVariables: this.getUpdateVariable(),
        settings: this.context?.values?.settings,
      };
      let formData = new FormData();

      if (this.context.values.requestBodyType === 'form-data') {
        let map = new Array();
        let FileSizeExcced = false;
        map = this.context.values.formData;

        let fileParamMapValue = new Map();
        if (!(map.length === 1 && map.at(0).value === undefined)) {
          map.forEach(function (input) {
            let fileName = {
              isEnabled: input.isEnabled,
              isModified: false,
              name: '',
              id: '',
              folderId: '',
              value: input.value,
              type: input.type,
            };

            if (!input?.type) {
              fileName['type'] = 'text';
            }
            let fileNames = new Array();
            if (fileParamMapValue.has(input.name)) {
              fileNames = fileParamMapValue.get(input.name);
            }

            if (input.type === 'file') {
              fileName.name = input.value?.name || input.value;
              fileName.value = '';
              fileName.id = input?.value?._id || input?.id || '';
              fileName.folderId = input?.value?.parentId || input?.folderId || '';
              fileName.isModified = input.value?.actualPath ? false : input.isModified;
              fileNames.push(fileName);
              fileParamMapValue.set(input.name, fileNames);
              const fileSize = input?.value?.size / 1024;
              if (fileSize > 1024 * 500) {
                FileSizeExcced = true;
              } else {
                FileSizeExcced = false;
              }
              if (typeof input.value === 'object' && !input?.value.parentId) {
                formData.append(input.name, input.value);
              }
            } else {
              fileNames.push(fileName);
              fileParamMapValue.set(input.name, fileNames);
            }
          });
          payload.fileParamMap = Object.fromEntries(fileParamMapValue);
        }
      }
      const stringifyPayload = JSON.stringify(payload);
      if (stringifyPayload.includes(FOR_LOOP_DISPLAY_VALUE)) {
        const updatedInitialConditionalStepData = {
          stepData: {
            metadata: { ...this.state.metaDataofIterationStep },
            values: this.state.optionalParamValueObj,
          },
        };
        this.updateForLoopName(updatedInitialConditionalStepData.stepData);
        await this.setState({ optionalParam: updatedInitialConditionalStepData });
      }
      let dataProvider_check = true;
      if (this.state.dataProviderVariables.length > 0) {
        dataProvider_check = false;
        let updatedDataProviderData = this.onHandleDataProvider(stringifyPayload);
        updatedDataProviderData.stepData.metadata = this.state.metaDataofIterationStep;
        formData.append('optionalParam', JSON.stringify(updatedDataProviderData));
      }
      if (dataProvider_check) {
        formData.append('optionalParam', JSON.stringify(this.state.optionalParam));
      }
      formData.append('stepInputs', JSON.stringify(values || {}));
      formData.append('body', JSON.stringify(payload));
      let project = JSON.parse(localStorage.getItem('selected-project'));
      const headers =
        this.props?.type === 'Webservice'
          ? {
              type: 'formdata',
              projectId: project.id,
              scriptId: this.scriptId,
              moduleId: this.moduleId,
              Sectionname: 'Web Service',
            }
          : {
              type: 'formdata',
              projectId: project.id,
              stepGroupId: this.stepGroupId,
              libraryId: this.libraryId,
              Sectionname: 'Web Service',
            };
      const moduleId = this.props?.type === 'Webservice' ? this.moduleId : this.libraryId;
      const scriptId = this.props?.type === 'Webservice' ? this.scriptId : this.stepGroupId;
      postWebServiceStepNLP(formData, project.id, moduleId, scriptId, headers, this.props?.type)
        .then((resp) => {
          resolve(resp);
          this.setState({
            isLoadingNlp: true,
          });
        })
        .catch((error) => {
          reject();
        });
      if (this.state.conditionalStepObject?.marginLeft) {
        await this.setState({
          optionalParam: {
            stepData: {
              metadata: { ...this.state.metaDataofIterationStep },
              values: {},
            },
          },
        });
      }
    });

  getUpdateVariable = () => {
    const value = [];
    function isValidData(variable, isEnabled, variableGroup) {
      const hasValidReturnValue = !isEmptyValue(variable?.returnValue);
      const hasValidName = ![undefined, null, '', 'Header Key', 'JSON path'].includes(variable?.name);
      const hasValidLHS = ![
        '',
        'responseBody',
        "(headers[?name==''].value)[0]",
        "(headers[?name=='Content-Type'].value)[0]",
      ].includes(variable?.lhs);
      return isEnabled || hasValidReturnValue || hasValidName || hasValidLHS || variableGroup;
    }
    Object.values(this.context.values.captureResponse)
      .filter((variables) => variables.title)
      .map((variableGroup) => {
        variableGroup.values.map((variable) => {
          let objValue = {};
          const conditionChecked = isValidData(variable, variableGroup.isEnabled, variableGroup);
          if (conditionChecked) {
            for (const variableData in variable) {
              if (variable[variableData] && typeof variable[variableData] === 'object') {
                objValue = {
                  ...objValue,
                  ...variable[variableData],
                  ...variable[variableData].isEnabled,
                  isEnabled: variableGroup.isEnabled,
                  fieldName: variable?.name,
                };
              } else {
                objValue[variableData] = variable[variableData];
              }
            }
            value.push(objValue);
          }
        });
      });
    return value.reduce(
      (acc, crr) => [
        ...acc,
        {
          variable: {
            id: crr.id,
            name: crr.name,
            type: crr.type,
            projectId: crr.projectId,
            parentVariableType: crr.parentVariableType,
            parentVariableId: crr.parentVariableId,
            subParentVariableId: crr.subParentVariableId,
            masked: crr.masked,
            isSystemVariable: crr.isSystemVariable,
          },
          valueType: { path: crr.lhs || crr.title },
          group: crr.group,
          isEnabled: crr.isEnabled,
          name: crr?.fieldName,
        },
      ],
      []
    );
  };

  getRequestBodyData = () => {
    switch (this.context.values.requestBodyType) {
      case 'raw':
        return this.context.values.rawData;
      default:
        return null;
    }
  };

  getAuth = () => {
    switch (this.context.values.authType) {
      case 'No Auth':
        return this.context.values.noAuthTypeData;
      case 'Basic':
        return this.context.values.basicAuthData;
      case 'BearerToken':
        return this.context.values.tokenVariableId
          ? { token: this.context.values.tokenVariableId }
          : this.context.values.bearerTokenData;
      case 'Oauth1':
        return this.context.values.oauth1Data;
      case 'Oauth2':
        return this.context.values.oauth2Data;
      case 'Digest':
        return this.context.values.digestAuthData;
      case 'AWS':
        return this.context.values.awsAuthData;
      case 'Hawk':
        return this.context.values.hawkAuthData;
      case 'NTLM':
        return this.context.values.microsoftNtlmAuthData;
      case 'JWTBearer':
        return this.context.values.jwtAuth;
      case 'APIKey':
        return this.context.values.apiKey;
      default:
        return this.context.values.noAuthTypeData;
    }
  };

  toggleAccordionRequest = () => {
    this.setState({ setIsDownArrowRequest: 'hide-request-tab', setIsUpArrowRequest: 'show-request-tab' });
    this.context.values.setResponse = {
      ...this.context.values.setResponse,
      setIsDownArrowRequest: 'hide-request-tab',
      setIsUpArrowRequest: 'show-request-tab',
    };
  };

  toggleAccordionInsideRequest = () => {
    this.setState({ setIsUpArrowRequest: 'hide-request-tab', setIsDownArrowRequest: 'show-request-tab' });
    this.context.values.setResponse = {
      ...this.context.values.setResponse,
      setIsDownArrowRequest: 'show-request-tab',
      setIsUpArrowRequest: 'hide-request-tab',
    };
  };

  toggleAccordionResponse = () => {
    this.setState({
      setIsUpArrowResponse: 'activeQuery',
    });
    this.context.values.setResponse = {
      ...this.context.values.setResponse,
      setIsUpArrowResponse: 'activeQuery',
    };
  };

  toggleAccordionInsideResponse = () => {
    this.setState({
      setIsUpArrowResponse: '',
    });
    this.context.values.setResponse = {
      ...this.context.values.setResponse,
      setIsUpArrowResponse: '',
    };
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleCaptureDataResponseError = (name, status, mode) => {
    if (name) {
      const updateCaptureResponseError = this.state.captureResponseDataError;
      const _captureDataError = this.state.captureDataError;
      if (mode === 'delete') {
        const _keys = Object.keys(updateCaptureResponseError);
        if (_keys.length) {
          _keys.forEach((_key) => {
            if (_key === name) {
              delete updateCaptureResponseError[_key];
            }
          });
        }
      } else {
        updateCaptureResponseError[name] = status;
      }
      this.setState({ captureResponseDataError: updateCaptureResponseError, captureDataError: _captureDataError });
    }
  };

  onRequestUrlFocus = (value) => {
    this.setState({ isRequestUrlFocus: value });
  };

  handleSaveAsSteps = (status) => {
    if (this.state.updateData) {
      this.setState({ setSaveAsEnable: status });
    }
    this.setState({ historyId: '' });
  };

  onSetValueByType = (name, type, data) => {
    if (['LOCAL', 'Step'].includes(type) && data?.parentVariableType === 'STEPGROUP') {
      return '${SGV_' + name + '}';
    } else if (type === 'LOCAL') {
      return '${LV_' + name + '}';
    } else if (type === 'GLOBAL') {
      return '${GV_' + name + '}';
    } else if (type === 'PROJECT_ENVIRONMENT') {
      return '${PEV_' + name + '}';
    } else if (ASSERT_CONSTANT.PARAMETER_TYPE.includes(type)) {
      return '${SGP_' + name + '}';
    } else if (name === FOR_LOOP_INITIAL_VALUE) {
      return '${FLV_' + name + '}';
    } else {
      return '${DPV_' + name + '}';
    }
  };

  handleDragStart = (event) => {
    event.preventDefault();
  };

  onSettingsConfigureChanged = () => {
    const {
      initialValues,
      values: { settings },
    } = this.context;
    const {
      tlsCertificateEnabled,
      requestTimeOut,
      maxNumberOfRedirect,
      redirectEnabled,
      originalHttpMethodEnabled,
      retainAuthorizationHeaderEnabled,
      tlsVersions,
    } = initialValues?.settings;
    return (
      tlsCertificateEnabled !== settings?.tlsCertificateEnabled ||
      requestTimeOut.time !== settings?.requestTimeOut.time ||
      requestTimeOut.unit?.toLowerCase() !== settings?.requestTimeOut.unit?.toLowerCase() ||
      maxNumberOfRedirect !== settings?.maxNumberOfRedirect ||
      redirectEnabled !== settings.redirectEnabled ||
      originalHttpMethodEnabled !== settings.originalHttpMethodEnabled ||
      retainAuthorizationHeaderEnabled !== settings.retainAuthorizationHeaderEnabled ||
      tlsVersions.some((tls, index) => tls.isEnabled !== settings.tlsVersions[index].isEnabled)
    );
  };

  onSelectRequestMethod = (value) => {
    const defaultAssert = this.getBasicAssertions();
    const captureResponse = this.getUpdateVariable();
    const { statusCode, respTime, respBody, getHeader, jsonPath, headerKey, contentType } = this.state.cdfrTabState;
    const allValuesExist = [statusCode, respTime, respBody, getHeader, jsonPath, headerKey, contentType].some(
      (value) => !["(headers[?name==''].value)[0]", 'Header Key', 'JSON path', 'responseBody', ''].includes(value)
    );
    const { setParamValue } = this.state;
    const { requestBodyType, rawData, formData, urlformData, preRequisiteCodeValue, assertCodeValue, requestHeaders } =
      this.context.values;
    let isModified = value;
    switch (value) {
      case 'Query Parameter':
        if (
          setParamValue.length > 1 ||
          setParamValue[0]?.name ||
          setParamValue[0]?.value ||
          setParamValue[0]?.isEnabled
        ) {
          this.modifiedCheckWebserviceData['queryParam'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['queryParam'] = false;
        }
        break;
      case 'Request Header':
        if (
          requestHeaders.length > 8 ||
          this.context.values?.currentRequestKey?.target?.value ||
          this.context.values?.currentRequestValue?.target?.value ||
          requestHeaders[7]?.isEnabled ||
          !requestHeaders[6]?.isEnabled ||
          !requestHeaders[2]?.isEnabled
        ) {
          this.modifiedCheckWebserviceData['requestHeader'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['requestHeader'] = false;
        }
        break;

      case 'Request Body':
        if (
          (requestBodyType === 'raw' && rawData?.value) ||
          (requestBodyType === 'form-data' &&
            (formData.length > 1 || formData[0]?.name || formData[0]?.value || formData[0]?.isEnabled)) ||
          (requestBodyType === 'x-www-form-urlencoded' &&
            (urlformData.length > 1 || urlformData[0]?.name || urlformData[0]?.value || urlformData[0]?.isEnabled))
        ) {
          this.modifiedCheckWebserviceData['requestBody'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['requestBody'] = false;
        }
        break;

      case 'Authorization':
        if (this.context.values.authType && this.context.values.authType !== 'Inherit from Parent') {
          this.modifiedCheckWebserviceData['authorization'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['authorization'] = false;
        }
        break;

      case 'Pre Requisite':
        if (PREREQUISITE_CONSTANT.ONlOAD_CHANGE !== preRequisiteCodeValue && !isEmptyValue(preRequisiteCodeValue)) {
          this.modifiedCheckWebserviceData['prerequisite'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['prerequisite'] = false;
        }
        break;

      case 'Assert':
        if (defaultAssert.length > 0 || ASSERT_CONSTANT.ONlOAD_CHANGE !== assertCodeValue) {
          this.modifiedCheckWebserviceData['assert'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['assert'] = false;
        }
        break;

      case 'Capture Data From Response':
        if (captureResponse.length > 0 || allValuesExist) {
          this.modifiedCheckWebserviceData['captureResponseData'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['captureResponseData'] = false;
        }
        break;

      case 'Settings':
        if (this.onSettingsConfigureChanged()) {
          this.modifiedCheckWebserviceData['settings'] = true;
          isModified = (
            <>
              {value}
              <span className="selected-method pl-2">
                <CircleIcon className="captcha-response-circle-icon" />
              </span>
            </>
          );
        } else {
          this.modifiedCheckWebserviceData['settings'] = false;
        }
        break;

      default:
        isModified = value;
        break;
    }
    return isModified;
  };

  handleCopyClick = () => {
    if (this.state.isCopied) {
      this.setState({ isCopied: false });
    } else {
      this.setState({ isCopied: true });
      this.copyTimeout = setTimeout(() => {
        this.setState({ isCopied: false });
      }, 3000);
    }
  };

  onUpdateResponseTab = (headers) => {
    let projectTypeList = headers;
    let HTMLContentTypeApplication = projectTypeList
      ? projectTypeList.filter((rows) => {
          return (
            rows.name.includes(CONTENT_TYPE_RESPONSE_HEADERS) &&
            (rows.value.includes(APPLICATION_HTML_WITH_CHARSET) ||
              rows.value.includes(APPLICATION_HTML_WITHOUT_CHARSET) ||
              rows.value.includes(TEXT_HTML_WITH_CHARSET) ||
              rows.value.includes(TEXT_HTML_WITHOUT_CHARSET))
          );
        })
      : '';
    if (HTMLContentTypeApplication.length > 0) {
      this.setState({
        HTMLContentTypeLen: 1,
      });
      this.context.values.setResponse = {
        ...this.context.values.setResponse,
        HTMLContentTypeLen: 1,
      };
    } else if (HTMLContentTypeApplication.length === 0) {
      this.setState({
        HTMLContentTypeLen: 0,
      });
      this.context.values.setResponse = {
        ...this.context.values.setResponse,
        HTMLContentTypeLen: 0,
      };
    }

    let JsonContentTypeApplication = projectTypeList
      ? projectTypeList.filter((rows) => {
          return (
            rows.name.includes(CONTENT_TYPE_RESPONSE_HEADERS) &&
            (rows.value.includes(APPLICATION_JSON_WITH_CHARSET) ||
              rows.value.includes(APPLICATION_JSON_WITHOUT_CHARSET) ||
              rows.value.includes(TEXT_JSON_WITH_CHARSET) ||
              rows.value.includes(TEXT_JSON_WITHOUT_CHARSET))
          );
        })
      : '';
    if (JsonContentTypeApplication.length > 0) {
      this.setState({
        JsonContentTypeLen: 1,
      });
      this.context.values.setResponse = {
        ...this.context.values.setResponse,
        JsonContentTypeLen: 1,
      };
    } else if (JsonContentTypeApplication.length === 0) {
      this.setState({
        JsonContentTypeLen: 0,
      });
      this.context.values.setResponse = {
        ...this.context.values.setResponse,
        JsonContentTypeLen: 0,
      };
    }
    let JsonContentTypeXML = projectTypeList
      ? projectTypeList.filter((rows) => {
          return (
            rows.name.includes(CONTENT_TYPE_RESPONSE_HEADERS) &&
            (rows.value.includes(APPLICATION_XML_WITH_CHARSET) ||
              rows.value.includes(APPLICATION_XML_WITHOUT_CHARSET) ||
              rows.value.includes(TEXT_XML_WITHOUT_CHARSET) ||
              rows.value.includes(TEXT_XML_WITH_CHARSET))
          );
        })
      : '';
    if (JsonContentTypeXML.length > 0) {
      this.setState({
        XMLContentTypeLen: 1,
      });
      this.context.values.setResponse = {
        ...this.context.values.setResponse,
        XMLContentTypeLen: 1,
      };
    } else if (JsonContentTypeXML.length === 0) {
      this.setState({
        XMLContentTypeLen: 0,
      });
      this.context.values.setResponse = {
        ...this.context.values.setResponse,
        XMLContentTypeLen: 0,
      };
    }
  };

  project = JSON.parse(localStorage.getItem('selected-project'));
  onValidateRequestName = () => {
    if (!this.context.values.requestName) {
      this.setState({
        requestNameErr: REQUEST_NAME_ERROR,
      });
    }
  };
  onValidateRequestUrl = (value) => {
    if (!this.context.values.requestUrl) {
      this.setState({
        requestUrlErr: REQUEST_URL_ERROR,
      });
    }
  };

  validateNameField = (values, fieldValue, index, mode) => {
    const fieldName = this.createAssertFieldName(values[index], index);
    if (mode) {
      const tempDefaultAssertError = {};
      values.map((assertRow, assertRowIndex) => {
        const fieldName = this.createAssertFieldName(assertRow, assertRowIndex);
        let value = assertRow.name;
        const error = this.getErrorMessage(value);
        tempDefaultAssertError[fieldName] = error;
      });
      this.setState({ defaultAssertErrors: tempDefaultAssertError });
    } else {
      const value = fieldValue;
      const error = this.getErrorMessage(value);
      this.setState((prevState) => ({
        defaultAssertErrors: {
          ...prevState.defaultAssertErrors,
          [fieldName]: error,
        },
      }));
    }
  };

  createCaptureFieldName = (captureRow, captureRowIndex) => {
    let fieldName = '';
    if (captureRow?.lhs?.startsWith('responseBody')) {
      fieldName = 'jsonPathName' + captureRowIndex;
    } else {
      fieldName = 'headerKeyName' + captureRowIndex;
    }
    return fieldName;
  };

  validateCaptureDataFields = (values, fieldValue, index, mode) => {
    const fieldName = this.createCaptureFieldName(values[index], index);
    if (mode === 'delete') {
      const tempCaptureDataError = {};
      values.map((captureRow, captureRowIndex) => {
        const fieldName = this.createCaptureFieldName(captureRow, captureRowIndex);
        let value = captureRow.name;
        const error = this.getErrorMessage(value);
        tempCaptureDataError[fieldName] = error;
      });
      this.setState({ captureDataError: tempCaptureDataError });
    } else {
      const value = fieldValue;
      const error = this.getErrorMessage(value);
      this.setState((prevState) => ({
        captureDataError: {
          ...prevState.captureDataError,
          [fieldName]: error,
        },
      }));
    }
  };

  createAssertFieldName = (assertRow, assertRowIndex) => {
    let fieldName = '';
    if (['Status Code', 'Status Message'].includes(assertRow?.group)) {
      fieldName = assertRow?.group.split(' ').join('') + 'Name';
    } else {
      fieldName = assertRow?.group.split(' ').join('') + assertRowIndex + 'Name';
    }
    return fieldName;
  };

  getErrorMessage = (value) => {
    let error = '';
    if (!value) {
      error = VALIDATION_REQUEST_NAME;
    } else if (value?.startsWith(' ') || value.endsWith(' ')) {
      error = VALIDATION_REQUEST_NAME_WHITESPACE;
    } else if (/[^a-zA-Z0-9 _-]/.test(value)) {
      error = VALIDATION_REQUEST_NAME_ALPHANUMERIC;
    } else if (value.length < 3) {
      error = VALIDATION_REQUEST_NAME_MIN_LENGTH;
    } else if (value.length > 300) {
      error = VALIDATION_REQUEST_NAME_MAX_LENGTH;
    }
    return error;
  };

  onCheckPayloadValidation = (
    defaultAssertErrors,
    basicAssertions,
    captureResponseDataError,
    captureDataError,
    updateVariables,
    errors,
    authType
  ) => {
    let isCaptureDataError,
      isCaptureResponseDataErr,
      isUpdateVariable,
      isDefaultAssertErrors,
      isBasicAssertion,
      isAuthError = false;
    if (!this.context.values.requestName) {
      this.setState({
        requestNameErr: REQUEST_NAME_ERROR,
      });
    }

    if (!this.context.values.requestUrl) {
      this.setState({
        requestUrlErr: 'Enter Request Url',
      });
    }

    if (Object.keys(defaultAssertErrors).length > 0) {
      isDefaultAssertErrors = Object.values(defaultAssertErrors).some((value) => value);
      const defaultAssertErrorsKeys = Object.keys(defaultAssertErrors)
        .filter((key) => defaultAssertErrors[key] !== '')
        .map((key) => key.replace(/(Name)/g, '').trim());

      const isEnabledAsserts = basicAssertions
        .filter((value) => value.isEnabled)
        .map((value) => value.group.replace(/\s/g, ''));

      isBasicAssertion = isEnabledAsserts.some((value) =>
        defaultAssertErrorsKeys.some((newValue) => newValue.includes(value))
      );
      if (isBasicAssertion) {
        this.props.MyAlert?.alertbanner(ASSERT_CONSTANT.ASSERT_ERROR_MESSAGE);
      }
    }

    if (Object.keys(captureResponseDataError).length > 0) {
      isCaptureDataError = Object.values(captureDataError).some((value) => value);
      isCaptureResponseDataErr = Object.values(captureResponseDataError).some((value) => value);
      isUpdateVariable = updateVariables.some((values) => values.isEnabled);
    }

    isAuthError =
      Object.keys(errors || {}).length > 0 &&
      ['BearerToken', 'Basic', 'Oauth1', 'Digest', 'AWS', 'Hawk', 'NTLM', 'JWTBearer', 'APIKey'].includes(authType);
    if (isAuthError) {
      this.props.MyAlert?.alertbanner('Authorization tab is empty, Please fill the mandatory fields.');
    } else if ((isCaptureDataError && isUpdateVariable) || isCaptureResponseDataErr) {
      this.props.MyAlert?.alertbanner('Capture Data from Response tab is empty, Please fill the mandatory fields.');
    }

    if (
      !this.context.values.requestName ||
      !this.context.values.requestUrl ||
      (isCaptureDataError && isUpdateVariable) ||
      isCaptureResponseDataErr ||
      (isDefaultAssertErrors && isBasicAssertion) ||
      isAuthError ||
      this.state.requestNameErr ||
      this.state.requestUrlErr
    ) {
      return true;
    } else {
      return false;
    }
  };

  getStepGroupVariable = async () => {
    try {
      const response = await getAllStepGroupVariable(this.libraryId, this.stepGroupId);
      const stepGroupResponse = response.data.responseObject;
      if (stepGroupResponse && stepGroupResponse.length > 0) {
        this.setState({ stepGroupVariable: stepGroupResponse });
      }
      this.getStepGroupParameter();
    } catch (err) {
      console.error('GET_STEPGROUP_VARIABLE : ', err);
    }
  };

  getStepGroupParameter = async (isGlobalOpen = false) => {
    const {
      initialValues,
      values: { requestUrl, requestName, requestType },
    } = this.context;
    try {
      const response = await gellAllParameter(this.libraryId, this.stepGroupId);
      const stepGroupParamResponse = response.data.responseObject;
      if (stepGroupParamResponse && stepGroupParamResponse.length > 0) {
        this.setState({ stepGroupParam: stepGroupParamResponse });
      }
      if (!this.isCdfrVariableApiCall) {
        if (this.stepId) {
          this.getUserStepsById(this.stepId);
        } else if (this.state.conditionalStepObject?.executionId) {
          this.getUserStepsById(this.state.conditionalStepObject?.executionId);
        } else if (
          initialValues.requestUrl === requestUrl &&
          initialValues.requestName === requestName &&
          initialValues.requestType === requestType
        ) {
          this.context.values.requestUrl = '';
          this.context.values.requestName = '';
          this.context.values.btnChange = '';
          this.context.values.requestType = 'GET';
          this.context.values.ifFailed = '';
        }
      }

      if (!this.isCdfrVariableUpdated && !isGlobalOpen) {
        this.props.stopProgress();
        this.props.startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
      }

      this.setState({ isProgressLoding: false });
    } catch (err) {
      console.error('GET_STEPGROUP_PARAMETER : ', err);
    }
  };

  onCheckStepGroupParam = (stringifyPayload) =>
    this.state.stepGroupParam.some((paramData) => stringifyPayload.includes('${' + paramData._id + '}'));

  onHandleWebserviceModal = (status) => {
    this.setState({ showWebserviceModal: false });
    if (status === 'exit') {
      this.setState({ openModal: true });
    }
  };

  handleCurlModalClick = () => {
    if (this.props.isImportCurlOpen === IMPORT) {
      this.props.setImportCurlOpen(false);
    } else {
      this.setState({ curlModal: false });
      this.setState({ curlData: '' });
    }
  };

  handleMouseOver = () => {
    this.setState({ iconHovered: true });
  };

  handleMouseOut = () => {
    setTimeout(() => {
      this.setState({ iconHovered: false });
    }, 5000);
  };

  onCheckGraphQLDataFormat = (rawData) => {
    return GRAPHQL_FORMAT_REGEX.test(rawData);
  };

  parseCurlCommand = async (value) => {
    try {
      const { requestName, variableId } = this.context.values;
      const response = await importCurlToJson(value);
      const {
        method,
        type,
        data,
        headers = {},
        raw_url,
        queryParams = [],
        fileParamMap = {},
      } = response?.data?.data || {};
      if (!method || UNSUPPORTED_METHOD.includes(method?.toUpperCase())) {
        throw new Error(INVALID_IMPORT_CURL);
      } else {
        let bodyType = 'raw';
        const requestHeaders = [...DEFAULT_REQUEST_HEADERS];
        const urlEncoded = [];
        let isDefaultContentType = true;
        let headerRow = requestHeaders.find((items) => items.name.toLowerCase() === 'content-type');
        headerRow.value = 'application/json';
        if (type === 'form-data') {
          bodyType = 'form-data';
          headerRow.value = 'multipart/form-data';
        }
        Object.entries(headers).forEach(([key, value]) => {
          if (value === 'application/x-www-form-urlencoded') {
            bodyType = 'x-www-form-urlencoded';
          }
          const headerRowData = this.onModifiedRequestHeader(
            key,
            value,
            isDefaultContentType,
            headerRow,
            requestHeaders
          );
          requestHeaders.push(headerRowData);
        });

        const requestBody =
          bodyType === 'raw'
            ? {
                isEnabled: true,
                isModified: false,
                name: '',
                id: '',
                folderId: '',
                value: typeof data === 'object' ? JSON.stringify(data, null, 2) : data,
                type: 'text',
              }
            : {};

        if (bodyType === 'x-www-form-urlencoded') {
          Object.entries(data).forEach(([key, value]) => {
            urlEncoded.push({ isEnabled: true, name: key, value: value || '' });
          });
        }

        return {
          name: variableId || requestName,
          method: method?.toUpperCase(),
          url: raw_url,
          headers: requestHeaders,
          requestBody,
          bodyType,
          queryParams: queryParams || [],
          urlEncoded,
          fileParamMap,
          type: IMPORT,
        };
      }
    } catch (error) {
      if (error.message === 'Request failed with status code 500') {
        throw new Error(IMPORT_CURL_FAILURE);
      } else {
        throw new Error(error.message);
      }
    }
  };

  getCodeValue = async (value, e) => {
    if (e === ONCHANGE) {
      this.setState({ isValidImportCurl: true });
    } else {
      try {
        const result = await this.parseCurlCommand(value);

        if (e === ONPASTE) {
          this.handleCurlModalClick();
        }
        this.handleRequestData(result);
        this.props.MyAlert.success(IMPORT_CURL_SUCCESS);
      } catch (error) {
        console.error(IMPORT_CURL_ERROR, error);
        this.props.MyAlert.alertbanner(error.message);
      }
    }
    this.setState({ curlData: value });
  };

  handleRequestData = (stepsDataResponse, response) => {
    const updateRequestUrl = this.getUserInputValue(stepsDataResponse.url, true);
    if (updateRequestUrl !== stepsDataResponse.url) {
      this.context.setFieldValue('urlVariableId', stepsDataResponse.url);
    }
    this.context.setFieldValue('requestName', stepsDataResponse.name);
    this.context.setFieldValue('requestUrl', updateRequestUrl);
    this.context.setFieldValue('requestType', stepsDataResponse.method);
    this.context.setFieldValue('captureDataFromResponseObject', stepsDataResponse?.updateVariables);
    if (this.stepId) {
      this.context.setFieldValue('btnChange', stepsDataResponse.name);
    }
    this.context.setFieldValue('isStepUpdated', true);
    const requestUrlParam = this.getRequestUrlParam(updateRequestUrl);

    const {
      responseBody,
      statusCode,
      statusCodeValue,
      metaData,
      headers,
      responseAssertionResults,
      postStepResult,
      updatedVariables,
      assertionResult,
    } = response?.data?.success?.response || {};

    this.onUpdateResponseTab(headers);

    const newRequestData = {
      selectedStepData: stepsDataResponse,
      updateResponseBody: stepsDataResponse?.type ? false : true,
      updateData: true,
      setIsUpArrowResponse: stepsDataResponse?.type ? '' : 'activeQuery',
      responseBodyValueWithoutParse: responseBody,
      statusCode,
      statusCodeValue,
      apiTime: metaData?.apiTime,
      responseBodyValueWithoutParseError: responseBody,
      responseHeadersValue: headers,
      responseHeadersLenValue: headers?.length,
      responseAssertionResultsValue: responseAssertionResults || [],
      assertionResultValue: postStepResult || [],
      capturedDataFromResponseValue: updatedVariables,
      responseAssertionResultsLen: responseAssertionResults?.length || 0,
      assertionResultValueLen: postStepResult?.assertionResult?.assertionResults?.length || 0,
      capturedDataFromResponseLenValue: updatedVariables?.length,
      onGetRequestURL: requestUrlParam,
      status: assertionResult?.status,
      setSaveAsEnable: stepsDataResponse?.type ? false : this.stepId,
    };

    this.setState(newRequestData);
    this.context.setFieldValue('setResponse', newRequestData);
  };

  componentWillUnmount() {
    clearTimeout(this.copyTimeout);
  }

  getButtonStatus = (buttonStatus = '') => {
    const status = buttonStatus.toLowerCase();
    return buttonStatus && <button className={`response-${status}`}>{BUTTON_STATUS[status]}</button>;
  };

  getApiTime = () => {
    const { setResponse } = this.context.values || {};
    const apiTime = setResponse?.apiTime || this.state.apiTime;

    if (!apiTime || apiTime === NA) {
      return <span className="responseTime">{NA}</span>;
    }
    return <span className="responseTime">{`${apiTime} ${MS}`}</span>;
  };
  onRequestUrlValidation = (value) => {
    const isValidUrl =
      value && (!REQUEST_URL_PREFIXES.some((prefix) => value.startsWith(prefix)) || REQUEST_URL_REGEX.test(value));
    this.setState({ requestUrlErr: isValidUrl ? REQUEST_URL_ERROR : '' });
  };

  onRequestNameValidation = (value) => {
    const requestNameErr = value && this.getErrorMessage(value);
    this.setState({
      setRequestNameResponse: value,
      requestNameErr,
    });
  };
  onModifiedRequestHeader = (headerKey, headerValue, isDefaultContentType, headerRow, headers) => {
    const normalizedHeaderKey = headerKey.toLowerCase();
    if (isDefaultContentType && normalizedHeaderKey === 'content-type') {
      const headerMapping = {
        'application/octet-stream': 'text/plain',
        'text/xml': 'application/xml',
      };
      let updatedHeaderValue = headerMapping[headerValue] || headerValue;
      if (headerRow) {
        headerRow.value = updatedHeaderValue;
      }
      isDefaultContentType = false;
    }
    return { isEnabled: true, name: headerKey?.trim(), value: headerValue?.trim() || '' };
  };

  getCdfrResponseVariable = (cdfrField) => {
    try {
      const { captureResponse, captureDataFromResponseObject } = this.context.values || {};
      const { isCdfrResponseVariableUpdated, isSendExecuted, isCdfrResponseVariableAssigned } = this.state || {};
      const enabledCdfr = Object.values(captureResponse)
        .filter((item) => item.isEnabled)
        .map((item) => item.title);

      const updatedCaptureResponse = captureDataFromResponseObject
        ?.filter((data) => data.isEnabled)
        ?.map((data) => `Get ${data?.group}`);

      const isVariableAssignedUpdated = isCdfrResponseVariableUpdated || isCdfrResponseVariableAssigned;
      const isCdfrApiCall = enabledCdfr.includes(cdfrField) || isVariableAssignedUpdated;

      if (!isEmptyValue(this.stepId?.trim())) {
        if (updatedCaptureResponse.length === enabledCdfr.length && isVariableAssignedUpdated) {
          return true;
        }

        if (isCdfrApiCall) {
          return true;
        }
        return false;
      }

      if (isCdfrApiCall || (!isEmptyValue(enabledCdfr) && isSendExecuted)) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error in getCdfrResponseVariable :>> ', error);
    }
  };

  isCdfrApiCall(statusCode, apiTime, isStatusCode, isResponseTime) {
    const isCdfrVariableApiCall =
      (this.state.statusCode !== statusCode && this.getCdfrResponseVariable(isStatusCode)) ||
      (this.state.apiTime !== apiTime && this.getCdfrResponseVariable(isResponseTime)) ||
      this.getCdfrResponseVariable();
    this.isCdfrVariableApiCall = isCdfrVariableApiCall;
    this.isCdfrVariableUpdated = isCdfrVariableApiCall;

    if (isCdfrVariableApiCall) {
      this.getAllLocalVariables();
      this.setState({
        isSendExecuted: false,
        isCdfrResponseVariableUpdated: false,
        isCdfrResponseVariableAssigned: false,
      });
    }
  }

  cdfrResponseVariableUpdate = (variableTitle, variableAssigned) => {
    const { captureResponse } = this.context.values || {};
    const enabledCdfr = Object.values(captureResponse)
      .filter((item) => item.isEnabled)
      .map((item) => item.title);

    if (!variableAssigned && enabledCdfr.includes(variableTitle)) {
      this.setState({
        isCdfrResponseVariableUpdated: true,
      });
    }
    if (variableAssigned && enabledCdfr.includes(variableTitle)) {
      this.setState({
        isCdfrResponseVariableAssigned: true,
      });
    }
  };
  render() {
    const { status } = this.state;
    const responseStatus = status || this.context.values.setResponse?.status;

    if (this.state.isLoadingNlp) {
      return (
        <div className="base-h flex items-center justify-center">
          <div className="text-center font-bold align-middle empty_page_info -mt-20">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      );
    }
    const { classes } = this.props;

    return [ASSERT_CONSTANT.ROOT, ASSERT_CONSTANT.MODULE, ASSERT_CONSTANT.SCRIPT].includes(this.props?.label) ? (
      <WebserviceAuth
        filteredLocalVariable={this.state.localVariable}
        filteredGlobalVariable={this.state.globalVariable}
        filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
        selectedStepData={this.state.selectedStepData}
        getUserInputValue={this.getUserInputValue}
        onSetAllVariableName={this.onSetAllVariableName}
        MyAlert={this.props.MyAlert}
        handleSaveAsSteps={this.handleSaveAsSteps}
        handleDragStart={this.handleDragStart}
        filteredDataProviderVariable={this.state.dataProviderVariables}
        filteredStepGroupVariable={this.state.stepGroupVariable}
        filteredStepGroupParam={this.state.stepGroupParam}
        filteredForLoopVariable={this.state.forLoopVariables}
        getAuth={this.getAuth}
        onSetValueByType={this.onSetValueByType}
        {...this.props}
      />
    ) : this.state.isProgressLoding ? (
      <CommonLoader />
    ) : (
      <>
        <div className="flex-wrap mt-1 rest-api-container gap-2.5 ml-2 mr-2">
          {this.state.showWebserviceModal && (
            <WebserviceWarningModal
              onHandleWebserviceModal={this.onHandleWebserviceModal}
              type="Alert"
              label={this.context.values.btnChange}
              disabled={this.state.setSaveAsEnable || this.context.values.setSaveAsEnable?.setSaveAsEnable}
            />
          )}
          <GenerateCurlModal
            classes={classes}
            isModalOpen={this.props.isImportCurlOpen || this.state.curlModal}
            isCopied={this.state.isCopied}
            curlData={this.state.curlData}
            handleCopyClick={this.handleCopyClick}
            handleCurlModalClick={this.handleCurlModalClick}
            title={this.props.isImportCurlOpen ? IMPORT_CURL_TEXT : EXPORT_CURL_TEXT}
            iconHovered={this.state.iconHovered}
            handleMouseOver={this.handleMouseOver}
            handleMouseOut={this.handleMouseOut}
            getCodeValue={this.getCodeValue}
            isValidImportCurl={this.state.isValidImportCurl}
          />
          <div>
            <div className="input-filed-label fontPoppinsMediumSm select-none mb-1 restapi-name-text-label">
              <span className="text-red-400 mr-0.5">*</span>
              Request Name
            </div>
            <MyInput
              type="text"
              placeholder={!this.state.requestNameErr && REQUEST_NAME_ERROR}
              style={{ border: this.state.requestNameErr && `1px solid ${colors.button_red_60}` }}
              variableInput={false}
              error={this.context.errors.requestName && this.context.touched.requestName}
              value={this.context.values.requestName}
              onChange={(e) => {
                this.onRequestName(e);
              }}
              autoComplete="off"
              name="requestName"
              helperText={this.context.touched.requestName && this.context.errors.requestName}
              inputValue={'input'}
              handleSaveAsSteps={this.handleSaveAsSteps}
              onValidateRequestInput={this.onValidateRequestName}
              onRequestNameValidation={this.onRequestNameValidation}
            />{' '}
            {this.state.requestNameErr && <span className="request-section-errors">{this.state.requestNameErr}</span>}
          </div>
          <div className="rest-api-select">
            <div className="input-filed-label fontPoppinsMediumSm select-none mb-1 restapi-name-text-label">
              <span className="text-red-400 mr-0.5">*</span>
              HTTP Method
            </div>
            <MySelect
              variableInput={'restApi'}
              padding={'6px 12px'}
              sxPadding={'10px'}
              boxShadow={`0px 0px 1px grey`}
              verticalAnchor={'bottom'}
              verticalTransform={'top'}
              marginTop={'3px'}
              borderRadius={'5px'}
              marginRight={'3px'}
              classSelect={'request-dropdown w-28 h-9'}
              name="requestType"
              value={this.context.values.requestType}
              onChange={(e) => {
                this.onRequestType(e);
              }}
              background={'transparent'}
              MenuProps={{
                PaperProps: {
                  className: 'http-methods-dropdown',
                },
              }}
            >
              {ASSERT_CONSTANT.REQUEST_TYPE.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    <span className="text-xs">{value}</span>
                  </MenuItem>
                );
              })}
            </MySelect>
          </div>
          <div className="flex-1 ml-0.5">
            <div className="input-filed-label fontPoppinsMediumSm select-none mb-1 restapi-name-text-label">
              <span className="text-red-400 mr-0.5">*</span>
              Request URL
            </div>
            <MyInput
              canCreateVariable={this.state.isEditable}
              type="text"
              placeholder={!this.state.requestUrlErr && 'Enter request url'}
              variableInput={true}
              error={this.context.errors.requestUrl && this.context.touched.requestUrl}
              value={this.context.values.requestUrl}
              onChange={(e) => {
                this.onRequestUrl(e);
              }}
              autoComplete="off"
              name="requestUrl"
              id="requestUrl"
              style={{ border: this.state.requestUrlErr && `1px solid ${colors.button_red_60}` }}
              helperText={this.context.touched.requestUrl && this.context.errors.requestUrl}
              fullWidth
              inputValue={'input'}
              filteredLocalVariable={this.state.localVariable}
              filteredGlobalVariable={this.state.globalVariable}
              filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
              onSetAllVariableName={this.onSetAllVariableName}
              onRequestUrlFocus={this.onRequestUrlFocus}
              currentValue={this.context.values.currentRequestUrl}
              getUserInputValue={this.getUserInputValue}
              MyAlert={this.props.MyAlert}
              handleSaveAsSteps={this.handleSaveAsSteps}
              onPaste={(e) => {
                this.onRequestUrl(e);
              }}
              filteredDataProviderVariable={this.state.dataProviderVariables}
              onValidateRequestInput={this.onValidateRequestUrl}
              filteredStepGroupVariable={this.state.stepGroupVariable}
              filteredStepGroupParam={this.state.stepGroupParam}
              filteredForLoopVariable={this.state.forLoopVariables}
              onRequestUrlValidation={this.onRequestUrlValidation}
            />
            {this.state.requestUrlErr && <span className="request-section-errors">{this.state.requestUrlErr}</span>}
          </div>
          <CommonButton
            className="send-btn mr-1"
            btnType="primary"
            label={this.state.isLoading ? LOADING_SENDING : SEND_BUTTON_TEXT}
            onClick={this.CreateWebService}
            disabled={this.state.isLoading}
          />
          {this.state.isEditable && (
            <CommonButton
              className="send-btn"
              btnType="primary"
              label={this.context.values.btnChange ? UPDATE_AS_STEP : SAVE_AS_STEP}
              onClick={this.handleClickOpen}
              disabled={this.state.setSaveAsEnable || this.context.setResponse?.setSaveAsEnable}
            />
          )}
        </div>
        <div className="content_area_flow ml-2 mr-2">
          {this.props.AlertContainer}
          <Modal open={this.state.openModal} className={`${classes.customModal} modal-dialog`}>
            <SaveAapiRreq
              handleClickClose={this.handleClickClose}
              CreateWebServiceStepNLP={this.CreateWebServiceStepNLP}
              moduleId={this.moduleId}
              scriptId={this.scriptId}
              moduleName={this.moduleName}
              changeButton={this.context.values.btnChange}
              {...this.props}
            />
          </Modal>
          <div className="flex-row restApiTabLinksContainer first_Div" id="journal-scroll">
            <div
              id="journal-scroll"
              className={`restApiTabLinksContainerElement bg-white ${this.state.assert === 'disable'} ${
                this.state.requestHeader && 'overflow-hidden'
              } ${(this.state.preRequisite || this.state.captureResponseData) && 'overflow-auto'} ${
                this.state.setIsUpArrowRequest
              }`}
            >
              <div className="tab-container bg-white">
                {ASSERT_CONSTANT.REST_API.map((value, index) => {
                  const selectedMethod = this.onSelectRequestMethod(value.value);
                  const isActive = this.state.selectedRequestTab === value.value;
                  return (
                    <div
                      key={index}
                      className={`tab-request fontPoppinsRegularSm ${
                        isActive ? 'active' : 'hover-effect-response-header'
                      }`}
                      onClick={() => this.handleRequestTabChange(value.value)}
                    >
                      {selectedMethod}
                      {isActive && <div className="indicator"></div>}
                    </div>
                  );
                })}
                {this.state.isEditable && (
                  <>
                    <div
                      className={`tab fontPoppinsRegularSm ${
                        this.state.selectedRequestTab === 'Capture Data From Response'
                          ? 'active'
                          : 'hover-effect-response-header'
                      }`}
                      onClick={() => this.handleRequestTabChange('Capture Data From Response')}
                    >
                      {this.onSelectRequestMethod('Capture Data From Response')}
                      {this.state.activeCaptureResponseData === 'activeQuery' && <div className="indicator"></div>}
                    </div>
                    <div
                      className={`tab fontPoppinsRegularSm ${
                        this.state.selectedRequestTab === 'Settings' ? 'active' : 'hover-effect-response-header'
                      }`}
                      onClick={() => this.handleRequestTabChange('Settings')}
                    >
                      {this.onSelectRequestMethod('Settings')}
                      {this.state.activeSettings === 'activeQuery' && <div className="indicator"></div>}
                    </div>
                  </>
                )}

                <div className="request-option-collapse">
                  <img className="mr-1 cursor pointer hidden" alt="magnifier" src={Magnifier} />
                  <img className="mr-1 cursor pointer hidden" alt="expand icon" src={ExpandIcon} />

                  {/* commented expand icon for future implementations  
           <Tooltip placement='bottom' title="collapse">
            <img
              className='up-arrow-icon'
              alt="show more"
              src={ShowHide}
              onClick={() => { this.toggleAccordionInsideRequest() }}
              draggable="false"
            />
            </Tooltip> */}
                </div>
              </div>
              <div
                className={`custom-tabs relative  ${
                  this.state.queryParam || this.state.requestHeader ? 'top-1.5' : 'top-2'
                }`}
              >
                <div className="tab-contents">
                  <div id="Query Parameter" className={`${this.state.queryParam} tabcontent`}>
                    <StickyHeadTable
                      getParamValueCode={this.getParamValueCode}
                      getParamValue={this.getParamValue}
                      onGetRequestURL={this.state.onGetRequestURL}
                      requestUrlInput={this.context.values.requestUrl}
                      filteredLocalVariable={this.state.localVariable}
                      filteredGlobalVariable={this.state.globalVariable}
                      filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
                      selectedStepData={this.state.selectedStepData}
                      getUserInputValue={this.getUserInputValue}
                      onSetAllVariableName={this.onSetAllVariableName}
                      isRequestUrlFocus={this.state.isRequestUrlFocus}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                      MyAlert={this.props.MyAlert}
                      handleDragStart={this.handleDragStart}
                      filteredDataProviderVariable={this.state.dataProviderVariables}
                      filteredStepGroupVariable={this.state.stepGroupVariable}
                      filteredStepGroupParam={this.state.stepGroupParam}
                      filteredForLoopVariable={this.state.forLoopVariables}
                    />
                  </div>

                  <div id="Request Header" className={`${this.state.requestHeader} tabcontent`}>
                    <DataRequestParamGrid
                      filteredLocalVariable={this.state.localVariable}
                      filteredGlobalVariable={this.state.globalVariable}
                      filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
                      selectedStepData={this.state.selectedStepData}
                      getUserInputValue={this.getUserInputValue}
                      onSetAllVariableName={this.onSetAllVariableName}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                      MyAlert={this.props.MyAlert}
                      handleDragStart={this.handleDragStart}
                      filteredDataProviderVariable={this.state.dataProviderVariables}
                      filteredStepGroupVariable={this.state.stepGroupVariable}
                      filteredStepGroupParam={this.state.stepGroupParam}
                      filteredForLoopVariable={this.state.forLoopVariables}
                    />
                  </div>
                  <div id="DataRequestBodyGrid" className={`${this.state.requestBody} tabcontent bg-white`}>
                    <DataRequestBodyGrid
                      filteredLocalVariable={this.state.localVariable}
                      filteredGlobalVariable={this.state.globalVariable}
                      filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
                      selectedStepData={this.state.selectedStepData}
                      getUserInputValue={this.getUserInputValue}
                      onSetAllVariableName={this.onSetAllVariableName}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                      onSetValueByType={this.onSetValueByType}
                      MyAlert={this.props.MyAlert}
                      handleDragStart={this.handleDragStart}
                      filteredDataProviderVariable={this.state.dataProviderVariables}
                      filteredStepGroupVariable={this.state.stepGroupVariable}
                      filteredStepGroupParam={this.state.stepGroupParam}
                      filteredForLoopVariable={this.state.forLoopVariables}
                      requestBodyActive={this.state.requestBody}
                    />
                  </div>

                  <div id="Authorization" className={`${this.state.authorization} tabcontent`}>
                    <Authorization
                      filteredLocalVariable={this.state.localVariable}
                      filteredGlobalVariable={this.state.globalVariable}
                      filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
                      selectedStepData={this.state.selectedStepData}
                      getUserInputValue={this.getUserInputValue}
                      onSetAllVariableName={this.onSetAllVariableName}
                      MyAlert={this.props.MyAlert}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                      handleDragStart={this.handleDragStart}
                      filteredDataProviderVariable={this.state.dataProviderVariables}
                      filteredStepGroupVariable={this.state.stepGroupVariable}
                      filteredStepGroupParam={this.state.stepGroupParam}
                      filteredForLoopVariable={this.state.forLoopVariables}
                      type={this.props?.type}
                      checkWebserviceData={this.state.checkWebserviceData}
                      parentAuthData={this.state.parentAuthData}
                      moduleName={this.state.moduleName}
                      onSetValueByType={this.onSetValueByType}
                    />
                  </div>

                  <div
                    id="Prerequisite"
                    className={`${this.state.preRequisite} tabcontent tab-content-in-prerequisite`}
                  >
                    <Prerequisite
                      filteredLocalVariable={this.state.localVariable}
                      filteredGlobalVariable={this.state.globalVariable}
                      filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
                      selectedStepData={this.state.selectedStepData}
                      getUserInputValue={this.getUserInputValue}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                      MyAlert={this.props.MyAlert}
                      onSetValueByType={this.onSetValueByType}
                      prerequisiteStatus={this.state.preRequisite}
                      filteredDataProviderVariable={this.state.dataProviderVariables}
                      filteredStepGroupVariable={this.state.stepGroupVariable}
                      filteredStepGroupParam={this.state.stepGroupParam}
                      filteredForLoopVariable={this.state.forLoopVariables}
                    />
                  </div>

                  <div id="Assert" className={`${this.state.assert} tabcontent relative pr-3 pb-2 pl-3 pt-1`}>
                    <Assert
                      filteredLocalVariable={this.state.localVariable}
                      filteredGlobalVariable={this.state.globalVariable}
                      filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
                      selectedStepData={this.state.selectedStepData}
                      getUserInputValue={this.getUserInputValue}
                      onSetAllVariableName={this.onSetAllVariableName}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                      MyAlert={this.props.MyAlert}
                      onSetValueByType={this.onSetValueByType}
                      customAssertStatus={this.state.assert}
                      filteredDataProviderVariable={this.state.dataProviderVariables}
                      checkDefaultAssertValue={this.getBasicAssertions()}
                      checkCustomAssertValue={ASSERT_CONSTANT.ONlOAD_CHANGE !== this.context.values.assertCodeValue}
                      validateNameField={this.validateNameField}
                      defaultAssertErrors={this.state.defaultAssertErrors}
                      filteredStepGroupVariable={this.state.stepGroupVariable}
                      filteredStepGroupParam={this.state.stepGroupParam}
                      filteredForLoopVariable={this.state.forLoopVariables}
                    />
                  </div>

                  <div
                    id="Capture Data From Response"
                    className={`${this.state.captureResponseData} tabcontent  pr-3  pl-3 `}
                  >
                    <CaptureResponseData
                      data={{
                        statusCode: this.state.statusCode,
                        apiTime: this.state.apiTime,
                        responseBodyValue: this.state.responseBodyValue,
                        responseHeadersValue: this.state.responseHeadersValue,
                        responseValue: this.state.responseValue,
                      }}
                      MyAlert={this.props.MyAlert}
                      selectedStepData={this.state.selectedStepData}
                      setAllVariableName={this.state.setAllVariableName}
                      handleCaptureDataResponseError={this.handleCaptureDataResponseError}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                      localVariable={this.state.localVariable}
                      globalVariable={this.state.globalVariable}
                      onSetAllVariableName={this.onSetAllVariableName}
                      getUserInputValue={this.getUserInputValue}
                      validateCaptureDataFields={this.validateCaptureDataFields}
                      captureDataError={this.state.captureDataError}
                      projectEnvVariable={this.state.projEnvironmentVariable}
                      filteredStepGroupVariable={this.state.stepGroupVariable}
                      filteredStepGroupParam={this.state.stepGroupParam}
                      filteredLocalVariable={this.state.localVariable}
                      filteredGlobalVariable={this.state.globalVariable}
                      filteredProjectEnvironmentalVariable={this.state.projEnvironmentVariable}
                      filteredDataProviderVariable={this.state.dataProviderVariables}
                      filteredForLoopVariable={this.state.forLoopVariables}
                      onSelectRequestMethod={this.onSelectRequestMethod}
                      handleCdfrUpdate={this.handleCdfrUpdate}
                      cdfrTabState={this.state.cdfrTabState}
                      cdfrResponseVariableUpdate={this.cdfrResponseVariableUpdate}
                    />
                  </div>
                  <div id="Settings" className={`${this.state.settings}`} tabcontent>
                    <Settings
                      selectedStepData={this.state.selectedStepData}
                      handleSaveAsSteps={this.handleSaveAsSteps}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={this.state.setIsDownArrowRequest}>
              <div className="Request_Div request-expand-container flex flex-wrap border-2 border-opacity-100 ">
                <label className="ml-5 text-sm flex-auto fontPoppinsSemiboldSm select-none">REQUEST</label>
                <div className="request-option-expand">
                  <CustomTooltip placement="bottom" title="expand">
                    <img
                      className="rotate-0"
                      src={ShowHide}
                      alt="showmore"
                      onClick={() => {
                        this.toggleAccordionRequest();
                      }}
                    />
                  </CustomTooltip>
                </div>
              </div>
            </div>
            {this.state.setIsUpArrowResponse === 'activeQuery' ||
            this.context.values.setResponse?.setIsUpArrowResponse ? (
              <div className="mt-4 mb-4 response-tabs-container-list bg-white">
                <div className="flex response-option-container-border">
                  <div className={`response-option-container justify-start response-option-container-height`}>
                    <div className="flex relative width-of-response-tab-container response-tab-container-height">
                      {ASSERT_CONSTANT.RESPONSE_METHOD_TYPE.map((value, index) => (
                        <div
                          key={index}
                          className={`response-header-tabs ${
                            this.state.responseSelectedValue === value.value ? 'active' : 'hover-effect-response-header'
                          }`}
                          onClick={() => this.onChangeResponseOption(value.value)}
                        >
                          <span>{value.value}</span>
                          {this.state.responseSelectedValue === value.value && <div className="indicator"></div>}
                        </div>
                      ))}
                    </div>
                    <div className="response-option-collapse">
                      <div className="">{this.getButtonStatus(responseStatus)}</div>
                      <div className="responseStatusLabel  whitespace-nowrap ml-2  leading-3 ">
                        Status:
                        <span className="responseStatus">
                          {this.context.values.setResponse?.statusCodeValue !== ''
                            ? this.context.values.setResponse?.statusCodeValue
                            : this.state.statusCodeValue}
                        </span>
                      </div>
                      <div className="responseTimeLabel  whitespace-nowrap  leading-3 ">
                        Time:
                        {this.getApiTime()}
                      </div>
                      <img
                        className="expand-icon cursor-pointer hidden"
                        src={ExpandIcon}
                        alt="showmore"
                        onClick={() => {}}
                        draggable="false"
                      />
                      <CustomTooltip placement="bottom" title="collapse">
                        <img
                          className="up-arrow-icon "
                          src={ShowHide}
                          alt="showmore"
                          onClick={() => {
                            this.toggleAccordionInsideResponse();
                          }}
                          draggable="false"
                        />
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                {this.state.updateData || this.context.values.setResponse?.updateData ? (
                  <>
                    {this.state.isLoading || this.context.values.setResponse?.isLoading ? (
                      <div className="base-h flex items-center justify-center">
                        <div className="text-center font-bold align-middle empty_page_info -mt-20">
                          <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div
                          id="Query Parameter"
                          className={`${this.state.responseBody} border-opacity-100 ${
                            this.state.setIsDownArrowRequest?.includes('show-request-tab')
                              ? 'response-section-expand-height'
                              : 'response-section-collapse-height'
                          }`}
                        >
                          {this.state.updateResponseBody || this.context.values.setResponse?.updateResponseBody ? (
                            <ResponseBody
                              setRequestNameResponse={
                                this.state.setRequestNameResponse ||
                                this.context.values.setResponse.setRequestNameResponse
                              }
                              HTMLContentTypeLen={
                                this.state.HTMLContentTypeLen || this.context.values.setResponse.HTMLContentTypeLen
                              }
                              JsonContentTypeLen={
                                this.state.JsonContentTypeLen || this.context.values.setResponse.JsonContentTypeLen
                              }
                              XMLContentTypeLen={
                                this.state.XMLContentTypeLen || this.context.values.setResponse.XMLContentTypeLen
                              }
                              responseBodyValueWithoutParse={
                                this.state.responseBodyValueWithoutParse ||
                                this.context.values.setResponse.responseBodyValueWithoutParse
                              }
                              responseBodyValueWithoutParseError={
                                this.state.responseBodyValueWithoutParseError ||
                                this.context.values.setResponse.responseBodyValueWithoutParseError
                              }
                              requestSectionCollapse={
                                this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                                this.context.values.setResponse.setIsDownArrowRequest?.includes('show-request-tab')
                              }
                              fileBlobSize={this.state.blobFileSize}
                            />
                          ) : (
                            <div id="Querydata" className={`overflow-auto border-2 border-style border-opacity-100`}>
                              <div className={` ${this.state.responseNotFoundClassName}`} style={{ marginTop: '3%' }}>
                                {this.state.requestUrlError}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          id="Request Header"
                          className={`${
                            this.state.responseHeaders || this.context.values.setResponse.responseHeaders
                          }  border-opacity-100 ${
                            this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                            this.context.values.setResponse.setIsDownArrowRequest?.includes('show-request-tab')
                              ? 'response-section-expand-height'
                              : 'response-section-collapse-height'
                          }`}
                        >
                          <ResponseHeaders
                            responseHeadersValue={
                              this.state.responseHeadersValue || this.context.values.setResponse.responseHeadersValue
                            }
                            requestSectionCollapse={
                              this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                              this.context.values.setResponse.setIsDownArrowRequest?.includes('show-request-tab')
                            }
                          />
                        </div>
                        <div
                          id="Request Header"
                          className={`${
                            this.state.assertResults || this.context.values.setResponse.assertResults
                          }  border-opacity-100  ${
                            this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                            this.context.values.setResponse?.setIsDownArrowRequest?.includes('show-request-tab')
                              ? 'response-section-expand-height'
                              : 'response-section-collapse-height'
                          }`}
                        >
                          <AssertResult
                            responseAssertionResultsValue={
                              this.context.values.setResponse?.responseAssertionResultsValue ||
                              this.state.responseAssertionResultsValue
                            }
                            assertionResultValue={
                              this.context.values.setResponse?.assertionResultValue || this.state.assertionResultValue
                            }
                            requestSectionCollapse={
                              this.context.values.setResponse?.setIsDownArrowRequest?.includes('show-request-tab') ||
                              this.state.setIsDownArrowRequest?.includes('show-request-tab')
                            }
                          />
                        </div>
                        <div
                          id="Request Header"
                          className={`${
                            this.state.capturedDataFromResponse ||
                            this.context.values.setResponse?.capturedDataFromResponse
                          }  border-opacity-100 ${
                            this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                            this.context.values.setResponse?.setIsDownArrowRequest?.includes('show-request-tab')
                              ? 'response-section-expand-height'
                              : 'response-section-collapse-height'
                          }`}
                        >
                          <CapturedDataFromResponse
                            capturedDataFromResponseValue={
                              this.context.values.setResponse?.capturedDataFromResponseValue ||
                              this.state.capturedDataFromResponseValue
                            }
                            requestSectionCollapse={
                              this.context.values.setResponse?.setIsDownArrowRequest?.includes('show-request-tab') ||
                              this.state.setIsDownArrowRequest?.includes('show-request-tab')
                            }
                          />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className={`bg-white flex items-start select-none fontMontserratMediumXs height-of-response-section relative`}
                    >
                      {this.state.responseBody === 'enable' && (
                        <div className="svg-rocket-height flex gap-5 mt-6 ml-5">
                          {REQUEST_BODY_BUTTONS.map((button) => (
                            <Button
                              disabled
                              variant={'outlined'}
                              className={` ml-1  response-nav-bar fontMontserratMediumXs ${classes.customButton}`}
                            >
                              {button}
                            </Button>
                          ))}
                        </div>
                      )}
                      {(this.state.responseBody === 'enable' ||
                        this.context.values.setResponse?.responseBody === 'enable') && (
                        <div className="absolute top-3 right-3">
                          <button
                            className="hidden state-button px-1 rounded-l cursor-pointer pr-5 tooltip-nav-bar -mt-1"
                            onClick={(e) => {}}
                          >
                            <span className="-mt-1.5">
                              <Tooltip
                                title={<span className="fontPoppinsRegularXs">Search...</span>}
                                placement="bottom"
                              >
                                <SearchIcon className="search_icon_in_response_body" />
                              </Tooltip>
                            </span>
                          </button>
                          <a
                            href="download"
                            onClick={(event) => {
                              this.disabledDownload(event);
                            }}
                            draggable="false"
                          >
                            <button
                              className=" state-button px-1 rounded-l cursor-pointer tooltip-nav-bar"
                              disabled={true}
                            >
                              <CustomTooltip title="Download" placement="bottom">
                                <img
                                  src={
                                    this.state.isHoveredDownload
                                      ? '/assets/images/Download.svg'
                                      : '/assets/images/download-init.svg'
                                  }
                                  alt=""
                                  className={`download_image_in_response_body ${
                                    this.state.isHoveredDownload ? 'hovered-icons-response-body' : ''
                                  }`}
                                  height="20px"
                                  width="20px"
                                  draggable="false"
                                  onMouseEnter={this.handleMouseEnterDownload}
                                  onMouseLeave={this.handleMouseLeaveDownload}
                                />
                              </CustomTooltip>
                            </button>
                          </a>

                          <button
                            className=" state-button px-1 rounded-l cursor-pointer tooltip-nav-bar"
                            onClick={this.onCheckNavigatorClipboard}
                          >
                            <Tooltip
                              title={this.state.isCopyToClipBoard ? 'copied!' : 'Copy to clipboard'}
                              placement="bottom"
                            >
                              <img
                                src="/assets/images/copy-init.svg"
                                alt=""
                                height="20px"
                                className={`opacity-40 `}
                                width="20px"
                                draggable="false"
                              />
                            </Tooltip>
                          </button>
                        </div>
                      )}
                      {(this.state.responseHeaders === 'enable' ||
                        this.context.values.setResponse?.responseHeaders === 'enable') && (
                        <div
                          id="Request Header"
                          className={`${
                            this.state.responseHeaders || this.context.values.setResponse?.responseHeaders
                          }  ${
                            this.state.setIsDownArrowRequest?.includes('show-request-tab')
                              ? 'response-section-expand-height'
                              : 'response-section-collapse-height'
                          }`}
                        >
                          <ResponseHeaders
                            responseHeaders={
                              this.state.responseHeaders || this.context.values.setResponse?.responseHeaders
                            }
                            responseHeadersValue={
                              this.state.responseHeadersValue || this.context.values.setResponse?.responseHeadersValue
                            }
                            requestSectionCollapse={
                              this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                              this.context.values.setResponse?.setIsDownArrowRequest?.includes('show-request-tab')
                            }
                          />
                        </div>
                      )}
                      {this.state.assertResults === 'enable' && (
                        <div
                          id="Request Header"
                          className={`${
                            this.state.assertResults || this.context.values.setResponse?.assertResults
                          } enable    ${
                            this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                            this.context.values.setResponse?.setIsDownArrowRequest?.includes('show-request-tab')
                              ? 'response-section-expand-height'
                              : 'response-section-collapse-height'
                          }`}
                        >
                          <AssertResult
                            assertResults={this.state.assertResults || this.context.values.setResponse?.assertResults}
                            responseAssertionResultsValue={
                              this.state.responseAssertionResultsValue ||
                              this.context.values.setResponse?.responseAssertionResultsValue
                            }
                            assertionResultValue={
                              this.state.assertionResultValue || this.context.values.setResponse?.assertionResultValue
                            }
                            requestSectionCollapse={
                              this.state.setIsDownArrowRequest?.includes('show-request-tab') ||
                              this.context.values.setResponse?.setIsDownArrowRequest?.includes('show-request-tab')
                            }
                          />
                        </div>
                      )}
                      {(this.state.capturedDataFromResponse === 'enable' ||
                        this.context.values.setResponse?.capturedDataFromResponse === 'enable') && (
                        <div
                          id="Request Header"
                          className={`${
                            this.state.capturedDataFromResponse ||
                            this.context.values.setResponse?.capturedDataFromResponse
                          } enable   ${
                            (
                              this.state.setIsDownArrowRequest || this.context.values.setResponse?.setIsDownArrowRequest
                            )?.includes('show-request-tab')
                              ? 'response-section-expand-height'
                              : 'response-section-collapse-height'
                          }`}
                        >
                          <CapturedDataFromResponse
                            capturedDataFromResponse={true}
                            capturedDataFromResponseValue={
                              this.state.capturedDataFromResponseValue ||
                              this.context.values.setResponse.capturedDataFromResponseValue
                            }
                            requestSectionCollapse={(
                              this.state.setIsDownArrowRequest || this.context.values.setResponse?.setIsDownArrowRequest
                            )?.includes('show-request-tab')}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="Request_Div response-expand-container mt-4 flex flex-wrap border-2 border-opacity-100 response-expand-div-height">
                <label className="ml-5 text-sm flex-auto fontPoppinsSemiboldSm select-none">RESPONSE</label>
                <div className="response-option-expand">
                  <CustomTooltip placement="bottom" title="expand">
                    <img
                      className="rotate-0"
                      src={ShowExpand}
                      alt="showmore"
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = `${colors.text_white}`;
                        e.target.style.borderRadius = '50px';
                        e.target.src = ShowExpandHover;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = 'initial';
                        e.target.src = ShowExpand;
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = `${colors.text_white}`;
                        e.target.style.borderRadius = '50px';
                        e.target.src = ShowExpandHover;
                      }}
                      onClick={() => {
                        this.toggleAccordionResponse();
                      }}
                      draggable="false"
                    />
                  </CustomTooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(withAlertContainer(Restapi));
