import React, { useState, useEffect, useRef } from 'react';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import {
  gellAllParameter,
  getAllFiles,
  getAllGlobalVariableListReq,
  getAllLocalVariablesReq,
  getAllProjectVariableListReq,
  getAllStepGroupVariable,
  getAllTestDataFiles,
  getFolderPathReq,
} from '@src/api/api_services';
import { TextField, Tooltip } from '@material-ui/core';
import { Field } from 'formik';
import { AddToPhotosOutlined, Clear } from '@material-ui/icons';
import CreateVariable from '@src/pages/test-development/shared/variables/create-edit-variables';
import VariablesDetailsModal from '@src/pages/test-development/shared/variables/variables-details';
import VariableDetailsModal from '@src/pages/repository/components/step_groups/modals/variable_details_modal';
import ParamterModal from '@src/pages/repository/components/step_groups/modals/parameter_modal';
import StepGroupVariableModal from '@src/pages/repository/components/step_groups/modals/step_group_variable_modal';
import styles from './VariableSuggestion.module.scss';
import cx from 'classnames';
import { TooltipPoppin } from '@src/pages/analytics/common/util';
import {
  isEmptyValue,
  pathName,
  renderFileAndFolderPath,
  renderFilePath,
  searchFilePathBasedOnHash,
} from '@src/util/common_utils';
import { Checkbox, Icon } from 'fireflink-ui';
import { REPO, HASH_LABELS } from '@src/common/ui-constants';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@mui/base';
import { FOLDER_PATH_ARRAY } from '@src/util/validations';

const useStyles = makeStyles(() => ({
  customTooltip: {
    whiteSpace: 'pre-line',
    lineHeight: '2em',
    width: '240px',
  },
}));
const VariableSuggestion = (props) => {
  const classes = useStyles();
  const { MyAlert } = useAlert();
  const [localCheckboxValue, setLocalCheckboxValue] = useState(props?.stepInputs?.checkboxValue);
  const [localVariables, setLocalVariables] = useState(
    props.localVariableData?.length ? [...props.localVariableData] : []
  );
  const [globalVariables, setGlobalVariables] = useState([]);
  const [projectEnvironmentVariables, setProjectEnvironmentVariables] = useState([]);
  const [stepGroupParameters, setStepGroupParameters] = useState([]);
  const [stepGroupVariables, setStepGroupVariables] = useState([]);
  const [dataProviderVariableData, setDataProviderVariableData] = useState([]);
  let [forLoopVariable, setForLoopVariable] = useState([]);

  const [filteredLocalVariables, setFilteredLocalVariables] = useState([]);
  const [filteredGlobalVariables, setFilteredGlobalVariables] = useState([]);
  const [filteredProjectEnvironmentalVariables, setFilteredProjectEnvironmentalVariables] = useState([]);
  const [filteredStepGroupParameters, setFilteredStepGroupParameters] = useState([]);
  const [filteredStepGroupVariables, setFilteredStepGroupVariables] = useState([]);
  const [filteredDataProviderVariables, setFilteredDataProviderVariables] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [selectedVariableData, setSelectedVariableData] = useState();
  const [openLocalVariableDetailsModal, setOpenLocalVariableDetailsModal] = useState(false);
  const [openGlobalProjectVariableDetailsModal, setOpenGlobalProjectVariableDetailsModal] = useState(false);
  const [openStepGroupParameterDetailsModal, setopenStepGroupParameterDetailsModal] = useState(false);
  const [openStepGroupVariableDetailsModal, setOpenStepGroupVariableDetailsModal] = useState(false);
  const [openCreateStepGroupParameterModal, setOpenCreateStepGroupParameterModal] = useState(false);
  const [openCreateStepGroupVariableModal, setOpenCreateStepGroupVariableModal] = useState(false);
  const [openCreateGlobalVariableModal, setOpenCreateGlobalVariableModal] = useState(false);
  const [openCreateProjectEnvironmentVariableModal, setOpenCreateProjectEnvironmentVariableModal] = useState(false);
  const [openCreateVariableModal, setOpenCreateVariableModal] = useState(false);
  let [isPathSuggestionDropdown, setIsPathSuggestionDropdown] = useState(
    props?.stepInputType.includes('com.tyss.optimize.nlp.util') || props?.isFolderDropdown
  );
  const selectedLocVariable = useRef();
  const [onChangeValue, setOnChangeValue] = useState('');
  let fileTypes = ['csv', 'pdf', 'xls'];
  let inputType = props.stepInputType;
  let type = inputType.slice(inputType.lastIndexOf('.') + 1, inputType.length);
  const [showFilePathSuggestion, setShowFilePathSuggestion] = useState(false);
  const [showFolderPathSuggestion, setShowFolderPathSuggestion] = useState(false);
  const [showFileAndFolderPathSuggestion, setShowFileAndFolderPathSuggestion] = useState(false);

  const showFilePaths = () => {
    setShowFilePathSuggestion(true);
  };
  const hideFilePaths = () => {
    setShowFilePathSuggestion(false);
  };
  const showFolderPaths = () => {
    setShowFolderPathSuggestion(true);
  };
  const hideFolderPaths = () => {
    setShowFolderPathSuggestion(false);
  };
  const showFileAndFolderPaths = () => {
    setShowFileAndFolderPathSuggestion(true);
  };
  const hideFileAndFolderPaths = () => {
    setShowFileAndFolderPathSuggestion(false);
  };
  const showVariables = () => {
    setShowSuggestion(true);
  };
  const hideVariables = () => {
    setShowSuggestion(false);
  };

  const closeModal = (value) => {
    if (openLocalVariableDetailsModal) {
      setOpenLocalVariableDetailsModal(false);
    }
    if (openCreateVariableModal) {
      setOpenCreateVariableModal(false);
    }
    if (openStepGroupParameterDetailsModal) {
      setopenStepGroupParameterDetailsModal(false);
    }
    if (openCreateStepGroupParameterModal) {
      setOpenCreateStepGroupParameterModal(false);
    }
    if (openGlobalProjectVariableDetailsModal) {
      setOpenGlobalProjectVariableDetailsModal(false);
    }
    if (openStepGroupVariableDetailsModal) {
      setOpenStepGroupVariableDetailsModal(false);
    }
    if (openCreateStepGroupVariableModal) {
      setOpenCreateStepGroupVariableModal(false);
    }
    if (openCreateProjectEnvironmentVariableModal) {
      setOpenCreateProjectEnvironmentVariableModal(false);
    }
    if (openCreateGlobalVariableModal) {
      setOpenCreateGlobalVariableModal(false);
    }
  };
  const viewEditVariableDetails = (type) => {
    if (selectedVariableType === 'Step') {
      selectedVariableType = 'STEPGROUP';
    }
    if (type === 'view') {
      if (['LOCAL', 'FORLOOP'].includes(selectedVariableType)) {
        setOpenLocalVariableDetailsModal(true);
      } else if (selectedVariableType === 'STEPGROUPINPUT') {
        setopenStepGroupParameterDetailsModal(true);
      } else if (['GLOBAL', 'PROJECT_ENVIRONMENT'].includes(selectedVariableType)) {
        setOpenGlobalProjectVariableDetailsModal(true);
      } else if (['STEPGROUP', 'Step'].includes(selectedVariableType)) {
        setOpenStepGroupVariableDetailsModal(true);
      }
    } else {
      if (['LOCAL', 'GLOBAL', 'PROJECT_ENVIRONMENT'].includes(selectedVariableType)) {
        setOpenCreateVariableModal(true);
      } else if (selectedVariableType === 'STEPGROUPINPUT') {
        setOpenCreateStepGroupParameterModal(true);
      } else if (['STEPGROUP', 'Step'].includes(selectedVariableType)) {
        setOpenCreateStepGroupVariableModal(true);
      }
    }
  };
  const variableClick = (data, type) => {
    if (props?.fileType && props?.setFileType) {
      data.fileType = props?.fileType;
    }
    setSelectedVariableData(data);
    setSelectedVaribale(type);
    if (type) {
      data['reference'] = type === 'Step' ? 'STEPGROUP' : type === 'PROJECT_ENVIRONMENT' ? 'PROJECT_ENVIRONMENT' : type;
    }
    if (type === 'DATAPROVIDER') {
      props.setFieldValue(props.stepInputName, `${data.dpName}:${data.varname}`);
      if (props.onVariableSelect) props.onVariableSelect({ name: data.dpName + ':' + data.varname });
    } else {
      props.setFieldValue(`${props.stepInputName}`, data.name);
      if (props.onVariableSelect) props.onVariableSelect(data);
    }
    hideVariables();

    if (props.setReturnValue) {
      if (type === 'Step') {
        props.setReturnValue(data, props.stepInputName, 'STEPGROUP');
      } else if (type === 'PROJECT_ENVIRONMENT') {
        props.setReturnValue(data, props.stepInputName, 'PROJECT_ENVIRONMENT');
      } else {
        props.setReturnValue(data, props.stepInputName, type);
      }
    }
    if (props.setVariableValue) {
      props.setVariableValue(data, props.stepInputName, 'variable');
    }
  };

  const updateVariableHandler = (data, type) => {
    props?.reloadStepsTable();
    selectedLocVariable.current = {
      name: data?.name,
      id: data?.id,
      reference: type === 'Step' ? 'STEPGROUP' : type,
    };
    switch (type) {
      case 'LOCAL':
        getAllLocalVariables();
        break;
      case 'GLOBAL':
        getGlobalVariable();
        break;
      case 'PROJECT_ENVIRONMENT':
        getProjectEnvironmentVariable();
        break;
      case 'STEPGROUPINPUT':
        getStepGroupParameter();
        break;
      case 'DATAPROVIDER':
        getDataProviderVariableData();
        break;
      case 'FORLOOP':
        getForLoopVariable();
        break;
      default:
        getStepGroupVariable();
    }
    variableClick(data, type);
  };
  const [fileArray, setFileArray] = useState([]);
  const [filePathArray, setFilePathArray] = useState([]);
  const [allFilePathArray, setAllFilePathArray] = useState([]);
  let [selectedVariableType, setSelectedVaribale] = useState(props?.reference);
  let [variableName, setVariableName] = useState();
  let [variableValue, setVariableValue] = useState();
  const [pathVariableName, setPathVariableName] = useState(null);

  const checkStartWithDollar = (e) => {
    if (e.target.value.startsWith('$')) {
      showVariables();
      setIsPathSuggestionDropdown(false);
      search(e.target.value.substring(1));
    } else {
      hideFilePaths();
      hideVariables();
      if (['LOCAL', 'from_local', 'TESTDATA', 'from_local']?.includes(props?.fileType)) {
        if (props?.setFileType) {
          props?.setFileType(props?.fileType === 'LOCAL' ? 'LOCAL' : '');
        }
        let stepInputs = props?.stepsDataObject?.stepInputs?.map((input) => {
          if (input?.name === props?.stepInputName) {
            input.value = props?.fileType === 'LOCAL' ? e.target.value : null;
            input.fileType = props?.identity === 'from_local' ? 'LOCAL' : null;
          }
          return input;
        });
        let data = { ...props?.stepsDataObject };
        data.stepInputs = stepInputs;
        props?.setStepsDataObject(data);
      }
      if (props?.stepInputType === 'localpath') {
        props.setVariableValue(e.target.value, props.stepInputName, 'hardcoded');
      }
      setVariableName(e.target.value);
    }
    if (!e.target.value) {
      if (props.stepInputName === 'returnValue') {
        props.removeStepRef();
      }
    }
  };

  const checkStartsWithHash = (e) => {
    const { inputValue, fileArrayOption, filteredArray } = searchFilePathBasedOnHash(
      e,
      filePathArray,
      allFilePathArray
    );
    if (fileArrayOption) {
      setVariableName(fileArrayOption.path);
      hideFilePaths();
      hideFolderPaths();
      hideFileAndFolderPaths();
    } else {
      if (props?.stepInputs?.name === 'File or Folder Path' && inputValue.startsWith('#')) {
        showFileAndFolderPaths();
        getFolderPath();
        getAllFilePaths(fileTypes?.includes(type) ? type : null);
      } else if (inputValue.startsWith('#') && inputValue.length > 1) {
        if (['differenceImageDirectory', 'folderPath']?.includes(props?.stepInputName) || props?.isFolderDropdown) {
          setFileArray(filteredArray);
          showFolderPaths();
        } else {
          setFilePathArray(filteredArray);
          showFilePaths();
        }
        hideFileAndFolderPaths();
      } else if (inputValue.startsWith('#')) {
        if (FOLDER_PATH_ARRAY.includes(props?.stepInputName) || props?.isFolderDropdown) {
          getFolderPath();
          hideFilePaths();
          showFolderPaths();
        } else {
          getAllFilePaths(fileTypes?.includes(type) ? type : null);
          showFilePaths();
          hideFolderPaths();
        }
        hideFileAndFolderPaths();
      } else {
        hideFilePaths();
        hideFolderPaths();
        hideFileAndFolderPaths();
        setVariableName(inputValue);
      }
    }
    if (!inputValue) {
      if (props.stepInputName === 'returnValue') {
        props.removeStepRef();
      }
    }
  };

  const search = (data) => {
    const getFilteredList = (variableList) =>
      variableList.filter(
        (variable) =>
          variable?.name?.toLowerCase().includes(data?.toLowerCase()) ||
          variable?.colName?.toLowerCase()?.includes(data?.toLowerCase())
      );
    if (data?.trim()?.length) {
      setFilteredLocalVariables(getFilteredList(localVariables));
      setFilteredGlobalVariables(getFilteredList(globalVariables));
      setFilteredProjectEnvironmentalVariables(getFilteredList(projectEnvironmentVariables));
      setFilteredStepGroupParameters(getFilteredList(stepGroupParameters));
      setFilteredStepGroupVariables(getFilteredList(stepGroupVariables));
      setFilteredDataProviderVariables(getFilteredList(dataProviderVariableData));
    } else {
      setFilteredLocalVariables(localVariables);
      setFilteredGlobalVariables(globalVariables);
      setFilteredProjectEnvironmentalVariables(projectEnvironmentVariables);
      setFilteredStepGroupParameters(stepGroupParameters);
      setFilteredStepGroupVariables(stepGroupVariables);
      setFilteredDataProviderVariables(dataProviderVariableData);
    }
  };

  const createVariable = (e) => {
    setOpenCreateVariableModal(true);
    setSelectedVariableData();
  };

  const createSelectedVariableObject = (variable) => {
    let selectedVarObject;
    if (props.editData) {
      selectedVarObject = variable?.find((data) => data.name === props.editData);
      if (selectedLocVariable?.current?.id) {
        selectedVarObject = variable?.find((data) => data.name === selectedLocVariable?.current?.name);
      }
      if (!selectedVarObject && selectedLocVariable.current?.name !== props?.editData) {
        selectedVarObject = variable?.find((data) => data.id === selectedLocVariable.current.id);
      }
    } else if (selectedLocVariable.current?.id) {
      selectedVarObject = variable?.find((data) => data.id === selectedLocVariable.current.id);
    }
    if (selectedVarObject) {
      setSelectedVariableData(selectedVarObject);
    }
  };

  const getAllLocalVariables = async (onLoadCall) => {
    try {
      const { moduleId, scriptId } = props;
      if ((onLoadCall && localVariables.length) || !scriptId) {
        if (onLoadCall && props?.reference === 'LOCAL') {
          createSelectedVariableObject(localVariables);
        }
      }
      const response = await getAllLocalVariablesReq(moduleId, scriptId);
      if (response?.data?.responseObject) {
        setLocalVariables(response.data.responseObject);
        setFilteredLocalVariables(response.data.responseObject);
        if ([props?.reference, selectedLocVariable.current?.reference]?.includes('LOCAL')) {
          createSelectedVariableObject(response.data.responseObject);
        }
      } else {
        setLocalVariables([]);
        setFilteredLocalVariables([]);
      }
    } catch (err) {
      console.error('GET_LOCAL_VARIABLES :', err);
    }
  };
  const getStepGroupParameter = async (onLoadCall) => {
    try {
      const search = window.location.search;
      const id = new URLSearchParams(search).get('id');
      const parentId = new URLSearchParams(search).get('parentId');
      if ((onLoadCall && stepGroupParameters.length) || !id) {
        createSelectedVariableObject(stepGroupParameters);
      }
      const response = await gellAllParameter(parentId, id);
      if (response?.data?.responseObject) {
        setStepGroupParameters(response.data.responseObject);
        setFilteredStepGroupParameters(response.data.responseObject);
        if ([props?.reference, selectedLocVariable.current?.reference]?.includes('STEPGROUPINPUT')) {
          createSelectedVariableObject(response.data.responseObject);
        }
      } else {
        setStepGroupParameters([]);
        setFilteredStepGroupParameters([]);
      }
    } catch (err) {
      console.error('GET_STEPGROUP_PARAMETER :', err);
    }
  };
  const getStepGroupVariable = async (onLoadCall) => {
    try {
      const search = window.location.search;
      const id = new URLSearchParams(search).get('id');
      const parentId = new URLSearchParams(search).get('parentId');
      if ((onLoadCall && stepGroupVariables.length) || !id) {
        if (onLoadCall && props?.reference === 'STEPGROUP') {
          createSelectedVariableObject(stepGroupVariables);
        }
      }
      const response = await getAllStepGroupVariable(parentId, id);
      if (response?.data?.responseObject) {
        setStepGroupVariables(response.data.responseObject);
        setFilteredStepGroupVariables(response.data.responseObject);
        if ([props?.reference, selectedLocVariable.current?.reference]?.includes('STEPGROUP')) {
          createSelectedVariableObject(response.data.responseObject);
        }
      } else {
        setStepGroupVariables([]);
        setFilteredStepGroupVariables([]);
      }
    } catch (err) {
      console.error('GET_STEPGROUP_VARIABLE :', err);
    }
  };
  const getGlobalVariable = async (onLoadCall) => {
    try {
      if (onLoadCall && globalVariables.length) {
        if (onLoadCall && props?.reference === 'GLOBAL') {
          createSelectedVariableObject(globalVariables);
        }
      }
      const response = await getAllGlobalVariableListReq();
      if (response?.data?.responseObject) {
        setGlobalVariables(response.data.responseObject);
        setFilteredGlobalVariables(response.data.responseObject);
        if ([props?.reference, selectedLocVariable.current?.reference]?.includes('GLOBAL')) {
          createSelectedVariableObject(response.data.responseObject);
        }
      } else {
        setGlobalVariables([]);
        setFilteredGlobalVariables([]);
      }
    } catch (err) {
      console.error('GET_GLOBAL_VARIABLES : ', err);
    }
  };
  const getProjectEnvironmentVariable = async (onLoadCall) => {
    try {
      if (onLoadCall && projectEnvironmentVariables.length) {
        if (onLoadCall && props?.reference === 'PROJECT_ENVIRONMENT') {
          createSelectedVariableObject(projectEnvironmentVariables);
        }
      }
      const response = await getAllProjectVariableListReq();
      if (response?.data?.responseObject) {
        setProjectEnvironmentVariables(response.data.responseObject);
        setFilteredProjectEnvironmentalVariables(response.data.responseObject);
        if ([props?.reference, selectedLocVariable.current?.reference]?.includes('PROJECT_ENVIRONMENT')) {
          createSelectedVariableObject(response.data.responseObject);
        }
      } else {
        setProjectEnvironmentVariables([]);
        setFilteredProjectEnvironmentalVariables([]);
      }
    } catch (err) {
      console.error('GET_PROJECT_ENV_VARIABLES : ', err);
    }
  };

  const getDataProviderVariableData = (onLoadCall) => {
    if (onLoadCall && dataProviderVariableData?.length) {
      return;
    }
    if (
      (['_startforloop', '_startiteration'].includes(props?.parentStartDataproviderStep?.type) &&
        props?.parentStartDataproviderStep?.dataProviderVariables &&
        props?.parentStartDataproviderStep?.dataProviderVariables?.length) ||
      (props?.parentStartIfStep?.dataProviderVariables && props?.parentStartIfStep?.dataProviderVariables?.length)
    ) {
      let _dataProviderVariables;
      if (props?.parentStartIfStep?.dataProviderVariables?.length) {
        _dataProviderVariables = props.parentStartIfStep.dataProviderVariables;
      } else {
        _dataProviderVariables = props?.parentStartDataproviderStep?.dataProviderVariables;
      }
      _dataProviderVariables = _dataProviderVariables || [];
      setDataProviderVariableData(_dataProviderVariables);
      setFilteredDataProviderVariables(_dataProviderVariables);
      if (onLoadCall && props?.reference === 'DATAPROVIDER') {
        const [dpName, varname] = props?.editData?.split(':');
        _dataProviderVariables.forEach((data) => {
          if (data.dpName === dpName && data.varname === varname) {
            setSelectedVariableData(data);
          }
        });
      }
    }
  };

  const getForLoopVariable = (onLoadCall) => {
    const forVar = [
      {
        name: 'for:initialValue',
        reference: 'FORLOOP',
        value: '',
        type: 'string',
      },
    ];
    if (
      props.parentStartDataproviderStep?.type === '_startforloop' ||
      props.parentStartDataproviderStep?.forLoopVariables?.length ||
      props?.parentStartIfStep?.forLoopVariables?.length
    ) {
      setForLoopVariable(forVar);
      if (onLoadCall && props?.reference === 'FORLOOP') {
        setSelectedVariableData(forVar[0]);
        setSelectedVaribale('FORLOOP');
      }
    }
  };
  const getTextFieldLabel = () => {
    let fieldName = '';
    if (props?.stepInputName === 'returnValue') {
      fieldName = 'Assign Step Return Value To';
    } else if (props?.dynamicInputFieldName) {
      fieldName = props?.dynamicInputFieldName.replaceAll('dynamic', 'Dynamic Val');
    } else {
      fieldName = props?.stepInputName?.charAt(0).toUpperCase() + props?.stepInputName?.slice(1);
    }
    return (
      <>
        <span className="text-red-400 mr-1 fontPoppinsRegularXLg">&#42;</span>
        <span className="fontPoppinsRegularXLg inline-flex items-center">
          {fieldName}
          {props?.stepInputs?.infoText && (
            <Tooltip title={props?.stepInputs?.infoText} placement="right" classes={{ tooltip: classes.customTooltip }}>
              <span className="ml-1">
                <Icon name="info_icon" width={18} height={18} />
              </span>
            </Tooltip>
          )}
        </span>
      </>
    );
  };

  const getTextFieldPlaceholder = () => {
    if (
      !['rowIndex', 'returnValue', 'columnIndex', 'data', 'sheetName']?.includes(props?.stepInputName) &&
      props?.stepInputType !== 'localpath'
    ) {
      return 'Select path using # or variable using $';
    } else if (props?.stepInputType?.includes('com.tyss.optimize.nlp.util')) {
      return 'Search and select file';
    } else if (props?.isFolderDropdown) {
      return 'Select folder path';
    } else {
      return 'Search for variable using $';
    }
  };

  const editVariableHyperLinkRenderer = (field) => {
    let isVariableDisabled = false;
    if (selectedVariableData?.isSystemVariable || selectedVariableData?.reference === 'FORLOOP') {
      isVariableDisabled = true;
    }
    return selectedVariableData ? (
      <span
        id="hoverable"
        className={`${
          isVariableDisabled ? 'text-blue-500 opacity-50' : 'text-blue-500 cursor-pointer'
        } fontPoppinsRegularMd float-right ${field?.name === 'input3' ? 'mr-4' : 'mr-14'}`}
        onClick={() => {
          if (!isVariableDisabled) {
            viewEditVariableDetails('edit');
          }
        }}
      >
        {isVariableDisabled ? (
          <Tooltip
            title={`${
              selectedVariableData?.reference === 'FORLOOP' ? 'For Loop' : 'Default'
            } variables can't be edited`}
            placement="top"
          >
            <span>{`Edit ${selectedVariableType === 'STEPGROUPINPUT' ? 'parameter' : 'Variable'}`}</span>
          </Tooltip>
        ) : (
          `Edit ${selectedVariableType === 'STEPGROUPINPUT' ? 'parameter' : 'Variable'}`
        )}
      </span>
    ) : null;
  };

  const getFolderPath = async () => {
    try {
      const response = await getFolderPathReq();
      const {
        data: { responseObject },
      } = response || {};
      if (!isEmptyValue(responseObject)) {
        const folderPaths = responseObject.map((folder) => ({
          ...folder,
          path: pathName(folder.actualPath),
        }));
        setFileArray([...folderPaths]);
        setAllFilePathArray([...folderPaths]);
      } else {
        setFileArray([]);
      }
    } catch (err) {
      console.error('GET_FOLDER_PATH :', err);
    }
  };
  const getAllFilePaths = async (type) => {
    try {
      const response = await getAllTestDataFiles(type === 'xls' ? 'xlsx' : type);
      const {
        data: { responseObject },
      } = response || {};
      if (!isEmptyValue(responseObject)) {
        const folderPaths = responseObject.map((folder) => ({
          ...folder,
          path: pathName(folder.actualPath),
        }));
        setFilePathArray([...folderPaths]);
        setAllFilePathArray([...folderPaths]);
      } else {
        setFilePathArray([]);
      }
    } catch (error) {
      console.error('Get all file path :', error);
    }
  };
  const handleOptionSelect = (option) => {
    props?.setFieldValue(props?.stepInputName, option?.actualPath);
    props?.setVariableValue(option, props.stepInputName, 'file');
    setPathVariableName(option?.name);
    setVariableValue(option?.actualPath);
    if (props?.onPathSelect) {
      option.type = 'TESTDATA';
      props?.onPathSelect(option);
    }
    hideFilePaths();
    hideFolderPaths();
    hideFileAndFolderPaths();
  };

  useEffect(() => {
    if (props.parentStartDataproviderStep) {
      getDataProviderVariableData();
    }
    if (
      props?.stepInputType.includes(HASH_LABELS.STEP_INPUT_TYPE_UTIL) &&
      (!['LOCAL', 'GLOBAL', 'PROJECT_ENVIRONMENT', 'STEPGROUPINPUT', 'DATAPROVIDER', 'FORLOOP'].includes(
        props?.reference
      ) ||
        props.identity === HASH_LABELS.TEST_DATA)
    ) {
      fetchFiles();
    } else if (props?.stepInputName === HASH_LABELS.FOLDER_PATH && props?.isFolderDropdown) {
      getFolderPath();
    }
  }, [props.stepId, props.stepInputType]);

  useEffect(() => {
    initializeVariableData();
  }, [props.stepId]);

  function fetchFiles() {
    const project = JSON.parse(localStorage.getItem('selected-project'));
    getAllFiles(project.id, type).then((response) => {
      let options = [];
      if (response.data && response.data.responseCode === 200 && response.data.responseObject.length) {
        response.data.responseObject.forEach((file) => {
          if (file.actualPath?.includes('/Root/')) {
            options.push({
              name: file.actualPath.substr(file.actualPath.search('/Root/') + 6),
              path: file.actualPath,
            });
          }
        });
        setFileArray(options);
      }
    });
  }
  function initializeVariableData() {
    setSelectedVaribale(props?.reference);
    setVariableName(props?.editData);
    getAllLocalVariables(true);
    getStepGroupParameter(true);
    getGlobalVariable(true);
    getProjectEnvironmentVariable(true);
    getStepGroupVariable(true);
    getDataProviderVariableData(true);
    getForLoopVariable(true);
  }

  useEffect(() => {
    if (props?.clearField) {
      clearVariableData();
    }
  }, [props?.clearField]);

  const clearVariableData = () => {
    setSelectedVariableData();
    props.setFieldValue(`${props.stepInputName}`, '');
    if (props.onClearField) {
      props.onClearField(props.stepInputName);
    }
    props.reloadStepsTable();
    if (props.buttonDisable) {
      props.buttonDisable();
    }
    if (props.hasOwnProperty('setClearField')) {
      props.setClearField(false);
    }
  };

  const getCreateVariableCondition = (props) => {
    if (props?.type === 'Step Group') {
      return selectedVariableType !== 'DATAPROVIDER' && !props?.reference;
    } else if (!isEmptyValue(onChangeValue)) {
      if (selectedLocVariable?.current?.name) {
        return false;
      } else if (onChangeValue !== selectedVariableData?.value) {
        return true;
      }
    } else {
      return !selectedVariableData;
    }
  };
  const onValueChangeHandler = (value) => {
    setSelectedVariableData();
    checkStartWithDollar(value);
    checkStartsWithHash(value);
    selectedLocVariable.current = {};
  };
  const handleDelimiterCheckBox = (e) => {
    const checkboxLabel = e.target.getAttribute('label');
    let stepsDataObject = { ...props?.stepsDataObject };
    let stepInputs = stepsDataObject?.stepInputs;
    const inputObj = stepInputs?.find((input) => input.checkboxLabel === checkboxLabel);
    if (inputObj) {
      inputObj.checkboxValue = e.target.checked;
      setLocalCheckboxValue(e.target.checked);
    }

    stepsDataObject.stepInputs = stepInputs;
    if (props?.setStepsDataObject) {
      props?.setStepsDataObject(stepsDataObject);
    }
  };

  return (
    <div className="text-xs text-blue-700 relative fontPoppinsRegularMd" id="hovered">
      <Field name={props.stepInputName}>
        {({ field, form, meta }) => {
          return (
            <div className={`${field?.name === 'input3' ? 'flex flex-col ' : ''}mb-2 pb-2`}>
              <TooltipPoppin
                title={
                  selectedVariableData && selectedVariableType
                    ? ['LOCAL', 'GLOBAL', 'PROJECT_ENVIRONMENT', 'STEPGROUP', 'STEPINPUT'].includes(
                        selectedVariableType
                      )
                      ? `${
                          selectedVariableData?.name +
                          '-' +
                          selectedVariableType.charAt(0)?.toUpperCase() +
                          selectedVariableType.slice(1).toLowerCase() +
                          ' variable'
                        } `
                      : ['Step'].includes(selectedVariableType)
                      ? `${
                          selectedVariableData?.name +
                          '-' +
                          selectedVariableData.parentVariableType.charAt(0)?.toUpperCase() +
                          selectedVariableData.parentVariableType.slice(1).toLowerCase() +
                          ' variable'
                        } `
                      : ['STEPGROUPINPUT'].includes(selectedVariableType)
                      ? `${selectedVariableData?.name + '-' + REPO.PARAMETER} `
                      : `${field.value}`
                    : ''
                }
                placement="bottom"
              >
                {selectedVariableData && selectedVariableType ? (
                  <TextField
                    {...field}
                    error={meta.touched && meta.error}
                    autoFocus={false}
                    autoComplete="off"
                    onBlur={(e) => {
                      props.handleBlur(e);
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'w-max',
                    }}
                    label={getTextFieldLabel()}
                    type="text"
                    id={props?.stepInputName}
                    disabled={props?.defaultStepGroup ? (props?.defaultStepGroup === 'false' ? false : true) : false}
                    className={`search-nlp text-xs text-blue-700 ${props?.widthClass || 'w-10/12'} `}
                    value={field.value}
                    onChange={(e) => {
                      onValueChangeHandler(e);
                      props.handleChange(e);
                      if (props.buttonDisable) {
                        props.buttonDisable();
                      }
                      setOnChangeValue(e.target.value);
                      if (!isPathSuggestionDropdown) {
                        if (props.removeStepRef) {
                          props.removeStepRef();
                        }
                        if (props.setVariableValue) {
                          props.setVariableValue(e.target.value, props.stepInputName, 'hardcoded');
                        }
                      }
                    }}
                    onKeyUp={(e) => {
                      if (!isPathSuggestionDropdown) {
                        if (props.removeStepRef) {
                          props.removeStepRef();
                        }
                        onValueChangeHandler(e);
                      }
                    }}
                    onFocus={(e) => {
                      checkStartWithDollar(e);
                      checkStartsWithHash(e);
                    }}
                    placeholder={getTextFieldPlaceholder()}
                  />
                ) : (
                  <TextField
                    {...field}
                    error={meta.touched && meta.error}
                    autoFocus={false}
                    autoComplete="off"
                    onBlur={(e) => {
                      props.handleBlur(e);
                      hideVariables();
                    }}
                    onClick={() => {
                      if (isPathSuggestionDropdown) {
                        // showVariables();
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'w-max',
                    }}
                    label={getTextFieldLabel()}
                    type="text"
                    id={props?.stepInputName}
                    className={`search-nlp text-xs text-blue-700 ${props?.widthClass || 'w-10/12'} `}
                    value={field.value}
                    onChange={(e) => {
                      checkStartWithDollar(e);
                      if (
                        !['returnValue', 'rowIndex', 'columnIndex', 'data', 'sheetName']?.includes(
                          props?.stepInputName
                        ) &&
                        props?.stepInputType !== 'localpath'
                      ) {
                        checkStartsWithHash(e);
                      }
                      if (props.buttonDisable) {
                        props.buttonDisable();
                      }
                      props.handleChange(e);
                      if (!isPathSuggestionDropdown) {
                        if (props.removeStepRef) {
                          props.removeStepRef();
                        }
                        if (props.setVariableValue) {
                          props.setVariableValue(e.target.value, props.stepInputName, 'hardcoded');
                        }
                      }
                      selectedLocVariable.current = {};
                    }}
                    onKeyUp={(e) => {
                      if (!isPathSuggestionDropdown) {
                        if (props.removeStepRef) {
                          props.removeStepRef();
                        }
                        setSelectedVariableData();
                        checkStartWithDollar(e);
                        if (
                          !['rowIndex', 'returnValue', 'columnIndex', 'data', 'sheetName']?.includes(
                            props?.stepInputName
                          ) &&
                          props?.stepInputType !== 'localpath'
                        ) {
                          checkStartsWithHash(e);
                        }
                        selectedLocVariable.current = {};
                      }
                    }}
                    onFocus={(e) => {
                      checkStartWithDollar(e);
                      if (
                        !['rowIndex', 'returnValue', 'columnIndex', 'data', 'sheetName']?.includes(
                          props?.stepInputName
                        ) &&
                        props?.stepInputType !== 'localpath'
                      ) {
                        checkStartsWithHash(e);
                      }
                    }}
                    placeholder={getTextFieldPlaceholder()}
                  />
                )}
              </TooltipPoppin>
              {showFilePathSuggestion && (
                <ClickAwayListener onClickAway={hideFilePaths}>
                  <div className="absolute">{renderFilePath(filePathArray, handleOptionSelect, 'modal')}</div>
                </ClickAwayListener>
              )}
              {showFolderPathSuggestion && (
                <ClickAwayListener onClickAway={hideFolderPaths}>
                  <div className="absolute">{renderFilePath(fileArray, handleOptionSelect, 'modal')}</div>
                </ClickAwayListener>
              )}
              {showFileAndFolderPathSuggestion && (
                <ClickAwayListener onClickAway={hideFileAndFolderPaths}>
                  <div className="absolute">
                    {renderFileAndFolderPath([...fileArray, ...filePathArray], handleOptionSelect, 'modal')}
                  </div>
                </ClickAwayListener>
              )}
              {
                <div id="hoverable">
                  {getCreateVariableCondition(props) && field.value && props?.reference !== 'DATAPROVIDER' ? (
                    <Tooltip title="Create as variable">
                      <AddToPhotosOutlined
                        id={`create-${props.id}`}
                        className={` float-right cursor-pointer mr-3  -mt-6 text-blue-700 ml-5`}
                        style={{ fontSize: '20px', marginLeft: '220.5px' }}
                        onClick={createVariable}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              }

              {
                <div id="hoverable">
                  {(variableName || fileArray) && field.value ? (
                    <Clear
                      className={` float-right cursor-pointer  mr-9 -mt-6 text-red-700`}
                      style={{ fontSize: '20px' }}
                      onClick={() => clearVariableData()}
                    />
                  ) : null}
                </div>
              }

              {selectedVariableType !== 'DATAPROVIDER' && props?.reference && (
                <div id={`view-edit-${props.id}`} className="">
                  {selectedVariableData && (
                    <div className="flex whitespace-nowrap justify-between">
                      <span
                        id="hoverable"
                        className="text-blue-500 cursor-pointer float-left"
                        onClick={() => {
                          if (selectedVariableData) {
                            viewEditVariableDetails('view');
                          }
                        }}
                      >
                        {selectedVariableData && (
                          <>
                            View {selectedVariableType === 'STEPGROUPINPUT' ? 'parameter' : 'variable'}{' '}
                            {field?.name === 'input3' ? '' : 'details'}
                          </>
                        )}
                      </span>
                      {editVariableHyperLinkRenderer(field)}
                    </div>
                  )}
                </div>
              )}

              {meta.touched && meta.error && (
                <div className={`errorMessage  ${field?.name === 'input3' ? 'relative' : 'absolute'}`}>
                  {meta.error.charAt(0).toUpperCase() + meta.error.slice(1).toLowerCase()}
                </div>
              )}
              {props?.stepInputs?.checkboxLabel && (
                <div className="mt-4">
                  <Checkbox
                    key={props?.stepInputs?.checkboxValue}
                    label={props?.stepInputs?.checkboxLabel}
                    value={localCheckboxValue}
                    checked={localCheckboxValue}
                    onChange={(e) => handleDelimiterCheckBox(e)}
                  />
                </div>
              )}
            </div>
          );
        }}
      </Field>
      {showSuggestion && (
        <div
          className={cx('border shadow rounded text-sm bg-white z-10', styles['variable-suggestion-dropdown'])}
          id={props.id}
        >
          {isPathSuggestionDropdown ? (
            <div className="mt-2">
              {fileArray.map((data, index) => {
                if (isEmptyValue(onChangeValue?.trim())) {
                  return (
                    <div
                      onMouseDown={() => {
                        props?.setFieldValue(`${props.stepInputName}`, data?.name);
                        props?.setVariableValue(data, props.stepInputName, 'file');
                        hideVariables();
                      }}
                      id={'element' + index.toString()}
                      key={index}
                      className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                    >
                      <p className="mt-3">{data?.name}</p>
                    </div>
                  );
                } else {
                  const filteredData = fileArray.filter((item) =>
                    item.name.toLowerCase().includes(onChangeValue.toLowerCase())
                  );
                  if (filteredData.some((item) => item.name === data.name)) {
                    return (
                      <div
                        onMouseDown={() => {
                          props.setFieldValue(`${props.stepInputName}`, data.name);
                          props.setVariableValue(data, props.stepInputName, 'file');
                          hideVariables();
                        }}
                        id={'element' + index.toString()}
                        key={index}
                        className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                      >
                        <p className="mt-3">{data?.name}</p>
                      </div>
                    );
                  } else {
                    return null;
                  }
                }
              })}
            </div>
          ) : (
            <>
              {props.type === 'Step Group' ? (
                <>
                  {!props?.setReturnValue && (
                    <div className="mt-2">
                      {filteredStepGroupParameters.length ? <p className="ml-3">Step Group Parameter</p> : null}
                      {filteredStepGroupParameters.map((data, index) => {
                        return (
                          <div
                            onMouseDown={() => {
                              variableClick(data, 'STEPGROUPINPUT');
                            }}
                            id={'STEPGROUPINPUT' + index}
                            key={'STEPGROUPINPUT' + index}
                            className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                          >
                            <p className="mt-3">{data?.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="mt-2">
                    {filteredStepGroupVariables.length ? <p className="ml-3">Step Group Variable</p> : null}
                    {filteredStepGroupVariables.map((data, index) => {
                      return (
                        <div
                          onMouseDown={() => {
                            variableClick(data, 'STEPGROUP');
                          }}
                          id={'STEPGROUP' + index}
                          key={'STEPGROUP' + index}
                          className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                        >
                          <p className="mt-3">{data?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="mt-2">
                  {filteredLocalVariables.length ? <p className="ml-3">Local Variable</p> : null}
                  {filteredLocalVariables.map((data, index) => {
                    return (
                      <div
                        onMouseDown={() => {
                          variableClick(data, 'LOCAL');
                        }}
                        id={'LOCAL' + index}
                        key={'LOCAL' + index}
                        className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                      >
                        <p className="mt-3">{data?.name}</p>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="mt-2">
                {filteredGlobalVariables.length ? <p className="ml-3">Global Variable</p> : null}
                {filteredGlobalVariables.map((data, index) => {
                  return (
                    <div
                      onMouseDown={() => {
                        variableClick(data, 'GLOBAL');
                      }}
                      id={'GLOBAL' + index}
                      key={'GLOBAL' + index}
                      className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                    >
                      <p className="mt-3">{data?.name}</p>
                    </div>
                  );
                })}
              </div>
              <div className="mt-2">
                {filteredProjectEnvironmentalVariables.length ? (
                  <>
                    <p className="ml-3">Project Environment Variable</p>
                    {filteredProjectEnvironmentalVariables.map((data, index) => {
                      return (
                        <div
                          onMouseDown={() => {
                            variableClick(data, 'PROJECT_ENVIRONMENT');
                          }}
                          id={'PROJECT_ENVIRONMENT' + index}
                          key={'PROJECT_ENVIRONMENT' + index}
                          className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                        >
                          <p className="mt-3">{data?.name}</p>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
              <div className="mt-2">
                {filteredDataProviderVariables?.length ? <p className="ml-3">Data Provider Variable</p> : null}
                {filteredDataProviderVariables?.map((data, index) => {
                  return (
                    <div
                      onMouseDown={() => {
                        variableClick(data, 'DATAPROVIDER');
                      }}
                      id={'DATAPROVIDER' + index}
                      key={'DATAPROVIDER' + index}
                      className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                    >
                      <p className="mt-3">
                        {data?.dpName}:{data?.varname}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="mt-2">
                {forLoopVariable && forLoopVariable.length ? <p className="ml-3">For Loop Variable</p> : null}
                {forLoopVariable &&
                  forLoopVariable.map((data, index) => {
                    return (
                      <div
                        onMouseDown={() => {
                          variableClick(data, 'FORLOOP');
                        }}
                        id={'element' + index.toString()}
                        key={index}
                        className="cursor-pointer ml-3 mt-1 mb-1 text-gray-500"
                      >
                        <p className="mt-3">{data?.name}</p>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      )}

      {/* modal to create and edit variable */}
      {openCreateVariableModal ? (
        <CreateVariable
          createVariableFromModuleCondition={props.createVariableFromModuleCondition || null}
          returnValueScript={props?.setReturnValue ? true : null}
          MyAlert={props?.MyAlert || MyAlert}
          data={selectedVariableData}
          closeModal={closeModal}
          variableName={pathVariableName || variableName}
          variableValue={variableValue}
          variableType={
            props.createVariableFromModuleCondition ? 'GLOBAL' : props.type === 'Step Group' ? 'Step' : 'LOCAL'
          }
          id={{ moduleId: props.moduleId, scriptId: props.scriptId }}
          variableClick={updateVariableHandler}
          type={props.type}
        />
      ) : null}
      {/* modal for variable details */}
      {openLocalVariableDetailsModal ? (
        <VariablesDetailsModal closeModal={closeModal} data={selectedVariableData} />
      ) : null}
      {openStepGroupParameterDetailsModal ? (
        <VariableDetailsModal type="Parameter" closeModal={closeModal} data={selectedVariableData} />
      ) : null}

      {openCreateStepGroupParameterModal ? (
        <ParamterModal
          closeModal={closeModal}
          data={selectedVariableData}
          mode="Edit"
          onUpdateUpdateValue={updateVariableHandler}
        />
      ) : null}
      {openGlobalProjectVariableDetailsModal ? (
        <VariablesDetailsModal closeModal={closeModal} data={selectedVariableData} />
      ) : null}
      {openStepGroupVariableDetailsModal ? (
        <VariableDetailsModal type="StepGroupVariable" closeModal={closeModal} data={selectedVariableData} />
      ) : null}
      {openCreateStepGroupVariableModal ? (
        <StepGroupVariableModal
          closeModal={closeModal}
          mode="Edit"
          data={selectedVariableData}
          onUpdateUpdateValue={updateVariableHandler}
        />
      ) : null}
    </div>
  );
};

export default VariableSuggestion;
