import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { useTable } from 'react-table';
import { getOsLabel } from '@src/pages/configuration/system_config/system_config v1.1/utils/sys-config-common-methods';
import './MachineTable.scss';
import { HighlightText } from '@util/common_utils';
import { REGEX } from '@src/util/validations';

const MachineTable = ({
  classes = '',
  section = 'default',
  inputType = 'radio',
  systems = [],
  onRowClickHandler = () => {},
  radioButtonOnChangeHandler = () => {},
  checkBoxOnChangeHandler = () => {},
  selectedSystems = [],
  currentVisibleSystem = {},
  errors = [],
  searchTextHighlight,
  isHighlight,
  localSelected,
  fireCloudSelected,
  suiteType,
  selectedExecutionType,
  isRootSelected,
  disableIds,
  ...props
}) => {
  const [selectedRowClientId, setSelectedRowClientId] = useState();
  const check3PEnvironment = REGEX.CHECK_3P_ENVIRONMENT;

  const handleRowSelect = (rowSelected) => {
    const systemSelected = rowSelected?.values?.name[0]?.system;
    if (!systemSelected.disabled || section === 'configuration') {
      setSelectedRowClientId(systemSelected.clientId);
      onRowClickHandler(systemSelected);
    }
  };

  const renderInputBox = (value) => {
    const system = value[0]?.system;
    const { clientId, disabled } = system || {};
    const { osName, osVersion, hostName } = system?.machine || {};
    let checked = false;

    if (inputType === 'radio') {
      checked = clientId === selectedSystems[0]?.clientId;
    } else {
      checked = selectedSystems.some((selSys) => clientId === selSys?.clientId);
    }
    if (['MULTIPLE', 'AUTOMATIC'].includes(selectedExecutionType) && section !== 'configuration') {
      if (selectedSystems.some((selSys) => selSys.executionEnvironment === 'FireCloud')) {
        system.disabled = system.executionEnvironment !== 'FireCloud';
      } else if (
        selectedSystems.some(
          (selSys) => selSys.executionEnvironment === 'Local' || check3PEnvironment.test(selSys.executionEnvironment)
        )
      ) {
        system.disabled = system.executionEnvironment === 'FireCloud';
      } else {
        system.disabled = false;
      }
    }

    const onChangeHandler = (e) => {
      if (inputType === 'radio') {
        radioButtonOnChangeHandler([{ ...system }]);
      } else {
        checkBoxOnChangeHandler(e, system);
      }
    };
    if (!(osName && osVersion) && !system?.executionEnvironment?.includes('FireCloud')) {
      return (
        <Tooltip
          placement="right"
          title={`${hostName} machine cannot be selected because its OS name or OS version is not available.`}
        >
          <input className="inline-block opacity-50" type={inputType} id={clientId} checked={false} />
        </Tooltip>
      );
    } else {
      return (
        <>
          {disabled ? (
            <Tooltip title="Not allowed to execute on Local and FireCloud Environment simultaneously" placement="top">
              <div>
                <input
                  disabled={disabled}
                  className={`inline-block ${disabled ? 'opacity-50' : ''}`}
                  type={inputType}
                  name="selectedSystems"
                  value={clientId}
                  id={clientId}
                  onChange={onChangeHandler}
                  checked={checked}
                />
              </div>
            </Tooltip>
          ) : (
            <input
              disabled={disabled}
              className={`inline-block ${disabled ? 'opacity-50' : ''}`}
              type={inputType}
              name="selectedSystems"
              value={clientId}
              id={clientId}
              onChange={onChangeHandler}
              checked={checked}
            />
          )}
        </>
      );
    }
  };

  const getAccessTextColor = (accessStatus) => {
    switch (accessStatus?.toLowerCase()) {
      case 'public':
        return 'access-status-success';
      case 'partial public':
        return 'access-status-warning';
      case 'private':
        return 'access-status-error';
      default:
        return 'access-status-warning';
    }
  };
  const tableRowRenderer = (value, searchTextHighlight) => {
    const system = value[0]?.system;

    if (system?.executionEnvironment === 'FireCloud' && localSelected && section !== 'configuration') {
      system.disabled = localSelected;
    }
    if (fireCloudSelected && system?.executionEnvironment !== 'FireCloud' && section !== 'configuration') {
      system.disabled = true;
    }
    if (isRootSelected && !disableIds.includes(system.id)) {
      system.disabled = false;
    }

    const { access, disabled } = system || {};
    let { osName, osVersion, hostName } = system?.machine || {};
    const { osName: selectedOsName, osVersion: selectedOsVersion } =
      selectedSystems?.find((systemItem) => systemItem?.clientId === system?.clientId)?.machine || {};
    if (selectedOsName) {
      osVersion = selectedOsVersion;
      osName = selectedOsName;
    }

    return (
      <div className={`grid grid-cols-6 table-row-div pl-2.5 ${disabled ? 'opacity-50' : ''}`}>
        <div className="col-span-5 pt-2.5 flex">
          {renderInputBox(value)}
          <Tooltip title={hostName} placement="top">
            <span className="ml-2.5 px-3 machine-name text-overflow-style">
              <HighlightText text={hostName} highlight={searchTextHighlight} />
            </span>
          </Tooltip>
          <span className="machine-os-details text-overflow-style">
            {getOsLabel(osName, 'inline-block', true)}
            <Tooltip title={osVersion} placement="top">
              <span className="px-2 ">{osVersion}</span>
            </Tooltip>
          </span>
        </div>
        <div className="flex flex-row-reverse access-status fontPoppinsSemiboldXXs pr-1">
          <Tooltip title={'Access:' + access} placement="top">
            <span className={getAccessTextColor(access)}>{access}</span>
          </Tooltip>
        </div>
      </div>
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Client Machine',
        className: 'fontPoppinsRegularSm text-left block',
        width: '100%',
        accessor: 'name',
        Cell: ({ value }) => {
          return tableRowRenderer(value, searchTextHighlight);
        },
      },
    ],
    [selectedSystems, isHighlight]
  );

  const data = useMemo(() => {
    let a = [];
    systems?.forEach((_machine) => {
      a.push({
        name: [{ system: _machine }],
      });
    });
    return a;
  }, [systems]);
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });

  useEffect(() => {
    setSelectedRowClientId(currentVisibleSystem?.clientId);
  }, []);

  return (
    <div className={classes}>
      <table {...getTableProps()} className="table-non-link-color-common font-style-common">
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const { clientId } = row.values.name[0].system || {};
            const error = errors.some((errSystemCId) => errSystemCId === clientId);
            return (
              <tr
                key={i + clientId}
                {...row.getRowProps()}
                onClick={() => {
                  handleRowSelect(row, i);
                }}
                className={`machine-table-row ${selectedRowClientId === clientId ? 'selectedRow' : ''} ${
                  error ? `${selectedRowClientId === clientId ? 'errorRowWithoutLeftBorder' : 'errorRow'}` : ''
                }`}
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={i + clientId + cellIndex}
                      className={columns[cellIndex].className}
                      width={columns[cellIndex].width}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MachineTable;
