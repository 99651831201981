import React, { useEffect, useMemo, useState, useContext } from 'react';
import ReactTable from '@src/pages/common/Table/ReactTable';
import UploadAppFile from './UploadAppFile';
import {
  deleteAppName,
  getBrowserStackInstance,
  getFirecloudInstance,
  getLambdaTestInstance,
  getSaucelabInstance,
  uploadBrowserStackInstance,
  uploadFirecloudInstance,
  uploadLambdaTestInstance,
  uploadSaucelabInstance,
} from '@api/api_services';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { useRouteMatch } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CommonLoader } from '@pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { Icon, Popup } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';


const InstanceDetails = (props) => {
  const search = window.location.search;
  const instanceUrl = new URLSearchParams(search).get('instanceName');

  const pathName = window.location.pathname;
  const match = useRouteMatch();

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const { AlertContatiner, MyAlert } = useAlert();
  const [account, setAccount] = useState([]);
  const [showUploadAppModal, setShowUploadAppModal] = useState(false);
  const [dataValue, setDataValue] = useState();
  let [reload, setReload] = useState(true);
  let [isLoading, setIsLoading] = useState(true);
  const [firecloudInstanceName, SetFirecloudInstancename] = useState();
  const [apiResponseStatus, setApiResponseStatus] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const sectionName = 'Configuration';

  let uploadInstanceFunc = async (originData, instanceUrl) => {
    try {
      setApiResponseStatus(true);
      const file = new FormData();
      originData.map((data) => {
        file.append('file', data);
      });
      if (match?.params?.pluginName === 'BrowserStack') {
        let response = await uploadBrowserStackInstance(file, instanceUrl, sectionName);
        if (response?.data?.responseCode === 200) {
          MyAlert.success(`File Uploaded Successfully`);
        }
        if (response?.data?.responseCode === 403) {
          MyAlert.danger(`${response?.data?.responseObject?.error}`);
        }
        if (response?.data?.responseCode === 422) {
          MyAlert.danger(`${response?.data?.responseObject?.error}`);
        }
      } else if (match?.params?.pluginName === 'LambdaTest') {
        let response = await uploadLambdaTestInstance(file, instanceUrl, sectionName);
        if (response?.data?.responseCode === 413) {
          MyAlert.warning(response?.data?.responseObject);
        }
        if (response?.data?.responseCode === 200) {
          MyAlert.success(response?.data?.message);
        }
      } else if (match?.params?.pluginName === 'SauceLab') {
        let response = await uploadSaucelabInstance(file, instanceUrl, sectionName);
        if (response?.data?.responseCode === 406) {
          MyAlert.danger(`${response?.data?.responseObject?.detail}`);
        }
        if (response?.data?.responseCode === 201) {
          MyAlert.success('File Uploaded Successfully');
        }
      } else if (match?.params?.pluginName === 'Firecloud') {
        let response = await uploadFirecloudInstance(file, sectionName);
        if (response?.data?.responseCode === 406) {
          MyAlert.danger(`${response?.data?.responseObject?.detail}`);
        }
        if (response?.data?.responseCode === 201) {
          MyAlert.success(`${response?.data?.message}`);
        }
      }
      setIsLoading(false);
      setApiResponseStatus(false);
      closeModal(false);
      setReload(true);
    } catch (err) {
      console.error('Error Occored ', err);
    }
  };

  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }
  let getInstanceFunc = async () => {
    setIsLoading(true);
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      if (match?.params?.pluginName === 'BrowserStack') {
        let response = await getBrowserStackInstance(instanceUrl);

        let rawData = [];
        let responseObject = response?.data?.responseObject;
        let responsemsg = response?.data?.responseObject?.message;
        if (responseObject && response?.data?.responseCode === 200 && !responsemsg) {
          responseObject?.map((filedata, index) => {
            setDataValue(filedata);
            rawData.push({
              sl_no: <div className=" text-blue-700 extlibPathTrimmer text-left">{index + 1}. </div>,
              app_name: (
                <Tooltip title={filedata?.app_name ? filedata?.app_name : '--'} placement="top">
                  <div className=" text-blue-700 extlibPathTrimmer text-left">
                    <span>{filedata?.app_name}</span>
                  </div>
                </Tooltip>
              ),
              app_url: (
                <>
                  <div className="flex justify-start">
                    <div id="myInput" className="extlibPathTrimmer w-3/5 text-left mr-2" title={filedata?.app_url}>
                      {filedata?.app_url}
                    </div>
                    <div className="w-2/5">
                      <button
                        className=" text-left"
                        onClick={() => {
                          copyToClipboard(`${filedata && filedata?.app_url}`);
                          MyAlert.success(`${filedata?.app_url} - Copied  Successfully`);
                        }}
                        title="Copy BS Address"
                      >
                        <ContentCopyIcon className=" hover:text-blue-700" />
                      </button>
                    </div>
                  </div>
                </>
              ),
            });
          });
          setAccount(rawData);
          setIsLoading(false);
        }
      } else if (match?.params?.pluginName === 'LambdaTest') {
        let response = await getLambdaTestInstance(instanceUrl);
        let rawData = [];
        let responseObject = response?.data?.responseObject?.data;
        responseObject?.map((filedata, index) => {
          setDataValue(filedata);
          rawData.push({
            sl_no: <div className=" text-blue-700 extlibPathTrimmer text-left">{index + 1}</div>,
            app_name: (
              <Tooltip title={filedata?.name ? filedata?.name : '--'} placement="top">
                <div className=" text-blue-700 extlibPathTrimmer text-left">
                  <span>{filedata?.name}</span>
                </div>
              </Tooltip>
            ),
            app_url: (
              <>
                <div className="flex justify-start">
                  <div
                    id="myInput"
                    className="extlibPathTrimmer w-3/5 text-left mr-2"
                    title={'lt://' + filedata?.app_id}
                  >
                    {'lt://' + filedata?.app_id}
                  </div>
                  <div className="w-2/5">
                    <button
                      className="w-2/5 extlibPathTrimmer text-left"
                      onClick={() => {
                        copyToClipboard(`${filedata && 'lt://' + filedata?.app_id}`);
                        MyAlert.success(`${'lt://' + filedata?.app_id} - Copied  Successfully`);
                      }}
                      title="Copy App Id"
                    >
                      <ContentCopyIcon className=" hover:text-blue-700" />
                    </button>
                  </div>
                </div>
              </>
            ),
          });
        });
        setAccount(rawData);
        setIsLoading(false);
      } else if (match?.params?.pluginName === 'SauceLab') {
        let response = await getSaucelabInstance(instanceUrl);
        let rawData = [];
        let responseObject = response?.data?.responseObject?.items;
        responseObject?.map((filedata, index) => {
          setDataValue(filedata);
          rawData.push({
            sl_no: <div className=" text-blue-700 extlibPathTrimmer text-left">{index + 1}</div>,
            app_name: (
              <Tooltip title={filedata?.name ? filedata?.name : '--'} placement="top">
                <div className=" text-blue-700 extlibPathTrimmer text-left">
                  <span>{filedata?.name}</span>
                </div>
              </Tooltip>
            ),
            app_url: (
              <>
                <div className="flex justify-start">
                  <div id="myInput" className="extlibPathTrimmer w-3/5 text-left mr-2" title={filedata?.id}>
                    {filedata?.id}
                  </div>
                  <div className="w-2/5">
                    <button
                      onClick={() => {
                        copyToClipboard(`${filedata && filedata?.id}`);
                        MyAlert.success(`${filedata?.id} - Copied  Successfully`);
                      }}
                      title="Copy App Id"
                    >
                      <ContentCopyIcon className=" hover:text-blue-700" />
                    </button>
                  </div>
                </div>
              </>
            ),
          });
        });
        setAccount(rawData);
        setIsLoading(false);
      } else if (match?.params?.pluginName === 'Firecloud') {
        let response = await getFirecloudInstance();
        const firecloudName = response.data.responseObject?.instanceName;
        SetFirecloudInstancename(firecloudName);

        let responseObject = response?.data?.responseObject?.listOfApps;
        let rawData = [];
        responseObject?.map((filedata, index) => {
          setDataValue(filedata);          
          rawData.push({
            sl_no: <div className="text-blue-700 extlibPathTrimmer text-left">{index + 1}</div>,
            app_name: (
              <Tooltip title={filedata?.name ? filedata?.name : '--'} placement="top">
                <div className="text-blue-700 extlibPathTrimmer text-left">
                  <span>{filedata?.name}</span>
                </div>
              </Tooltip>
            ),
            delete_app_name: (
              <Tooltip title="Delete" placement='right'>
              <div className="flex justify-center items-center w-10 h-10">
                  <Icon
                    className="cursor-pointer"
                    name="delete"
                    color={colors.blue_dark}
                    height={14}
                    width={14}
                    onClick={() => openDeleteModal(filedata)}
                  />
                  </div>
              </Tooltip>
            ),
          });
        });
        setAccount([...rawData]);

        setIsLoading(false);
      }
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
    } catch (err) {
      resetProgress();
      console.error('Error: Instance Details - ', err);
    }
    setIsLoading(false);
  };
 
  const openDeleteModal = (filedata) => {
    setModalData(filedata);
    setOpenModal(true);
  };

  const handleDeleteApi = async () => {
    try {
      const response = await deleteAppName(modalData?.name); 
      if (response?.data) {
        setAccount((prevData) => {
          const updatedData = prevData.filter(
            (item) => item.app_name.props.title !== modalData.name
          );
          return [...updatedData];
        });
        setOpenModal(false);
        setModalData(null); 
        MyAlert.success(`${modalData.name} - Deleted Successfully`);
        if(response?.data?.responseCode === 404){
          MyAlert.danger(`Failed to Delete - ${modalData.name}`);
        }
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error('Error deleting app name:', error);
    }
  };
  
  const cancelDelete = () => {
    setOpenModal(false);
    setModalData(null); 
  };
  


  useEffect(() => {
    if (reload) {
      setIsLoading(true);
      getInstanceFunc();
    }
    setReload(false);
  }, [reload]);

  const closeModal = (val) => {
    if (showUploadAppModal) {
      setShowUploadAppModal(false);
    }
  };

  const reloadComponent = () => {
    setReload(true);
  };

  const handleUploadBtn = () => {
    if (!apiResponseStatus) {
      setShowUploadAppModal(true);
    } else {
      MyAlert.info('Please wait until file has been uploaded successfully');
    }
  };
  const data = useMemo(() => account, [account]);

  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        Header: 'Sl. No',
        accessor: 'sl_no',
        width: '10%',
      },
      {
        Header: 'App',
        accessor: 'app_name',
        width: '40%',
      },
      {
        Header: 'Action',
        accessor: 'delete_app_name',
        width: '40%',
      },
    ];

    if (!pathName.includes('Firecloud')) {
      baseColumns.push({
        Header: pathName.includes('BrowserStack') ? 'BS address' : 'App Id',
        accessor: 'app_url',
        width: '50%',
      });
    }

    return baseColumns;
  }, [pathName]);

  if (isLoading) {
    return <CommonLoader />;
  }
  return (
    <>
      <div className="content-wrapper">
        <div className="mt-3 content_area_style" id="journal-scroll">
          <div className="alert-position-style">
            <AlertContatiner></AlertContatiner>
          </div>
          <div className="fontPoppinsSemiboldXlg mt-5 ml-7">Instance Details</div> <hr id="hr" />
          {!firecloudInstanceName?.toLowerCase().includes('cloud') ? (
            <div className="flex gap-x-60 mt-4">
              <div className="flex flex-col gap-y-6">
                <div className="fontPoppinsRegularMd ml-7 max-w-xs truncate">
                  Instance Name :{' '}
                  <span className="" title={localStorage.getItem('instanceLocal')}>
                    {localStorage.getItem('instanceLocal')}
                  </span>
                </div>
                <div className="fontPoppinsRegularMd ml-7 whitespace-nowrap max-w-xs overflow-hidden">
                  Access Key : <span className="encode">{localStorage.getItem('accessKeyLocal')}</span>
                </div>
              </div>
              <div className="fontPoppinsRegularMd ml-7 max-w-xs truncate">
                Username : <span title={localStorage.getItem('userLocal')}>{localStorage.getItem('userLocal')}</span>
              </div>
            </div>
          ) : (
            <div className="flex gap-x-60 mt-4">
              <div className="flex flex-col gap-y-6">
                <div className="fontPoppinsRegularMd ml-7 max-w-xs truncate">
                  Instance Name :{' '}
                  <span className="" title={firecloudInstanceName}>
                    {firecloudInstanceName}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="flex mt-3">
            <span className="fontPoppinsSemiboldLg ml-7 flex-1">Upload Apps</span>
            <div className="pr-4">
              <button className="ml-3 primary-btn" onClick={handleUploadBtn}>
                Upload App
              </button>
            </div>
          </div>
          <div className="tab-height mt-4">
            {isLoading ? (
              <CommonLoader />
            ) : account.length === 0 ? (
              <div className="project_label flex justify-center align-baseline mt-20">
                No Apps are Uploaded. Click{' '}
                <button className="text-blue-700 cursor-pointer mx-2" onClick={handleUploadBtn}>
                  here
                </button>{' '}
                to upload apps.
              </div>
            ) : (
              <ReactTable data={data} columns={columns} tableHeightClass={'instance-details-table'} />
            )}
          </div>
        </div>
      </div>

      {showUploadAppModal ? (
        <UploadAppFile
          closeModal={closeModal}
          uploadFile={uploadInstanceFunc}
          reload={reloadComponent}
          MyAlert={MyAlert}
        />
      ) : null}
      
      <div>

      {openModal && modalData && (
      <Popup
        isOpen={openModal}
        type="warning"
        headerTitle="Warning"
        headerText="Delete"
        continueButtonLabel="Delete"
        cancelButtonLabel="Cancel"
        continueButtonDisable={false}
        cancelButtonDisable={false}
        onContinueClick={handleDeleteApi} 
        onCancelClick={cancelDelete}
        children={
          <>
            <div>
              <span>Are you sure you want to delete {modalData.name}?</span>
            </div>
          </>
        }
      />
    )}
    </div>
    </>
  );
  
};

export default InstanceDetails;
