import { Switch, withStyles, Select, Tooltip } from '@material-ui/core';
import { ArrowDropDown, ArrowRight, ChevronRightOutlined, KeyboardArrowDownOutlined } from '@material-ui/icons';
import { Formik, Form as BaseForm } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';
import {
  cascadeConditionToScriptReq,
  createModulePrePostConditionReq,
  getSingleModuleReq,
  getStepGroupReq,
  updateModulePrePostConditionReq,
} from '@api/api_services';
import '../../../../test-development.scss';
import { decodeSpecialCharacter, getStepId } from '@src/pages/test-development/shared/common-methods';
import { ifFailedOptionsModuleLevel } from '@src/pages/test-development/script/modules/module/utils/constants';
import VariableSuggestion from '@src/pages/test-development/script/scripts/steps/VariableSuggestion';
import {
  getUrlPath,
  resourceNavigation,
} from '@src/pages/test-development/script/modules/module/utils/common-functions';
import { isEmptyObject, isEmptyValue } from '@src/util/common_utils';

const AntSwitch = withStyles((theme) => ({
  root: {
    marginRight: 15,
    width: 28,
    height: 16,
    padding: 0,
    display: 'inline-flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const CreateEditModuleConditions = (props) => {
  const params = useParams();
  const search = window.location.search;
  const [apiCompleteFlag, setApiCompleteFlag] = useState(false);
  const moduleId = new URLSearchParams(search).get('moduleId');
  const moduleName = decodeSpecialCharacter(params.moduleName)?.slice(
    0,
    decodeSpecialCharacter(params.moduleName)?.lastIndexOf('_')
  );
  const formRef = useRef();
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [stepGroupResponse, setStepGroupResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const divStyle = {
    position: 'absolute',
    left: '47%',
    right: '20%',
    top: '31%',
    height: 'fit-content',
    width: '52.25%',
    zIndex: '1',
    backgroundColor: ' #fff',
    boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
  };

  const [ifCheckPointIsFailed, setIfCheckPointIsFailed] = useState(ifFailedOptionsModuleLevel[0].value);
  const [returnValuePayload, setReturnValuePayload] = useState();
  const [status, setStatus] = useState(true);
  const [cascaded, setCascaded] = useState(false);
  const [mustExecute, setMustExecute] = useState(true);
  const [stepsOpen, setStepsOpen] = useState(true);
  const [stepsArr, setStepsArr] = useState([]);
  const [stepInputs, setStepInputs] = useState([]);
  const [initialValues, setInitialValues] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [returnTypeReference, setReturnTypeReference] = useState();
  const [hierarchy, setHierarchy] = useState();
  const [onCancelReloadRequired, setOnCancelReloadRequired] = useState(false);

  const isRepositoryEditable = React.useMemo(() => window.permission.isEditAccess('repository'), []);

  const onCancelReloadStateHandler = () => {
    setOnCancelReloadRequired(true);
  };

  const initizationForFormik = (data, type) => {
    let valuesForInitilization = {};
    let validationfield = [];
    let stepInputs = data.stepInputs;
    if (stepInputs && stepInputs.length > 0) {
      stepInputs.forEach((stepInput, index) => {
        if (type === 'create') {
          valuesForInitilization[stepInput.name] = '';
          if (stepInput?.value && stepInput?.reference) {
            valuesForInitilization[stepInput.name] = stepInput.value;
          }
        } else if (type === 'update') {
          if (stepInput.value) {
            valuesForInitilization[stepInput.name] = stepInput.value;
          } else {
            valuesForInitilization[stepInput.name] = '';
          }
        }
        let obj = {
          id: stepInput.name,
          type: 'text',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: [`${stepInput.name} is required`],
            },
          ],
        };
        validationfield.push(obj);
      });
    }
    if (data.returnType && data.returnType !== 'void' && type === 'create') {
      // setReturnTypeReference(data.stepGroupReferenceInfo.type);
      valuesForInitilization['returnValue'] = '';
      let obj = {
        id: 'returnValue',
        type: 'text',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Return Value is required'],
          },
        ],
      };
      validationfield.push(obj);
    } else if (data.stepGroupReferenceInfo && type === 'update') {
      let stepReferenceInfo = data.stepGroupReferenceInfo;
      setReturnTypeReference(stepReferenceInfo.type);
      valuesForInitilization['returnValue'] = stepReferenceInfo.name;
      let obj = {
        id: 'returnValue',
        type: 'text',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Return Value is required'],
          },
        ],
      };
      validationfield.push(obj);
    } else if (data.returnType && data.returnType !== 'void' && type === 'update') {
      valuesForInitilization['returnValue'] = '';
      let obj = {
        id: 'returnValue',
        type: 'text',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Return Value is required'],
          },
        ],
      };
      validationfield.push(obj);
    }
    if (valuesForInitilization && validationfield.length > 0) {
      const yepSchema = validationfield.reduce(createYupSchema, {});
      setInitialValues(valuesForInitilization);
      setValidationSchema(yup.object().shape(yepSchema));
    } else {
      setInitialValues({});
      setValidationSchema({});
    }
  };

  const createYupSchema = (schema, config) => {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  };

  const setVariableValue = (data, stepInputName, type) => {
    const stpInp = [...stepInputs];
    stpInp?.forEach((inputObj) => {
      if (inputObj.name === stepInputName) {
        if (type === 'variable') {
          inputObj.value = data.name;
          inputObj.reference = data?.reference;
        } else {
          inputObj.value = data;
          inputObj.reference = null;
        }
      }
    });
    setStepInputs(stpInp);
  };

  const setReturnValue = (data, stepInputName, type) => {
    setReturnTypeReference(type);
    setReturnValuePayload((preVal) => ({
      returnType: preVal.returnType,
      stepGroupReferenceInfo: {
        type: type,
        name: data.name,
      },
    }));
  };

  const removeStepRef = () => {
    if (returnValuePayload.stepGroupReferenceInfo) {
      setReturnTypeReference();
      setReturnValuePayload((preVal) => ({
        returnType: preVal.returnType,
      }));
    }
  };

  const bannerMessage = (title, key) => {
    props.MyAlert.success(`${title?.length > 22 ? title?.substring(0, 22) + '...' : title} ${key} successfully`);
  };

  const createPreCondition = async (data) => {
    try {
      setIsLoading(true);
      await cascadeConditionToScriptReq(moduleId, data);
      const response = await createModulePrePostConditionReq(moduleId, data);
      setIsLoading(false);
      if (response.data && response.data.responseObject) {
        props.updateTableData('create', response.data.responseObject);
        bannerMessage(data.stepGroupName, 'created');
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
      setCreateUpdateCalled(true);
      props.closeModal(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const updatePreCondition = async (data) => {
    try {
      setIsLoading(true);
      await cascadeConditionToScriptReq(moduleId, data);
      const response = await updateModulePrePostConditionReq(moduleId, data.id, data);
      setIsLoading(false);
      if (response.data && response.data.responseObject) {
        props.updateTableData('edit', response.data.responseObject, props.editConditionIndex);
        bannerMessage(data.stepGroupName, 'updated');
      } else if (response.data.message) {
        props.MyAlert.warning(`${response.data.message}`);
      }
      setCreateUpdateCalled(true);
      props.reload(true);
      props.closeModal(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handleOnSubmit = () => {
    let stepReferenceInfoExist = false;
    if (returnValuePayload.returnType !== 'void') {
      if (returnValuePayload.stepGroupReferenceInfo) {
      } else {
        stepReferenceInfoExist = true;
        formRef.current.setFieldError('returnValue', 'Always use Variable for Return');
        setCreateUpdateCalled(true);
      }
    }
    if (stepReferenceInfoExist === false) {
      let requestBody;
      if (props.edit) {
        if (props.editData !== null) {
          requestBody = {
            id: props.editData.id,
            // "name": '',
            stepGroupId: props.data.parentId,
            stepGroupName: props.data.nlpName,
            stepInputs: stepInputs,
            type: props.type,
            steps: [],
            cascaded: cascaded ? 'cascaded' : 'not_cascaded',
            moduleName: cascaded ? moduleName : '',
            cascadedFrom: cascaded ? moduleId : '',
            status: status ? 'enabled' : 'disabled',
            mustExecute: mustExecute,
            ifCheckPointIsFailed: ifCheckPointIsFailed,
            platform: props.data.platform,
            executionOrder: props.editData.executionOrder,
            libraryId: props.data.libraryId,
            stepId: props.editData.stepId,
            returnType: returnValuePayload.returnType,
            stepReferenceInfo: returnValuePayload.stepGroupReferenceInfo,
            cascadedReference: props.editData.cascadedReference,
            hierarchy: props.editData.hierarchy,
          };
        } else {
          requestBody = {
            id: props.data.id,
            // "name": '',
            stepGroupId: props.data.stepGroupId,
            stepGroupName: props.data.stepGroupName,
            stepInputs: stepInputs,
            type: props.type,
            steps: [],
            cascaded: cascaded ? 'cascaded' : 'not_cascaded',
            cascadedFrom: cascaded ? moduleId : '',
            status: status ? 'enabled' : 'disabled',
            mustExecute: mustExecute,
            ifCheckPointIsFailed: ifCheckPointIsFailed,
            platform: props.data.platform,
            executionOrder: props.data.executionOrder,
            libraryId: props.data.libraryId,
            stepId: props.data.stepId,
            returnType: returnValuePayload.returnType,
            stepReferenceInfo: returnValuePayload.stepGroupReferenceInfo,
            cascadedReference: props.data.cascadedReference,
            moduleName: cascaded ? moduleName : '',
            hierarchy: props.data.hierarchy,
          };
        }
        //if we dont need to pass the must execute key in pre-condition need to utilize the below code
        // if(props?.type==="POST"){
        //     requestBody.mustExecute=mustExecute;
        // }
        updatePreCondition(requestBody);
      } else {
        const stepId = getStepId();
        requestBody = {
          // "name": props.data.nlpName,
          stepGroupId: props.data.parentId,
          stepGroupName: props.data.nlpName,
          stepInputs: stepInputs,
          type: props.type,
          steps: [],
          cascaded: cascaded ? 'cascaded' : 'not_cascaded',
          cascadedFrom: cascaded ? moduleId : '',
          moduleName: cascaded ? moduleName : '',
          status: status ? 'enabled' : 'disabled',
          mustExecute: mustExecute,
          ifCheckPointIsFailed: ifCheckPointIsFailed,
          platform: props.data.platform,
          executionOrder: props.orderOfInsertion,
          libraryId: props.data.libraryId,
          stepId: stepId,
          returnType: returnValuePayload.returnType,
          stepReferenceInfo: returnValuePayload.stepGroupReferenceInfo,
          cascadedReference: 'MODREF' + stepId,
          hierarchy: hierarchy,
        };
        //if we dont need to pass the must execute key in pre-condition need to utilize the below code
        // if(props?.type==="POST"){
        //     requestBody.mustExecute=mustExecute;
        // }
        createPreCondition(requestBody);
      }
    }
  };

  let accordionIcon = (elementId) => {
    return (
      <span>
        <ArrowRight className="expand-icon cursor-pointer" id={`${elementId}-expand`} />
        <ArrowDropDown className="collapse-icon cursor-pointer" id={`${elementId}-collapse`} />
      </span>
    );
  };
  let toggleAccordionIcon = (event, elementId) => {
    let expandElement = document.getElementById(elementId + '-expand');
    let collapseElement = document.getElementById(elementId + '-collapse');
    if (expandElement && expandElement.style) {
      if (expandElement.style.display === 'none') {
        expandElement.style.display = 'inline-block';
      } else if (expandElement.style.display !== 'none') {
        expandElement.style.display = 'none';
      }
    }
    if (collapseElement && collapseElement.style) {
      if (collapseElement.style.display === 'none' || collapseElement.style.display === '') {
        collapseElement.style.display = 'inline-block';
      } else if (collapseElement.style.display !== 'none') {
        collapseElement.style.display = 'none';
      }
    }
  };

  function stepGroup(stepGroup, stepGroupIndex, marginLeftSize) {
    return (
      <div>
        <div className="accordion">
          <div className="">
            <div
              className=""
              onClick={(e) => {
                toggleAccordionIcon(e, stepGroup?.name);
              }}
              style={{ marginLeft: marginLeftSize + 'rem' }}
            >
              {stepGroupIndex}.{stepGroup?.name}
              {accordionIcon(stepGroup?.name)}
            </div>
          </div>
        </div>
        <div className="panel">{steps(stepGroup?.steps, Number(marginLeftSize) + Number(1.25))}</div>
      </div>
    );
  }
  function steps(steps, marginLeftSize) {
    return (
      <div>
        {steps.map((step, index) => (
          <div key={'_' + index + '_' + index}>
            {!(step?.steps && step.steps.length > 0) ? (
              <div className="" key={`step${index}`}>
                <div
                  className="py-1"
                  style={{
                    marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '0.5rem',
                  }}
                >
                  {index + 1}.{step?.name}
                </div>
              </div>
            ) : (
              <div>{stepGroup(step, index + 1, marginLeftSize ? marginLeftSize : 0.5)}</div>
            )}
          </div>
        ))}
      </div>
    );
  }
  async function getSteps(val) {
    try {
      let libId = val.libraryId;
      let stepGrpId = val.stepGroupId ? val.stepGroupId : val.parentId;
      const res = await getStepGroupReq(libId, stepGrpId);
      if (res.data && res.data.responseObject) {
        setStepGroupResponse(res.data.responseObject);
        setStepsArr(res.data.responseObject.steps);
        setApiCompleteFlag(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function navigateToStepGroupPage() {
    const { name, id, parentId, defaultStepGroup, path, searchKey } = stepGroupResponse || {};
    const nameArray = path?.split('/')?.slice(1) || [];
    const searchKeyArray = searchKey?.split('/')?.slice(1) || [];
    const { json } = resourceNavigation({
      searchKeyArray,
      nameArray,
      isTestDev: false,
      rootName: 'Root Library',
    });
    json.splice(0, 1);
    localStorage.setItem('parentAndSonJson', JSON.stringify(json));
    history.push(
      `/repository/step_group/${getUrlPath(
        json
      )}Steps?id=${id}&parentId=${parentId}&stepGroup=${name}&defaultStepGroup=${defaultStepGroup}`
    );
  }
  function returnTouchedField(values) {
    let touchedField = {};
    for (var key in values) {
      if (values.hasOwnProperty(key)) {
        if (!values[key]) {
          touchedField[key] = true;
        }
      }
    }
    return touchedField;
  }
  async function getModuleData() {
    try {
      // const search = window.location.search;
      // const moduleId = new URLSearchParams(search).get("moduleId");
      const response = await getSingleModuleReq(moduleId);
      if (response?.data && response?.data?.responseObject) {
        setHierarchy(Number(response?.data?.responseObject?.hierarchy) + 1);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    var acc = document.getElementsByClassName('accordion');
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel) {
          if (panel.style.display === 'block') {
            panel.style.display = 'none';
          } else {
            panel.style.display = 'block';
          }
        }
      });
    }
  }, []);
  useEffect(() => {
    if (props?.edit) {
      if (props.editData !== null) {
        setIfCheckPointIsFailed(props.editData.ifCheckPointIsFailed);
        setStatus(props.editData.status === 'disabled' ? false : true);
        setCascaded(props.editData.cascaded === 'cascaded' ? true : false);
        setMustExecute(props?.editData.mustExecute);
      } else {
        setIfCheckPointIsFailed(props.data.ifCheckPointIsFailed);
        setStatus(props.data.status === 'disabled' ? false : true);
        setCascaded(props.data.cascaded === 'cascaded' ? true : false);
        setMustExecute(props?.data.mustExecute);
      }
    }
    setStepInputs(props.data.stepInputs);
    getSteps(props.data);
  }, [props.data, props.edit, props.editData]);
  useEffect(() => {
    if (apiCompleteFlag) {
      const retType =
        stepGroupResponse.returnType === 'void'
          ? 'void'
          : stepGroupResponse.returnType !== 'void' && props?.data?.returnType !== 'void' && props.edit
          ? props?.data?.returnType
          : stepGroupResponse.returnType;
      const stpRefInf =
        stepGroupResponse.returnType === 'void'
          ? null
          : stepGroupResponse.returnType !== 'void' && props?.data?.returnType !== 'void' && props.edit
          ? props?.data?.stepReferenceInfo
          : null;
      setReturnValuePayload({
        returnType: retType,
        stepGroupReferenceInfo: stpRefInf,
      });
      const val = {
        stepInputs: stepInputs,
        returnType: retType,
        stepGroupReferenceInfo: stpRefInf,
      };
      if (props.edit) {
        initizationForFormik(val, 'update');
      } else {
        initizationForFormik(val, 'create');
      }
    }
    setApiCompleteFlag(false);
  }, [apiCompleteFlag]);
  useEffect(() => {
    getModuleData();
  }, []);
  const getTitleText = (props) => {
    if (props.edit) {
      if (!props.editData) {
        return props.data.nlpName;
      }
      return props.data.stepGroupName;
    }
    return props.data.nlpName;
  };
  const handleFormValidationAndSubmission = () => {
    if (returnValuePayload?.hasOwnProperty('returnType') && returnValuePayload?.returnType !== 'void') {
      if (!returnValuePayload.stepGroupReferenceInfo) {
        formRef.current.setFieldError('returnValue', 'Always use Variable for Return');
        setCreateUpdateCalled(true);
      }
    }
    if (initialValues && validationSchema) {
      let isFieldTouched = returnTouchedField(formRef.current.values);
      if (!isEmptyObject(isFieldTouched)) {
        formRef.current.setTouched(isFieldTouched);
      } else {
        if (createUpdateCalled) {
          setCreateUpdateCalled(false);
          handleOnSubmit();
        }
      }
    }
    // const { type, preConditionHandleUpdate, postConditionHandleUpdate } = props;
    // if (type === 'PRE') {
    //   preConditionHandleUpdate();
    // } else if (type === 'POST') {
    //   postConditionHandleUpdate();
    // }
  };

  return (
    <>
      <div id="step-group-input-parameter" className="shadow-md responsive-parameter-div" style={divStyle}>
        <div className="mt-2">
          <span className="Popup-header-common pl-5">Step Group Input Parameters</span>
        </div>
        <div>
          <div
            className="border-b border-t border-blue-100 mt-2 mb-2 responsive-parameter-div-height overflow-y-auto overflow-x-hidden relative"
            id="journal-scroll"
          >
            <div className="input-filed-label-style-common mt-3 ml-6 mr-px" id="journal-scroll">
              <div>
                <div className="inline-block">
                  <span>Step Group</span>
                  <Tooltip title={getTitleText(props)} placement="right">
                    <p className="text-blue-700 mt-1">
                      {props.edit
                        ? props.editData !== null
                          ? `${props.data.nlpName?.substring(0, 30)}${props.data.nlpName?.length > 30 ? '...' : ''}`
                          : `${props.data.stepGroupName?.substring(0, 30)}${
                              props.data.stepGroupName?.length > 30 ? '...' : ''
                            }`
                        : `${props.data.nlpName?.substring(0, 30)}${props.data.nlpName?.length > 30 ? '...' : ''}`}
                    </p>
                  </Tooltip>
                </div>
                {isRepositoryEditable && (
                  <span
                    onClick={navigateToStepGroupPage}
                    className="text-blue-700 mt-4 mr-1.5 ml-auto float-right cursor-pointer"
                  >
                    Go to step group
                  </span>
                )}
              </div>
              <div className="mt-2">
                <div className="inline-block">
                  <label htmlFor="status" className={'input-filed-label-style-common block'}>
                    Status
                  </label>
                  <div className="mt-3">
                    <AntSwitch
                      checked={status}
                      onChange={(e) => {
                        setStatus(e.target.checked);
                      }}
                      name="status"
                      className="inline-block"
                    />
                    <span className="input-filed-label-style-common">{status ? 'Enabled' : 'Disabled'}</span>
                  </div>
                </div>
                <div className="ml-12 inline-block">
                  <label htmlFor="cascaded" className={'input-filed-label-style-common block'}>
                    Cascade
                  </label>
                  <div className="mt-3">
                    <AntSwitch
                      checked={cascaded}
                      onChange={(e) => {
                        setCascaded(e.target.checked);
                      }}
                      name="cascaded"
                      className="inline-block"
                    />
                    <span className="text-gray-700">{cascaded ? 'Cascaded' : 'Not Cascaded'}</span>
                  </div>
                </div>
                {props?.type === 'POST' && (
                  <div className="ml-12 inline-block">
                    <label htmlFor="mustExecute" className={'input-filed-label-style-common block'}>
                      Must Execute
                    </label>
                    <div className="mt-3">
                      <AntSwitch
                        checked={mustExecute}
                        onChange={(e) => {
                          setMustExecute(e.target.checked);
                        }}
                        name="mustExecute"
                        className="inline-block"
                      />
                      <span className="input-filed-label-style-common">{mustExecute ? 'Enabled' : 'Disabled'}</span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {initialValues && validationSchema ? (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleReset,
                      setTouched,
                      setFieldValue,
                      setFieldError,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                    }) => {
                      return (
                        <BaseForm>
                          <div>
                            <div className="ml-6">
                              {!isEmptyValue(stepInputs) && (
                                <div className="mt-3 grid grid-cols-2 gap-4">
                                  {stepInputs.map(
                                    ({ name = '', type = '', value = '', reference = '' }, inputIndex) => {
                                      return (
                                        <div key={name} className="mt-2">
                                          <div>
                                            <VariableSuggestion
                                              reloadStepsTable={onCancelReloadStateHandler}
                                              createVariableFromModuleCondition={true}
                                              stepInputType={type}
                                              moduleId={moduleId}
                                              id={'variables' + inputIndex}
                                              stepInputName={name}
                                              setVariableValue={setVariableValue}
                                              editData={value}
                                              type={props.type}
                                              reference={reference}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              setFieldValue={setFieldValue}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                            {stepGroupResponse.returnType ? (
                              stepGroupResponse.returnType !== 'void' ? (
                                <div className="mt-2">
                                  <div className="ml-7 mt-3 w-2/4">
                                    {
                                      <VariableSuggestion
                                        reloadStepsTable={onCancelReloadStateHandler}
                                        moduleId={moduleId}
                                        id={'return-value'}
                                        createVariableFromModuleCondition={true}
                                        stepInputType={stepGroupResponse.returnType}
                                        stepInputName={'returnValue'}
                                        setVariableValue={setVariableValue}
                                        editData={returnValuePayload?.stepGroupReferenceInfo?.name}
                                        type={props.type}
                                        reference={returnTypeReference}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        removeStepRef={removeStepRef}
                                        setReturnValue={setReturnValue}
                                      />
                                    }
                                  </div>
                                </div>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </BaseForm>
                      );
                    }}
                  </Formik>
                ) : null}
              </div>

              <div className="border-t border-b border-dashed mt-4 mb-2.5 mr-6 fontPoppinsRegularMd border-blue-100 p-2">
                <span>
                  Steps
                  {stepsOpen ? (
                    <ChevronRightOutlined
                      onClick={() => {
                        setStepsOpen(false);
                      }}
                      fontSize="small"
                      className="text-blue-700 cursor-pointer"
                    />
                  ) : (
                    <KeyboardArrowDownOutlined
                      onClick={() => {
                        setStepsOpen(true);
                      }}
                      fontSize="small"
                      className="text-blue-700 cursor-pointer"
                    />
                  )}
                  {!stepsOpen ? (
                    !isEmptyValue(stepsArr) ? (
                      steps(stepsArr, '0.5')
                    ) : (
                      <p className="ml-2">No steps</p>
                    )
                  ) : null}
                </span>
              </div>
            </div>
            <div className="ml-6 mb-6">
              <label htmlFor="ifFailedStatus" className="input-filed-label-style-common">
                <span className="text-red-400">&#42;</span>If Failed
              </label>
              <div className="fontPoppinsRegularMd">
                <Select
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  name="ifFailedStatus"
                  id="ifFailedStatus"
                  value={ifCheckPointIsFailed}
                  onChange={(e) => {
                    setIfCheckPointIsFailed(e.target.value);
                  }}
                  className="w-3/5 text-sm text-gray-500 mt-2"
                >
                  {ifFailedOptionsModuleLevel.map((opt) => (
                    <option
                      key={opt.label}
                      className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 text-sm"
                      value={opt.value}
                    >
                      {opt.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="float-right mt-1 mr-4 mb-3 footer">
            <button
              disabled={isLoading}
              onClick={() => {
                props.closeModal(false);
                if (onCancelReloadRequired) {
                  props.reload(true);
                }
              }}
              type="button"
              className="sm:mr-10 md:mr-1 md:ml-8 bg-gray-200 py-1.5 rounded-sm px-3.5 border border-gray-300 shadow-sm text-sm font-semibold text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 fontPoppinsMediumMd"
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={() => {
                handleFormValidationAndSubmission();
              }}
              type="button"
              className="ml-4 inline-flex justify-center py-1.5 px-3.5 border rounded-sm border-transparent shadow-sm text-sm font-medium text-white btn-primary hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 fontPoppinsMediumMd"
            >
              {props.edit ? 'Update' : 'Add'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEditModuleConditions;
