import React, { useEffect, useState } from 'react';
import NavigateToCreateInstanceModal from '@pages/configuration/system_config/system_config v1.1/modals/navigate-to-instance-modal';
import {
  getCloudPlatformBrowserVerData,
  getCloudPlatformOSData,
  getCloudPlatformBrowserAndDeviceData,
  getMobileOSVersion,
  integratedEnvironment,
} from '@src/pages/configuration/system_config/system_config v1.1/utils/cloud-platform-methods';
import { CommonLoader } from '@src/pages/common/common-loader';
import {
  getBrowserLabel,
  getOsLabel,
} from '@pages/configuration/system_config/system_config v1.1/utils/sys-config-common-methods';
import { ToggleHeadless } from '@pagescommon/execution/client-machine.js';
import SelectBox from '@src/common/atoms/SelectComponent';
import IconWithLabelledInput from '@src/common/atoms/icon-with-labelled-input/IconWithLabelledInput';
import { colors } from '@src/css_config/colorConstants';
import { FIRECLOUD_DATA } from '@src/common/ui-constants';
import { getDeviceData } from '@src/util/common_utils';


const QrsMachineDetails = (props) => {
  let {
    webOSDataPassToParent,
    androidDataPassToParent,
    iosDataPassToParent,
    webOsSystemParent,
    andriodSystemParent,
    iosSystemParent,
    setRunButtonClickedAction,
    andriodDeviceAppSystemBase,
    iosDeviceAppSystemBase,
    runButtonDisabled,
  } = props;
  const projectType = props.project.type?.toLowerCase();
  const [showBrowser] = useState(projectType === 'mobile' ? false : true);
  const [showDevice] = useState(projectType === 'web' ? false : true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(props.selectedSystem);
  const [browserListsApiData, setBrowserListsApiData] = useState();
  const [executionEnvData, setExecutionEnvData] = useState();
  const [osData, setOsData] = useState();
  const [osVersionData, setOsVersionData] = useState();
  const [browserData, setBrowserData] = useState();
  const [browserVersionData, setBrowserVersionData] = useState();
  const [deviceData, setDeviceData] = useState();
  const [androidOsVersionData, setAndroidOsVersionData] = useState();
  const [navigateToInstanceModal, setNavigateToInstanceModal] = useState({
    openModal: false,
    data: null,
  });
  const [isdefaultMachineEdited, setIsDefaultMachineEdited] = useState(false);
  const [appData, setAppData] = useState();
  const [iosDeviceData, setIosDeviceData] = useState();
  const [iosOsVersionData, setIosOsVersionData] = useState();
  const [iosAppData, setIosAppData] = useState();
  const [headlessSwitch, setHeadlessSwitch] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState();
  const [changeValues, setChangeValues] = useState(false);
  const [androidValueChanges, setAndroidValueChanges] = useState(false);
  const [iosValueChanges, setIosValueChanges] = useState(false);

  const closeModal = (val) => {
    if (navigateToInstanceModal.openModal) {
      setNavigateToInstanceModal({ openModal: false, data: null });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    webOSDataPassToParent(osData);
    webOsSystemParent(osData);
  }, [osData]);

  useEffect(() => {
    androidDataPassToParent(deviceData);
    andriodSystemParent(deviceData);
    andriodDeviceAppSystemBase(appData);
  }, [deviceData, appData]);

  useEffect(() => {
    iosDataPassToParent(iosDeviceData);
    iosSystemParent(iosDeviceData);
    iosDeviceAppSystemBase(iosAppData);
  }, [iosDeviceData, iosAppData]);

  const [errorMsg, setErrorMsg] = useState('');
  const [validation, setValidation] = useState(false);

  const handleExecutionEnvChange = async (values, e) => {
    if (values?.length === 0) {
      setValidation(true);
      setErrorMsg('Please select execution environment');
      runButtonDisabled(true);
    } else {
      setValidation(false);
      runButtonDisabled(false);
    }
    if (values[0]?.value !== executionEnvData.selectedOption[0]?.value) {
      // runButtonDisabled(false)
      try {
        let osNameOptions = [];
        let selectedOsName = [];
        let osVersionOptions = [];
        let selectedOsVersion = [];
        let browserNameOptions = [];
        let selectedBrowserName = [];
        let browserVersionOptions = [];
        let selectedBrowserVersion = [];
        let devicesOptions = [];
        let selectedDevices = [];
        let androidOsVersionOptions = [];
        let selectedAndroidOsVersion = [];
        let selectedAppData = [];
        let appDataOptions = [];
        let iosDevicesOptions = [];
        let selectedIosDevices = [];
        let iosOsVersionOptions = [];
        let selectedIosOsVersion = [];
        let iosAppDataOptions = [];
        let selectedIosAppData = [];
        const browserInstanceArray = props.instanceList.browserInstanceArray;
        const sauceLabInstanceArray = props.instanceList.sauceLabInstanceArray;
        const lambdaTestsInstanceArray = props.instanceList.lambdaTestsInstanceArray;
        const selectedEnvOption = values[0].value.toLowerCase();
        if (selectedEnvOption.includes('local')) {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }
          selectedOsName = props?.selectedSystem.machine.osName || '--';
          selectedOsVersion = props?.selectedSystem.machine.osVersion || '--';
          props?.selectedSystem?.systemConfigDetails?.forEach((_ele) => {
            const deviceDetails = {
              ..._ele,
              label: _ele.name,
              name: _ele.name,
              value: _ele.name,
            };
            if (_ele.subType === 'browser' && _ele.name) {
              browserNameOptions.push({
                label: getBrowserLabel(_ele.name),
                name: _ele.name,
                value: _ele.name,
                platform: _ele.platform,
                version: _ele.version,
                type: _ele.type,
                subType: _ele.subType,
              });
            } else if (_ele.subType === 'device') {
              if (_ele?.platform === 'android') {
                devicesOptions.push(deviceDetails);
              } else {
                iosDevicesOptions.push(deviceDetails);
              }
            }
          });
          if (
            props?.selectedSystem?.clientId === props?.defaultSystem?.clientId &&
            props?.defaultSystem.executionEnvironment.toLowerCase() === 'local'
          ) {
            props?.defaultSystem?.systemConfigDetails?.forEach((_defEle) => {
              if (_defEle.subType === 'browser') {
                const selcBrow = browserNameOptions.find((val) => val.name === _defEle.name);
                selectedBrowserName = selcBrow ? [selcBrow] : [];
                selectedBrowserVersion = selectedBrowserName[0]?.version || [];
              }
              if (selectedBrowserName.length === 0) {
                selectedBrowserName = browserNameOptions[0] ? [{ ...browserNameOptions[0] }] : [];
                selectedBrowserVersion = selectedBrowserName[0]?.version || '';
              }
              if (_defEle.subType === 'device') {
                if (_defEle?.platform === 'android') {
                  const selectAndroidDevice = devicesOptions.find((val) => val.name === _defEle.name);
                  selectedDevices = selectAndroidDevice ? [selectAndroidDevice] : [];
                  selectedAndroidOsVersion = selectedDevices[0]?.version;
                } else {
                  const selectedIosDevice = iosDevicesOptions.find((val) => val.name === _defEle.name);
                  selectedIosDevices = selectedIosDevice ? [selectedIosDevice] : [];
                  selectedIosOsVersion = selectedIosDevices[0].version;
                }
              }
            });
            setData(props.defaultSystem);
            setHeadlessSwitch(props?.defaultSystem?.headless);
          } else {
            selectedBrowserName = [{ ...browserNameOptions[0] }];
            selectedBrowserVersion = selectedBrowserName[0].version;
            let updatedData = JSON.parse(JSON.stringify(props.selectedSystem));
            updatedData.executionEnvironment = values[0].value;
            updatedData.machine.osName = Array.isArray(selectedOsName) ? selectedOsName[0].value : selectedOsName;
            updatedData.machine.osVersion = Array.isArray(selectedOsVersion)
              ? selectedOsVersion[0].value
              : selectedOsVersion;
            updatedData.systemConfigDetails = [];
            const browserTemp = {
              version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0].value : selectedBrowserVersion,
              name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
              type: 'local',
              subType: 'browser',
              platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
            };
            const deviceTemp = {
              version: Array.isArray(selectedOsVersion) ? selectedOsVersion[0].value : selectedOsVersion,
              name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
              type: 'local',
              subType: 'device',
              fullName: selectedDevices?.[0]?.fullName,
              appId: selectedDevices?.[0]?.appId,
              appName: selectedDevices?.[0]?.appName,
              platform: selectedDevices?.[0]?.platform
                ? selectedDevices[0].platform
                : Array.isArray(selectedOsName)
                  ? selectedOsName[0].value
                  : selectedOsName,
            };
            if (browserTemp.name) {
              updatedData.systemConfigDetails.push(browserTemp);
            }
            if (deviceTemp.name) {
              updatedData.systemConfigDetails.push(deviceTemp);
            }
            setData(updatedData);
            setHeadlessSwitch(props?.selectedSystem?.headless);
          }
        } else if (
          selectedEnvOption.includes('lambdatest-') ||
          selectedEnvOption.includes('browserstack-') ||
          selectedEnvOption.includes('saucelabs-') ||
          selectedEnvOption.includes('firecloud')
        ) {
          runButtonDisabled(true);
          setValidation(false);
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }
          setIsLoading(true);
          setHeadlessSwitch(false);
          let retObj;
          let instanceName = selectedEnvOption.slice(selectedEnvOption.indexOf('-') + 1);
          if (
            props.instanceList.browserInstanceArray ||
            props.instanceList.sauceLabInstanceArray ||
            props.instanceList.lambdaTestsInstanceArray ||
            selectedEnvOption?.includes("firecloud")
          ) {
            let selectedPlatformTemp = selectedEnvOption.slice(0, selectedEnvOption.indexOf('-'));
            let selectedInstance;
            if (selectedPlatformTemp === 'browserstack') {
              selectedInstance = browserInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            } else if (selectedPlatformTemp === 'lambdatest') {
              selectedInstance = lambdaTestsInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            } else if (selectedPlatformTemp === 'saucelabs') {
              selectedInstance = sauceLabInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            }
            else if(selectedEnvOption?.includes(FIRECLOUD_DATA.FIRE_CLOUD)){
              selectedPlatformTemp = selectedEnvOption;
            }
            retObj = await getCloudPlatformOSData(selectedInstance, props.selectedSystem, props.defaultSystem, true, selectedPlatformTemp );
            runButtonDisabled(false);
          } else {
            return;
          }
          osNameOptions = retObj?.osObj?.options;
          selectedOsName = [retObj?.osObj.selectedOption];
          osVersionOptions = retObj?.osVerObj?.options;
          selectedOsVersion = retObj?.osVerObj?.selectedOption ? [retObj?.osVerObj?.selectedOption] : [];
          browserNameOptions = retObj?.browserObj?.options;
          selectedBrowserName = retObj?.browserObj?.selectedOption ? [retObj?.browserObj?.selectedOption] : [];
          browserVersionOptions = retObj?.browserVerObj?.options;
          selectedBrowserVersion = retObj?.browserVerObj?.selectedOption ? [retObj?.browserVerObj?.selectedOption] : [];
          devicesOptions = retObj?.deviceObj?.options;
          selectedDevices = retObj?.deviceObj?.selectedOption ? [retObj?.deviceObj?.selectedOption] : [];
          androidOsVersionOptions = retObj?.androidOsVersionObj?.options;
          selectedAndroidOsVersion = retObj?.androidOsVersionObj?.selectedOption
            ? [retObj?.androidOsVersionObj?.selectedOption]
            : [];
          appDataOptions = retObj?.appDataObj?.options;
          selectedAppData = retObj?.appDataObj?.selectedOption;
          iosDevicesOptions = retObj?.deviceObjIOS?.options;
          selectedIosDevices = retObj?.deviceObjIOS?.selectedOption ? [retObj?.deviceObjIOS?.selectedOption] : [];
          iosOsVersionOptions = retObj?.iosOsVersionObj?.options;
          selectedIosOsVersion = retObj?.iosOsVersionObj?.selectedOption
            ? [retObj?.iosOsVersionObj?.selectedOption]
            : [];
          iosAppDataOptions = retObj?.appDataObjIos?.options;
          selectedIosAppData = retObj?.appDataObjIos?.selectedOption;
          setBrowserListsApiData(retObj?.apiData);
          let updatedData = JSON.parse(JSON.stringify(data));
          let tempOsName, tempOsVersion;
          if (Array.isArray(selectedOsName)) {
            [tempOsName, tempOsVersion] = selectedOsName?.[0]?.value?.split('-') || [];
          } else {
            tempOsName = selectedOsName;
            tempOsVersion = selectedOsVersion;
          }
          updatedData.executionEnvironment = values[0].value;
          updatedData.machine.osName = tempOsName;
          updatedData.machine.osVersion = tempOsVersion;
          updatedData.systemConfigDetails = [];
          const browserTemp = {
            version: Array.isArray(selectedBrowserVersion)
              ? selectedBrowserVersion?.[0]?.value
              : selectedBrowserVersion,
            name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
            type: 'local',
            subType: 'browser',
            platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
          };
          const deviceTemp = {
            version: Array.isArray(selectedAndroidOsVersion)
              ? selectedAndroidOsVersion[0]?.value
              : selectedAndroidOsVersion,
            name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
            type: 'local',
            subType: 'device',
            fullName: selectedDevices?.[0]?.fullName,
            appId: selectedDevices?.[0]?.appId,
            appName: selectedDevices?.[0]?.appName,
            platform: 'android',
          };
          const iosDeviceTemp = {
            version: Array.isArray(selectedIosOsVersion) ? selectedIosOsVersion[0]?.value : selectedIosOsVersion,
            name: Array.isArray(selectedIosDevices) ? selectedIosDevices[0]?.value : selectedIosDevices,
            type: 'local',
            subType: 'device',
            fullName: selectedIosDevices?.[0]?.fullName,
            appId: selectedIosDevices?.[0]?.appId,
            appName: selectedIosDevices?.[0]?.appName,
            platform: 'ios',
          };
          if (browserTemp.name) {
            updatedData.systemConfigDetails.push(browserTemp);
          }
          if (deviceTemp.name) {
            updatedData.systemConfigDetails.push(deviceTemp);
          }
          if (iosDeviceTemp.name) {
            updatedData.systemConfigDetails.push(iosDeviceTemp);
          }
          setData(updatedData);
          setIsLoading(false);
        } else {
          setIsLoading(true);
          if (
            selectedEnvOption === 'browserstack' ||
            selectedEnvOption === 'saucelabs' ||
            selectedEnvOption === 'lambdatest'
          ) {
            setNavigateToInstanceModal({ openModal: true, data: values[0].value });
          } else {
            setIsLoading(false);
          }
          return;
        }

        if (!Array.isArray(selectedOsName)) {
          selectedOsName = selectedOsName + '-' + selectedOsVersion;
        }
        setExecutionEnvData((val) => {
          return { ...val, selectedOption: values };
        });
        setOsData({ options: osNameOptions, selectedOption: selectedOsName });
        setOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion });
        setBrowserData({ options: browserNameOptions, selectedOption: selectedBrowserName });
        setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
        setDeviceData({ options: devicesOptions, selectedOption: selectedDevices });
        setAndroidOsVersionData({ options: androidOsVersionOptions, selectedOption: selectedAndroidOsVersion });
        setAppData({ options: appDataOptions, selectedOption: selectedAppData });
        setIosDeviceData({ options: iosDevicesOptions, selectedOption: selectedIosDevices });
        setIosOsVersionData({ options: iosOsVersionOptions, selectedOption: selectedIosOsVersion });
        setIosAppData({ options: iosAppDataOptions, selectedOption: selectedIosAppData });
      } catch (err) {
        console.error('HANDLE_EXE_ENV : ', err);
      }
    }
  };
  const handleOsChange = async (values) => {
    if (changeValues !== true) {
      try {
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
          setIsDefaultMachineEdited(true);
        }
        let retObj;
        let selectedExecEnv = executionEnvData?.selectedOption?.[0]?.value?.toLowerCase();
        if (
          selectedExecEnv.includes('lambdatest-') ||
          selectedExecEnv.includes('browserstack-') ||
          selectedExecEnv.includes('saucelabs-') ||
          selectedExecEnv.includes( 'firecloud')
        ) {
          retObj = await getCloudPlatformBrowserAndDeviceData(
            browserListsApiData,
            values[0],
            props.selectedSystem,
            props.defaultSystem,
            true
          );
        }
        const browserNameOptions = retObj?.browserObj?.options;
        const selectedBrowserName = retObj?.browserObj?.selectedOption ? [retObj?.browserObj?.selectedOption] : [];
        const browserVersionOptions = retObj?.browserVerObj?.options;
        const selectedBrowserVersion = retObj?.browserVerObj?.selectedOption
          ? [retObj?.browserVerObj?.selectedOption]
          : [];
        const devicesOptions = retObj?.deviceObj?.options;
        const selectedDevices = retObj?.deviceObj?.selectedOption ? [retObj?.deviceObj?.selectedOption] : [];

        let updatedData = JSON.parse(JSON.stringify(data));

        const [tempOsName, tempOsVersion] = values[0].value.split('-') || [];

        updatedData.machine.osName = tempOsName;
        updatedData.machine.osVersion = tempOsVersion;
        updatedData.systemConfigDetails = [];
        const browserTemp = {
          version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0]?.value : selectedBrowserVersion,
          name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
          type: 'local',
          subType: 'browser',
          platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
        };
        if (browserTemp.name) {
          updatedData.systemConfigDetails.push(browserTemp);
        }
        setOsData((val) => {
          return { ...val, selectedOption: values };
        });
        setBrowserData({ options: browserNameOptions, selectedOption: selectedBrowserName });
        setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
        setData(updatedData);
      } catch (err) {
        console.error('HANDLE_OS : ', err);
      }
    }
    setChangeValues(false);
  };

  const handleBrowserChange = (values) => {
    if (changeValues !== true) {
      try {
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId || values.length === 0) {
          setIsDefaultMachineEdited(true);
        }
        let retObj;
        let browserVersionOptions = [];
        let selectedBrowserVersion;
        if (integratedEnvironment(executionEnvData?.selectedOption[0]?.value)) {
          retObj = getCloudPlatformBrowserVerData(
            browserListsApiData,
            osData.selectedOption[0],
            values[0],
            props.selectedSystem,
            props.defaultSystem,
            true
          );
          browserVersionOptions = retObj?.options;
          selectedBrowserVersion = retObj?.selectedOption ? [retObj?.selectedOption] : [];
        } else if (executionEnvData.selectedOption[0].value.toLowerCase().includes('local')) {
          selectedBrowserVersion = values[0]?.version;
        }

        let updatedData = JSON.parse(JSON.stringify(data));
        updatedData.systemConfigDetails.forEach((_ele) => {
          if (_ele.subType === 'browser') {
            _ele.version = Array.isArray(selectedBrowserVersion)
              ? selectedBrowserVersion[0]?.value
              : selectedBrowserVersion;
            _ele.name = values[0]?.value;
            _ele.platform = values[0]?.value;
          }
        });
        if (values[0]?.value?.toLowerCase().includes('internet')) {
          setHeadlessSwitch(false);
          updatedData['headless'] = false;
        }
        setData(updatedData);
        setBrowserData((val) => {
          return { ...val, selectedOption: values };
        });
        setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
      } catch (err) {
        console.error('HANDLE_BROWSER : ', err);
      }
    }
    setChangeValues(false);
  };

  const handleBrowserVersionChange = (values) => {
    if (changeValues !== true) {
      if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
        setIsDefaultMachineEdited(true);
      }
      let updatedData = JSON.parse(JSON.stringify(data));
      updatedData.systemConfigDetails.forEach((_ele) => {
        if (_ele.subType === 'browser') {
          _ele.version = values[0]?.value;
        }
      });
      setData(updatedData);
      setBrowserVersionData((val) => {
        return { ...val, selectedOption: values };
      });
    }
    setChangeValues(false);
  };

  const handleDeviceAppPlgChange = (values, osType = 'android') => {
    if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
      setIsDefaultMachineEdited(true);
    }
    let updatedData = JSON.parse(JSON.stringify(data));
    let deviceFound = false;
    updatedData.systemConfigDetails.forEach((_ele) => {
      if (_ele.subType === 'device' && _ele.platform === osType) {
          deviceFound = true;
        _ele['appId'] = values[0]?.appId;
        _ele['appName'] = values[0]?.appName;
      }
  });
  
    if (!deviceFound) {
      updatedData.systemConfigDetails=updatedData.systemConfigDetails.filter((_ele)=>(_ele.subType !=='device'))
      updatedData.systemConfigDetails.push({
        type: 'local',
        subType: 'device',
        platform: osType,
        appId: values[0]?.appId,
        appName: values[0]?.appName,
      });
    }
  
    setData(updatedData);
    osType === 'android'
      ? setAppData((val) => {
          return { ...val, selectedOption: values };
        })
      : setIosAppData((val) => {
          return { ...val, selectedOption: values };
        });
  };

  const dataConstructor = async () => {
    try {
      setIsLoading(true);
      let executionEnvOptions = [];
      let selectedExecutionEnv = [];
      let osNameOptions = [];
      let selectedOsName = [];
      let osVersionOptions = [];
      let selectedOsVersion = [];
      let browserNameOptions = [];
      let selectedBrowserName = [];
      let browserVersionOptions = [];
      let selectedBrowserVersion = [];
      let devicesOptions = [];
      let selectedDevices = [];
      let androidOsVersionOptions = [];
      let selectedAndroidOsVersion = [];
      let selectedAppData = [];
      let appDataOptions = [];
      let iosDevicesOptions = [];
      let selectedIosDevices = [];
      let iosOsVersionOptions = [];
      let selectedIosOsVersion = [];
      let iosAppDataOptions = [];
      let selectedIosAppData = [];
      const browserInstanceArray = props.instanceList.browserInstanceArray;
      const sauceLabInstanceArray = props.instanceList.sauceLabInstanceArray;
      const lambdaTestsInstanceArray = props.instanceList.lambdaTestsInstanceArray;
      props?.selectedSystem?.externalPlugins?.forEach((env) => {
        if (env.toLowerCase() === 'browserstack') {
          if (browserInstanceArray.length > 0) {
            browserInstanceArray.forEach((instance) => {
              const envName = `${env}-${instance.instanceName}`;
              executionEnvOptions.push({ label: envName, value: envName });
            });
          } else {
            executionEnvOptions.push({ label: env, value: env });
          }
        } else if (env.toLowerCase() === 'saucelabs') {
          if (sauceLabInstanceArray?.length > 0) {
            sauceLabInstanceArray.forEach((instance) => {
              const envName = `${env}-${instance.instanceName}`;
              executionEnvOptions.push({ label: envName, value: envName });
            });
          } else {
            executionEnvOptions.push({ label: env, value: env });
          }
        } else if (env.toLowerCase() === 'lambdatest') {
          if (lambdaTestsInstanceArray?.length > 0) {
            lambdaTestsInstanceArray.forEach((instance) => {
              const envName = `${env}-${instance.instanceName}`;
              executionEnvOptions.push({ label: envName, value: envName });
            });
          } else {
            executionEnvOptions.push({ label: env, value: env });
          }
        } else {
          executionEnvOptions.push({ label: env, value: env });
        }
      });
      if (executionEnvOptions.length) {
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
          selectedExecutionEnv = [
            { label: props.defaultSystem.executionEnvironment, value: props.defaultSystem.executionEnvironment },
          ];
          if (!props.defaultSystem?.headless) {
            setHeadlessSwitch(false);
          } else {
            setHeadlessSwitch(props.defaultSystem?.headless);
          }
        } else {
          selectedExecutionEnv = [executionEnvOptions[0]];
          if (!props.selectedSystem?.headless) {
            setHeadlessSwitch(false);
          } else {
            setHeadlessSwitch(props.selectedSystem?.headless);
          }
        }
      }
      if (selectedExecutionEnv[0]?.value?.toLowerCase() === 'local') {
        selectedOsName = props?.selectedSystem.machine.osName || '--';
        selectedOsVersion = props?.selectedSystem.machine.osVersion || '--';
        props?.selectedSystem?.systemConfigDetails?.forEach((_ele) => {
          const deviceDetails = {
            ..._ele,
            label: _ele.name,
            name: _ele.name,
            value: _ele.name,
          };
          if (_ele.subType === 'browser' && _ele.name) {
            browserNameOptions.push({
              label: getBrowserLabel(_ele.name),
              name: _ele.name,
              value: _ele.name,
              platform: _ele.platform,
              version: _ele.version,
              type: _ele.type,
              subType: _ele.subType,
            });
          } else if (_ele.subType === 'device') {
            if (_ele?.platform === 'android') {
              devicesOptions.push(deviceDetails);
            } else {
              iosDevicesOptions.push(deviceDetails);
            }
          }
        });
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
          props?.defaultSystem?.systemConfigDetails?.forEach((_defEle) => {
            if (_defEle.subType === 'browser') {
              const selcBrow = browserNameOptions.find((val) => val.name === _defEle.name);
              selectedBrowserName = selcBrow ? [selcBrow] : [];
              selectedBrowserVersion = selectedBrowserName[0]?.version || [];
            }
            if (_defEle.subType === 'device') {
              if (_defEle?.platform === 'android') {
                const selectAndroidDevice = devicesOptions.find((val) => val.name === _defEle.name);
                selectedDevices = selectAndroidDevice ? [selectAndroidDevice] : [];
                selectedAndroidOsVersion = selectedDevices[0]?.version;
              } else {
                const selectedIosDevice = iosDevicesOptions.find((val) => val.name === _defEle.name);
                selectedIosDevices = selectedIosDevice ? [selectedIosDevice] : [];
                selectedIosOsVersion = selectedIosDevices[0].version;
              }
            }
          });
        } else {
        }
        if (selectedBrowserName.length === 0) {
          selectedBrowserName = browserNameOptions[0] ? [{ ...browserNameOptions[0] }] : [];
          selectedBrowserVersion = selectedBrowserName[0]?.version || '';
        }
      } else if (integratedEnvironment(selectedExecutionEnv[0]?.value)) {
        const selectedEnvOpt = selectedExecutionEnv[0]?.value?.toLowerCase();
        let instanceName = selectedEnvOpt.slice(selectedEnvOpt.indexOf('-') + 1);

        let selectedPlatformTemp = selectedEnvOpt.slice(0, selectedEnvOpt.indexOf('-'));

        if (browserInstanceArray || lambdaTestsInstanceArray || sauceLabInstanceArray || selectedEnvOpt?.includes("firecloud")) {
          let selectedInstance;
          if (selectedPlatformTemp === 'browserstack') {
            selectedInstance = browserInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          } else if (selectedPlatformTemp === 'lambdatest') {
            selectedInstance = lambdaTestsInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          } else if (selectedPlatformTemp === 'saucelabs') {
            selectedInstance = sauceLabInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          }
          else if(selectedEnvOpt?.includes("firecloud")){    
            selectedPlatformTemp = "firecloud";
          }
          if (selectedInstance || selectedEnvOpt?.includes("firecloud")) {
            let retObj;
          retObj = await getCloudPlatformOSData(selectedInstance, props.selectedSystem, props.defaultSystem, false, selectedPlatformTemp );
            osNameOptions = retObj?.osObj?.options;
            selectedOsName = [retObj?.osObj.selectedOption];
            osVersionOptions = retObj?.osVerObj?.options;
            selectedOsVersion = [retObj?.osVerObj?.selectedOption];
            browserNameOptions = retObj?.browserObj?.options;
            selectedBrowserName = retObj?.browserObj?.selectedOption ? [retObj?.browserObj?.selectedOption] : [];
            browserVersionOptions = retObj?.browserVerObj?.options;
            selectedBrowserVersion = retObj?.browserVerObj?.selectedOption
              ? [retObj?.browserVerObj?.selectedOption]
              : [];
            devicesOptions = retObj?.deviceObj?.options;
            selectedDevices = retObj?.deviceObj?.selectedOption ? [retObj?.deviceObj?.selectedOption] : [];
            androidOsVersionOptions = retObj?.androidOsVersionObj?.options;
            selectedAndroidOsVersion = retObj?.androidOsVersionObj?.selectedOption
              ? [retObj?.androidOsVersionObj?.selectedOption]
              : [];
            appDataOptions = retObj?.appDataObj?.options;
            selectedAppData = retObj?.appDataObj?.selectedOption ? [retObj?.appDataObj?.selectedOption] : [];
            iosDevicesOptions = retObj?.deviceObjIOS?.options;
            selectedIosDevices = retObj?.deviceObjIOS?.selectedOption ? [retObj?.deviceObjIOS?.selectedOption] : [];
            iosOsVersionOptions = retObj?.iosOsVersionObj?.options;
            selectedIosOsVersion = retObj?.iosOsVersionObj?.selectedOption
              ? [retObj?.iosOsVersionObj?.selectedOption]
              : [];
            iosAppDataOptions = retObj?.appDataObjIos?.options;
            selectedIosAppData = retObj?.appDataObjIos?.selectedOption ? [retObj?.appDataObjIos?.selectedOption] : [];
            setBrowserListsApiData(retObj?.apiData);
          }
        }
      } else {
        // code for other plugins except (Local, BS, SL, LT)
      }
      if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
        setData(props.defaultSystem);
      } else {
        let updatedData = JSON.parse(JSON.stringify(props.selectedSystem));
        let tempOsName, tempOsVersion;
        if (Array.isArray(selectedOsName)) {
          [tempOsName, tempOsVersion] = selectedOsName[0].value.split('-') || [];
        } else {
          tempOsName = selectedOsName;
          tempOsVersion = selectedOsVersion;
        }
        updatedData.executionEnvironment = selectedExecutionEnv[0].value;
        updatedData.machine.osName = tempOsName;
        updatedData.machine.osVersion = tempOsVersion;
        updatedData.systemConfigDetails = [];
        const browserTemp = {
          version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0].value : selectedBrowserVersion,
          name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
          type: 'local',
          subType: 'browser',
          platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
        };
        const deviceTemp = {
          version: Array.isArray(selectedAndroidOsVersion)
            ? selectedAndroidOsVersion[0]?.value
            : selectedAndroidOsVersion,
          name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
          type: 'local',
          subType: 'device',
          fullName: selectedDevices?.[0]?.fullName,
          appId: selectedDevices?.[0]?.appId,
          appName: selectedDevices?.[0]?.appName,
          platform: 'android',
        };

        const iosDeviceTemp = {
          version: Array.isArray(selectedIosOsVersion) ? selectedIosOsVersion[0]?.value : selectedIosOsVersion,
          name: Array.isArray(selectedIosDevices) ? selectedIosDevices[0]?.value : selectedIosDevices,
          type: 'local',
          subType: 'device',
          fullName: selectedIosDevices?.[0]?.fullName,
          appId: selectedIosDevices?.[0]?.appId,
          appName: selectedIosDevices?.[0]?.appName,
          platform: 'ios',
        };
        if (browserTemp.name) {
          updatedData.systemConfigDetails.push(browserTemp);
        }
        if (deviceTemp.name) {
          updatedData.systemConfigDetails.push(deviceTemp);
        }
        if (iosDeviceTemp.name) {
          updatedData.systemConfigDetails.push(iosDeviceTemp);
        }
        setData(updatedData);
      }
      if (!Array.isArray(selectedOsName)) {
        selectedOsName = selectedOsName + '-' + selectedOsVersion;
      }
      setExecutionEnvData({ options: executionEnvOptions, selectedOption: selectedExecutionEnv });
      setOsData({ options: osNameOptions, selectedOption: selectedOsName });
      setOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion });
      setBrowserData({ options: browserNameOptions, selectedOption: selectedBrowserName });
      setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
      setDeviceData({ options: devicesOptions, selectedOption: selectedDevices });
      setAndroidOsVersionData({ options: androidOsVersionOptions, selectedOption: selectedAndroidOsVersion });
      setAppData({ options: appDataOptions, selectedOption: selectedAppData });
      setIosDeviceData({ options: iosDevicesOptions, selectedOption: selectedIosDevices });
      setIosOsVersionData({ options: iosOsVersionOptions, selectedOption: selectedIosOsVersion });
      setIosAppData({ options: iosAppDataOptions, selectedOption: selectedIosAppData });
      setIsLoading(false);
    } catch (err) {
      console.error('DATA_CONSTRUCTOR : ', err);
    }
  };

  const handleHeadlessSwitch = (e) => {
    let updatedData = JSON.parse(JSON.stringify(data));
    if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
      setIsDefaultMachineEdited(true);
    }
    setHeadlessSwitch(e.target.checked);
    if (updatedData) {
      updatedData['headless'] = e.target.checked;
    }
    setData(updatedData);
  };

  const handleMobileDeviceChange = (values, osType) => {
    if (
      (values[0]?.value !== deviceData.selectedOption[0]?.value && osType === 'android') ||
      (values[0]?.value !== iosDeviceData.selectedOption[0]?.value && osType === 'ios')
    ) {
      if (changeValues !== true) {
        try {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }
          let retObj;
          let osVersionOptions = [];
          let selectedOsVersion;
          let selectedExecEnv = executionEnvData?.selectedOption?.[0]?.value?.toLowerCase();
          if (
            selectedExecEnv.includes('lambdatest-') ||
            selectedExecEnv.includes('browserstack-') ||
            selectedExecEnv.includes('saucelabs-') ||
            selectedExecEnv?.includes(FIRECLOUD_DATA.FIRE_CLOUD)
          ) {
            retObj = getMobileOSVersion(
              browserListsApiData,
              values[0],
              props.selectedSystem,
              props.defaultSystem,
              true,
              osType
            );
            osVersionOptions = retObj?.options;
            selectedOsVersion = retObj?.selectedOption ? [retObj?.selectedOption] : [];
          } else if (executionEnvData.selectedOption[0].value.toLowerCase().includes('local')) {
            selectedOsVersion = values[0]?.version;
          }
          let updatedData = JSON.parse(JSON.stringify(data));
          let deviceFound = false;
          const { value, fullName, platform, serial_no, deviceUniqueId } = values?.[0] || {};
          updatedData.systemConfigDetails.forEach((_ele) => {
            if (_ele.subType === 'device' && _ele.platform === osType) {
              deviceFound = true;
              _ele.name = value;
              _ele.version = Array.isArray(selectedOsVersion) ? selectedOsVersion?.[0]?.value : selectedOsVersion;
              _ele['fullName'] = fullName;
              if (platform) {
                _ele.platform = platform;
              }
              _ele.serial_no = serial_no;
              _ele.deviceUniqueId = deviceUniqueId;
            }
          });
          if (!deviceFound) {
            updatedData.systemConfigDetails=updatedData.systemConfigDetails.filter((_ele)=>(_ele.subType !=='device'))
            updatedData.systemConfigDetails.push({
              version: Array.isArray(selectedOsVersion) ? selectedOsVersion?.[0]?.value : selectedOsVersion,
              name: value,
              type: 'local',
              subType: 'device',
              fullName: fullName,
              appId:
                osType === 'android' ? appData?.selectedOption?.[0]?.appId : iosAppData?.selectedOption?.[0]?.appId,
              appName:
                osType === 'android' ? appData?.selectedOption?.[0]?.appName : iosAppData?.selectedOption?.[0]?.appName,
              platform: osType,
              serial_no: serial_no,
              deviceUniqueId: deviceUniqueId,
            });
          }

          if (values.length) {
            setData(updatedData);
          } else {
            updatedData.systemConfigDetails = updatedData.systemConfigDetails.filter(
              (_ele) => !(_ele.subType === 'device' && _ele.platform === osType)
            );
            setData(updatedData);
          }
          osType === 'android'
            ? setDeviceData((val) => {
                return { ...val, selectedOption: values };
              })
            : setIosDeviceData((val) => {
                return { ...val, selectedOption: values };
              });
          osType === 'android'
            ? setAndroidOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion })
            : setIosOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion });
        } catch (err) {
          console.error('HANDLE_MOBILE_DEVICE_CHANGE : ', err);
        }
      }
      setChangeValues(false);
    }
  };

  const handleMobileDeviceOSChange = (values, osType) => {
    if (changeValues !== true) {
      try {
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
          setIsDefaultMachineEdited(true);
        }

        let updatedData = JSON.parse(JSON.stringify(data));

        updatedData.systemConfigDetails.forEach((_ele) => {
          if (_ele.subType === 'device' && _ele.platform === osType) {
            _ele['version'] = values[0]?.value;
          }
        });
        setData(updatedData);
        osType === 'android'
          ? setAndroidOsVersionData((val) => {
              return { ...val, selectedOption: values };
            })
          : setIosOsVersionData((val) => {
              return { ...val, selectedOption: values };
            });
      } catch (err) {
        console.error('HANDLE_MOBILE_DEVICE_OS_CHANGE : ', err);
      }
    }
    setChangeValues(false);
  };

  useEffect(() => {
    props.getUpdatedSelectedMachine(data);
  }, [data]);

  useEffect(() => {
    setData(props.selectedSystem);
    dataConstructor();
  }, [props.selectedSystem]);

  return (
    <div className="grid grid-cols-1">
      {!isLoading ? (
        <>
          <div
            className={`qrs-details-div-data-height grid grid-cols-1 gap-2 ${
              props?.selectedSystem?.disabled ? 'opacity-50' : ''
            }`}
          >
            <div className="qrs-shadow">
              <div className={`details-data-style-common my-2 px-4 py-2 ${validation ? 'border-red-error' : ''}`}>
                <SelectBox
                  labelProps={{
                    label: 'Execution Environment',
                  }}
                  searchable={true}
                  disabled={props?.selectedSystem?.disabled}
                  selectedOption={executionEnvData?.selectedOption}
                  options={executionEnvData?.options}
                  handleOptionChange={handleExecutionEnvChange}
                />
                {validation && (
                  <p className="overflow-hidden overflow-ellipsis whitespace-nowrap field-error-message">{errorMsg}</p>
                )}
              </div>
              {showBrowser && (
                <>
                  <div className="system-config-web-section px-4">
                    <span className="fontPoppinsMediumMd">Web</span>
                    <span className="fontPoppinsMediumMd">
                      {!isLoading && executionEnvData?.selectedOption[0]?.value === 'Local' && (
                        <ToggleHeadless
                          headlessSwitch={props.disableHeadless ? false : headlessSwitch}
                          handleHeadlessSwitch={handleHeadlessSwitch}
                          executionEnvData={executionEnvData}
                          browserData={browserData}
                          project={props.project}
                          disabled={props.disableHeadless || props?.selectedSystem?.disabled}
                        />
                      )}
                    </span>
                  </div>

                  <div className="px-4 py-2 flex items-center gap-3">
                    <div className="dropdown-width">
                      {osData.selectedOption &&
                      executionEnvData?.selectedOption[0]?.value === 'Local' &&
                      !Array.isArray(osData.selectedOption) ? (
                        <IconWithLabelledInput
                          toolTipTitle={osData?.selectedOption}
                          placement="top"
                          showToolTip={true}
                          className={`${props.disabled ? 'opacity-50' : ''}`}
                          labelProps={{ label: 'OS & Os Version' }}
                          text={getOsLabel(osData?.selectedOption, 'inline-block ml-2')}
                        />
                      ) : (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={osData?.options}
                          selectedOption={osData?.selectedOption}
                          handleOptionChange={handleOsChange}
                          labelProps={{
                            label: 'OS & Os Version',
                          }}
                          placeholder="Select"
                          error={props.runButtonClicked === true && osData?.selectedOption?.length === 0 ? true : false}
                          errMsg={
                            props.runButtonClicked === true && osData?.selectedOption?.length === 0
                              ? 'Select Os & Os Version'
                              : ''
                          }
                        />
                        )}
                    </div>
                    <div div className="dropdown-width">
                      <SelectBox
                        labelProps={{
                          label: 'Browser',
                        }}
                        disabled={props?.selectedSystem?.disabled}
                        selectedOption={browserData?.selectedOption}
                        options={browserData?.options}
                        handleOptionChange={handleBrowserChange}
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="Select"
                        error={
                          props.runButtonClicked === true && browserData?.selectedOption?.length === 0 ? true : false
                        }
                        errMsg={
                          props.runButtonClicked === true && browserData?.selectedOption?.length === 0
                            ? 'Select Browser'
                            : ''
                        }
                      />
                    </div>

                    {!Array.isArray(browserVersionData.selectedOption) ? (
                      <div div className="dropdown-width">
                        <IconWithLabelledInput
                          toolTipTitle={osData?.selectedOption}
                          placement="top"
                          showToolTip={true}
                          className={`table-font-style-common px-1.5 py-0.5 ${props.disabled ? 'opacity-50' : ''}`}
                          labelProps={{ label: 'Browser Version' }}
                          text={browserVersionData.selectedOption}
                        />
                      </div>
                    ) : (
                      <div div className="dropdown-width">
                        <SelectBox
                          labelProps={{
                            label: 'Browser Version',
                          }}
                          disabled={props?.selectedSystem?.disabled}
                          selectedOption={browserVersionData?.selectedOption}
                          options={browserVersionData?.options}
                          handleOptionChange={handleBrowserVersionChange}
                          searchable={true}
                          placeholder="Select"
                          error={
                            props.runButtonClicked === true && browserVersionData?.selectedOption?.length === 0
                              ? true
                              : false
                          }
                          errMsg={
                            props.runButtonClicked === true && browserVersionData?.selectedOption?.length === 0
                              ? 'Select Browser Version'
                              : ''
                          }
                        />
                      </div>
                    )}

                    {executionEnvData?.selectedOption[0]?.value !== 'Local' && (
                      <div className="mt-5">
                        {/* <HighlightOff
                          style={{ color: colors.rs_error }}
                          onClick={() => {
                            setChangeValues(true);
                            setOsData({
                              options: osData?.options,
                              selectedOption: [],
                            });
                            setBrowserData({
                              options: [],
                              selectedOption: [],
                            });
                            setBrowserVersionData({
                              options: [],
                              selectedOption: [],
                            });
                          }}
                        /> */}
                      </div>
                    )}
                  </div>
                </>
              )}
              {showDevice && (
                <>
                  <div className="system-config-web-section px-4">
                    <span className="fontPoppinsMediumMd">Mobile</span>
                  </div>
                  <div className="px-4 py-2 flex items-center gap-3">
                    <div div className="dropdown-width">
                      {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) ? (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={deviceData?.options}
                          selectedOption={getDeviceData(deviceData?.selectedOption)}
                          handleOptionChange={(values) => {
                            handleMobileDeviceChange(values, 'android');
                          }}
                          labelProps={{
                            label: 'Android Device',
                          }}
                          placeholder="Select"
                          // error={(props.runButtonClicked === true && deviceData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && deviceData?.selectedOption?.length === 0) ? "Select Device" : ""}
                        />
                      ) : (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={deviceData?.options}
                          selectedOption={deviceData?.selectedOption}
                          handleOptionChange={(values) => {
                            handleMobileDeviceChange(values, 'android');
                          }}
                          labelProps={{
                            label: 'Android Device',
                          }}
                          placeholder="Select"
                          // error={(props.runButtonClicked === true && deviceData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && deviceData?.selectedOption?.length === 0) ? "Select Device" : ""}
                        />
                      )}
                    </div>

                    <div div className="dropdown-width">
                      {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) ? (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={androidOsVersionData?.options}
                          selectedOption={androidOsVersionData?.selectedOption}
                          handleOptionChange={(values) => {
                            handleMobileDeviceOSChange(values, 'android');
                          }}
                          labelProps={{
                            label: 'OS Version',
                          }}
                          placeholder="Select"
                          // error={(props.runButtonClicked === true && androidOsVersionData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && androidOsVersionData?.selectedOption?.length === 0) ? "Select Os Version" : ""}
                        />
                      ) : (
                        <IconWithLabelledInput
                          toolTipTitle={androidOsVersionData?.selectedOption}
                          placement="top"
                          showToolTip={true}
                          className={`table-font-style-common px-1.5 py-0.5 ${props.disabled ? 'opacity-50' : ''}`}
                          labelProps={{ label: 'OS Version' }}
                          text={androidOsVersionData.selectedOption}
                        />
                      )}
                    </div>

                    {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) && (
                      <div className="dropdown-width">
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={appData?.options}
                          selectedOption={appData?.selectedOption}
                          handleOptionChange={(values) => {
                            handleDeviceAppPlgChange(values, 'android');
                          }}
                          placeholder="Select"
                          labelProps={{
                            label: 'App',
                          }}
                          // error={(props.runButtonClicked === true && appData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && appData?.selectedOption?.length === 0) ? "Select App" : ""}
                        />
                      </div>
                    )}
                    <div className="mt-5">
                      {/* <HighlightOff
                        style={{ color: colors.rs_error }}
                        onClick={() => {
                          setAndroidValueChanges(true)
                          setDeviceData({
                            options: deviceData?.options,
                            selectedOption: [],
                          });
                          setAndroidOsVersionData({
                            options: [],
                            selectedOption: [],
                          });
                          if (
                            executionEnvData?.selectedOption[0]?.value !==
                            "Local"
                          ) {
                            setAppData({
                              options: appData?.options,
                              selectedOption: [],
                            });
                          }
                        }}
                      /> */}
                    </div>
                  </div>

                  <div className="px-4 py-2 flex items-center gap-3">
                    <div div className="dropdown-width">
                      {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) ? (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={iosDeviceData?.options}
                          selectedOption={getDeviceData(iosDeviceData?.selectedOption)}
                          handleOptionChange={(values) => {
                            handleMobileDeviceChange(values, 'ios');
                          }}
                          placeholder="Select"
                          labelProps={{
                            label: 'iOS Device',
                          }}
                          // error={(props.runButtonClicked === true && iosDeviceData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && iosDeviceData?.selectedOption?.length === 0) ? "Select Device" : ""}
                        />
                      ) : (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={iosDeviceData?.options}
                          selectedOption={iosDeviceData?.selectedOption}
                          handleOptionChange={(values) => {
                            handleMobileDeviceChange(values, 'ios');
                          }}
                          placeholder="Select"
                          labelProps={{
                            label: 'iOS Device',
                          }}
                          // error={(props.runButtonClicked === true && iosDeviceData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && iosDeviceData?.selectedOption?.length === 0) ? "Select Device" : ""}
                        />
                      )}
                    </div>

                    <div div className="dropdown-width">
                      {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) ? (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={iosOsVersionData?.options}
                          selectedOption={iosOsVersionData?.selectedOption}
                          handleOptionChange={(values) => {
                            handleMobileDeviceOSChange(values, 'ios');
                          }}
                          placeholder="Select"
                          labelProps={{
                            label: 'OS Version',
                          }}
                          // error={(props.runButtonClicked === true && iosOsVersionData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && iosOsVersionData?.selectedOption?.length === 0) ? "Select Os Version" : ""}
                        />
                      ) : (
                        <IconWithLabelledInput
                          toolTipTitle={iosOsVersionData?.selectedOption}
                          placement="top"
                          showToolTip={true}
                          className={`table-font-style-common px-1.5 py-0.5 ${props.disabled ? 'opacity-50' : ''}`}
                          labelProps={{ label: 'OS Version' }}
                          text={iosOsVersionData.selectedOption}
                        />
                      )}
                    </div>

                    {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) && (
                      <div div className="dropdown-width">
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={iosAppData?.options}
                          selectedOption={iosAppData?.selectedOption}
                          handleOptionChange={(values) => {
                            handleDeviceAppPlgChange(values, 'ios');
                          }}
                          placeholder="Select"
                          labelProps={{
                            label: 'App',
                          }}
                          // error={(props.runButtonClicked === true && iosAppData?.selectedOption?.length === 0) ? true : false}
                          // errMsg ={(props.runButtonClicked === true && iosAppData?.selectedOption?.length === 0) ? "Select App" : ""}
                        />
                      </div>
                    )}
                    <div className="mt-5">
                      {/* <HighlightOff
                        style={{ color: colors.rs_error }}
                        onClick={() => {
                          setIosValueChanges(true)
                          if (
                            executionEnvData?.selectedOption[0]?.value !==
                            "Local"
                          ) {
                            setIosAppData({
                              options: iosAppData?.options,
                              selectedOption: [],
                            });
                          }
                          setIosOsVersionData({
                            options: [],
                            selectedOption: [],
                          });
                          setIosDeviceData({
                            options: iosDeviceData?.options,
                            selectedOption: [],
                          });
                        }}
                      /> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="allign-div-center qrs-details-div-data-height">
          <CommonLoader />
        </div>
      )}

      {navigateToInstanceModal?.openModal && (
        <NavigateToCreateInstanceModal closeModal={closeModal} data={navigateToInstanceModal.data} />
      )}
    </div>
  );
};

export default QrsMachineDetails;
