import React, { Component, Fragment } from 'react';
import AceEditorQueryPopUp from './Ace_editor_query_pop_up';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import xmlBeautifier from 'xml-beautifier';
import AceEditor from '@pages/test-development/script/scripts/webservice/restapi/request/request_body/jsx/Ace_Editor';
import '../css/Ace_Editor.css';
import { Tooltip } from '@material-ui/core';
import JSONbig from 'json-bigint';
import jsBeautify from 'js-beautify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { colors } from '@src/css_config/colorConstants.js';
import Button from '@mui/material/Button';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import '@pages/test-development/script/scripts/webservice/restapi/css/common_Webservice.scss';
import CustomTooltip from '@pages/test-development/script/scripts/webservice/CustomTooltip';
import { Icon } from 'fireflink-ui';
import classNames from 'classnames';

const styles = {
  resizedButton: {
    fontSize: '18px',
    width: '50px',
    height: '25px',
    borderRadius: '5px',
    padding: '6px 12px',
  },
  normalButton: {
    border: `1px solid ${colors.silver}`,
    color: colors.gray,
  },
  hoveredButton: {
    '&:hover': {
      color: `${colors.text_white}`,
      border: `1px solid ${colors.light_gray_60}`,
      backgroundColor: `${colors.light_gray_60}`,
    },
  },
  hoveredActiveButton: {
    '&:hover': {
      color: `${colors.text_white}`,
      border: `1px solid ${colors.rs_primary}`,
      backgroundColor: `${colors.rs_primary}`,
    },
  },
  activeButtonStyle: {
    color: `${colors.text_white}`,
    border: `1px solid ${colors.rs_primary}`,
    backgroundColor: `${colors.rs_primary}`,
  },
};

class ResponseBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setColorXML: '',
      setColorJSON: '',
      setColorText: '',
      setColorHTML: '',
      mode: '',
      setOpen: false,
      value: '',
      jsonValueShow: false,
      loadStringValue: true,
      htmlValueShow: false,
      textValueShow: false,
      setresponseBodyValueWithParse: '',
      xmlValueShow: false,
      responseBodyValueWithParseXML: '',
      setXml: false,
      setJSON: false,
      dataExpandPopUp: '',
      modeOfPopUp: '',
      setresponseBodyValueWithParseError: '',
      responseBodyValueWithParseHTML: '',
      isCopyToClipBoard: false,
      activeButton: null,
      isHovered: false,
      isHoveredDownload: false,
      isJsonHoveredButton: false,
      isHtmlHoveredButton: false,
      isTextHoveredButton: false,
    };
    this.xmlClick = this.xmlClick.bind(this);
  }

  componentDidMount() {
    this.onLoad();
  }
  componentDidUpdate(prevProps) {
    const { responseBodyValueWithoutParse, HTMLContentTypeLen, JsonContentTypeLen, XMLContentTypeLen } = this.props;
    if (
      responseBodyValueWithoutParse !== prevProps.responseBodyValueWithoutParse &&
      HTMLContentTypeLen === 0 &&
      JsonContentTypeLen === 0 &&
      XMLContentTypeLen >= 1
    ) {
      this.onParentXmlTrigger();
    } else if (
      responseBodyValueWithoutParse !== prevProps.responseBodyValueWithoutParse &&
      HTMLContentTypeLen === 0 &&
      JsonContentTypeLen >= 1 &&
      XMLContentTypeLen === 0
    ) {
      this.onParentJSONTrigger();
    } else if (
      responseBodyValueWithoutParse !== prevProps.responseBodyValueWithoutParse &&
      HTMLContentTypeLen >= 1 &&
      JsonContentTypeLen === 0 &&
      XMLContentTypeLen === 0
    ) {
      this.onParentXmlTrigger();
    }
  }

  expandIcon = () => {
    const { responseBodyValueWithoutParse, responseBodyValueWithoutParseError } = this.props;
    if (this.state.mode === 'html') {
      let responseBodyValueWithParseHTML = jsBeautify.html(responseBodyValueWithoutParse);
      this.setState({
        modeOfPopUp: 'html',
        setOpen: true,
        dataExpandPopUp: responseBodyValueWithoutParse
          ? responseBodyValueWithParseHTML
          : responseBodyValueWithoutParseError,
      });
    } else if (this.state.mode === 'xml') {
      let responseBodyValueWithParseXML = xmlBeautifier(responseBodyValueWithoutParse);
      this.setState({
        modeOfPopUp: 'xml',
        setOpen: true,
        dataExpandPopUp: responseBodyValueWithoutParse
          ? responseBodyValueWithParseXML
          : responseBodyValueWithoutParseError,
      });
    } else if (this.state.mode === 'json') {
      this.setState({
        modeOfPopUp: 'json',
        setOpen: true,
        dataExpandPopUp: responseBodyValueWithoutParse
          ? JSON.stringify(responseBodyValueWithoutParse, null, 2)
          : responseBodyValueWithoutParseError,
      });
    } else if (this.state.mode === 'text') {
      this.setState({
        modeOfPopUp: 'html',
        setOpen: true,
        dataExpandPopUp: responseBodyValueWithoutParse
          ? responseBodyValueWithoutParse
          : responseBodyValueWithoutParseError,
      });
    }
  };

  onLoad() {
    const { JsonContentTypeLen, XMLContentTypeLen, HTMLContentTypeLen } = this.props;
    if (JsonContentTypeLen === 0 && XMLContentTypeLen >= 1 && HTMLContentTypeLen === 0) {
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'default',
        jsonValueShow: false,
        loadStringValue: true,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: false,
        mode: 'html',
        setOpen: false,
        setJSON: false,
        setXml: true,
      });
    } else if (JsonContentTypeLen === 0 && XMLContentTypeLen === 0 && HTMLContentTypeLen === 0) {
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'default',
        jsonValueShow: false,
        loadStringValue: true,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: false,
        mode: 'html',
        setOpen: false,
        setJSON: true,
        setXml: false,
      });
    } else if (JsonContentTypeLen >= 1 && XMLContentTypeLen === 0 && HTMLContentTypeLen === 0) {
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'default',
        jsonValueShow: false,
        loadStringValue: true,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: false,
        mode: 'html',
        setOpen: false,
        setJSON: true,
        setXml: false,
      });
    } else if (JsonContentTypeLen === 0 && XMLContentTypeLen === 0 && HTMLContentTypeLen >= 1) {
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'default',
        jsonValueShow: false,
        loadStringValue: true,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: false,
        mode: 'html',
        setOpen: false,
        setJSON: false,
        setXml: true,
      });
    }
  }

  onParentXmlTrigger() {
    this.setState({
      setColorXML: 'default',
      setColorJSON: 'default',
      setColorText: 'default',
      setColorHTML: 'default',
      jsonValueShow: false,
      loadStringValue: true,
      htmlValueShow: false,
      textValueShow: false,
      xmlValueShow: false,
      mode: 'html',
      setOpen: false,
      setJSON: false,
      setXml: true,
    });
  }

  onParentJSONTrigger() {
    this.setState({
      setColorXML: 'default',
      setColorJSON: 'default',
      setColorText: 'default',
      setColorHTML: 'default',
      jsonValueShow: false,
      loadStringValue: true,
      htmlValueShow: false,
      textValueShow: false,
      xmlValueShow: false,
      mode: 'html',
      setOpen: false,
      setJSON: true,
      setXml: false,
    });
  }
  changeClick = (buttonIndex) => {
    this.setState({
      activeButton: buttonIndex,
    });
  };
  xmlClick = () => {
    const { responseBodyValueWithoutParse } = this.props;
    let prettifyXml = function (sourceXml) {
      let xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
      let xsltDoc = new DOMParser().parseFromString(
        [
          // describes how we want to modify the XML - indent everything
          '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
          '  <xsl:strip-space elements="*"/>',
          '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
          '    <xsl:value-of select="normalize-space(.)"/>',
          '  </xsl:template>',
          '  <xsl:template match="node()|@*">',
          '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
          '  </xsl:template>',
          '  <xsl:output indent="yes"/>',
          '</xsl:stylesheet>',
        ].join('\n'),
        'application/xml'
      );

      let xsltProcessor = new XSLTProcessor();
      xsltProcessor.importStylesheet(xsltDoc);
      let resultDoc = xsltProcessor.transformToDocument(xmlDoc);
      let resultXml = new XMLSerializer().serializeToString(resultDoc);
      return resultXml;
    };

    if (responseBodyValueWithoutParse !== '') {
      let responseBodyValueWithParseXML = prettifyXml(`${responseBodyValueWithoutParse}`);
      this.setState({
        setColorXML: 'primary',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'default',
        mode: 'xml',
        jsonValueShow: false,
        loadStringValue: false,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: true,
        responseBodyValueWithParseXML: responseBodyValueWithParseXML,
      });
    } else if (responseBodyValueWithoutParse === '') {
      this.setState({
        setColorXML: 'primary',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'default',
        mode: 'xml',
        jsonValueShow: false,
        loadStringValue: false,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: true,
      });
    }
  };

  textClick = () => {
    this.setState({
      setColorXML: 'default',
      setColorJSON: 'default',
      setColorText: 'primary',
      setColorHTML: 'default',
      mode: 'text',
      jsonValueShow: false,
      loadStringValue: false,
      htmlValueShow: false,
      textValueShow: true,
      xmlValueShow: false,
    });
  };

  htmlClick = () => {
    const { responseBodyValueWithoutParse } = this.props;
    if (responseBodyValueWithoutParse !== '') {
      let responseBodyValueWithParseHTML = jsBeautify.html(responseBodyValueWithoutParse);
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'primary',
        mode: 'html',
        jsonValueShow: false,
        loadStringValue: false,
        htmlValueShow: true,
        textValueShow: false,
        xmlValueShow: false,
        responseBodyValueWithParseHTML: responseBodyValueWithParseHTML,
      });
    } else if (responseBodyValueWithoutParse === '') {
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'default',
        setColorText: 'default',
        setColorHTML: 'primary',
        mode: 'html',
        jsonValueShow: false,
        loadStringValue: false,
        htmlValueShow: true,
        textValueShow: false,
        xmlValueShow: false,
      });
    }
  };

  jsonClick = () => {
    const { responseBodyValueWithoutParse, responseBodyValueWithoutParseError } = this.props;
    if (responseBodyValueWithoutParse !== '') {
      let responseBodyValueWithParse = JSONbig.parse(responseBodyValueWithoutParse);
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'primary',
        setColorText: 'default',
        setColorHTML: 'default',
        mode: 'json',
        jsonValueShow: true,
        loadStringValue: false,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: false,
        setresponseBodyValueWithParse: responseBodyValueWithParse,
      });
    } else if (responseBodyValueWithoutParseError !== '') {
      let responseBodyValueWithoutError = responseBodyValueWithoutParseError;
      if (this.checkJson(responseBodyValueWithoutParseError)) {
        let responseBodyValueWithoutErrorObj = JSONbig.parse(responseBodyValueWithoutParseError);
        responseBodyValueWithoutError = JSON.stringify(responseBodyValueWithoutErrorObj, null, 2);
      } else {
        responseBodyValueWithoutError = responseBodyValueWithoutParseError;
      }

      this.setState({
        setColorXML: 'default',
        setColorJSON: 'primary',
        setColorText: 'default',
        setColorHTML: 'default',
        mode: 'json',
        jsonValueShow: true,
        loadStringValue: false,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: false,
        setresponseBodyValueWithParseError: responseBodyValueWithoutError,
      });
    } else if (responseBodyValueWithoutParse === '') {
      this.setState({
        setColorXML: 'default',
        setColorJSON: 'primary',
        setColorText: 'default',
        setColorHTML: 'default',
        mode: 'json',
        jsonValueShow: true,
        loadStringValue: false,
        htmlValueShow: false,
        textValueShow: false,
        xmlValueShow: false,
      });
    }
  };
  disabledDownload = (event) => {
    const { responseBodyValueWithoutParse, responseBodyValueWithoutParseError } = this.props;
    if (
      this.state.loadStringValue === true &&
      (responseBodyValueWithoutParse !== '' || responseBodyValueWithoutParseError !== '')
    ) {
      event.preventDefault();
    }
  };

  checkJson(str) {
    try {
      const parsed = JSON.parse(str);
      if (typeof parsed === 'object') {
        return true;
      }
    } catch (e) {
      return false;
    }

    if (typeof str === 'string') {
      return false;
    }
  }
  onCheckNavigatorClipboard = async () => {
    const { responseBodyValueWithoutParse } = this.props;
    this.setState({ isCopyToClipBoard: true });
    setTimeout(() => {
      this.setState({ isCopyToClipBoard: false });
    }, 1000);
    if (navigator.clipboard) {
      try {
        if (this.state.jsonValueShow) {
          await navigator?.clipboard?.writeText(
            JSON.stringify(JSON.parse(responseBodyValueWithoutParse), undefined, 2) || 'no content to copy'
          );
        } else {
          let json = responseBodyValueWithoutParse.replace(/\n|\r/g, '');
          await navigator?.clipboard?.writeText(
            json || this.state?.setresponseBodyValueWithParseError || 'no content to copy'
          );
        }
      } catch (error) {
        await navigator.clipboard.writeText(this.state?.setresponseBodyValueWithParseError || 'no content to copy');
      }
    } else {
      if (this.state.jsonValueShow) {
        await window.navigator?.clipboard?.writeText(
          JSON.stringify(JSON.parse(responseBodyValueWithoutParse), undefined, 2) || 'no content to copy'
        );
      } else {
        let json = responseBodyValueWithoutParse.replace(/\n|\r/g, '');
        await window?.navigator?.clipboard?.writeText(json || 'no content to copy');
      }
    }
  };
  handleMouseEnter = () => {
    this.setState({
      isHovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isHovered: false,
    });
  };
  handleMouseEnterDownload = () => {
    this.setState({
      isHoveredDownload: true,
    });
  };

  handleMouseLeaveDownload = () => {
    this.setState({
      isHoveredDownload: false,
    });
  };
  handleMouseJsonEnterButton = () => {
    this.setState({ isJsonHoveredButton: true });
  };
  handleMouseJsonLeaveButton = () => {
    this.setState({ isJsonHoveredButton: false });
  };
  handleMouseTextEnterButton = () => {
    this.setState({ isTextHoveredButton: true });
  };
  handleMouseTextLeaveButton = () => {
    this.setState({ isTextHoveredButton: false });
  };
  handleMouseHtmlEnterButton = () => {
    this.setState({ isHtmlHoveredButton: true });
  };
  handleMouseHtmlLeaveButton = () => {
    this.setState({ isHtmlHoveredButton: false });
  };
  bytesToMB = (bytes) => {
    const bytesPerMB = 1048576;
    return bytes / bytesPerMB;
  };

  render() {
    const {
      responseBodyValueWithoutParse,
      requestSectionCollapse,
      setRequestNameResponse,
      responseBodyValueWithoutParseError,
      fileBlobSize,
    } = this.props;
    let responseBodyValue = '';
    if (responseBodyValueWithoutParse !== '' && this.checkJson(responseBodyValueWithoutParse)) {
      responseBodyValue = JSON.stringify(JSONbig.parse(responseBodyValueWithoutParse));
    } else if (responseBodyValueWithoutParse !== '') {
      responseBodyValue = responseBodyValueWithoutParse;
    } else {
      responseBodyValue = responseBodyValueWithoutParseError;
    }

    const { classes } = this.props;

    const isFileSizeExceeded = this.bytesToMB(fileBlobSize);
    console.log(isFileSizeExceeded >= 15, Number(isFileSizeExceeded), 'finalJson', fileBlobSize, isFileSizeExceeded);
    return (
      <Fragment>
        <div
          className={`flex grid-flex-wrp border-light-red-300 parentContent ${
            requestSectionCollapse && 'response-section-expand-height'
          }`}
          id="journal-scroll"
        >
          <Modal open={this.state.setOpen} className="modal-dialog">
            <div className="modal-container page-modal-size1">
              <div className="modal-header">
                <h2 className="title">{`Response body`}</h2>
                <IconButton
                  color="primary"
                  component="span"
                  className="close"
                  onClick={() => {
                    this.setState({
                      setOpen: false,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="modal-body" id="journal-scroll">
                <div>
                  {this.state.modeOfPopUp === 'xml' ? (
                    <AceEditorQueryPopUp mode={this.state.modeOfPopUp} modeValue={this.state.dataExpandPopUp} />
                  ) : (
                    ''
                  )}
                  {this.state.modeOfPopUp === 'html' ? (
                    <div className="flex flex-row mt-2" id="journal-scroll">
                      <div
                        className="console_output_container_Response fontPoppinsRegularMd shadow w-screen"
                        id="journal-scroll"
                      >
                        {this.state.dataExpandPopUp}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.modeOfPopUp === 'text' ? (
                    <div className="flex flex-row mt-2" id="journal-scroll">
                      <div
                        className="console_output_container_Response fontPoppinsRegularMd shadow w-screen"
                        id="journal-scroll"
                      >
                        {this.state.dataExpandPopUp}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.modeOfPopUp === 'json' ? (
                    <div className="flex flex-row mt-2" id="journal-scroll">
                      <div
                        className="console_output_container_Response fontPoppinsRegularMd shadow w-screen"
                        id="journal-scroll"
                      >
                        <AceEditor
                          className="flex relative h-full  min-h-160"
                          id="journal-scroll"
                          mode={'text'}
                          value={
                            responseBodyValueWithoutParse !== ''
                              ? JSON.stringify(this.state.setresponseBodyValueWithParse, null, 2)
                              : JSON.stringify(this.state.setresponseBodyValueWithParseError, null, 2)
                          }
                          isReadOnly={true}
                          showGutter={false}
                          highlightActiveLine={false}
                          requestSectionCollapse={requestSectionCollapse}
                          responseBodyExpandPopUp={'popUp'}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="cancel-button-common cancel-button-layout-style-project mr-3 fontPoppinsRegularMd"
                  onClick={() => {
                    this.setState({
                      setOpen: false,
                    });
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
          <div className="grid grid-rows-1 grid-cols-2 ml-1 mr-1 responseHeader">
            <div className="mt-3 ml-3 grid-rows-1 grid-cols-1 response-body-button-alignments">
              {this.state.setXml ? (
                <Button
                  onMouseEnter={this.handleMouseJsonEnterButton}
                  onMouseLeave={this.handleMouseJsonLeaveButton}
                  style={{
                    border:
                      this.state.activeButton === 0
                        ? `1px solid ${colors.button_blue_50}`
                        : `1px solid ${colors.silver}`,
                    color: this.state.isJsonHoveredButton
                      ? colors.text_white
                      : this.state.activeButton === 0
                      ? colors.rs_primary
                      : colors.grey_light,
                    backgroundColor:
                      this.state.isJsonHoveredButton && this.state.activeButton === 0
                        ? colors.rs_primary
                        : this.state.isJsonHoveredButton && colors.grey_light,
                  }}
                  variant={'outlined'}
                  className={`${classes.resizedButton} ml-1 text-sm response-nav-bar fontMontserratMediumXs  ${this.state.setColorXML}`}
                  onClick={() => {
                    this.xmlClick();
                    this.changeClick(0);
                  }}
                  disabled={isFileSizeExceeded >= 15}
                >
                  <span className="fontMontserratMediumXs">XML</span>
                </Button>
              ) : (
                <Button
                  onMouseEnter={this.handleMouseJsonEnterButton}
                  onMouseLeave={this.handleMouseJsonLeaveButton}
                  style={{
                    border:
                      this.state.activeButton === 0
                        ? `1px solid ${colors.button_blue_50}`
                        : `1px solid ${colors.silver}`,
                    color: this.state.isJsonHoveredButton
                      ? colors.text_white
                      : this.state.activeButton === 0
                      ? colors.rs_primary
                      : colors.gray,
                    backgroundColor:
                      this.state.isJsonHoveredButton && this.state.activeButton === 0
                        ? colors.rs_primary
                        : this.state.isJsonHoveredButton && colors.gray,
                    padding: '6px 12px',
                  }}
                  variant={'outlined'}
                  className={`${classes.resizedButton} ml-1 text-sm response-nav-bar fontMontserratMediumXs  ${this.state.setColorJSON}`}
                  onClick={() => {
                    this.jsonClick();
                    this.changeClick(0);
                  }}
                  disabled={isFileSizeExceeded >= 15}
                >
                  <span className="fontMontserratMediumXs">JSON</span>
                </Button>
              )}
              <Button
                variant={'outlined'}
                onMouseEnter={this.handleMouseTextEnterButton}
                onMouseLeave={this.handleMouseTextLeaveButton}
                style={{
                  border:
                    this.state.activeButton === 1 ? `1px solid ${colors.button_blue_50}` : `1px solid ${colors.silver}`,
                  color: this.state.isTextHoveredButton
                    ? colors.text_white
                    : this.state.activeButton === 1
                    ? colors.rs_primary
                    : colors.gray,
                  backgroundColor:
                    this.state.isTextHoveredButton && this.state.activeButton === 1
                      ? colors.rs_primary
                      : this.state.isTextHoveredButton && colors.gray,
                  padding: '6px 12px',
                }}
                className={`${classes.resizedButton} ml-1 text-sm response-nav-bar fontMontserratMediumXs  ${
                  this.state.setColorText
                }  ${isFileSizeExceeded >= 15 ? 'cursor-not-allowed' : ''}`}
                onClick={() => {
                  this.textClick();
                  this.changeClick(1);
                }}
                disabled={isFileSizeExceeded >= 15}
              >
                <span className="fontMontserratMediumXs">Text</span>
              </Button>
              <Button
                variant={'outlined'}
                onMouseEnter={this.handleMouseHtmlEnterButton}
                onMouseLeave={this.handleMouseHtmlLeaveButton}
                style={{
                  border:
                    this.state.activeButton === 2 ? `1px solid ${colors.button_blue_50}` : `1px solid ${colors.silver}`,
                  color: this.state.isHtmlHoveredButton
                    ? colors.text_white
                    : this.state.activeButton === 2
                    ? colors.rs_primary
                    : colors.gray,
                  backgroundColor:
                    this.state.isHtmlHoveredButton && this.state.activeButton === 2
                      ? colors.rs_primary
                      : this.state.isHtmlHoveredButton && colors.gray,
                  padding: '6px 12px',
                }}
                className={`${classes.resizedButton} ml-1 text-sm response-nav-bar fontMontserratMediumXs ${this.state.setColorHTML}`}
                onClick={() => {
                  this.htmlClick();
                  this.changeClick(2);
                }}
                disabled={isFileSizeExceeded >= 15}
              >
                <span className="fontMontserratMediumXs">HTML</span>
              </Button>
            </div>
            <div className="grid-rows-1 grid-cols-2">
              <div className="float-right mr-2">
                <button
                  className="hidden state-button px-1 rounded-l cursor-pointer pr-5 tooltip-nav-bar -mt-1"
                  onClick={(e) => {}}
                >
                  <span className="-mt-1.5">
                    <Tooltip title={<span className="fontPoppinsRegularXs">Search...</span>} placement="bottom">
                      <SearchIcon className="search_icon_in_response_body" />
                    </Tooltip>
                  </span>
                </button>
                <span className="flex justify-center">
                  <a
                    href={
                      this.state.mode === 'json' && this.checkJson(responseBodyValue)
                        ? window.URL.createObjectURL(
                            new Blob([JSON.stringify(JSONbig.parse(responseBodyValue), null, 2)], {
                              type: 'application/json',
                            })
                          )
                        : this.state.mode === 'xml'
                        ? window.URL.createObjectURL(
                            new Blob([xmlBeautifier(responseBodyValue)], { type: 'application/xml' })
                          )
                        : window.URL.createObjectURL(new Blob([responseBodyValue]))
                    }
                    onClick={(event) => {
                      this.disabledDownload(event);
                    }}
                    download={`${setRequestNameResponse}.${this.state.mode}`}
                    draggable="false"
                  >
                    <span className="rounded-l cursor-pointer ">
                      <CustomTooltip title="Download" placement="bottom">
                        <span className="rounded-full ">
                          <Icon
                            name="download"
                            hoverEffect={true}
                            disabled={
                              this.state.loadStringValue === true &&
                              (responseBodyValueWithoutParse !== '' || responseBodyValueWithoutParseError !== '')
                            }
                          />
                        </span>
                      </CustomTooltip>
                    </span>
                  </a>

                  <span className="rounded-l cursor-pointer ">
                    <CustomTooltip
                      title={this.state.isCopyToClipBoard ? 'copied!' : 'Copy to clipboard'}
                      placement="bottom"
                    >
                      {this.state.isCopyToClipBoard ? (
                        <CheckCircleOutlineIcon
                          className="check-circle-outline-copyIcon"
                          onMouseLeave={this.handleMouseLeave}
                        />
                      ) : (
                        <span className={`flex rounded-full `}>
                          <Icon name="copy" hoverEffect={true} onClick={this.onCheckNavigatorClipboard} />
                        </span>
                      )}
                    </CustomTooltip>
                  </span>
                </span>
              </div>
            </div>
          </div>

          {this.state.loadStringValue === true &&
          (responseBodyValueWithoutParse !== '' || responseBodyValueWithoutParseError !== '') ? (
            <div className="mt-0" id="journal-scroll">
              <AceEditor
                className="flex relative min-h-160 response-body-code-editor-format"
                id="journal-scroll"
                editorProps={{
                  $blockScrolling: false,
                }}
                wrapEnabled={true}
                value={responseBodyValue}
                isReadOnly={true}
                requestSectionCollapse={requestSectionCollapse}
              />
            </div>
          ) : (
            ''
          )}

          {this.state.jsonValueShow === true &&
          (responseBodyValueWithoutParse !== '' || responseBodyValueWithoutParseError !== '') ? (
            <div className="flex relative mt-0 pb-2" id="journal-scroll">
              <AceEditor
                className="flex relative min-h-160 response-body-code-editor-format"
                id="journal-scroll"
                mode={this.state.setresponseBodyValueWithParseError ? 'text' : this.state.mode}
                value={
                  responseBodyValueWithoutParse !== ''
                    ? JSON.stringify(this.state.setresponseBodyValueWithParse, null, 2)
                    : this.state.setresponseBodyValueWithParseError
                }
                isReadOnly={true}
                requestSectionCollapse={requestSectionCollapse}
              />
            </div>
          ) : (
            ''
          )}

          {this.state.textValueShow === true &&
          (responseBodyValueWithoutParse !== '' || responseBodyValueWithoutParseError !== '') ? (
            <div className="mt-0" id="journal-scroll">
              <AceEditor
                className="flex relative min-h-160 response-body-code-editor-format"
                id="journal-scroll"
                editorProps={{
                  $blockScrolling: false,
                }}
                wrapEnabled={true}
                value={responseBodyValue}
                isReadOnly={true}
                requestSectionCollapse={requestSectionCollapse}
              />
            </div>
          ) : (
            ''
          )}

          {this.state.htmlValueShow === true &&
          (responseBodyValueWithoutParse !== '' || responseBodyValueWithoutParseError !== '') ? (
            <div className="mt-0" id="journal-scroll">
              <AceEditor
                className="flex relative min-h-160 response-body-code-editor-format"
                id="journal-scroll"
                editorProps={{
                  $blockScrolling: false,
                }}
                wrapEnabled={true}
                value={
                  responseBodyValueWithoutParse !== ''
                    ? jsBeautify.html(responseBodyValueWithoutParse)
                    : responseBodyValueWithoutParseError
                }
                isReadOnly={true}
                requestSectionCollapse={requestSectionCollapse}
              />
            </div>
          ) : (
            ''
          )}

          {this.state.xmlValueShow === true &&
          (responseBodyValueWithoutParse !== '' || responseBodyValueWithoutParseError !== '') ? (
            <div className="mt-0" id="journal-scroll">
              <AceEditor
                className="flex relative min-h-160 response-body-code-editor-format"
                id="journal-scroll"
                editorProps={{
                  $blockScrolling: false,
                }}
                mode={this.state.mode}
                value={
                  responseBodyValueWithoutParse !== ''
                    ? xmlBeautifier(responseBodyValueWithoutParse)
                    : responseBodyValueWithoutParseError
                }
                isReadOnly={true}
                requestSectionCollapse={requestSectionCollapse}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </Fragment>
    );
  }
}
export default withStyles(styles)(ResponseBody);
