import React from 'react';
import Details from './detailsChildPages/details';
import { useHistory } from 'react-router-dom';
import { NavigateBefore } from '@material-ui/icons';
import SuiteOfSuiteDashboard from './detailsChildPages/suite-of-suite-dashboard';
import ScheduledInstance from './detailsChildPages/scheduled-instance';

var logoArray = [
  {
    name: 'Details',
    selectedLogo: '/assets/images/project_rec_logo_blue.svg',
    pathUrl: '/projectmenu/project',
    selectedName: 'details',
  },
  {
    name: 'Execution Dashboard',
    selectedLogo: '/assets/images/user_logo_blue.svg',
    pathUrl: '/projectmenu/user',
    selectedName: 'dashboard',
  },
  {
    name: 'Scheduled Instances',
    selectedLogo: '/assets/images/role_logo_blue.svg',
    pathUrl: '/projectmenu/role',
    selectedName: 'scheduled-instance',
  },
];

let selectedIndex = '';
const DetailsLandingPage = () => {
  const fullUrl = window.location.pathname;
  const tab = window.location.pathname.split('/')[4];
  console.log(`tab`, tab);
  const history = useHistory();
  console.log(`tab`, tab, fullUrl);

  function NavigateFun(selectedNavItem) {
    console.log(`selectrdNavItem`, selectedNavItem);
    history.push(`/execution/suiteofsuite/suite1/${selectedNavItem}`);
  }

  return (
    <div>
      {/* <ul className="navBar text-blue-600 flex flex-row primary-tab">
                {logoArray.map((log, index) => {
                    return (
                        <li>
                            <a href="" onClick={(event) => { event.preventDefault(); NavigateFun(log.selectedName) }} className={`${selectedIndex === log.selectedName ? "selected_tab_style" : ""}`}>
                                {log.name}
                            </a >
                        </li>
                    )
                })}
            </ul>
            <div className="page-table-body">
                {tab === "details" ?
                    <Details />
                    : tab === "dashboard" ?
                        <SuiteOfSuiteDashboard />
                        : tab === 'scheduled-instance' ?
                            <ScheduledInstance />
                            : null
                }
            </div> */}
    </div>
  );
};

export default DetailsLandingPage;
