import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

function MoreButtonMenu(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div>
      <Menu as="div" className="relative text-left">
        {({ open }) => (
          <>
            <div className="ml-5 mr-1">
              <Menu.Button>
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-5 w-5 black" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10"
              >
                <div className="py-1">
                  {props.contextMenuData.map((data) => (
                    <Menu.Item key={data.option}>
                      {({ active }) => (
                        <label
                          className={classNames(
                            active ? 'bg-gray-100 text-blue-600' : 'text-gray-700',
                            'block px-4 py-2 text-sm fontPoppinsRegularMd'
                          )}
                        >
                          <label>{data.option}</label>
                        </label>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default MoreButtonMenu;
