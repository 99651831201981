import React, { useEffect, useRef, useState } from 'react';
import Janus from 'janus-gateway';
import { Icon, Loader, MobileSkin } from 'fireflink-ui';
import { getUpdatedExecutionData } from '@api/api_services';
import { colors } from '@src/css_config/colorConstants';
import adapter from 'webrtc-adapter';
import Draggable from 'react-draggable';
import './mobile_modal.scss';

const MobileLiveVideoModal = (props) => {
  const videoRef = useRef(null);
  const [janusUrl, setJanusUrl] = useState('');
  const [streamID, setStreamID] = useState();
  const [errorMsg, setErrorMsg] = useState('');
  const timerId = useRef();
  let janusInstance = useRef(null);
  let streamingPlugin = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(true);
        props.closeDeviceModal(false);
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const getStreamId = async (isOneTimeCall) => {
    try {
      const res = await getUpdatedExecutionData(props.liveExecutionPayload);
      const { responseObject, status, responseCode } = res?.data || {};

      const janusURL = responseObject?.JanusSever;
      //  Todo have to take Stream ID from backend as integer
      const streamID = responseObject?.streamID;

      setJanusUrl(janusURL);
      setStreamID(streamID);

      if (responseCode === 404) {
        setErrorMsg('Something went wrong');
      } else if (['Execution Completed', 'Execution Failed'].includes(status)) {
        setErrorMsg(status);
        setTimeout(() => {
          props.closeDeviceModal();
        }, 3000);
      } else if (status === 'Script execution not yet started') {
        setErrorMsg(status);
      }

      if (
        responseCode === 404 ||
        ['Execution Completed', 'Execution Failed', 'Execution not yet started'].includes(status)
      ) {
        if (!isOneTimeCall) {
          clearInterval(timerId.current);
        }
      }
    } catch (err) {
      console.error('Error fetching stream ID:', err);
      setErrorMsg('Something went wrong while fetching execution data.');
      if (!isOneTimeCall) {
        clearInterval(timerId.current);
      }
    } finally {
    }
  };

  // Initialize Janus and start streaming

  async function createJanusSession(janusUrl, streamID) {
    let janus = null;
    let streamingPlugin = null;
    // Initialize Janus
    Janus.init({
      debug: 'all',
      // eslint-disable-next-line react-hooks/rules-of-hooks
      dependencies: Janus.useDefaultDependencies({ adapter }),
      callback: () => {
        janus = new Janus({
          server: janusUrl,
          success: () => {
            janus.attach({
              plugin: 'janus.plugin.streaming',
              success: (pluginHandle) => {
                streamingPlugin = pluginHandle;
                // Request to watch the stream
                streamingPlugin.send({
                  message: { request: 'watch', id: streamID },
                });
              },
              onmessage: (msg, jsep) => {
                if (jsep) {
                  streamingPlugin.createAnswer({
                    jsep,
                    media: { audioSend: false, videoSend: false },
                    success: (jsep) => {
                      streamingPlugin.send({
                        message: { request: 'start' },
                        jsep,
                      });
                    },
                    error: (error) => {
                      console.error('Error creating answer:', error);
                    },
                  });
                }
              },
              onremotetrack: (track, mid, on) => {
                if (!on || !videoRef.current) return;

                if (track.kind === 'video') {
                  const stream = videoRef?.current?.srcObject || new MediaStream();
                  stream.addTrack(track);
                  videoRef.current.srcObject = stream;
                }
              },
              error: (error) => {
                console.error('Error attaching plugin:', error);
              },
            });
          },
          error: (error) => {
            console.error('Janus error:', error);
          },
          destroyed: () => {
            console.info('Janus destroyed');
          },
        });
      },
    });
    return () => {
      if (janus) janus.destroy();
    };
  }
  useEffect(() => {
    const fetchAndSetupWebSocket = async () => {
      const isOneTimeCall = true;
      await getStreamId(isOneTimeCall);
      if (janusUrl && streamID) {
        createJanusSession(janusUrl, streamID);
      }
    };

    fetchAndSetupWebSocket();
    timerId.current = setInterval(() => getStreamId(false), 2000);

    return () => {
      clearInterval(timerId.current);
      if (janusInstance.current) {
        janusInstance.current.destroy();
      }
    };
  }, [janusUrl, streamID]);


  const isVideoReady = videoRef.current !== null;

  function showMobileData() {
    return (
      isOpen && (
        <Draggable>
          <div ref={modalRef} className="mobilemodal-container">
            <div className="mobilevideo-container">
              <MobileSkin
                mobileHeight={448}
                mobileWidth={220}
                style={{
                  width: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                }}
                orientation="portrait"
              >
                <>
                  <video ref={videoRef} autoPlay playsInline className="mobilevideo-stream" />
                  {isVideoReady && <p className="mobileerror-message">{errorMsg || loadingVideo()}</p>}
                </>
              </MobileSkin>
              <Icon
                name="dismiss_icon"
                onClick={handleOnCloseChange}
                color={colors.text_black}
                height={24}
                width={24}
                className="mobileclose_icon"
              />
            </div>
          </div>
        </Draggable>
      )
    );
  }

  function handleOnCloseChange() {
    setIsOpen(true);
    if (videoRef.current) {
      videoRef.current.srcObject = null; // Clear the video stream
    }
    props.closeDeviceModal(false); // Close the video modal
  }

  // Helper Components
  const loadingVideo = () => (
    <p className="fontPoppinsRegularMd video-loading">
     Video is getting loaded <Loader variant="BouncingDots" />
    </p>
  );

  return showMobileData();
};

export default MobileLiveVideoModal;
