import { useContext, useEffect, useState } from 'react';
import { getMemoryConfigReq, importProjectV2 } from '@api/api_services';
import { Drawer, Tooltip, Icon } from 'fireflink-ui';
import { BUTTON_CONSTANTS, IMPORT_EXPORT_CONSTANTS } from '@src/common/ui-constants';
import FileUploadButton from '../common/fileUpload_button';
import { FormControl } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
// import DraggableProgressBar from '@src/common/DraggableProgressBar';
import { isEmptyValue } from '@util/common_utils';
import { colors } from '@src/css_config/colorConstants';

const ImportAndExport = ({ isDrawerOpen, setIsDrawerOpen, getAllProjects, MyAlert }) => {
  const [droppedFile, setDroppedFile] = useState([]);
  const [droppedFileName, setDroppedFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [availableMemoryInLicense, setAvailableMemoryInLicense] = useState('');
  // const [showDraggable, setShowDraggable] = useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setDroppedFile([]);
    setFileError('');
  };

  const getAvailableMemory = async () => {
    try {
      const response = await getMemoryConfigReq();
      const { remainingMemory = '' } = response.data.responseObject?.totalConsumption || {};
      setAvailableMemoryInLicense(remainingMemory);
    } catch (err) {
      console.error('Error fetching available memory:', err);
    }
  };

  useEffect(() => {
    getAvailableMemory();
  }, []);

  const handleDraggedFile = async (e) => {
    const zipFile = e.target.files[0];
    setDroppedFile([zipFile]);
    setDroppedFileName(zipFile?.name);
    setFileError('');
    if (!zipFile?.name.toLowerCase().endsWith('.zip')) {
      return setFileError(IMPORT_EXPORT_CONSTANTS.INVALID_FILE_FORMAT);
    }

    if (zipFile) {
      await handleValidationOfExtractedZipFile(zipFile);
    }
  };

  const { getRootProps } = useDropzone({
    accept: '.zip',
    multiple: false,
    onDrop: async (acceptedFiles, rejectedFiles, event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isEmptyValue(rejectedFiles)) {
        setDroppedFileName(rejectedFiles[0].file?.name);
        setFileError(IMPORT_EXPORT_CONSTANTS.INVALID_FILE_FORMAT);
        return;
      }
      if (acceptedFiles) {
        setDroppedFileName(acceptedFiles[0]?.name);
        setDroppedFile([acceptedFiles[0]]);
        await handleValidationOfExtractedZipFile(acceptedFiles[0]);
      }
    },
  });

  const handleMemoryValidation = () => {
    if (availableMemoryInLicense.includes('MB') && parseFloat(availableMemoryInLicense) < 200) {
      return setFileError(IMPORT_EXPORT_CONSTANTS.INSUFFICIENT_STORAGE);
    } else if (availableMemoryInLicense.includes('KB')) {
      return setFileError(IMPORT_EXPORT_CONSTANTS.INSUFFICIENT_STORAGE);
    } else {
      setFileError('');
    }
  };

  const handleValidationOfExtractedZipFile = async (file) => {
    const zip = await JSZip.loadAsync(file);
    const extractedFiles = [];
    let metaDataFolderExists = false;

    zip.forEach((relativePath, zipEntry) => {
      if (!metaDataFolderExists && zipEntry?.dir && relativePath?.toLowerCase().includes('metadata')) {
        metaDataFolderExists = true;
      }
      if (metaDataFolderExists && relativePath.endsWith('.json')) {
        zipEntry.async('text').then((content) => {
          extractedFiles.push({ name: relativePath, content });
          extractedFiles.forEach((file) => {
            const { name, content } = file;
            if (!name && !content) {
              return setFileError(IMPORT_EXPORT_CONSTANTS.IMPROPER_FILE);
            }

            let contentObject;
            try {
              contentObject = JSON.parse(content);
              if (!('licenseId' in contentObject && 'projectId' in contentObject)) {
                return setFileError(IMPORT_EXPORT_CONSTANTS.IMPROPER_FILE);
              } else {
                handleMemoryValidation();
              }
            } catch (error) {
              console.error(`Error parsing content of zip file:`, error);
              return;
            }
          });
        });
      }
    });
    if (!metaDataFolderExists) {
      setFileError(IMPORT_EXPORT_CONSTANTS.IMPROPER_FILE);
      return;
    }
  };

  const handleImportClick = async () => {
    try {
      const payload = new FormData();
      payload.append('file', droppedFile[0]);
      const response = await importProjectV2(payload);
      const { responseCode, message } = response.data;
      if (responseCode !== 200) {
        setFileError(response?.data?.message);
        return;
      }
      setIsDrawerOpen(false);
      await getAllProjects();
      if (responseCode === 200) {
        // setMessageState(response?.data?.message); //TODO - FPI-1614 Phase II :: Alert context message to display on any page

        if (message.includes('successfully')) {
          MyAlert.success(message);
        } else if (message.includes('initiated')) {
          MyAlert.info(message);
        }
      }
    } catch (err) {
      console.error('API FAILURE:', err);
    }
  };

  return (
    <div>
      <Drawer
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        title={IMPORT_EXPORT_CONSTANTS.IMPORT_PROJECT}
        size="medium"
        primaryButtonProps={{
          label: IMPORT_EXPORT_CONSTANTS.IMPORT,
          disabled: !isEmptyValue(fileError) || isEmptyValue(droppedFile),
          onClick: handleImportClick,
        }}
        secondaryButtonProps={{ label: BUTTON_CONSTANTS.CANCEL }}
        overlay
      >
        <div className={`flex flex-col items-center h-72 drag-and-drop-bg fontPoppinsRegularSm`} {...getRootProps()}>
          <div className="icon-wrapper">
            <div className="arrow-icon-container">
              <Icon name="curve_arrow" width={90} height={96} hoverEffect={false} />
            </div>
            <div className="zip-icon-bg">
              <Icon height={29} width={29} hoverEffect={false} name="zip_file_icon" />
            </div>
            <div className="ff-icon-bg">
              <Icon name="fireflink_mini_logo" width={65} height={65} color={colors.rs_primary} hoverEffect={false} />
            </div>
          </div>
          <div className="flex flex-col items-center ">
            {fileError && (
              <div className="flex items-center drag-file-error-text">
                <div className="mr-2">
                  <Icon
                    name="info_icon_with_background"
                    color={colors.rs_red}
                    height={24}
                    width={24}
                    className="addDefectIcon"
                  />
                </div>
                <div className="flex-grow mr-2">
                  <p>{fileError}</p>
                </div>
              </div>
            )}
            {isEmptyValue(droppedFile) && isEmptyValue(fileError) ? (
              <>
                <label className="fontPoppinsMediumSm font-semibold leading-5">
                  {IMPORT_EXPORT_CONSTANTS.DRAG_AND_DROP_FILE}
                </label>
                <label className="fontPoppinsMediumSm font-normal leading-5">
                  {IMPORT_EXPORT_CONSTANTS.ZIP_FILE_SUPPORTED}
                </label>
                <FormControl variant="standard">
                  <FileUploadButton
                    accept=".zip"
                    multiple={false}
                    className="drag-file-btn"
                    dragAndDrop
                    onChange={handleDraggedFile}
                  />
                </FormControl>
              </>
            ) : (
              <div className="flex items-center justify-around max-w-xs mt-2">
                <Tooltip title={droppedFileName} placement="bottom">
                  <div className="max-w-xs truncate dropped-filename">{droppedFileName}</div>
                </Tooltip>
                <div className="h-6 w-6 flex items-center justify-center ml-2">
                  <Tooltip title="Remove file" placement="bottom">
                    <button
                      onClick={() => {
                        setDroppedFile([]);
                        setFileError('');
                      }}
                      className="flex items-center justify-center"
                    >
                      <Icon name="close" hoverEffect={true} />
                    </button>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
      {/* {showDraggable && <DraggableProgressBar projectName={droppedFileName} />} //TODO:: FPI-1614 - Import and export phase II*/}
    </div>
  );
};

export default ImportAndExport;
