import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  getCloudPlatformBrowserVerData,
  getCloudPlatformOSData,
  getCloudPlatformBrowserAndDeviceData,
  getMobileOSVersion,
  integratedEnvironment,
} from '@pages/configuration/system_config/system_config v1.1/utils/cloud-platform-methods';
import { deleteMachineReq, disableMachineReq } from '@api/api_services';
import DeleteMachineModal from '../modals/delete_machine_modal';
import DisableModal from '../modals/disable-modal';
import NavigateToCreateInstanceModal from '../modals/navigate-to-instance-modal';
import { CommonLoader } from '@pages/common/common-loader';
import { getBrowserLabel, getOsLabel } from '../utils/sys-config-common-methods';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { ToggleHeadless } from '@pagescommon/execution/client-machine.js';
import CommonButton from '@src/common/button/Button';
import SelectBox from '@src/common/atoms/SelectComponent';
import IconWithLabelledInput from '@src/common/atoms/icon-with-labelled-input/IconWithLabelledInput';
import { ALERT_MODAL_CONSTANTS, FIRECLOUD_DATA } from '@src/common/ui-constants';
import AlertPopUps from '@src/pages/common/alert_pop_ups/AlertPopUps';
import { getDeviceData } from '@src/util/common_utils';

const ConfigMachineDetails = (props) => {
  const { WARNING, ALERT } = ALERT_MODAL_CONSTANTS;
  const projectType = props.project.type?.toLowerCase();
  const [showBrowser] = useState(projectType === 'mobile' ? false : true);
  const [showDevice] = useState(projectType === 'web' ? false : true);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(props.selectedSystem);
  const [browserListsApiData, setBrowserListsApiData] = useState();
  const [executionEnvData, setExecutionEnvData] = useState();
  const [osData, setOsData] = useState();
  const [osVersionData, setOsVersionData] = useState();
  const [browserData, setBrowserData] = useState();
  const [browserVersionData, setBrowserVersionData] = useState();
  const [deviceData, setDeviceData] = useState();
  const [androidOsVersionData, setAndroidOsVersionData] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [navigateToInstanceModal, setNavigateToInstanceModal] = useState({ openModal: false, data: null });
  const [isdefaultMachineEdited, setIsDefaultMachineEdited] = useState(false);
  const [appData, setAppData] = useState();
  const [iosDeviceData, setIosDeviceData] = useState();
  const [iosOsVersionData, setIosOsVersionData] = useState();
  const [iosAppData, setIosAppData] = useState();
  const [headlessSwitch, setHeadlessSwitch] = useState(false);
  const [changeValues, setChangeValues] = useState(false);

  const isEditable = React.useMemo(() => window.permission?.isEditAccess('configuration'), []);

  const LoggedInUserName = JSON.parse(localStorage.getItem('test-optimize-user')).name;

  const handleExecutionEnvChange = async (values) => {
    if (values[0]?.value !== executionEnvData.selectedOption[0]?.value) {
      try {
        let osNameOptions = [];
        let selectedOsName = [];
        let osVersionOptions = [];
        let selectedOsVersion = [];
        let browserNameOptions = [];
        let selectedBrowserName = [];
        let browserVersionOptions = [];
        let selectedBrowserVersion = [];
        let devicesOptions = [];
        let selectedDevices = [];
        let androidOsVersionOptions = [];
        let selectedAndroidOsVersion = [];
        let selectedAppData = [];
        let appDataOptions = [];
        let iosDevicesOptions = [];
        let selectedIosDevices = [];
        let iosOsVersionOptions = [];
        let selectedIosOsVersion = [];
        let iosAppDataOptions = [];
        let selectedIosAppData = [];
        const browserInstanceArray = props.instanceList.browserInstanceArray;
        const sauceLabInstanceArray = props.instanceList.sauceLabInstanceArray;
        const lambdaTestsInstanceArray = props.instanceList.lambdaTestsInstanceArray;
        const selectedEnvOption = values[0].value.toLowerCase();
        if (selectedEnvOption.includes('local')) {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }
          selectedOsName = props?.selectedSystem.machine.osName || '--';
          selectedOsVersion = props?.selectedSystem.machine.osVersion || '--';
          props?.selectedSystem?.systemConfigDetails?.forEach((_ele) => {
            if (_ele.subType === 'browser' && _ele.name) {
              browserNameOptions.push({
                ..._ele,
                label: getBrowserLabel(_ele.name),
                name: _ele.name,
                value: _ele.name,
              });
            } else if (_ele.subType === 'device') {
              const devicePayloadObj = {
                ..._ele,
                label: _ele.name,
                value: _ele.name,
              };
              if (_ele?.platform === 'android') {
                devicesOptions.push({
                  ...devicePayloadObj,
                });
              } else {
                iosDevicesOptions.push({
                  ...devicePayloadObj,
                });
              }
            }
          });
          if (
            props?.selectedSystem?.clientId === props?.defaultSystem?.clientId &&
            props?.defaultSystem.executionEnvironment.toLowerCase() === 'local'
          ) {
            props?.defaultSystem?.systemConfigDetails?.forEach((_defEle) => {
              if (_defEle.subType === 'browser') {
                const selcBrow = browserNameOptions.find((val) => val.name === _defEle.name);
                selectedBrowserName = selcBrow ? [selcBrow] : [];
                selectedBrowserVersion = selectedBrowserName[0]?.version || [];
              }
              if (selectedBrowserName.length === 0) {
                selectedBrowserName = browserNameOptions[0] ? [{ ...browserNameOptions[0] }] : [];
                selectedBrowserVersion = selectedBrowserName[0]?.version || '';
              }
              if (_defEle.subType === 'device') {
                if (_defEle?.platform === 'android') {
                  const selectAndroidDevice = devicesOptions.find((val) => val.name === _defEle.name);
                  selectedDevices = selectAndroidDevice ? [selectAndroidDevice] : [];
                  selectedAndroidOsVersion = selectedDevices[0]?.version;
                } else {
                  const selectedIosDevice = iosDevicesOptions.find((val) => val.name === _defEle.name);
                  selectedIosDevices = selectedIosDevice ? [selectedIosDevice] : [];
                  selectedIosOsVersion = selectedIosDevices[0].version;
                }
              }
            });
            setData(props.defaultSystem);
            setHeadlessSwitch(props?.defaultSystem?.headless);
          } else {
            selectedBrowserName = [{ ...browserNameOptions[0] }];
            selectedBrowserVersion = selectedBrowserName[0].version;
            let updatedData = JSON.parse(JSON.stringify(props.selectedSystem));
            updatedData.executionEnvironment = values[0].value;
            updatedData.machine.osName = Array.isArray(selectedOsName) ? selectedOsName[0].value : selectedOsName;
            updatedData.machine.osVersion = Array.isArray(selectedOsVersion)
              ? selectedOsVersion[0].value
              : selectedOsVersion;
            updatedData.systemConfigDetails = [];
            const browserTemp = {
              version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0].value : selectedBrowserVersion,
              name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
              type: 'local',
              subType: 'browser',
              platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
            };
            const deviceTemp = {
              version: Array.isArray(selectedOsVersion) ? selectedOsVersion[0].value : selectedOsVersion,
              name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
              type: 'local',
              subType: 'device',
              fullName: selectedDevices?.[0]?.fullName,
              appId: selectedDevices?.[0]?.appId,
              appName: selectedDevices?.[0]?.appName,
              platform: selectedDevices?.[0]?.platform
                ? selectedDevices[0].platform
                : Array.isArray(selectedOsName)
                ? selectedOsName[0].value
                : selectedOsName,
            };
            if (browserTemp.name) {
              updatedData.systemConfigDetails.push(browserTemp);
            }
            if (deviceTemp.name) {
              updatedData.systemConfigDetails.push(deviceTemp);
            }
            setData(updatedData);
            setHeadlessSwitch(props?.selectedSystem?.headless);
          }
        } else if (
          selectedEnvOption.includes('lambdatest-') ||
          selectedEnvOption.includes('browserstack-') ||
          selectedEnvOption.includes('saucelabs-') ||
          selectedEnvOption?.includes(FIRECLOUD_DATA.FIRE_CLOUD)
        ) {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }
          setIsLoading(true);
          setHeadlessSwitch(false);
          let retObj;
          let instanceName = selectedEnvOption.slice(selectedEnvOption.indexOf('-') + 1);
          if (
            props.instanceList.browserInstanceArray ||
            props.instanceList.sauceLabInstanceArray ||
            props.instanceList.lambdaTestsInstanceArray ||
            selectedEnvOption?.includes(FIRECLOUD_DATA.FIRE_CLOUD)
          ) {
            let selectedPlatformTemp = selectedEnvOption.slice(0, selectedEnvOption.indexOf('-'));
            let selectedInstance;
            if (selectedPlatformTemp === 'browserstack') {
              selectedInstance = browserInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            } else if (selectedPlatformTemp === 'lambdatest') {
              selectedInstance = lambdaTestsInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            } else if (selectedPlatformTemp === 'saucelabs') {
              selectedInstance = sauceLabInstanceArray.find(
                (instance) => instance.instanceName.toLowerCase() === instanceName
              );
            } else if (selectedEnvOption?.includes(FIRECLOUD_DATA.FIRE_CLOUD)) {
              selectedPlatformTemp = selectedEnvOption;
            }
            retObj = await getCloudPlatformOSData(
              selectedInstance,
              props.selectedSystem,
              props.defaultSystem,
              true,
              selectedPlatformTemp
            );
          } else if (selectedEnvOption?.includes(FIRECLOUD_DATA.FIRECLOUD)) {
            let selectedInstance = {};
            retObj = await getCloudPlatformOSData(
              selectedInstance,
              props.selectedSystem,
              props.defaultSystem,
              false,
              selectedEnvOption
            );
          } else {
            return;
          }
          osNameOptions = retObj?.osObj?.options;
          selectedOsName = [retObj?.osObj.selectedOption];
          osVersionOptions = retObj?.osVerObj?.options;
          selectedOsVersion = retObj?.osVerObj?.selectedOption ? [retObj?.osVerObj?.selectedOption] : [];
          browserNameOptions = retObj?.browserObj?.options;
          selectedBrowserName = retObj?.browserObj?.selectedOption ? [retObj?.browserObj?.selectedOption] : [];
          browserVersionOptions = retObj?.browserVerObj?.options;
          selectedBrowserVersion = retObj?.browserVerObj?.selectedOption ? [retObj?.browserVerObj?.selectedOption] : [];
          devicesOptions = retObj?.deviceObj?.options;
          selectedDevices = retObj?.deviceObj?.selectedOption ? [retObj?.deviceObj?.selectedOption] : [];
          androidOsVersionOptions = retObj?.androidOsVersionObj?.options;
          selectedAndroidOsVersion = retObj?.androidOsVersionObj?.selectedOption
            ? [retObj?.androidOsVersionObj?.selectedOption]
            : [];
          appDataOptions = retObj?.appDataObj?.options;
          selectedAppData = retObj?.appDataObj?.selectedOption;
          iosDevicesOptions = retObj?.deviceObjIOS?.options;
          selectedIosDevices = retObj?.deviceObjIOS?.selectedOption ? [retObj?.deviceObjIOS?.selectedOption] : [];
          iosOsVersionOptions = retObj?.iosOsVersionObj?.options;
          selectedIosOsVersion = retObj?.iosOsVersionObj?.selectedOption
            ? [retObj?.iosOsVersionObj?.selectedOption]
            : [];
          iosAppDataOptions = retObj?.appDataObjIos?.options;
          selectedIosAppData = retObj?.appDataObjIos?.selectedOption;
          setBrowserListsApiData(retObj?.apiData);
          let updatedData = JSON.parse(JSON.stringify(data));
          let tempOsName, tempOsVersion;
          if (Array.isArray(selectedOsName)) {
            [tempOsName, tempOsVersion] = selectedOsName[0].value.split(/-(.*)/s) || [];
          } else {
            tempOsName = selectedOsName;
            tempOsVersion = selectedOsVersion;
          }
          updatedData.executionEnvironment = values[0].value;
          updatedData.machine.osName = tempOsName;
          updatedData.machine.osVersion = tempOsVersion;
          updatedData.systemConfigDetails = [];
          const browserTemp = {
            version: Array.isArray(selectedBrowserVersion)
              ? selectedBrowserVersion?.[0]?.value
              : selectedBrowserVersion,
            name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
            type: 'local',
            subType: 'browser',
            platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0].value : selectedBrowserName,
          };
          const deviceTemp = {
            version: Array.isArray(selectedAndroidOsVersion)
              ? selectedAndroidOsVersion[0]?.value
              : selectedAndroidOsVersion,
            name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
            type: 'local',
            subType: 'device',
            fullName: selectedDevices?.[0]?.fullName,
            appId: selectedDevices?.[0]?.appId,
            appName: selectedDevices?.[0]?.appName,
            platform: 'android',
          };
          const iosDeviceTemp = {
            version: Array.isArray(selectedIosOsVersion) ? selectedIosOsVersion[0]?.value : selectedIosOsVersion,
            name: Array.isArray(selectedIosDevices) ? selectedIosDevices[0]?.value : selectedIosDevices,
            type: 'local',
            subType: 'device',
            fullName: selectedIosDevices?.[0]?.fullName,
            appId: selectedIosDevices?.[0]?.appId,
            appName: selectedIosDevices?.[0]?.appName,
            platform: 'ios',
          };
          if (browserTemp.name) {
            updatedData.systemConfigDetails.push(browserTemp);
          }
          if (deviceTemp.name) {
            updatedData.systemConfigDetails.push(deviceTemp);
          }
          if (iosDeviceTemp.name) {
            updatedData.systemConfigDetails.push(iosDeviceTemp);
          }
          setData(updatedData);
          setIsLoading(false);
        } else {
          setIsLoading(true);
          if (
            selectedEnvOption === 'browserstack' ||
            selectedEnvOption === 'saucelabs' ||
            selectedEnvOption === 'lambdatest'
          ) {
            setNavigateToInstanceModal({ openModal: true, data: values[0].value });
          } else {
            setIsLoading(false);
          }
          return;
        }

        if (!Array.isArray(selectedOsName)) {
          selectedOsName = selectedOsName + '-' + selectedOsVersion;
        }
        setExecutionEnvData((val) => {
          return { ...val, selectedOption: values };
        });
        setOsData({ options: osNameOptions, selectedOption: selectedOsName });
        setOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion });
        setBrowserData({ options: browserNameOptions, selectedOption: selectedBrowserName });
        setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
        setDeviceData({ options: devicesOptions, selectedOption: selectedDevices });
        setAndroidOsVersionData({ options: androidOsVersionOptions, selectedOption: selectedAndroidOsVersion });
        setAppData({ options: appDataOptions, selectedOption: selectedAppData });
        setIosDeviceData({ options: iosDevicesOptions, selectedOption: selectedIosDevices });
        setIosOsVersionData({ options: iosOsVersionOptions, selectedOption: selectedIosOsVersion });
        setIosAppData({ options: iosAppDataOptions, selectedOption: selectedIosAppData });
      } catch (err) {
        console.error('HANDLE_EXE_ENV : ', err);
      }
    }
  };

  const handleHeadlessSwitch = (e) => {
    let updatedData = JSON.parse(JSON.stringify(data));
    if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
      setIsDefaultMachineEdited(true);
    }
    setHeadlessSwitch(e.target.checked);
    if (updatedData) {
      updatedData['headless'] = e.target.checked;
    }
    setData(updatedData);
  };

  const handleOsChange = async (values) => {
    if (values[0]?.value !== osData?.selectedOption[0]?.value) {
      if (changeValues !== true) {
        try {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }
          let retObj;
          if (integratedEnvironment(executionEnvData?.selectedOption[0]?.value)) {
            retObj = await getCloudPlatformBrowserAndDeviceData(
              browserListsApiData,
              values[0],
              props.selectedSystem,
              props.defaultSystem,
              true
            );
          }
          const browserNameOptions = retObj?.browserObj?.options;
          const selectedBrowserName = retObj?.browserObj?.selectedOption ? [retObj?.browserObj?.selectedOption] : [];
          const browserVersionOptions = retObj?.browserVerObj?.options;
          const selectedBrowserVersion = retObj?.browserVerObj?.selectedOption
            ? [retObj?.browserVerObj?.selectedOption]
            : [];
          const devicesOptions = retObj?.deviceObj?.options;
          const selectedDevices = retObj?.deviceObj?.selectedOption ? [retObj?.deviceObj?.selectedOption] : [];

          let updatedData = JSON.parse(JSON.stringify(data));

          const [tempOsName, tempOsVersion] = values[0].value.split(/-(.*)/s) || [];
          updatedData.machine.osName = tempOsName;
          updatedData.machine.osVersion = tempOsVersion;
          updatedData.systemConfigDetails = [];
          const browserTemp = {
            version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0]?.value : selectedBrowserVersion,
            name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
            type: 'local',
            subType: 'browser',
            platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
          };
          if (browserTemp.name) {
            updatedData.systemConfigDetails.push(browserTemp);
          }
          setOsData((val) => {
            return { ...val, selectedOption: values };
          });
          setBrowserData({ options: browserNameOptions, selectedOption: selectedBrowserName });
          setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
          setData(updatedData);
        } catch (err) {
          console.error('HANDLE_OS : ', err);
        }
      }
      setChangeValues(false);
    }
  };

  const handleBrowserChange = (values) => {
    if (values[0]?.value !== browserData?.selectedOption[0]?.value) {
      if (changeValues !== true) {
        try {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId || values?.length === 0) {
            setIsDefaultMachineEdited(true);
          }
          let retObj;
          let browserVersionOptions = [];
          let selectedBrowserVersion;
          if (integratedEnvironment(executionEnvData?.selectedOption[0]?.value)) {
            retObj = getCloudPlatformBrowserVerData(
              browserListsApiData,
              osData.selectedOption[0],
              values[0],
              props.selectedSystem,
              props.defaultSystem,
              true
            );
            browserVersionOptions = retObj?.options;
            selectedBrowserVersion = retObj?.selectedOption ? [retObj?.selectedOption] : [];
          } else if (executionEnvData.selectedOption[0].value.toLowerCase().includes('local')) {
            selectedBrowserVersion = values[0]?.version;
          }

          let updatedData = JSON.parse(JSON.stringify(data));
          updatedData.systemConfigDetails.forEach((_ele) => {
            if (_ele.subType === 'browser') {
              _ele.version = Array.isArray(selectedBrowserVersion)
                ? selectedBrowserVersion[0]?.value
                : selectedBrowserVersion;
              _ele.name = values[0]?.value;
              _ele.platform = values[0]?.value;
            }
          });
          if (values[0]?.value?.toLowerCase().includes('internet')) {
            setHeadlessSwitch(false);
            updatedData['headless'] = false;
          }
          setData(updatedData);
          setBrowserData((val) => {
            return { ...val, selectedOption: values };
          });
          setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
        } catch (err) {
          console.error('HANDLE_BROWSER : ', err);
        }
      }
      setChangeValues(false);
    }
  };

  const handleBrowserVersionChange = (values) => {
    if (values[0]?.value !== browserVersionData?.selectedOption[0]?.value) {
      if (changeValues !== true) {
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
          setIsDefaultMachineEdited(true);
        }
        let updatedData = JSON.parse(JSON.stringify(data));
        updatedData.systemConfigDetails.forEach((_ele) => {
          if (_ele.subType === 'browser') {
            _ele.version = values[0]?.value;
          }
        });
        setData(updatedData);
        setBrowserVersionData((val) => {
          return { ...val, selectedOption: values };
        });
      }
      setChangeValues(false);
    }
  };

  const handleDelete = () => {
    if (props.selectedSystem.clientId === props.defaultSystem.clientId) {
      props.MyAlert.info(
        ` You are not allowed to delete ${props?.defaultSystem?.machine?.hostName} as it is being used as the default machine`
      );
    } else {
      setOpenDeleteModal(true);
    }
  };
  const deleteApiReq = async () => {
    try {
      let response = await deleteMachineReq(props?.selectedSystem?.id);
      if (response?.data?.responseObject) {
        props.MyAlert.success(`${props?.selectedSystem?.machine.hostName} deleted successfully`);
        props.handleReload(true);
      } else if (response?.data?.responseCode === 400) {
        setOpenDeleteModal(false);
        props.MyAlert.info(
          `You are not allowed to delete ${props?.selectedSystem?.machine.hostName} as it is being used as the default machine`
        );
      }
    } catch (err) {
      console.error('DELETE_MACHINE_API : ', err);
    }
  };

  const handleDisable = () => {
    if (props.defaultSystem?.id === props.selectedSystem?.id) {
      props.MyAlert.info(
        ` You are not allowed to disable the ${props.selectedSystem?.machine?.hostName} as it is being used as the default machine`
      );
    } else {
      setOpenDisableModal(true);
    }
  };

  const disableOrEnableMachine = async () => {
    let payload = {
      disabled: true,
    };
    if (
      props.user.privilege === 'Super Admin' ||
      props.user.privilege === 'Admin' ||
      props.selectedSystem.createdBy === props.user.id
    ) {
      if (props.selectedSystem?.disabled !== true) {
        try {
          let response = await disableMachineReq(props.selectedSystem.id, payload);
          if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
            props.handleReload(true);
            props.MyAlert.success(`${props.selectedSystem.machine.hostName} disabled successfully`);
          } else if (response.data && response.data.responseCode === 400) {
            setOpenDisableModal(false);
            props.MyAlert.info(
              `You are not allowed to disable the ${props.selectedSystem.machine.hostName} as it is being used as the default machine`
            );
          }
        } catch (err) {
          console.error('DISABLE_MACHINE : ', err);
        }
      } else if (props.selectedSystem?.disabled === true) {
        payload.disabled = false;
        try {
          let response = await disableMachineReq(props.selectedSystem.id, payload);
          if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
            props.handleReload(true);
            props.MyAlert.success(`${props.selectedSystem.machine.hostName} enabled successfully`);
          } else if (response.data && response.data.responseCode === 400) {
            props.MyAlert.warning(`${response.data.status}`);
          }
        } catch (err) {
          console.error('ENABLE_MACHINE : ', err);
        }
      }
    }
  };

  const handleAccessDetails = () => {
    props.handleAccessDetailsModal(true);
  };

  const closeModal = (val) => {
    if (openDeleteModal) {
      setOpenDeleteModal(false);
    }
    if (openDisableModal) {
      setOpenDisableModal(false);
    }
    if (navigateToInstanceModal.openModal) {
      setNavigateToInstanceModal({ openModal: false, data: null });
      setIsLoading(false);
    }
  };

  const dataConstructor = async () => {
    try {
      setIsLoading(true);
      let executionEnvOptions = [];
      let selectedExecutionEnv = [];
      let osNameOptions = [];
      let selectedOsName = [];
      let osVersionOptions = [];
      let selectedOsVersion = [];
      let browserNameOptions = [];
      let selectedBrowserName = [];
      let browserVersionOptions = [];
      let selectedBrowserVersion = [];
      let devicesOptions = [];
      let selectedDevices = [];
      let androidOsVersionOptions = [];
      let selectedAndroidOsVersion = [];
      let selectedAppData = [];
      let appDataOptions = [];
      let iosDevicesOptions = [];
      let selectedIosDevices = [];
      let iosOsVersionOptions = [];
      let selectedIosOsVersion = [];
      let iosAppDataOptions = [];
      let selectedIosAppData = [];
      const browserInstanceArray = props.instanceList.browserInstanceArray;
      const sauceLabInstanceArray = props.instanceList.sauceLabInstanceArray;
      const lambdaTestsInstanceArray = props.instanceList.lambdaTestsInstanceArray;
      props?.selectedSystem?.externalPlugins?.forEach((env) => {
        if (env.toLowerCase() === 'browserstack') {
          if (browserInstanceArray.length > 0) {
            browserInstanceArray.forEach((instance) => {
              const envName = `${env}-${instance.instanceName}`;
              executionEnvOptions.push({ label: envName, value: envName });
            });
          } else {
            executionEnvOptions.push({ label: env, value: env });
          }
        } else if (env.toLowerCase() === 'saucelabs') {
          if (sauceLabInstanceArray?.length > 0) {
            sauceLabInstanceArray.forEach((instance) => {
              const envName = `${env}-${instance.instanceName}`;
              executionEnvOptions.push({ label: envName, value: envName });
            });
          } else {
            executionEnvOptions.push({ label: env, value: env });
          }
        } else if (env.toLowerCase() === 'lambdatest') {
          if (lambdaTestsInstanceArray?.length > 0) {
            lambdaTestsInstanceArray.forEach((instance) => {
              const envName = `${env}-${instance.instanceName}`;
              executionEnvOptions.push({ label: envName, value: envName });
            });
          } else {
            executionEnvOptions.push({ label: env, value: env });
          }
        } else {
          executionEnvOptions.push({ label: env, value: env });
        }
      });
      if (executionEnvOptions.length) {
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
          selectedExecutionEnv = [
            { label: props.defaultSystem.executionEnvironment, value: props.defaultSystem.executionEnvironment },
          ];
          if (!props.defaultSystem?.headless) {
            setHeadlessSwitch(false);
          } else {
            setHeadlessSwitch(props.defaultSystem?.headless);
          }
        } else {
          selectedExecutionEnv = [executionEnvOptions[0]];
          if (!props.selectedSystem?.headless) {
            setHeadlessSwitch(false);
          } else {
            setHeadlessSwitch(props.selectedSystem?.headless);
          }
        }
      }
      if (selectedExecutionEnv[0]?.value?.toLowerCase() === 'local') {
        selectedOsName = props?.selectedSystem.machine.osName || '--';
        selectedOsVersion = props?.selectedSystem.machine.osVersion || '--';
        props?.selectedSystem?.systemConfigDetails?.forEach((_ele) => {
          if (_ele.subType === 'browser' && _ele.name) {
            browserNameOptions.push({
              ..._ele,
              label: getBrowserLabel(_ele.name),
              name: _ele.name,
              value: _ele.name,
            });
          } else if (_ele.subType === 'device') {
            const devicePayloadObj = {
              ..._ele,
              label: _ele.name,
              value: _ele.name,
            };
            if (_ele?.platform === 'android') {
              devicesOptions.push({
                ...devicePayloadObj,
              });
            } else {
              iosDevicesOptions.push({
                ...devicePayloadObj,
              });
            }
          }
        });
        if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
          props?.defaultSystem?.systemConfigDetails?.forEach((_defEle) => {
            if (_defEle.subType === 'browser') {
              const selcBrow = browserNameOptions.find((val) => val.name === _defEle.name);
              selectedBrowserName = selcBrow ? [selcBrow] : [];
              selectedBrowserVersion = selectedBrowserName[0]?.version || [];
            }
            if (_defEle.subType === 'device') {
              if (_defEle?.platform === 'android') {
                const selectAndroidDevice = devicesOptions.find((val) => val.name === _defEle.name);
                selectedDevices = selectAndroidDevice ? [selectAndroidDevice] : [];
                selectedAndroidOsVersion = selectedDevices[0]?.version;
              } else {
                const selectedIosDevice = iosDevicesOptions.find((val) => val.name === _defEle.name);
                selectedIosDevices = selectedIosDevice ? [selectedIosDevice] : [];
                selectedIosOsVersion = selectedIosDevices[0].version;
              }
            }
          });
        } else {
        }
        if (selectedBrowserName.length === 0) {
          selectedBrowserName = browserNameOptions[0] ? [{ ...browserNameOptions[0] }] : [];
          selectedBrowserVersion = selectedBrowserName[0]?.version || '';
        }
      } else if (integratedEnvironment(selectedExecutionEnv[0]?.value)) {
        const selectedEnvOpt = selectedExecutionEnv[0]?.value?.toLowerCase();
        let instanceName = selectedEnvOpt.slice(selectedEnvOpt.indexOf('-') + 1);

        let selectedPlatformTemp = selectedEnvOpt.slice(0, selectedEnvOpt.indexOf('-'));
        const isFireCloud = selectedEnvOpt?.includes(FIRECLOUD_DATA.FIRE_CLOUD);
        const hasValidInstanceArray =
          browserInstanceArray || lambdaTestsInstanceArray || sauceLabInstanceArray || isFireCloud;
        if (hasValidInstanceArray) {
          let selectedInstance;
          if (selectedPlatformTemp === 'browserstack') {
            selectedInstance = browserInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          } else if (selectedPlatformTemp === 'lambdatest') {
            selectedInstance = lambdaTestsInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          } else if (selectedPlatformTemp === 'saucelabs') {
            selectedInstance = sauceLabInstanceArray.find(
              (instance) => instance.instanceName.toLowerCase() === instanceName
            );
          } else if (selectedEnvOpt?.includes(FIRECLOUD_DATA.FIRE_CLOUD)) {
            selectedPlatformTemp = FIRECLOUD_DATA.FIRE_CLOUD;
          }
          if (selectedInstance || isFireCloud) {
            let retObj;
            //cloud
            retObj = await getCloudPlatformOSData(
              selectedInstance,
              props.selectedSystem,
              props.defaultSystem,
              false,
              selectedPlatformTemp
            );
            osNameOptions = retObj?.osObj?.options;
            selectedOsName = [retObj?.osObj.selectedOption];
            osVersionOptions = retObj?.osVerObj?.options;
            selectedOsVersion = [retObj?.osVerObj?.selectedOption];
            browserNameOptions = retObj?.browserObj?.options;
            selectedBrowserName = retObj?.browserObj?.selectedOption ? [retObj?.browserObj?.selectedOption] : [];
            browserVersionOptions = retObj?.browserVerObj?.options;
            selectedBrowserVersion = retObj?.browserVerObj?.selectedOption
              ? [retObj?.browserVerObj?.selectedOption]
              : [];
            devicesOptions = retObj?.deviceObj?.options;
            selectedDevices = retObj?.deviceObj?.selectedOption ? [retObj?.deviceObj?.selectedOption] : [];
            androidOsVersionOptions = retObj?.androidOsVersionObj?.options;
            selectedAndroidOsVersion = retObj?.androidOsVersionObj?.selectedOption
              ? [retObj?.androidOsVersionObj?.selectedOption]
              : [];
            appDataOptions = retObj?.appDataObj?.options;
            selectedAppData = retObj?.appDataObj?.selectedOption ? [retObj?.appDataObj?.selectedOption] : [];
            iosDevicesOptions = retObj?.deviceObjIOS?.options;
            selectedIosDevices = retObj?.deviceObjIOS?.selectedOption ? [retObj?.deviceObjIOS?.selectedOption] : [];
            iosOsVersionOptions = retObj?.iosOsVersionObj?.options;
            selectedIosOsVersion = retObj?.iosOsVersionObj?.selectedOption
              ? [retObj?.iosOsVersionObj?.selectedOption]
              : [];
            iosAppDataOptions = retObj?.appDataObjIos?.options;
            selectedIosAppData = retObj?.appDataObjIos?.selectedOption ? [retObj?.appDataObjIos?.selectedOption] : [];
            setBrowserListsApiData(retObj?.apiData);
          }
        }
      } else {
        // code for other plugins except (Local, BS, SL, LT)
      }
      if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
        setData(props.defaultSystem);
      } else {
        let updatedData = JSON.parse(JSON.stringify(props.selectedSystem));
        let tempOsName, tempOsVersion;
        if (Array.isArray(selectedOsName)) {
          [tempOsName, tempOsVersion] = selectedOsName[0].value.split(/-(.*)/s) || [];
        } else {
          tempOsName = selectedOsName;
          tempOsVersion = selectedOsVersion;
        }
        updatedData.executionEnvironment = selectedExecutionEnv[0].value;
        updatedData.machine.osName = tempOsName;
        updatedData.machine.osVersion = tempOsVersion;
        updatedData.systemConfigDetails = [];
        const browserTemp = {
          version: Array.isArray(selectedBrowserVersion) ? selectedBrowserVersion[0].value : selectedBrowserVersion,
          name: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
          type: 'local',
          subType: 'browser',
          platform: Array.isArray(selectedBrowserName) ? selectedBrowserName[0]?.value : selectedBrowserName,
        };
        const deviceTemp = {
          version: Array.isArray(selectedAndroidOsVersion)
            ? selectedAndroidOsVersion[0]?.value
            : selectedAndroidOsVersion,
          name: Array.isArray(selectedDevices) ? selectedDevices[0]?.value : selectedDevices,
          type: 'local',
          subType: 'device',
          fullName: selectedDevices?.[0]?.fullName,
          appId: selectedDevices?.[0]?.appId,
          appName: selectedDevices?.[0]?.appName,
          platform: 'android',
        };

        const iosDeviceTemp = {
          version: Array.isArray(selectedIosOsVersion) ? selectedIosOsVersion[0]?.value : selectedIosOsVersion,
          name: Array.isArray(selectedIosDevices) ? selectedIosDevices[0]?.value : selectedIosDevices,
          type: 'local',
          subType: 'device',
          fullName: selectedIosDevices?.[0]?.fullName,
          appId: selectedIosDevices?.[0]?.appId,
          appName: selectedIosDevices?.[0]?.appName,
          platform: 'ios',
        };
        if (browserTemp.name) {
          updatedData.systemConfigDetails.push(browserTemp);
        }
        if (deviceTemp.name) {
          updatedData.systemConfigDetails.push(deviceTemp);
        }
        if (iosDeviceTemp.name) {
          updatedData.systemConfigDetails.push(iosDeviceTemp);
        }
        setData(updatedData);
      }
      if (!Array.isArray(selectedOsName)) {
        selectedOsName = selectedOsName + '-' + selectedOsVersion;
      }
      setExecutionEnvData({ options: executionEnvOptions, selectedOption: selectedExecutionEnv });
      setOsData({ options: osNameOptions, selectedOption: selectedOsName });
      setOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion });
      setBrowserData({ options: browserNameOptions, selectedOption: selectedBrowserName });
      setBrowserVersionData({ options: browserVersionOptions, selectedOption: selectedBrowserVersion });
      setDeviceData({ options: devicesOptions, selectedOption: selectedDevices });
      setAndroidOsVersionData({ options: androidOsVersionOptions, selectedOption: selectedAndroidOsVersion });
      setAppData({ options: appDataOptions, selectedOption: selectedAppData });
      setIosDeviceData({ options: iosDevicesOptions, selectedOption: selectedIosDevices });
      setIosOsVersionData({ options: iosOsVersionOptions, selectedOption: selectedIosOsVersion });
      setIosAppData({ options: iosAppDataOptions, selectedOption: selectedIosAppData });
      setIsLoading(false);
    } catch (err) {
      console.error('DATA_CONSTRUCTOR : ', err);
    }
  };

  const handleMobileDeviceChange = (values, osType) => {
    if (
      (values[0]?.value !== deviceData?.selectedOption[0]?.value && osType === 'android') ||
      (values[0]?.value !== iosDeviceData?.selectedOption[0]?.value && osType === 'ios')
    ) {
      if (changeValues !== true) {
        try {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }
          let retObj;
          let osVersionOptions = [];
          let selectedOsVersion;
          let selectedExecEnv = executionEnvData?.selectedOption?.[0]?.value?.toLowerCase();
          if (
            selectedExecEnv.includes('lambdatest-') ||
            selectedExecEnv.includes('browserstack-') ||
            selectedExecEnv.includes('saucelabs-') ||
            selectedExecEnv?.includes(FIRECLOUD_DATA.FIRE_CLOUD)
          ) {
            retObj = getMobileOSVersion(
              browserListsApiData,
              values[0],
              props.selectedSystem,
              props.defaultSystem,
              true,
              osType
            );
            osVersionOptions = retObj?.options;
            selectedOsVersion = retObj?.selectedOption ? [retObj?.selectedOption] : [];
          } else if (executionEnvData.selectedOption[0].value.toLowerCase().includes('local')) {
            selectedOsVersion = values[0]?.version;
          }
          let updatedData = JSON.parse(JSON.stringify(data));
          let deviceFound = false;
          updatedData.systemConfigDetails.forEach((_ele) => {
            if (_ele.subType === 'device' && _ele?.platform === osType) {
              const { value: name, version, fullName, appId, appName, serial_no, deviceUniqueId } = values?.[0] || {};
              deviceFound = true;
              _ele.name = values[0]?.value;
              _ele = {
                ..._ele,
                name,
                version,
                fullName,
                appId,
                appName,
                serial_no,
                deviceUniqueId,
              };
            }
          });
          if (!deviceFound) {
            updatedData.systemConfigDetails.push({
              version: Array.isArray(selectedOsVersion) ? selectedOsVersion[0]?.value : selectedOsVersion,
              name: values[0]?.value,
              type: 'local',
              subType: 'device',
              fullName: values?.[0]?.fullName,
              appId:
                osType === 'android' ? appData?.selectedOption?.[0]?.appId : iosAppData?.selectedOption?.[0]?.appId,
              appName:
                osType === 'android' ? appData?.selectedOption?.[0]?.appName : iosAppData?.selectedOption?.[0]?.appName,
              platform: osType,
              serial_no: values?.[0]?.serial_no,
              deviceUniqueId: values?.[0]?.deviceUniqueId,
            });
          }

          if (values.length) {
            setData(updatedData);
          } else {
            updatedData.systemConfigDetails = updatedData.systemConfigDetails.filter(
              (_ele) => !(_ele.subType === 'device' && _ele.platform === osType)
            );
            setData(updatedData);
          }
          osType === 'android'
            ? setDeviceData((val) => {
                return { ...val, selectedOption: values };
              })
            : setIosDeviceData((val) => {
                return { ...val, selectedOption: values };
              });
          osType === 'android'
            ? setAndroidOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion })
            : setIosOsVersionData({ options: osVersionOptions, selectedOption: selectedOsVersion });
        } catch (err) {
          console.error('HANDLE_MOBILE_DEVICE_CHANGE : ', err);
        }
      }
      setChangeValues(false);
    }
  };

  const handleDeviceAppPlgChange = (values, osType = 'android') => {
    if (
      (values[0]?.appName !== (appData?.selectedOption && appData?.selectedOption[0]?.appName) &&
        osType === 'android') ||
      (values[0]?.appName !== (iosAppData?.selectedOption && iosAppData?.selectedOption[0]?.appName) &&
        osType === 'ios')
    ) {
      if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
        setIsDefaultMachineEdited(true);
      }
      let updatedData = JSON.parse(JSON.stringify(data));
      let deviceFound = false;
      updatedData.systemConfigDetails.forEach((_ele) => {
        if (_ele.subType === 'device' && _ele.platform === osType) {
          deviceFound = true;
          _ele['appId'] = values[0]?.appId;
          _ele['appName'] = values[0]?.appName;
        }
      });
      setData(updatedData);
      osType === 'android'
        ? setAppData((val) => {
            return { ...val, selectedOption: values };
          })
        : setIosAppData((val) => {
            return { ...val, selectedOption: values };
          });
    }
  };

  const handleMobileDeviceOSChange = (values, osType) => {
    if (
      (values[0]?.value !== androidOsVersionData?.selectedOption[0]?.value && osType === 'android') ||
      (values[0]?.value !== iosOsVersionData?.selectedOption[0]?.value && osType === 'ios')
    ) {
      if (changeValues !== true) {
        try {
          if (props?.selectedSystem?.clientId === props?.defaultSystem?.clientId) {
            setIsDefaultMachineEdited(true);
          }

          let updatedData = JSON.parse(JSON.stringify(data));

          updatedData.systemConfigDetails.forEach((_ele) => {
            if (_ele.subType === 'device' && _ele.platform === osType) {
              _ele['version'] = values[0]?.value;
            }
          });
          setData(updatedData);
          osType === 'android'
            ? setAndroidOsVersionData((val) => {
                return { ...val, selectedOption: values };
              })
            : setIosOsVersionData((val) => {
                return { ...val, selectedOption: values };
              });
        } catch (err) {
          console.error('HANDLE_MOBILE_DEVICE_OS_CHANGE : ', err);
        }
      }
      setChangeValues(false);
    }
  };
  useEffect(() => {
    props.getUpdatedSelectedMachine(data);
    if (isdefaultMachineEdited) {
      props.handleDataChangedState(true, data);
    } else {
      props.handleDataChangedState(false, null);
    }
  }, [data]);

  useEffect(() => {
    setIsDefaultMachineEdited(false);
    setData(props.selectedSystem);
    dataConstructor();
  }, [props.selectedSystem]);

  return (
    <div className="">
      <div className="grid grid-cols-1">
        <div className="grid grid-cols-2"></div>
        {!isLoading ? (
          <>
            <div
              className={`details-section-height grid grid-cols-1 gap-2 ${
                props?.selectedSystem?.disabled ? 'opacity-50' : ''
              }`}
            >
              <div className="details-data-style-common px-4 py-2">
                <SelectBox
                  labelProps={{
                    label: 'Execution Environment',
                  }}
                  tooltipPosition="top"
                  optionPosition="bottom"
                  disabled={props?.selectedSystem?.disabled}
                  selectedOption={executionEnvData?.selectedOption}
                  options={executionEnvData?.options}
                  handleOptionChange={handleExecutionEnvChange}
                  searchable={true}
                />
              </div>
              <div className="system-config-web-section px-4">
                <span className="fontPoppinsMediumMd">Web</span>
                {executionEnvData?.selectedOption[0]?.value === 'Local' && (
                  <span className="fontPoppinsMediumMd">
                    <ToggleHeadless
                      headlessSwitch={headlessSwitch}
                      handleHeadlessSwitch={handleHeadlessSwitch}
                      executionEnvData={executionEnvData}
                      browserData={browserData}
                      project={props.project}
                      disabled={props?.selectedSystem?.disabled}
                    />
                  </span>
                )}
              </div>
              <div className="flex justify-between items-center px-4 py-2">
                <div className="select-dropdown-width">
                  <div className="">
                    {osData.selectedOption && !Array.isArray(osData.selectedOption) ? (
                      <IconWithLabelledInput
                        toolTipTitle={osData?.selectedOption}
                        placement="top"
                        showToolTip={true}
                        className={`${props.disabled ? 'opacity-50' : ''}`}
                        labelProps={{ label: 'OS & OS Version' }}
                        text={getOsLabel(osData?.selectedOption, 'inline-block ml-2')}
                      />
                    ) : (
                      <SelectBox
                        disabled={props?.selectedSystem?.disabled}
                        options={osData?.options}
                        selectedOption={osData?.selectedOption}
                        backspaceDelete={true}
                        searchable={true}
                        handleOptionChange={handleOsChange}
                        optionPosition="bottom"
                        labelProps={{
                          label: 'OS & OS Version',
                        }}
                      />
                    )}
                  </div>
                </div>
                {showBrowser && (
                  <>
                    <div className="select-dropdown-width">
                      <SelectBox
                        isBrowserDropdown={true}
                        disabled={props?.selectedSystem?.disabled}
                        options={browserData?.options}
                        selectedOption={browserData?.selectedOption}
                        handleOptionChange={handleBrowserChange}
                        optionPosition="bottom"
                        labelProps={{
                          label: 'Browser',
                        }}
                      />
                    </div>
                    <div className="select-dropdown-width">
                      {!Array.isArray(browserVersionData.selectedOption) ? (
                        <IconWithLabelledInput
                          toolTipTitle={browserVersionData?.selectedOption}
                          placement="top"
                          showToolTip={true}
                          className={`table-font-style-common px-1.5 py-0.5 ${props.disabled ? 'opacity-50' : ''}`}
                          labelProps={{ label: 'Browser Version' }}
                          text={browserVersionData.selectedOption}
                        />
                      ) : (
                        <SelectBox
                          disabled={props?.selectedSystem?.disabled}
                          options={browserVersionData?.options}
                          selectedOption={browserVersionData?.selectedOption}
                          handleOptionChange={handleBrowserVersionChange}
                          placeholder="Select Browser Version"
                          optionPosition="bottom"
                          labelProps={{
                            label: 'Browser Version',
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              {showDevice && (
                <>
                  <div className="system-config-web-section px-4">
                    <span className="fontPoppinsMediumMd">Mobile</span>
                  </div>
                  <div className="px-4 py-2">
                    <div
                      className={`flex items-center mb-3 ${
                        executionEnvData?.selectedOption[0]?.value !== 'Local'
                          ? 'justify-between'
                          : 'justify-items-stretch gap-8'
                      }`}
                    >
                      <div className="flex flex-col select-dropdown-width">
                        <div className="">
                          {showDevice && (
                            <>
                              <div className="details-data-style-common">
                                <SelectBox
                                  disabled={props?.selectedSystem?.disabled}
                                  options={deviceData?.options}
                                  selectedOption={getDeviceData(deviceData?.selectedOption)}
                                  optionPosition={deviceData?.options?.length >= 4 ? 'top' : 'bottom'}
                                  handleOptionChange={(values) => {
                                    handleMobileDeviceChange(values, 'android');
                                  }}
                                  labelProps={{
                                    label: 'Android Device',
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col select-dropdown-width">
                        <div>
                          {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) ? (
                            <SelectBox
                              disabled={props?.selectedSystem?.disabled}
                              options={androidOsVersionData?.options}
                              selectedOption={androidOsVersionData?.selectedOption}
                              handleOptionChange={(values) => {
                                handleMobileDeviceOSChange(values, 'android');
                              }}
                              optionPosition={androidOsVersionData?.options?.length <= 4 ? 'bottom' : 'top'}
                              labelProps={{
                                label: 'OS Version',
                              }}
                            />
                          ) : (
                            <IconWithLabelledInput
                              toolTipTitle={androidOsVersionData?.selectedOption}
                              placement="top"
                              showToolTip={true}
                              className={`${props.disabled ? 'opacity-50' : ''}`}
                              labelProps={{ label: 'OS Version' }}
                              text={androidOsVersionData?.selectedOption}
                            />
                          )}
                        </div>
                      </div>
                      {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) && (
                        <div className="flex flex-col select-dropdown-width">
                          <div>
                            <SelectBox
                              toolTipKey="label"
                              disabled={props?.selectedSystem?.disabled}
                              options={appData?.options}
                              selectedOption={appData?.selectedOption}
                              optionPosition={appData?.options?.length >= 2 ? 'top' : 'bottom'}
                              handleOptionChange={(values) => {
                                handleDeviceAppPlgChange(values, 'android');
                              }}
                              labelProps={{
                                label: 'App',
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={`flex items-center mb-3 ${
                        executionEnvData?.selectedOption[0]?.value !== 'Local'
                          ? 'justify-between'
                          : 'justify-items-stretch gap-8'
                      }`}
                    >
                      <div className="flex flex-col select-dropdown-width">
                        <div className="">
                          <div className="details-data-style-common">
                            <SelectBox
                              disabled={props?.selectedSystem?.disabled}
                              options={iosDeviceData?.options}
                              selectedOption={getDeviceData(iosDeviceData?.selectedOption)}
                              handleOptionChange={(values) => {
                                handleMobileDeviceChange(values, 'ios');
                              }}
                              optionPosition={deviceData?.options?.length >= 2 ? 'top' : 'bottom'}
                              labelProps={{
                                label: 'iOS Device',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="select-dropdown-width">
                        {integratedEnvironment(executionEnvData?.selectedOption[0]?.value) ? (
                          <SelectBox
                            disabled={props?.selectedSystem?.disabled}
                            options={iosOsVersionData?.options}
                            selectedOption={iosOsVersionData?.selectedOption}
                            handleOptionChange={(values) => {
                              handleMobileDeviceOSChange(values, 'ios');
                            }}
                            optionPosition={iosOsVersionData?.options?.length >= 2 ? 'top' : 'bottom'}
                            labelProps={{
                              label: 'OS Version',
                            }}
                          />
                        ) : (
                          <IconWithLabelledInput
                            toolTipTitle={iosOsVersionData?.selectedOption}
                            placement="top"
                            showToolTip={true}
                            className={`${props.disabled ? 'opacity-50' : ''}`}
                            labelProps={{ label: 'OS Version' }}
                            text={iosOsVersionData?.selectedOption}
                          />
                        )}
                      </div>
                      {showDevice && executionEnvData?.selectedOption[0]?.value !== 'Local' && (
                        <div className="select-dropdown-width">
                          <SelectBox
                            toolTipKey="label"
                            disabled={props?.selectedSystem?.disabled}
                            options={iosAppData?.options}
                            selectedOption={iosAppData?.selectedOption}
                            handleOptionChange={(values) => {
                              handleDeviceAppPlgChange(values, 'ios');
                            }}
                            optionPosition={iosAppData?.options?.length >= 2 ? 'top' : 'bottom'}
                            labelProps={{
                              label: 'App',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {isEditable && (
                <div className="flex justify-between border-t-2">
                  <div className="flex justify-evenly pl-8 py-4">
                    {executionEnvData?.selectedOption?.[0]?.value === FIRECLOUD_DATA.FIRECLOUD ? (
                      <CommonButton
                        disabled={props.user.privilege === 'User' && props.selectedSystem.createdBy !== props.user.id}
                        onClick={handleAccessDetails}
                        label="Access Details"
                        btnType="secondary"
                      />
                    ) : (
                      <>
                        <div>
                          <CommonButton
                            disabled={
                              props.user.privilege === 'User' && props.selectedSystem.createdBy !== props.user.id
                            }
                            label="Delete"
                            onClick={handleDelete}
                            btnType="destructive"
                          />
                        </div>
                        {props?.selectedSystem?.disabled ? (
                          <CommonButton
                            className="mx-2"
                            disabled={
                              props.user.privilege === 'User' && props.selectedSystem.createdBy !== props.user.id
                            }
                            onClick={disableOrEnableMachine}
                            label="Enable"
                            btnType="secondary"
                          />
                        ) : (
                          <>
                            <CommonButton
                              className="mx-2"
                              disabled={
                                props.user.privilege === 'User' && props.selectedSystem.createdBy !== props.user.id
                              }
                              onClick={handleDisable}
                              label="Disable"
                              btnType="secondary"
                            />
                            <CommonButton
                              disabled={
                                props.user.privilege === 'User' && props.selectedSystem.createdBy !== props.user.id
                              }
                              onClick={handleAccessDetails}
                              label="Access Details"
                              btnType="secondary"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {props.dataChange && (
                    <div className="flex justify-evenly pr-4 py-4">
                      <div className="mr-2">
                        <CommonButton onClick={props.onResetHandler} label="Reset" btnType="secondary" />
                      </div>
                      <div>
                        <CommonButton
                          disabled={props.btnDisabled}
                          onClick={props.onUpdateHandler}
                          label="Update"
                          btnType="primary"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="allign-div-center details-div-data-height">
            <CommonLoader />
          </div>
        )}
      </div>
      {openDeleteModal && (
        <AlertPopUps
          execution={true}
          open={openDeleteModal}
          alertType={WARNING}
          showHowToProceed={true}
          showCancelBtn={true}
          showSaveBtn={true}
          alertTitle="Delete"
          saveBtnText="Delete"
          onCancelBtnClick={closeModal}
          onSaveBtnClick={deleteApiReq}
          content={[
            <div className="mb-2 capitalize">Hi {LoggedInUserName},</div>,
            <div>Are you sure you want to delete {props?.selectedSystem?.machine?.hostName} machine ?</div>,
          ]}
        />
      )}
      {navigateToInstanceModal?.openModal && (
        <NavigateToCreateInstanceModal closeModal={closeModal} data={navigateToInstanceModal.data} />
      )}
      {openDisableModal && (
        <AlertPopUps
          execution={true}
          open={openDisableModal}
          alertType={ALERT}
          showHowToProceed={true}
          showCancelBtn={true}
          showSaveBtn={true}
          saveBtnText="Disable"
          alertTitle="Disable"
          onCancelBtnClick={closeModal}
          onSaveBtnClick={disableOrEnableMachine}
          content={[
            <div className="mb-2 capitalize">Hi {LoggedInUserName},</div>,
            <div>Are you sure you want to disable client machine {props?.selectedSystem?.machine?.hostName} ?</div>,
          ]}
        />
      )}
    </div>
  );
};

export default ConfigMachineDetails;
