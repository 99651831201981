import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { FiMaximize2 } from 'react-icons/fi';
import MoreInfoButton from '@pages/common/more_info_button';
import InvalidMachineModal from '@src/pages/execution/common/invalid-machine-modal';
import TableTree from '@pages/common/table_tree/table_tree';
import { getAllMachineListReq, getBrowserStackList, getSauceLabsList, getAllLambdaTestsList } from '@api/api_services';
import MachineTable from '@src/pages/common/MachineTable/MachineTable';
import MachineDetails from '../run-settings-machines-common/machine-details';
import UserDetailsPage from '@pages/execution/common/user-details-page';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { renderBrowserCell } from '@src/pages/execution/execution';
import { Tooltip } from '@material-ui/core';
import ParallelRunExecutionStatus from '@src/pages/common/parallelRunExecutionStatus/parallel_run_executionStatus';
import { useHistory } from 'react-router-dom';
import { REGEX } from '@src/util/validations';
import { getDeviceLabel, getOsLabel } from '../run-settings-machines-common/common-methods';
import { isEmptyValue } from '@src/util/common_utils';
import {
  ALERT_MODAL_CONSTANTS,
  AZURE_DEVOPS_CONSTANTS,
  DEFECT_CONSTANTS,
  LABELS_CONSTANTS,
  SUITES_CONSTANTS,
} from '@src/common/ui-constants';
import { Button } from 'fireflink-ui';
let tempUpdatedSelectedMachine;
const ManualDistribution = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let [treeData, setTreeData] = useState([]);
  let [rootData, setRootData] = useState([]);
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [isLoading, setIsLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  let [selectedOperation, setSelectedOperation] = useState();
  let [selectedNodeForUser, setSelectedNodeForUser] = useState();
  let [treeValue, setTreeValue] = useState('');
  const [allClientSystems, setAllClientSystems] = useState({});
  const [instanceList, setInstanceList] = useState({});
  const [defaultSystem, setDefaultSystem] = useState({});
  const [selectedSystem, setSelectedSystem] = useState({});
  const [distributionData, setDistributionData] = useState([...props?.manualDistribution]);
  const [tempDistributionData, setTempDistributionData] = useState([]);
  const [updatedSelectedMachine, setUpdatedSelectedMachine] = useState();
  const [selectedJsonData, setSelectedJsonData] = useState([]);
  const [selectedNodeArray, setSelectedNodeArray] = React.useState([]);
  const [selectMachineDisable, setSelectMachineDisable] = useState(true);
  const [noCallGetUpdated, setNoCallGetUpdated] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [disableHeadlessSwitch, setdisableHeadlessSwitch] = useState(true);
  const check3PEnvironment = REGEX.CHECK_3P_ENVIRONMENT;
  const machineDetailsRef = React.useRef();
  const [searchKeys, setSearchKeys] = useState([]);
  const disableHeadlessSwitchRef = useRef();
  disableHeadlessSwitchRef.current = disableHeadlessSwitch;
  const distributionDataRef = useRef();
  distributionDataRef.current = distributionData;
  const selectedJsonDataRef = useRef();
  selectedJsonDataRef.current = selectedJsonData;
  let selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  let renderTree;
  let renderMaxTree;
  let history = useHistory();
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const [disabledClientIds, setDisabledClientIds] = useState([]);

  const isRootSelected = treeData[0]?.totalModuleCount + treeData[0]?.totalScriptCount === selectedNodeArray?.length;

  const colDefs = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 796px)`,
      folderTitleWidth: `calc(100% - 770px)`,
      paddingRight: '16px',
      unselectable: props.rerunMode ? true : false,
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                {treeData?.[0]?.totalModuleCount ? (
                  <span className="count-badge folder-count ml-2">M {treeData[0]?.totalModuleCount} </span>
                ) : null}
                {treeData?.[0]?.totalScriptCount ? (
                  <span className="count-badge file-count"> S {treeData[0]?.totalScriptCount} </span>
                ) : null}
              </>
            )}
            {!nodeObj.isRootNode && nodeObj.data.scriptCount > 0 && (
              <span class="count-badge file-count"> S {nodeObj?.data?.calculatedScriptCount} </span>
            )}
            {nodeObj.folder && (
              <button className="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type truncate',
      width: '68px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.scriptType}>
            <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
              {nodeObj?.data?.scriptType}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Assigned to Machine',
      title: 'Client Machine',
      class: 'AssignedMachine truncate',
      width: '152px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.assignedToMachine}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.assignedToMachine}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Execution Environment',
      title: 'Execution Environment',
      class: 'executionEnvironment truncate',
      width: '168px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.executionEnv}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.executionEnv}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Os',
      title: 'OS & OS Ver.',
      class: 'os',
      width: '90px',
      render: (nodeObj) => {
        return <span>{nodeObj?.data?.Os ? getOsLabel(nodeObj.data.Os) : ''}</span>;
      },
    },
    {
      field: project?.type?.toLowerCase() === 'web' ? 'Browser' : 'Device',
      title: project?.type?.toLowerCase() === 'web' ? 'Browser' : 'Device',
      class: project?.type?.toLowerCase() === 'web' ? 'browser' : 'device truncate',
      width: project?.type?.toLowerCase() === 'web' ? '52px' : '80px',
      render: (nodeObj) => {
        if (project?.type.toLowerCase() !== 'web') {
          return <span className="">{nodeObj?.data?.device ? nodeObj.data.device : ''}</span>;
        } else {
          return nodeObj?.data?.browser ? (
            <Tooltip title={nodeObj.data?.browser} placement="top">
              {renderBrowserCell(nodeObj.data.browser)}
            </Tooltip>
          ) : (
            ''
          );
        }
      },
    },
    {
      field: 'numberOfRuns',
      title: 'No.of Runs',
      class: 'numberOfRuns',
      width: '80px',
      render: (nodeObj) => {
        return (
          <div className="truncate">
            {!nodeObj.isRootNode && (
              <span className={'table-non-link-color-common'}>{nodeObj?.data?.numberOfRuns}</span>
            )}
          </div>
        );
      },
    },
  ];

  const closeModal = (val) => {
    if (downloadClientModal) {
      setDownloadClientModal(val);
    }
  };

  const colDefsForWebAndMobile = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 954px)`,
      folderTitleWidth: `calc(100% - 850px)`,
      paddingRight: '16px',
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                {treeData && treeData?.[0]?.totalModuleCount ? (
                  <span className="count-badge folder-count ml-2 modifiedBy">M {treeData?.[0]?.totalModuleCount} </span>
                ) : null}

                {treeData?.[0]?.totalScriptCount ? (
                  <span className="count-badge file-count"> S {treeData?.[0]?.totalScriptCount} </span>
                ) : null}
              </>
            )}
            {!nodeObj.isRootNode && nodeObj.data.calculatedScriptCount > 0 && (
              <span class="count-badge file-count"> S {nodeObj.data.calculatedScriptCount} </span>
            )}
            {nodeObj.folder && (
              <button className="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type truncate',
      width: '68px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.scriptType}>
            <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
              {nodeObj?.data?.scriptType}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Assigned to Machine',
      title: 'Client Machine',
      class: 'AssignedMachine truncate',
      width: '152px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.assignedToMachine}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.assignedToMachine}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Execution Environment',
      title: 'Execution Environment',
      class: 'executionEnvironment truncate',
      width: '168px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.executionEnv}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.executionEnv}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Os',
      title: 'OS & OS Ver.',
      class: 'os',
      width: '90px',
      render: (nodeObj) => {
        return <span className="version-text-color">{nodeObj?.data?.Os ? getOsLabel(nodeObj.data.Os) : ''}</span>;
      },
    },

    {
      field: 'Browser',
      title: 'Browser',
      class: 'browser',
      width: '70px',
      render: (nodeObj) => {
        return nodeObj?.data?.browser ? (
          <Tooltip title={nodeObj.data?.browser} placement="top">
            {renderBrowserCell(nodeObj.data.browser)}
          </Tooltip>
        ) : (
          ''
        );
      },
    },
    {
      field: 'Device',
      title: 'Device',
      class: 'device truncate',
      width: '70px',
      render: (nodeObj) => {
        return <span className="version-text-color">{nodeObj?.data?.device ? nodeObj.data.device : ''}</span>;
      },
    },
    {
      field: 'numberOfRuns',
      title: 'No.of Runs',
      class: 'numberOfRuns',
      width: '80px',
      render: (nodeObj) => {
        return (
          <div className="truncate">
            {!nodeObj.isRootNode && (
              <span className={'table-non-link-color-common'}>{nodeObj?.data?.numberOfRuns}</span>
            )}
          </div>
        );
      },
    },
  ];

  function onNodeSelected(onNode) {
    console.info('Node Selected');
  }

  const onCheckedNodes = (nodes) => {
    let nodeArray = [];
    updateSelectedNodes(nodes, nodeArray);
    setSelectedNodeArray(nodeArray);
    const _nodeObjArray = nodes.map((_node) => _node.node);
    setdisableHeadlessSwitch(enableDisableHeadlessSwitch(_nodeObjArray, selectedProject));
    let tempDistributionData = JSON.parse(JSON.stringify([...distributionDataRef.current]));
    setTempDistributionData(tempDistributionData);
  };

  const enableDisableHeadlessSwitch = (moduleArray, selectedProject) => {
    if (selectedProject.type.toLowerCase() === 'web') {
      return false;
    }
    if (['salesforce', 'web & mobile', 'ms dynamics'].includes(selectedProject?.type?.toLowerCase())) {
      if (ifValidToggleCondition(moduleArray)) {
        return false;
      }
    }
    return true;
  };

  const ifValidToggleCondition = (selectedScriptsAndModules) => {
    for (const _node of selectedScriptsAndModules) {
      if (_node?.type === 'Script') {
        if (['web', 'web & mobile'].includes(_node?.data?.scriptType.toLowerCase())) {
          return true;
        }
      } else if (_node?.children) {
        if (ifValidToggleCondition(_node?.children)) {
          return true;
        }
      }
    }
    return false;
  };
  const updateSelectedNodes = (nodes, nodeArray) => {
    nodes.forEach((node) => {
      nodeArray.push(node);
    });
  };

  const plusIconArray = [
    {
      name: 'Add Module',
      value: 'folder',
    },
    {
      name: 'Add Script',
      value: 'file',
    },
  ];
  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  function createFilterData(treeData) {
    if (treeData) {
      let tempArray = [];
      let scriptArray = [];
      let tempNamesArray = [];
      let tempTypeArray = [];
      treeData.forEach((treeVal) => {
        if (treeVal) {
          if (treeVal.title) {
            tempArray.push(treeVal.title);
          }
          if (treeVal.createdByUname) {
            tempNamesArray.push(treeVal.createdByUname);
          }
          if (treeVal && treeVal.children) {
            scriptArray = treeVal.children;
            scriptArray.forEach((scriptVal) => {
              if (scriptVal.title) {
                tempArray.push(scriptVal.title);
              }
              if (scriptVal.createdByUname) {
                tempNamesArray.push(treeVal.createdByUname);
              }
              if (scriptVal.scriptType) {
                tempTypeArray.push(scriptVal.scriptType);
              }
            });
          }
        }
      });
    }
  }
  if (isLoading) {
    renderTree = (
      <div className="base-h flex items-center justify-center">
        <span className="text-center font-bold align-middle empty_page_info -mt-20">
          <h2>data loading...</h2>
        </span>
      </div>
    );
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={
          project?.type?.toLowerCase() === 'web'
            ? colDefs
            : project?.type?.toLowerCase() === 'mobile'
            ? colDefs
            : colDefsForWebAndMobile
        }
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={true}
        disableRootCheckbox={props?.rerunMode ? true : false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={true}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="distributeTree"
      />
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={[]}
            rootData={rootData}
            colDefs={
              project?.type?.toLowerCase() === 'web'
                ? colDefs
                : project?.type?.toLowerCase() === 'mobile'
                ? colDefs
                : colDefsForWebAndMobile
            }
            filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideElements={false}
            hideConditions={true}
            // onRootNodeClicked={rootNodeClicked}
            conditions={[
              { key: 'title', value: 'Preconditions' },
              { key: 'title', value: 'Postconditions' },
            ]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="distributeTree"
          />
        </div>
        <div className="overflow-y-auto h-82">
          <div className=" fontPoppinsRegularMd mt-28 no-steps">
            <div classname="create-set-layout">
              <div className="qucBtn">
                <label className="quick-start-style px-1  ">Select some modules </label>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    renderMaxTree = (
      <div className="base-h flex items-center justify-center">
        <span className="text-center font-bold align-middle empty_page_info -mt-20">
          <h2>data loading...</h2>
        </span>
      </div>
    );
  } else if (treeData && treeData.length > 0) {
    renderMaxTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={
          project?.type?.toLowerCase() === 'web'
            ? colDefs
            : project?.type?.toLowerCase() === 'mobile'
            ? colDefs
            : colDefsForWebAndMobile
        }
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={true}
        disableRootCheckbox={props?.rerunMode ? true : false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={true}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="distributeMaxTree"
      />
    );
  }

  function getModuleTree() {
    setIsLoading(true);
    let root;
    let rootChildren;
    let rootDetails = {};
    rootDetails.children = [...props?.moduleArray];
    const _rootDetails = rootDetails.children[0];
    root = {
      subModuleCount: _rootDetails?.totalModuleCount,
      title: _rootDetails?.parentName,
      key: _rootDetails?.parentId,
      ver: _rootDetails?.ver,
      modifiedByUname: _rootDetails?.modifiedByUname,
      scriptCount: _rootDetails?.totalScriptCount,
    };
    rootChildren = rootDetails.children;
    collectSearchKeys(rootChildren);
    setRootData(root);
    setIsExpandAll(rootDetails.expanded);
    setTreeData(rootChildren);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  function collectSearchKeys(rootArray) {
    let flattenedData = [];
    flatten(flattenedData, rootArray);
    if (flattenedData?.length) {
      setSearchKeys([...flattenedData]);
    }
    return flattenedData;
  }

  function flatten(flattenedData, rootArray) {
    if (rootArray == null) return flattenedData;
    if (Array.isArray(rootArray)) {
      return rootArray.reduce(flatten, flattenedData);
    } else if (rootArray?.searchKey && !rootArray?.folder) {
      flattenedData.push(rootArray?.searchKey);
    }
    return flatten(flattenedData, rootArray?.children);
  }

  function selectModule(val, obj) {
    if (val === 'create') {
      const tempSystem = allClientSystems.find((machineTemp) => !machineTemp.disabled);
      if (tempSystem) {
        setSelectedSystem({ ...tempSystem });
        setDefaultSystem({ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] });
      }
    } else if (val === 'create') {
      // history.push(`?hideParentLevelParallelRun=${false}`)
    }
    setShowModal(true);
    setSelectedOperation(val);
  }

  async function getUserAllAvailableMachine() {
    try {
      const allMacData = await getAllMachineListReq();
      if (allMacData?.data?.responseObject) {
        const runSetApiData = allMacData?.data?.responseObject?.clientSystems;
        setDisabledClientIds(
          runSetApiData.filter((systemMach) => systemMach.disabled === true).map((systemMach) => systemMach.id)
        );
        setAllClientSystems(runSetApiData);
        setSelectMachineDisable(false);
        let instanceList = {};
        if (runSetApiData) {
          try {
            const browserStackResponse = await getBrowserStackList();
            if (browserStackResponse?.data?.responseCode === 200) {
              instanceList['browserInstanceArray'] = browserStackResponse?.data?.responseObject;
            }
            const saucelabResponse = await getSauceLabsList();
            if (saucelabResponse?.data?.responseCode === 200) {
              instanceList['sauceLabInstanceArray'] = saucelabResponse?.data?.responseObject;
            }
            const lambdaTestResponse = await getAllLambdaTestsList();
            if (lambdaTestResponse?.data?.responseCode === 200) {
              instanceList['lambdaTestsInstanceArray'] = lambdaTestResponse?.data?.responseObject;
            }
            setInstanceList({ ...instanceList });
            setDistributionData([...props?.manualDistribution]);
            setSelectedJsonData([...props?.manualJsonData]);
            const tempSystem = runSetApiData.find((machineTemp) => !machineTemp.disabled);
            if (tempSystem) {
              setSelectedSystem({ ...tempSystem });
              setDefaultSystem({ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] });
              setEnableButton(true);
            }
          } catch (error) {
            console.error('GET_INSTANCE_ERROR : ', error);
          }
        }
      }
    } catch (error) {
      console.error('GET_ALL_MACHINE_LIST : ', error);
    }
  }

  const handleChangeDefaultMachine = (machine) => {
    if (['web', 'web & mobile', 'salesforce'].includes(project?.type?.toLowerCase())) {
      machine[0].systemConfigDetails = getSingleBrowserList(machine[0]?.systemConfigDetails);
    } else if (project?.type?.toLowerCase() === 'mobile') {
      machine[0].systemConfigDetails = getSingleBrowserListForMobile(machine[0]?.systemConfigDetails);
    }
    setDefaultSystem({ ...machine[0] });
    setEnableButton(true);
  };

  function reloadModuleTree(selectedJsonData) {
    setIsLoading(true);
    let root;
    let rootChildren;
    let rootDetailsTemp = {
      children: [...props?.moduleArray],
    };
    let flattenData = collectSearchKeys(rootDetailsTemp);
    let updateProps = false;
    let rootDetails = JSON.parse(JSON.stringify(rootDetailsTemp));
    if (selectedJsonData.length) {
      selectedJsonData.forEach((machineData) => {
        machineData.machineInstances.forEach((machineInstance) => {
          const selectedModulesCopy = [...machineInstance?.distribution?.selectedModules];
          const containsAll = selectedModulesCopy.filter((arr1Item) => !flattenData.includes(arr1Item));
          if (containsAll?.length) {
            machineInstance.distribution.selectedModules = selectedModulesCopy.filter(
              (i) => flattenData?.indexOf(i) !== -1
            );
            updateProps = true;
          }
          if (selectedModulesCopy?.length) {
            selectedModulesCopy.forEach((searchKey, index) => {
              if (rootDetails?.children && rootDetails?.children?.length) {
                rootDetails.children.forEach((nodeObj) => {
                  if (searchKey?.includes(nodeObj.key)) {
                    if (nodeObj?.children) {
                      updateAssignedMachine(searchKey, nodeObj, machineInstance);
                    }
                    if (!nodeObj?.folder && machineInstance) {
                      updateClientDetails(nodeObj, machineInstance);
                    }
                  }
                });
              }
            });
          }
        });
      });
    }
    if (updateProps) {
      autoUpdateManualDistribution(selectedJsonData);
    }
    const _rootDetails = rootDetails.children[0];
    root = {
      subModuleCount: _rootDetails?.totalModuleCount,
      title: _rootDetails?.parentName,
      key: _rootDetails?.parentId,
      ver: _rootDetails?.ver,
      modifiedByUname: _rootDetails?.modifiedByUname,
      scriptCount: _rootDetails?.totalScriptCount,
    };
    rootChildren = rootDetails.children;
    setRootData(root);
    setIsExpandAll(rootDetails.expanded);
    setTreeData(rootChildren);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  function autoUpdateManualDistribution(selectedJsonData) {
    const updatedJsonData = [];
    const updatedDistributionData = [];
    selectedJsonData.forEach((machine) => {
      const updatedMachineInstances = [];
      machine.machineInstances.forEach((machineInstance) => {
        if (machineInstance?.distribution?.selectedModules?.length) {
          updatedMachineInstances.push(machineInstance);
          updatedDistributionData.push(machineInstance?.distribution);
        }
      });
      updatedJsonData.push({
        clientId: machine.clientId,
        machineInstances: updatedMachineInstances,
      });
    });
    setSelectedJsonData([...updatedJsonData]);
    props?.manualDistributionAllData([...updatedJsonData], updatedDistributionData);
  }

  function updateAssignedMachine(searchKey, childrenObj, machineInstance) {
    childrenObj.children.forEach((nodeObj) => {
      if (searchKey?.includes(nodeObj.key)) {
        if (nodeObj?.children) {
          updateAssignedMachine(searchKey, nodeObj, machineInstance);
        }
        if (!nodeObj?.folder && machineInstance) {
          updateClientDetails(nodeObj, machineInstance);
        }
      }
    });
  }

  function updateClientDetails(nodeObj, selectedMachine) {
    if (selectedMachine) {
      nodeObj.browser = selectedMachine.browserName;
      nodeObj.Os = `${selectedMachine.machineInfo.osName} - ${selectedMachine.machineInfo.osVersion}`;
      nodeObj.assignedToMachine = selectedMachine.machineInfo.hostName;
      nodeObj.executionEnv = selectedMachine.executionEnv;
      nodeObj.numberOfRuns = selectedMachine.numberOfRuns;
      if (selectedMachine?.deviceInfo?.length) {
        nodeObj.device = getDeviceLabel(selectedMachine?.deviceInfo);
      } else {
        nodeObj.device = '';
      }
    }
  }

  useEffect(() => {
    getUserAllAvailableMachine();
  }, []);

  useEffect(() => {
    if (props?.moduleArray?.length) {
      props?.moduleArray?.forEach((obj) => {
        if (obj) {
          obj.checkbox = true;
          ['browser', 'device', 'executionEnv', 'Os', 'assignedToMachine'].forEach((e) => delete obj[e]);
        }
      });
    }
    if (props?.manualDistribution?.length) {
      setTreeData([]);
      reloadModuleTree(props?.manualJsonData);
    } else {
      getModuleTree();
    }
  }, [props?.moduleArray, props?.manualJsonData]);

  const getUpdatedSelectedMachine = (updatedSystem) => {
    updatedSystem = { ...updatedSystem[0] };
    if (updatedSystem?.clientId === defaultSystem?.clientId) {
      setDefaultSystem({ ...updatedSystem });
    }
    setUpdatedSelectedMachine({ ...updatedSystem });
    tempUpdatedSelectedMachine = { ...updatedSystem };
  };

  function getSingleBrowserList(systemConfig) {
    let tempArray = [];
    if (systemConfig?.length) {
      systemConfig?.forEach((obj) => {
        if (obj.subType === 'browser') {
          if (!tempArray?.length) {
            tempArray?.push(obj);
          }
        }
      });
      return tempArray;
    } else {
      return systemConfig;
    }
  }

  function getSingleBrowserListForMobile(systemConfig) {
    if (systemConfig?.length) {
      let tempArray = [];
      return tempArray;
    }
  }

  const handleSelectedSystem = (system) => {
    radioBtnOnChangeHandler([{ ...system }]);
    if (system?.clientId !== selectedSystem?.clientId) {
      setSelectedSystem(system);
    }
  };

  function getSelectedDeviceData() {
    if (defaultSystem) {
      let deviceArray = [];
      let browserObj = {};
      let updatedSystem;
      let deviceName;
      let tempSelectedModules = [];
      if (updatedSelectedMachine?.clientId === defaultSystem?.clientId) {
        updatedSystem = { ...updatedSelectedMachine };
      } else {
        updatedSystem = { ...defaultSystem };
      }
      if (updatedSystem?.systemConfigDetails?.length) {
        updatedSystem.systemConfigDetails.forEach((obj) => {
          if (obj?.subType === 'device') {
            deviceArray?.push(obj);
          } else {
            browserObj = { ...obj };
          }
        });
      }
      let tempDistributionArray = [...tempDistributionData];
      let tempSelectedNodArray = [...selectedNodeArray];
      tempSelectedNodArray.forEach((modObj) => {
        if (modObj?.data?.searchKey && !modObj?.data?.folder) {
          tempSelectedModules.push(modObj?.data?.searchKey);
        }
      });
      const distributionObj = {
        clientSystemId: updatedSystem?.clientId,
        distributionId: updatedSystem?.clientId + Math.floor(Math.random() * 100),
        selectedModules: [...tempSelectedModules],
      };
      if (tempDistributionArray?.length) {
        tempDistributionArray = refactorTempDistributionArray(tempDistributionArray, tempSelectedModules);
        tempDistributionArray?.push({ ...distributionObj });
      } else {
        tempDistributionArray?.push({ ...distributionObj });
      }
      setDistributionData([...tempDistributionArray]);
      let selecteSystemObj = {
        clientId: updatedSystem?.clientId,
        numberOfRuns: updatedSystem?.numberOfRuns,
        executionEnv: updatedSystem?.executionEnvironment,
        browserName: browserObj?.name || '',
        browserVersion: browserObj?.version || '',
        machineInfo: {
          osName: updatedSystem?.machine?.osName,
          osVersion: updatedSystem?.machine?.osVersion,
          hostName: updatedSystem?.machine?.hostName,
        },
        deviceInfo: deviceArray || [],
        headless: updatedSystem?.headless || false,
        distribution: {
          ...distributionObj,
        },
      };
      if (project?.type?.toLowerCase() === 'web') {
        selecteSystemObj.deviceInfo = [];
      } else if (project?.type?.toLowerCase() === 'mobile') {
        selecteSystemObj.browserName = '';
        selecteSystemObj.browserVersion = '';
        updatedSystem['deviceNames'] = deviceName;
      } else if (project?.type?.toLowerCase() === 'web & mobile') {
        updatedSystem['deviceNames'] = deviceName;
      }
      const tempSelectedJsonData = selectedJsonDataRef.current || [];
      let updatedJsonData = [];
      if (tempSelectedJsonData.length) {
        tempSelectedJsonData.forEach((selectedMachine) => {
          const updatedMachineInstances = [];
          selectedMachine.machineInstances.forEach((machineInstance) => {
            const updatedDistributionObj = refactorTempDistributionArray(
              [{ ...machineInstance.distribution }],
              tempSelectedModules
            );
            if (updatedDistributionObj?.length) {
              updatedMachineInstances.push({
                ...machineInstance,
                distribution: updatedDistributionObj[0],
              });
            }
          });
          if (selectedMachine.clientId === selecteSystemObj?.clientId) {
            updatedMachineInstances.push({ ...selecteSystemObj });
          }
          if (updatedMachineInstances.length) {
            updatedJsonData.push({
              clientId: selectedMachine.clientId,
              machineInstances: updatedMachineInstances,
            });
          }
        });
        if (!updatedJsonData.some((client) => client.clientId === selecteSystemObj?.clientId)) {
          updatedJsonData.push({ clientId: selecteSystemObj?.clientId, machineInstances: [selecteSystemObj] });
        }
      } else {
        updatedJsonData.push({ clientId: selecteSystemObj?.clientId, machineInstances: [selecteSystemObj] });
      }
      setSelectedJsonData([...updatedJsonData]);
      setDefaultSystem({ ...updatedSystem });
      props?.manualDistributionAllData(updatedJsonData, tempDistributionArray);
      setShowModal(false);
      reloadModuleTree(updatedJsonData);
      setNoCallGetUpdated(false);
      setSelectedNodeArray([]);
    }
  }

  const refactorTempDistributionArray = (tempDistributionArray, tempSelectedModules) => {
    tempDistributionArray.forEach((tempDistObj, ind) => {
      const updatedSelectedModules = JSON.parse(JSON.stringify([...tempDistObj.selectedModules]));
      tempDistObj.selectedModules.forEach((_selMod) => {
        if (tempSelectedModules.some((tmpSelmod) => _selMod === tmpSelmod)) {
          const __index = updatedSelectedModules?.findIndex((__mod) => __mod === _selMod);
          updatedSelectedModules.splice(__index, 1);
        }
      });
      tempDistObj.selectedModules = updatedSelectedModules;
      if (tempDistObj.selectedModules.length === 0) {
        tempDistributionArray.splice(ind, 1);
      }
    });
    return tempDistributionArray;
  };

  function showAlertMsg() {
    MyAlert.warning(
      `This license is limited to ${props?.numberOfParallelRuns} parallel runs, so you cannot select more than ${props?.numberOfParallelRuns}  machines.`
    );
  }

  const handleClose = () => {
    setDownloadClientModal(true);
  };
  function radioBtnOnChangeHandler(selSystem) {
    selSystem = selSystem[0];
    let tempSelectedJsonArray = [...selectedJsonData];
    if (props?.numberOfParallelRuns > tempSelectedJsonArray.length) {
      let tempArray = [];
      tempArray?.push({ ...selSystem });
      handleChangeDefaultMachine([...tempArray]);
    } else {
      let propObj = tempSelectedJsonArray?.find((obj) => obj?.clientId === selSystem?.clientId);
      if (!propObj) {
        showAlertMsg();
      } else {
        let tempArray = [];
        tempArray?.push({ ...selSystem });
        handleChangeDefaultMachine([...tempArray]);
      }
    }
  }

  function extractScriptsWithScriptType(data) {
    let result = [];

    data.forEach((node) => {
      if (node.scriptType) {
        result.push(node);
      }
      if (node.children && Array.isArray(node.children)) {
        result = result.concat(extractScriptsWithScriptType(node.children));
      }
    });

    return result;
  }
  const treeDataScripts = extractScriptsWithScriptType(treeData);
  return (
    <>
      <Modal
        isOpen={showModal}
        className={
          selectedOperation === 'create'
            ? 'react-modal-main-style modal-overlay popup-height-runsettings'
            : selectedOperation === 'maximize'
            ? 'maximize-react-modal-style modal-overlay popup-height-runsettings'
            : selectedOperation === 'createdBy'
            ? 'user-react-modal-style modal-overlay popup-height-runsettings'
            : null
        }
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <div className="testEnv-header-section">
          <span className="ml-3 fontPoppinsSemiboldSm py-0.5">
            {selectedOperation === 'maximize'
              ? ' Modules / Scripts'
              : selectedOperation === 'createdBy'
              ? `User Details -  ${selectedNodeForUser?.data?.modifiedByUname}`
              : 'Test Environments'}
          </span>
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="float-right mr-4 text-white hover:ring-2 ring-white focus:ring-2 rounded-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {selectedOperation === 'create' ? (
          <>
            <div className="alert-variable-modal-position-style">
              <AlertContatiner />
            </div>
            {!isEmptyValue(allClientSystems) ? (
              <div
                className="table_height  border-b  border-t mt-3 mb-4 border-blue-100  qrs_modal_body"
                id="journal-scroll"
              >
                <div className="m-2 qrs-system-grid-container grid grid-cols-10 gap-2.5 px-4 py-1">
                  <div className="qrs-machine-table col-span-4">
                    <MachineTable
                      inputType={'radio'}
                      systems={allClientSystems}
                      currentVisibleSystem={selectedSystem}
                      selectedSystems={[{ ...defaultSystem }]}
                      onRowClickHandler={handleSelectedSystem}
                      classes={''}
                      defaultSystem={defaultSystem}
                      fireCloudSelected={treeDataScripts.some((script) => script.executionEnv === 'FireCloud')}
                      localSelected={treeDataScripts.some(
                        (script) => script.executionEnv === 'Local' || check3PEnvironment.test(script.executionEnv)
                      )}
                      isRootSelected={isRootSelected}
                      disableIds={disabledClientIds}
                    />
                  </div>
                  <div className="qrs-details-div col-span-6 overflow-y-auto">
                    <MachineDetails
                      executionType="manual distribution"
                      ref={machineDetailsRef}
                      selectedSystem={selectedSystem}
                      instanceList={instanceList}
                      defaultSystem={[{ ...defaultSystem }]}
                      disableHeadless={disableHeadlessSwitchRef.current}
                      project={project}
                      getUpdatedSelectedMachine={getUpdatedSelectedMachine}
                      noCallGetUpdated={noCallGetUpdated}
                      setNoCallGetUpdated={setNoCallGetUpdated}
                      scriptTypes={props?.scriptTypes}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <InvalidMachineModal
                handleClose={handleClose}
                downloadClientModal={downloadClientModal}
                closeModal={closeModal}
              />
            )}

            {!isEmptyValue(allClientSystems) && (
              <div className="float-right mr-4 pb-3 flex flex-row">
                <Button
                  className="mr-3"
                  label={LABELS_CONSTANTS.CANCEL}
                  variant="secondary"
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
                <Button
                  type="submit"
                  id="selectBtn"
                  label={DEFECT_CONSTANTS.SELECT}
                  variant="primary"
                  onClick={async () => {
                    const isValid = await machineDetailsRef.current?.validateForm();
                    if (!isValid?.machineInstances?.length) {
                      getSelectedDeviceData();
                    }
                  }}
                  disabled={!enableButton}
                />
              </div>
            )}
          </>
        ) : selectedOperation === 'maximize' ? (
          <div className="table_height  border-t  mt-3 mb-4 border-blue-100 qrs_modal_body" id="journal-scroll">
            <div className="react-modal-style">{renderMaxTree}</div>
          </div>
        ) : selectedOperation === 'createdBy' ? (
          <div className="table_height  border-t p-4 mt-3 mb-4 border-blue-100 qrs_modal_body" id="journal-scroll">
            <div>
              <UserDetailsPage modifiedBy={selectedNodeForUser?.data?.modifiedBy} />
            </div>
          </div>
        ) : null}
      </Modal>
      <div className="content_area_header_style">
        <div>
          <label className="main-header-label float-left ">Modules / Scripts</label>
        </div>
        <div className="flex flex-row float-right">
          {/* <FaSearch className="mt-2 mr-3 searchBtn" /> */}
          <button
            className={`${
              props?.rerunMode
                ? 'secondary-btn-disable opacity-50 cursor-auto'
                : selectedNodeArray?.length
                ? 'secondary-btn fontPoppinsMediumMd'
                : 'secondary-btn-disable opacity-50 cursor-auto fontPoppinsMediumMd'
            } mr-3`}
            disabled={props?.rerunMode || !selectedNodeArray?.length || selectMachineDisable}
            onClick={(e) => {
              selectModule('create');
              e.preventDefault();
            }}
          >
            Select Machines
          </button>
          <FiMaximize2
            title="Maximize"
            className={`${renderTree ? 'cursor-hand maximize-btn-style ' : 'disabled-action '} mt-2 mr-3`}
            onClick={() => {
              selectModule('maximize');
            }}
          />
        </div>
      </div>
      <div className="table_tree_height">{renderTree}</div>
    </>
  );
};

export default ManualDistribution;
