import React, { useState, useEffect } from 'react';
import { useAlert } from '@pagescommon/alert_service/useAlert';
import {
  getAllElementsForSuggestion,
  getAllElementsReq,
  getElementReq,
  getSharedElementReq,
} from '@src/api/api_services';
import { Field } from 'formik';
import { getPagesName } from '@src/pages/test-development/shared/common-methods';
import { TextField, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import ElementModal from '@src/pages/repository/components/project_elements/modals/ElementModal';
import LocatorDetailsModal from '@src/pages/repository/components/project_elements/modals/locator_details_modal';
import { ReactComponent as Web } from '@assets/web_black_48dp.svg';
import { ReactComponent as IOS } from '@assets/apple_icon.svg';
import { ReactComponent as Android } from '@assets/android_icon.svg';
import { ReactComponent as MobileWeb } from '@assets/Mobile_web_icon.svg';
import { ReactComponent as Mobile } from '@assets/smartphone_black_24dp.svg';
import cx from 'classnames';

const ElementSuggestion = (props) => {
  const classNames = 'search-nlp fontPoppinsRegularMd text-blue-700 w-11/12 relative';
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const platform = props.stepsDataObject?.defaultToolTip?.includes('*elementPage*');
  const { MyAlert } = useAlert();
  const { isEditable, hasViewAccess } = React.useMemo(
    () => ({
      hasViewAccess: window.permission?.isViewAccess('repository'),
      isEditable: window.permission?.isEditAccess('repository'),
    }),
    []
  );
  const initialList = {
    Web: [],
    Android: [],
    iOS: [],
    Mobile: [],
    MobileWeb: [],
  };
  const pageTerms = {
    Web: {
      type: 'Page',
      header: 'Web',
      icon: <Web className="mr-3 h-4 w-4" />,
    },
    Android: {
      type: 'Screen',
      header: 'Android',
      icon: <Android className="mr-3 h-4 w-4" />,
    },
    iOS: {
      type: 'Screen',
      header: 'iOS',
      icon: <IOS className="mr-3 h-4 w-4" />,
    },
    MobileWeb: {
      type: 'Screen',
      header: 'MobileWeb',
      icon: <MobileWeb className="mr-3 h-4 w-4" />,
    },
    Mobile: {
      type: 'Screen',
      header: 'Mobile',
      icon: <Mobile className="mr-3 h-4 w-4" />,
    },
  };
  const [elementsResponseList, setElementResponseList] = useState(initialList);
  const [filteredElementList, setFilteredElementList] = useState(elementsResponseList);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedElementData, setSelectedElementData] = useState();
  const [openElementDetailsModal, setOpenElementDetailsModal] = useState(false);
  const [openElementEditModal, setOpenElementEditModal] = useState(false);
  const returnParentNames = (nameArray) => {
    let names = [];
    for (let i = 0; i < nameArray?.length; i++) {
      names.push(nameArray[i].name);
    }
    return names.toString();
  };
  const isSharedElement = (value) => ['y', 'yes'].includes(value?.toLowerCase());
  const getElementDetails = async (data) => {
    try {
      const response = isSharedElement(data.isShared)
        ? await getSharedElementReq(data.id)
        : await getElementReq(data.pageId, data.id);
      if (response?.data?.responseObject) {
        const responseObject = response.data.responseObject;
        const { locators } = responseObject;
        let updatedLocators = [];
        locators?.forEach((locator) => {
          if (['static', 'png', 'jpeg', 'jpg', 'svg', 'svg+xml'].includes(locator.type.toLowerCase())) {
            updatedLocators.push(locator);
          }  else if (['dynamic', 'Dynamic'].includes(locator.type)) {
            locator.value = locator?.value?.replaceAll('dynamic', 'Dynamic Val');
            updatedLocators.push(locator);
          }
        });
        if (props?.getElementValue) {
          props.getElementValue(response?.data?.responseObject?.platform);
        }
        responseObject.locators = updatedLocators;
        if (isSharedElement(responseObject?.isShared)) {
          responseObject.parentName = returnParentNames(responseObject.pageIdsNames);
        }
        setSelectedElementData(responseObject);
      } else {
        setSelectedElementData();
      }
    } catch (err) {
      console.error('GET_ELEMENT_DETAILS :', err);
    }
  };

  const showElements = () => {
    setShowSuggestions(true);
  };
  const hideElements = () => {
    setShowSuggestions(false);
  };
  const elementNameClickHandler = (data) => {
    const getElement = async () =>
      isSharedElement(data.isShared)
        ? await getSharedElementReq(data.id || data['_id'])
        : await getElementReq(data.parentId, data.id || data['_id']);

    const setCurrentElement = (selectedElement) => {
      if (selectedElement) {
        const { locators } = selectedElement;
        let updatedLocators = [];
        locators.forEach((locator) => {
          if (['static', 'png', 'jpeg', 'jpg', 'svg', 'svg+xml'].includes(locator.type.toLowerCase())) {
            updatedLocators.push(locator);
          } else {
            locator.value = locator?.value?.replaceAll('Dynamic Val', 'dynamic');
            updatedLocators.push(locator);
          }
        });
        if (props?.getElementValue) {
          props.getElementValue(selectedElement?.platform);
        }
        selectedElement.locators = updatedLocators;
        if (isSharedElement(selectedElement?.isShared)) {
          selectedElement.parentName = returnParentNames(selectedElement.pageIdsNames);
        }
        setSelectedElementData(selectedElement);
      } else {
        setSelectedElementData();
      }
    };

    setTimeout(async () => {
      const response = await getElement();
      const selectedElement = response?.data?.responseObject;
      if (selectedElement) {
        selectedElement['pageId'] = selectedElement.parentId;
      }
      props.updateNlpDescription(selectedElement, props.stepInputName);
      setCurrentElement(selectedElement);
      props.setFieldValue(`${props.stepInputName}`, data.name, true);
      hideElements();
    });
  };
  const handleBlur = (e) => {
    props.handleBlur(e);
    let elementDetails = {
      path: '',
      locators: [],
      name: '*elementName*',
      id: '',
      type: '*elementType*',
      pageId: '',
      pageName: platform ? '*elementPage*' : '*elementScreen*',
      isShared: '',
    };
    if (!selectedElementData || e.target.textContent === '') {
      let stepDataObjectEmpty = JSON.parse(JSON.stringify(props.stepsDataObject));
      stepDataObjectEmpty.stepInputs = stepDataObjectEmpty?.stepInputs.map((item) => {
        if (item.name === 'elementName' || item.name === 'elementType') {
          // item.value = '';
        }
        return item;
      });
      props.setFieldValue(props.stepInputName, '', false);
      props.updateNlpDescription(elementDetails, props.stepInputName, null, stepDataObjectEmpty);
      props.setFieldError(props.stepInputName, 'Select elements from drop down');
      setSelectedElementData();
    }
    hideElements();
    setFilteredElementList({ ...elementsResponseList });
  };
  const search = (data) => {
    const getFilteredList = (list) =>
      list.filter((element) => element?.name && element.name.toLowerCase().includes(data?.toLowerCase()));
    let filteredList = { ...initialList };
    if (elementsResponseList.Web.length) {
      filteredList.Web = getFilteredList(elementsResponseList.Web);
    }
    if (elementsResponseList.Android.length) {
      filteredList.Android = getFilteredList(elementsResponseList.Android);
    }
    if (elementsResponseList.iOS.length) {
      filteredList.iOS = getFilteredList(elementsResponseList.iOS);
    }
    if (elementsResponseList.MobileWeb.length) {
      filteredList.MobileWeb = getFilteredList(elementsResponseList.MobileWeb);
    }
    if (elementsResponseList.Mobile.length) {
      filteredList.Mobile = getFilteredList(elementsResponseList.Mobile);
    }
    setFilteredElementList(filteredList);
  };
  const handleChange = (e) => {
    props.handleChange(e);
    setSelectedElementData();
    search(e.target.value);
  };
  const closeModal = (value) => {
    if (openElementDetailsModal) {
      setOpenElementDetailsModal(false);
    }
    if (openElementEditModal) {
      setOpenElementEditModal(false);
    }
  };
  const viewEditElementDetails = (type) => {
    if (type === 'view') {
      setOpenElementDetailsModal(true);
    } else if (type === 'edit') {
      setOpenElementEditModal(true);
    }
  };
  const updateEditedElement = (data) => {
    let details = {
      path: data.path,
      locators: data.locators,
      name: data.name,
      id: data.id,
      type: data.type,
      pageId: data.parentId,
      pageName: data.parentName,
      isShared: data.isShared,
    };
    props.reloadStepsTable();
    props.updateNlpDescription(details, props.stepInputName);
    props.setFieldValue(`${props.stepInputName}`, data.name);
    let responseObject = JSON.parse(JSON.stringify(data));
    let locators = responseObject.locators;
    let updatedLocators = [];
    locators.forEach((locator, index) => {
      if (['static', 'png', 'jpeg', 'jpg', 'svg', 'svg+xml'].includes(locator.type.toLowerCase())) {
        updatedLocators.push(locator);
      } else if (locator.type === 'dynamic') {
        locator.value = locator?.value?.replaceAll('dynamic', 'Dynamic Val');
        updatedLocators.push(locator);
      }
    });
    setSelectedElementData(responseObject);
    getAllElements();
  };
  const reloadTreeData = (value) => {};
  const getAllElements = async (editData) => {
    try {
      const scriptTypes = [
        'Mobile (Android & iOS)',
        'Mobile (Android or iOS)',
        'Web & Mobile (Android & iOS)',
        'Web & Mobile (Android or iOS)',
      ];
      const [pageId, elementId] = editData ? editData.split(':') : ['', ''];
      const getElement = (list) =>
        list.find(
          (element) => (element.id === elementId || element.elementId === elementId) && element.pageId === pageId
        );
      let platform = null;
      if (scriptTypes.includes(props?.script?.scriptType) || scriptTypes.includes(props?.stepGroup?.type)) {
        platform = props?.nlpType?.toLowerCase() === 'generic' ? 'Mobile' : props.nlpType || 'Web & Mobile';
      } else if (project.type !== 'Web') {
        platform = props.type === 'Step Group' ? props?.stepGroup?.type : props.script.scriptType;
      }
      const response = await getAllElementsForSuggestion(project.id, project.type, platform);
      if (response?.data?.responseObject) {
        const newElementList = {
          ...initialList,
          ...JSON.parse(JSON.stringify(response?.data?.responseObject)),
        };
        delete newElementList[''];
        if (editData) {
          let elementFound;
          if (newElementList.Web.length) {
            elementFound = getElement(newElementList.Web);
          }
          if (!elementFound && newElementList.Android.length) {
            elementFound = getElement(newElementList.Android);
          }
          if (!elementFound && newElementList.iOS.length) {
            elementFound = getElement(newElementList.iOS);
          }
          if (!elementFound && newElementList.MobileWeb.length) {
            elementFound = getElement(newElementList.MobileWeb);
          }
          if (!elementFound && newElementList.Mobile.length) {
            elementFound = getElement(newElementList.Mobile);
          }
          if (elementFound) {
            getElementDetails(elementFound);
          }
        }
        setElementResponseList(newElementList);
        setFilteredElementList(newElementList);
      } else {
        setElementResponseList({ ...initialList });
        setFilteredElementList({ ...initialList });
      }
    } catch (err) {
      console.error('GET_ALL_ELEMENTS:', err);
    }
  };
  const getTooltipData = () => {
    if (isSharedElement(selectedElementData?.isShared)) {
      return `${selectedElementData.name} in ${getPagesName(selectedElementData)}`;
    } else {
      return `${selectedElementData?.name} in ${selectedElementData?.parentName}`;
    }
  };

  const getFieldLabel = () => {
    return (
      <>
        <span className="text-red-400 mr-1 fontPoppinsRegularXLg">&#42;</span>
        <span className="fontPoppinsRegularXLg">
          {props.stepInputName.charAt(0).toUpperCase() + props.stepInputName.slice(1)}
        </span>
      </>
    );
  };
  const getSuggestionList = () => {
    const hasMobileAppElements = ['Web & Mobile (Android & iOS)', 'Web & Mobile (Android or iOS)'].includes(
      props?.script?.scriptType || props?.script?.type
    );
    return Object.keys(filteredElementList).map((elementType) => {
      return filteredElementList[elementType].length
        ? (props?.script?.scriptType?.includes(elementType) ||
            props?.script?.type?.includes(elementType) ||
            (elementType === 'MobileWeb' && hasMobileAppElements)) && (
            <div key={elementType} className="mt-2">
              <p className="ml-3">{pageTerms[elementType].header}</p>
              {filteredElementList[elementType].map((data, index) => {
                return (
                  <div
                    onMouseDown={() => {
                      elementNameClickHandler(data);
                    }}
                    id={elementType + index}
                    key={elementType + index}
                    className="flex cursor-pointer ml-3 text-gray-500 my-3"
                  >
                    <div className={pageTerms[elementType].header !== 'Mobile' ? 'mt-1' : ''}>
                      {pageTerms[elementType].icon}
                    </div>
                    <div>
                      <span className="break-all">
                        <b className={cx({ 'ml-3': getPagesName(data).length > 15 })}>
                          {' '}
                          {data?.name} : {data?.type}
                        </b>{' '}
                        in {getPagesName(data)} {pageTerms[elementType].type}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )
        : null;
    });
  };

  useEffect(() => {
    getAllElements(props.editData);
  }, [props.editData]);

  return (
    <div className="text-sm text-blue-700" id="hovered">
      <Field name={props.stepInputName}>
        {({ field, form, meta }) => {
          return (
            <div>
              {selectedElementData ? (
                <Tooltip title={getTooltipData()} placement="right-end">
                  <TextField
                    {...field}
                    autoFocus={false}
                    autoComplete="off"
                    error={meta.touched && meta.error}
                    type="text"
                    className={classNames}
                    value={field.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label={getFieldLabel()}
                    onFocus={showElements}
                    placeholder="Search for elements"
                  />
                </Tooltip>
              ) : (
                <TextField
                  {...field}
                  autoFocus={false}
                  autoComplete="off"
                  error={meta.touched && meta.error}
                  type="text"
                  className={classNames}
                  value={field.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={getFieldLabel()}
                  onFocus={showElements}
                  placeholder="Search for elements"
                />
              )}

              {
                <div id="hoverable">
                  {field.value && (
                    <span onMouseDown={handleBlur}>
                      <Clear
                        type="button"
                        className="float-right cursor-pointer mr-2  -mt-6 text-red-700 ml-64 text-xl"
                      />
                    </span>
                  )}
                </div>
              }
              {selectedElementData && hasViewAccess && (
                <div id="hoverable">
                  <span
                    className="fontPoppinsRegularMd text-blue-500 cursor-pointer float-left"
                    onClick={() => {
                      viewEditElementDetails('view');
                    }}
                  >
                    View element details
                  </span>
                  {isEditable && (
                    <span
                      className={
                        selectedElementData?.isShared !== 'YES'
                          ? 'fontPoppinsRegularMd text-blue-500 cursor-pointer float-right mr-5'
                          : 'edit-element-disable-style text-blue-300 float-right mr-5'
                      }
                      onClick={() => {
                        viewEditElementDetails('edit');
                      }}
                    >
                      Edit element
                    </span>
                  )}
                </div>
              )}

              {meta.touched && meta.error && (
                <div className="errorMessage">
                  {meta.error.charAt(0).toUpperCase() + meta.error.slice(1).toLowerCase()}
                </div>
              )}
            </div>
          );
        }}
      </Field>
      {showSuggestions && (
        <div className="border shadow rounded text-sm bg-white suggestion-dropdown w-5/12 mt-1 absolute z-10" id={props.id}>
          {getSuggestionList()}
        </div>
      )}

      {openElementEditModal ? (
        <ElementModal
          treeData="test"
          isShared={isSharedElement(selectedElementData.isShared)}
          data={selectedElementData}
          closeModal={closeModal}
          reloadTree={reloadTreeData}
          mode="EDIT"
          projectType={project.type}
          platForm={selectedElementData.platform}
          updateEditedElement={updateEditedElement}
          MyAlert={props?.MyAlert}
        />
      ) : null}
      {/* modal for element details */}
      {openElementDetailsModal ? (
        <LocatorDetailsModal
          locatorsArray={selectedElementData.locators}
          popupType="element"
          data={selectedElementData}
          closeModal={closeModal}
          containerName="Page"
          isShared={isSharedElement(selectedElementData.isShared)}
          mode="ELEMENT_DETAILS"
        />
      ) : null}
    </div>
  );
};

export default ElementSuggestion;
