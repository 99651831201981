import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Tooltip } from '@material-ui/core';

function ImpactListActionMenu(props) {
  const sendvalue = (name, node) => {
    props.methodName(name, node);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div className="inline">
      <>
        <Menu as="div" className="more-button relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-100 mt-1 mr-2 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <Tooltip title="Impact List" placement="top">
                    <img src="/assets/images/Impact_List.svg" alt="" className="text-blue-700 hover:text-blue-800 " />
                  </Tooltip>
                </Menu.Button>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-1">
                    {props.optionList.map((data, index) => (
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className="menu-item"
                            onClick={() => {
                              sendvalue(data.name, props.nodeObj);
                            }}
                          >
                            <button
                              className={classNames(
                                active ? '' : '',
                                'text-gray-700 block w-full text-left px-4 py-2 text-sm'
                              )}
                            >
                              {data.name}
                              <span className="count-badge file-count ml-2">0</span>
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </>
    </div>
  );
}

export default ImpactListActionMenu;
