import Modal from 'react-modal';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, Tooltip, Loader, Button } from 'fireflink-ui';
import { getUpdatedExecutionData } from '@api/api_services';
import { colors } from '@src/css_config/colorConstants';
import './video_modal.scss';
import { getTruncatedText } from '@src/util/common_utils';

function LiveVideoModal(props) {
  const [webSocketUrl, setWebSocketUrl] = useState('');
  const [videoId, setVideoId] = useState(null);
  const [openModal, setOpenModal] = useState(true);
  const [isMaximize, setIsMaximize] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [executionStatus, setExecutionStatus] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [upgradedWebSocketURL, setupgradedWebSocketURL] = useState('');
  const webSocketRef = useRef(null); // Store WebSocket instance
  const timerId = useRef();

  const getVideoId = async (isOneTimeCall) => {
    try {
      const res = await getUpdatedExecutionData(props.liveExecutionPayload);
      const { responseObject, status, responseCode } = res?.data || {};
      const webSocketURL = responseObject?.WebSocket_URL;
      const videoID = responseObject?.videoId;

      setWebSocketUrl(webSocketURL);
      setVideoId(videoID);

      if (webSocketURL && videoID && isOneTimeCall) {
        // Secure environment handling
        if (webSocketURL.includes('https')) {
          const hostName = window.location.hostname;
          const upgradedWebSocketURL = `wss://${hostName}/websocket?websocket=${webSocketURL}`;
          setupgradedWebSocketURL(upgradedWebSocketURL);
          createWebSocket(upgradedWebSocketURL, videoID);
        } else {
          // Non-secure environment
          createWebSocket(webSocketURL, videoID);
        }
      }

      if (responseCode === 404) {
        setErrorMsg('Something went wrong');
        if (!isOneTimeCall) {
          clearInterval(timerId.current);
        }
      }

      if (['Execution Completed'].includes(status)) {
        setExecutionStatus(true);
        setErrorMsg(status);
        if (!isOneTimeCall) {
          clearInterval(timerId.current);
        }
        setTimeout(() => {
          props.closeDeviceModal();
          setOpenModal(false);
        }, 3000);
      }

      if (status === 'Script execution not yet started') {
        setErrorMsg(status);
        if (!isOneTimeCall) {
          clearInterval(timerId.current);
        }
      }
    } catch (err) {
      setErrorMsg('Something went wrong inside the catch');
      if (!isOneTimeCall) {
        clearInterval(timerId.current);
      }
    } finally {
      setLoading(false);
    }
  };

  const createWebSocket = (webSocketUrl, videoId) => {
    if (webSocketUrl) {
      const webSocket = new WebSocket(webSocketUrl);
      webSocketRef.current = webSocket; // Save WebSocket instance

      webSocket.onopen = () => {
        const payload = JSON.stringify({ command: 'subscribe', topic: videoId });
        webSocket.send(payload);
      };

      webSocket.onmessage = (event) => {
        setImageSrc('data:image/png;base64,' + event.data);
      };

      webSocket.onclose = () => {
        console.info('Disconnected from server');
        props.closeDeviceModal();
        setOpenModal(false);
      };

      webSocket.onerror = (error) => {
        console.error('WebSocket error: ', error);
      };
    } else {
      console.warn('WebSocket URL is null:', webSocketUrl);
    }
  };

  const handleOnCloseChange = () => {
    setOpenModal(false);
    props.closeDeviceModal();

    if (webSocketRef.current && videoId) {
      const payload = JSON.stringify({ command: 'unsubscribe', topic: videoId });
      webSocketRef.current.send(payload);
      webSocketRef.current.close();
    }
  };

  const loadingVideo = () => (
    <p className="text-center">
      <Loader variant="BouncingDots" />
    </p>
  );

  const getErrorMessage = () => (
    <p className="fontPoppinsRegularMd text-center pb-30">{errorMsg}</p>
  );

  const getVideoData = () => {
    if (imageSrc) {
      return <img src={imageSrc} alt="Live Video Feed" />;
    }
    return (
      <p className="fontPoppinsRegularMd video-loading">
        Video is getting loaded <Loader variant="BouncingDots" />
      </p>
    );
  };

  const showVideoData = () => {
    if (loading) {
      return loadingVideo();
    }
    if (!webSocketUrl || executionStatus) {
      return getErrorMessage();
    }
    return getVideoData();
  };

  useEffect(() => {
    const fetchAndSetupWebSocket = async () => {
      const isOneTimeCall = true;
      await getVideoId(isOneTimeCall);
    };

    fetchAndSetupWebSocket();
    timerId.current = setInterval(() => getVideoId(false), 2000);

    return () => {
      clearInterval(timerId.current);
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
  }, [props.liveExecutionPayload]);

  return (
    <Modal
      shouldCloseOnEsc={false}
      isOpen={openModal}
      className="focus:outline-none vnc-modal-content"
      onRequestClose={handleOnCloseChange}
    >
      <div className="vnc-modal-header">
        <Tooltip title={props.selectedScriptName}>
          <span className="vnc-modal-header-text">
            {getTruncatedText(props.selectedScriptName)}
          </span>
        </Tooltip>
        <div className="vnc-modal-header-icons">
          {upgradedWebSocketURL && imageSrc && (
            <Tooltip title={isMaximize ? 'Minimize' : 'Maximize'} placement="bottom">
              <Icon
                name={isMaximize ? 'close_fullscreen' : 'open_fullscreen'}
                className="cursor-pointer"
                onClick={() => setIsMaximize((prev) => !prev)}
                height={24}
                width={24}
                color={colors.text_black}
              />
            </Tooltip>
          )}
          <Tooltip title="Close" placement="bottom">
            <Icon
              className="cursor-pointer"
              onClick={handleOnCloseChange}
              name={'dismiss_icon'}
              height={24}
              width={24}
              color={colors.text_black}
            />
          </Tooltip>
        </div>
      </div>
      <div className={`vnc-viewer--${isMaximize ? 'maximize' : 'minimize'}`}>
        {showVideoData()}
      </div>
      <div className="vnc-modal-bottom">
        <Button
          variant="secondary"
          label="Cancel"
          size="small"
          onClick={handleOnCloseChange}
        />
      </div>
    </Modal>
  );
}

export default LiveVideoModal;