import Payment from 'payment';
import dayjs from 'dayjs';
import { addMethod as yupAddMethod, string as yupString } from 'yup';
import { ENVIRONMENT_URL_CONFIG } from '@common/environment-config';
import {
  PROJECT_TABPERMISSION_SECTIONS,
  LICENSE_FEATURES,
  NLP_CONDITIONAL_STEPS,
  NUMBER_RANGE_ERR_MESSAGE,
  SUPER_ADMIN_PRIVILEGE,
  FILTER_STATUS_CONSTANT,
  HASH_LABELS,
  VISUAL_TESTING_IMAGE_FILE_EXTENTION,
  TOAST_CONSTANTS,
  IMPORT_EXPORT_CONSTANTS,
  DASHBOARD_CONSTANTS,
} from '@src/common/ui-constants';
import { WEBSITE_ENVIRONMENT_URL_CONFIG } from '@common/website-env-url-config';
import Highlighter from 'react-highlight-words';
import { convertDate, TooltipPoppin } from '@pages/analytics/common/util';
import moment from 'moment-timezone';
import { WEBSERVICE } from '@src/pages/test-development/script/scripts/webservice/restapi/constants/constants';
import _moment from 'moment';
import { Tooltip } from 'fireflink-ui';
import { REGEX, UI_VALIDATIONS } from './validations';
import { exportProjectV2 } from '@src/api/api_services';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
const { NLP_CONDITION_START_ITERATION } = NLP_CONDITIONAL_STEPS;
const { NUM_ERR_MSG_ITEM, NUM_ERR_MSG_ATLEAST, NUM_ERR_MSG_BETWEEN } = NUMBER_RANGE_ERR_MESSAGE;
const {
  MAX_REQUEST_NAME_LENGTH,
  MAX_URL_LENGTH,
  LOCAL_VARIABLE_PREFIX,
  GLOBAL_VARIABLE_PREFIX,
  PROJECT_ENV_VARIABLE_PREFIX,
  HISTORY_SCRIPT_ID,
  HISTORY_MODULE_ID,
  BOTTOM_SCREEN_SIZE,
  OPEN_UP_SIZE,
  OPEN_DOWN_SIZE,
  DROPDOWN_WIDTH,
} = WEBSERVICE;
const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  TAB: 'Tab',
  SHIFT: 'Shift',
};

function logger(...args) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
    console.log(args);
  }
}

function getUSDFormatAmountString(amount) {
  if (typeof amount !== 'number' || isNaN(amount)) {
    return '00.00';
  }
  return Math.abs(amount).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function getHostname() {
  const urlData = window.location.href;
  if (urlData.includes('localhost')) {
    return ENVIRONMENT_URL_CONFIG.LOCAL_HOST;
  } else if (urlData.includes('dev')) {
    return ENVIRONMENT_URL_CONFIG.DEV_URL;
  } else if (urlData.includes('test')) {
    return ENVIRONMENT_URL_CONFIG.TEST_URL;
  } else if (urlData.includes('preprod')) {
    return ENVIRONMENT_URL_CONFIG.PRE_PROD_URL;
  } else if (urlData.includes('prod')) {
    return ENVIRONMENT_URL_CONFIG.PRODUCTION1_URL;
  } else if (urlData.includes('app')) {
    return ENVIRONMENT_URL_CONFIG.PRODUCTION_URL;
  } else if (urlData.includes('demo')) {
    return ENVIRONMENT_URL_CONFIG.DEMO_URL;
  } else if (urlData.includes('alpha')) {
    return ENVIRONMENT_URL_CONFIG.ALPHA_URL;
  } else if (urlData.includes('beta')) {
    return ENVIRONMENT_URL_CONFIG.BETA_URL;
  } else if (urlData.includes('devwswb')) {
    return ENVIRONMENT_URL_CONFIG.DEV_WEB_SERVICE_URL;
  } else if (urlData.includes('staging')) {
    return ENVIRONMENT_URL_CONFIG.STAGING_URL;
  } else if (urlData.includes('dev2')) {
    return ENVIRONMENT_URL_CONFIG.DEV2_URL;
  } else if (urlData.includes('test2')) {
    return ENVIRONMENT_URL_CONFIG.TEST2_URL;
  } else if (urlData.includes('test3')) {
    return ENVIRONMENT_URL_CONFIG.TEST3_URL;
  }
  return '';
}

function getWebSiteHostName() {
  const urlData = window.location.host;
  if (urlData.includes('localhost')) {
    return window.location.hostname;
  } else if (urlData.includes('alpha')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.ALPHA_URL;
  } else if (urlData.includes('beta')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.BETA_URL;
  } else if (urlData.includes('dev.')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.DEV_URL;
  } else if (urlData.includes('test.')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.TEST_URL;
  } else if (urlData.includes('onprem') || urlData.includes('product')) {
    return window.location.hostname;
  } else if (urlData.includes('preprod')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.PRE_PROD_URL;
  } else if (urlData.includes('app')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.PRODUCTION_URL;
  } else if (urlData.includes('prod')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.PRODUCTION1_URL;
  } else if (urlData.includes('devwswb')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.DEV_WEB_SERVICE_URL;
  } else if (urlData.includes('cloud')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.FIRE_CLOUD;
  } else if (urlData.includes('staging')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.STAGING_URL;
  } else if (urlData.includes('dev2')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.DEV2_URL;
  } else if (urlData.includes('test2')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.TEST2_URL;
  } else if (urlData.includes('test3')) {
    return WEBSITE_ENVIRONMENT_URL_CONFIG.TEST3_URL;
  }

  return window.location.hostname;
}

export const isEmailValid = (email) => {
  let tempEmailSplitArray = email?.split('@');
  let pattern = /[a-zA-Z]/;
  if (REGEX.EMAIL_PATTERN?.test(email)) {
    if (pattern?.test(tempEmailSplitArray[0])) {
      let tempTailPartOfEmailArray = tempEmailSplitArray[1].split('.');
      tempTailPartOfEmailArray?.pop();
      let tempEmailDomain = tempTailPartOfEmailArray.join('');
      if (pattern?.test(tempEmailDomain)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
function encode(plainText) {
  try {
    return Buffer.from(plainText, 'ascii').toString('hex');
  } catch (error) {
    console.error('encode string error', error);
    return plainText;
  }
}

function decode(encodedText) {
  try {
    return Buffer?.from(encodedText, 'hex')?.toString('ascii');
  } catch (error) {
    console.error('decode string error', error);
    return '';
  }
}

function isValidCardNumber(message) {
  return this.test('isValidCardNumber', message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({
        path,
        message: message ?? 'Please enter the card number',
      });
    }

    if (!Payment.fns.validateCardNumber(value)) {
      return createError({
        path,
        message: message ?? 'Please enter the valid card number',
      });
    }

    return true;
  });
}

function isValidCardExpiry(message) {
  return this.test('isValidCardExpiry', message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({
        path,
        message: message ?? 'Please enter the expiry date',
      });
    }

    if (!Payment.fns.validateCardExpiry(value)) {
      return createError({
        path,
        message: message ?? 'Please enter the valid expiry date',
      });
    }

    return true;
  });
}

function isValidCardCvv(message) {
  return this.test('isValidCardCvv', message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({
        path,
        message: message ?? 'Please enter the CVV number',
      });
    }

    if (!Payment.fns.validateCardCVC(value)) {
      return createError({
        path,
        message: message ?? 'Please enter the valid CVV number',
      });
    }

    return true;
  });
}

function numberRangeErrorMessage({ from, to, unit = '', item = NUM_ERR_MSG_ITEM }) {
  return `${item} ${to === Infinity ? NUM_ERR_MSG_ATLEAST : NUM_ERR_MSG_BETWEEN} ${from}${
    to !== Infinity ? ` and ${to}` : ''
  } ${unit.trim()}${unit.trim() && ''}`;
}

function isNumberInRange({ from, to }) {
  return (value) => Number(value) >= from && Number(value) <= to;
}

function testIsNumberInRange({ from, to, unit = '', item = 'item' }) {
  return this.test(
    'testIsNumberInRange',
    numberRangeErrorMessage({
      from,
      to,
      unit,
      item,
    }),
    isNumberInRange({ from, to })
  );
}

function cardValidationsYupMethodConfig() {
  yupAddMethod(yupString, 'isValidCardNumber', isValidCardNumber);
  yupAddMethod(yupString, 'isValidCardExpiry', isValidCardExpiry);
  yupAddMethod(yupString, 'isValidCardCvv', isValidCardCvv);
  yupAddMethod(yupString, 'testIsNumberInRange', testIsNumberInRange);
}

const restrictedNumberInputCharacters = ['e', 'E', '+', '-', '.'];

function allowWholeNumbersOnlyInNumberInputOnKeyDown(event) {
  restrictedNumberInputCharacters.includes(event.key) && event.preventDefault();
}

export function getUserPrivilege() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.privilege === 'User';
}

export function getAdminPrivilege() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.privilege === 'Admin';
}

export function getSuperAdminPrivilege() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.privilege === SUPER_ADMIN_PRIVILEGE.SUPER_ADMIN;
}

export function getAccessToken() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.access_token;
}
export function getRefreshToken() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.refresh_token;
}
export function getUserEmailId() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.userName;
}

export function getUserName() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.name;
}

export function getUserId() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.id;
}

export function getCurrentPrivilage() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.currentPrivilege;
}

export function getCurrentLicenseId() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.currentLicenseId;
}

export function getActivationStatus() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.activationStatus;
}

export function getCurrentProjectStatus() {
  return localStorage['selected-project'] && JSON.parse(localStorage['selected-project'])?.status;
}

export function getUserStatus() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.userStatus;
}

export function getLicenseStatus() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.licenseStatus;
}

export function getCurrentProjectId() {
  return localStorage['selected-project'] && JSON.parse(localStorage['selected-project'])?.id;
}

export function getCurrentProjectName() {
  return localStorage['selected-project'] && JSON.parse(localStorage['selected-project'])?.name;
}

export function getCurrentProjectType() {
  return localStorage['selected-project'] && JSON.parse(localStorage['selected-project'])?.type;
}

export function getCurrentProjectData() {
  return localStorage['selected-project'] && JSON.parse(localStorage['selected-project']);
}

export function getLicenseExpirationDays() {
  return JSON.parse(localStorage.getItem('test-optimize-user'))?.expires_in;
}

export function getLicenseFeatures() {
  return JSON.parse(localStorage.getItem('allowedProjects'))?.licenseFeatures;
}

export const getLicensePlans = () => {
  return JSON.parse(localStorage.getItem('allowedProjects'))?.licensePlan;
};

export const getLicenseType = () => {
  return JSON.parse(localStorage.getItem('allowedProjects'))?.licenseType;
};

export function getAnalyticsTabs() {
  return JSON.parse(
    localStorage.getItem('allowedProjects')
  )?.features?.analytics?.menu?.individualProjects?.subFeatures?.map((item) => item.name);
}

export function getPrivilege() {
  return JSON.parse(localStorage.getItem('allowedProjects'));
}

export function getProjectPermissionSections() {
  return JSON.parse(localStorage.getItem('allowedProjects'))?.features?.projectsMenu?.menu?.allProjects?.subFeatures[2]
    ?.subFeatures;
}

export function getProjectsRoleCount() {
  return JSON.parse(localStorage.getItem('allowedProjects'))?.features?.projectsMenu?.restrictions?.numberOfRoles;
}

export function getTestDevmenuItems() {
  return (
    JSON.parse(localStorage.getItem('allowedProjects'))?.features?.testDev?.menu?.individualProjects?.subFeatures?.[0]
      ?.subFeatures || []
  );
}

export function isImportExportEnabled() {
  return JSON.parse(localStorage.getItem('allowedProjects'))
    ?.features?.configuration?.menu?.allProjects?.subFeatures?.map((el) => {
      return el.name;
    })
    .includes('importAndExport');
}

export function isSearchFilterEnabled(module) {
  if (module === 'repository') {
    return JSON.parse(localStorage.getItem('allowedProjects'))
      ?.features?.[module]?.menu?.individualProjects?.subFeatures?.[0].subFeatures?.[0].subFeatures?.map((el) => {
        return el.name;
      })
      .includes('search' || 'filter');
  } else if (module === 'execution') {
    return JSON.parse(localStorage.getItem('allowedProjects'))
      ?.features?.[module]?.menu?.individualProjects?.subFeatures?.[0].subFeatures?.[1]?.subFeatures?.map((el) => {
        return el.name;
      })
      .includes('search' || 'filter');
  } else {
    return JSON.parse(localStorage.getItem('allowedProjects'))
      ?.features?.[module]?.menu?.individualProjects?.subFeatures?.[0].subFeatures?.map((el) => {
        return el.name;
      })
      .includes('search' || 'filter');
  }
}

export const purchasedFeatureTypeOfAutomation = (selectedProjectType) => {
  const licenseFeatures = getLicenseFeatures();
  const hasCBasic = global.privilege.hasCBasic(getLicenseFeatures());
  let purchasedProjectsType = licenseFeatures?.filter((type) => !['Manual Testcases', 'Defects'].includes(type));
  const isWebAndMobile =
    selectedProjectType === 'Web & Mobile' &&
    purchasedProjectsType?.includes('Web') &&
    purchasedProjectsType?.includes('Mobile');
  const isWebService = selectedProjectType === 'Web Service' && purchasedProjectsType?.includes('WebService');
  const isMSDynamic = selectedProjectType === 'MS Dynamics' && purchasedProjectsType?.includes('MSDynamics');
  return (
    purchasedProjectsType?.includes(selectedProjectType) ||
    hasCBasic ||
    isWebAndMobile ||
    isWebService ||
    isMSDynamic ||
    false
  );
};

export const isWebServiceRequired = (selectedProjectType) => {
  const hasManual = global.privilege.hasManualTestCase(getLicenseFeatures());
  const hasCBasic = global.privilege.hasCBasic(getLicenseFeatures());
  const hasDefects = global.privilege.hasDefects(getLicenseFeatures());
  return (
    (purchasedFeatureTypeOfAutomation(selectedProjectType) &&
      getLicenseFeatures().includes(LICENSE_FEATURES.WEB_SERVICE)) ||
    ((hasManual || hasDefects) && !purchasedFeatureTypeOfAutomation(selectedProjectType)) ||
    hasCBasic ||
    false
  );
};

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
export function isEmptyValue(val) {
  return val === undefined || val == null || val.length <= 0 ? true : false;
}

export function isValueIncludes(e, value) {
  return value?.toLowerCase()?.includes(String(e?.target?.value?.trim() || e?.trim())?.toLowerCase()) ? true : false;
}

// Decrypt phoneNumber
export function getDecodedPhoneNumber(phoneNumber) {
  let decodedPhoneNumber = atob(phoneNumber).includes('+') ? atob(phoneNumber).slice(1) : atob(phoneNumber);
  return decodedPhoneNumber;
}

export {
  KEYBOARD_KEYS,
  logger,
  getUSDFormatAmountString,
  getHostname,
  encode,
  decode,
  cardValidationsYupMethodConfig,
  allowWholeNumbersOnlyInNumberInputOnKeyDown,
  getWebSiteHostName,
};

/**
 * Get truncated text of given character count (default is 30) with ellipsis suffixed e.g. sampletext...
 * @param {*} value : String | Mandatory field
 * @param {*} charCount : number | optional | default is 30
 * @returns string
 */
export const getTruncatedText = (value = '', charCount = 30) => {
  if (value && charCount) {
    if (value.length < charCount) {
      return value;
    } else {
      return value.substring(0, charCount).concat('...');
    }
  }
};

// Text Highlighter in yellow color when search

export const HighlightText = ({ text, highlight, highlightedItemClass = 'highlightTheText' }) => {
  return (
    <Highlighter
      highlightClassName="highlightTheTexts"
      autoEscape={true}
      searchWords={[highlight]}
      textToHighlight={text || ''}
    />
  );
};

export const getUpdatedRoleName = (roleName) => {
  switch (roleName) {
    case 'dashboard':
      return PROJECT_TABPERMISSION_SECTIONS.DASHBOARD;
    case 'defects':
      return PROJECT_TABPERMISSION_SECTIONS.DEFECTS;
    case 'testDevelopment':
      return PROJECT_TABPERMISSION_SECTIONS.TEST_DEVELOPMENT;
    case 'analytics':
      return PROJECT_TABPERMISSION_SECTIONS.ANALYTICS;
    case 'repository':
      return PROJECT_TABPERMISSION_SECTIONS.REPOSITORY;
    case 'testData':
      return PROJECT_TABPERMISSION_SECTIONS.TEST_DATA;
    case 'execution':
      return PROJECT_TABPERMISSION_SECTIONS.TEST_EXECUTION;
    case 'configuration':
      return PROJECT_TABPERMISSION_SECTIONS.CONFIGURATION;
    default:
    // do nothing
  }
};

// TODO :: move scroll bar to the top of table
export let scrollTop = (tableRef) => {
  tableRef?.current?.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

// TODO :: move scroll bar to the bottom of block

export const scrollToBottom = (blockRef) => {
  if (blockRef?.current) {
    return (blockRef.current.scrollTop = blockRef.current.scrollHeight);
  }
};

/**
 * @param {*} privilegeObj
 * @returns Licence combination name
 */
export const getLicencePrivilegeCombinations = (privilegeObj) => {
  const { hasAutomation, hasManualTestCase, hasDefects } = privilegeObj;
  if (hasAutomation && !hasManualTestCase && !hasDefects) {
    return 'onlyAutomation';
  } else if (hasManualTestCase && !hasAutomation && !hasDefects) {
    return 'onlyManual';
  } else if (hasDefects && !hasAutomation && !hasManualTestCase) {
    return 'onlyDefects';
  } else if (hasAutomation && hasManualTestCase && !hasDefects) {
    return 'automationAndManual';
  } else if (hasAutomation && hasDefects && !hasManualTestCase) {
    return 'automationAndDefects';
  } else if (hasManualTestCase && hasDefects && !hasAutomation) {
    return 'manualAndDefects';
  } else {
    return 'all';
  }
};

export const getAutomationProjectTypes = (privilegeData) => {
  let allowedAutomationProjects;
  privilegeData?.features?.projectsMenu?.restrictions?.allowedProjects?.filter((type) => {
    if (type?.featureType === 'automation') {
      allowedAutomationProjects = type?.projects;
    }
    return true;
  });
  return allowedAutomationProjects;
};

export function toPascalCase(text) {
  text = text.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    let pascalText = g1.toUpperCase() + g2.toLowerCase();
    return pascalText;
  });
  return text;
}

export const getStringToBooleanValue = (value) => {
  switch (value?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false;
    default:
      return JSON.parse(value);
  }
};

// NOTE :: use this func to trim module name in ui
export const trimModuleName = (modName, action = 'name') => {
  if (action === 'path') {
    // NOTE :: action path is used to trim modulePath in tooltip
    if (modName?.includes(':')) {
      return modName?.split(':')?.[0];
    } else {
      return modName;
    }
  } else {
    if (modName?.includes(':')) {
      return modName?.split(':')[0]?.split('/')?.at(-1);
    } else {
      return modName?.split('/')?.at(-1);
    }
  }
};

export function getFormatedDate(currentDate) {
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  let formattedDate = `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
  return formattedDate;
}

export const getTimeStampForFileName = () => {
  return getFormatedDate(new Date());
};

export const getTypeListForMTC = (currentProjectType) => {
  //NOTE::  to get payload for Manual Testcase Template export
  switch (currentProjectType) {
    case 'Web':
      return [{ Web: ['Web', 'Webservice', 'Database'] }];
    case 'Mobile-Android':
      return [{ Mobile: ['Android', 'iOS', 'Webservice', 'Database'] }];
    case 'Mobile-iOS':
      return [{ Mobile: ['iOS', 'Webservice', 'Database'] }];
    case 'Mobile-Android & iOS':
      return [{ Mobile: ['Android', 'iOS', 'Webservice', 'Database'] }];
    case 'Web Services':
      return [{ 'Web Services': ['Webservice', 'Database'] }];
    case 'Web & Mobile-Android':
      return [
        {
          'Web & Mobile': ['Web', 'Android', 'Web & Mobile', 'Webservice', 'Database'],
        },
      ];
    case 'Web & Mobile-iOS':
      return [
        {
          'Web & Mobile': ['Web', 'iOS', 'Web & Mobile', 'Webservice', 'Database'],
        },
      ];
    case 'Web & Mobile-Android & iOS':
      return [
        {
          'Web & Mobile': ['Web', 'Android', 'iOS', 'Web & Mobile', 'Webservice', 'Database'],
        },
      ];
    case 'Salesforce':
      return [
        {
          Salesforce: ['Web', 'Android', 'iOS', 'Web & Mobile', 'Webservice', 'Database'],
        },
      ];
    default:
    // do nothing
  }
};

export const convertDateHyphenReverse = (dateVal) => {
  return dateVal == null ? null : new Date(dateVal?.split('/')?.reverse()?.join('-'));
};

const DATE_FORMATS_WITH_TIME = {
  DATE_WITH_MONTH_AND_TIME: 'DD MMM YYYY hh:mm a',
};

export const getDateFilteredByRange = (dataList, dateFieldName, fromDate, toDate) => {
  const endDate = new Date(toDate?.split('/')?.reverse()?.join('-'));
  const startDate = new Date(fromDate?.split('/')?.reverse()?.join('-'));

  function checkDateFormat(dateString) {
    return dateString.includes('-'); // DD-MM-YYYY HH:mm
  }
  function convertTimestampFromTimezone(timestamp) {
    if (timestamp && !checkDateFormat(timestamp)) {
      const formattedTime = _moment(timestamp, DATE_FORMATS_WITH_TIME.DATE_WITH_MONTH_AND_TIME)
        .tz(timestamp)
        .format('DD-MM-YYYY HH:mm');
      return formattedTime;
    } else {
      return timestamp;
    }
  }

  return dataList?.filter((item) => {
    let itemDate = convertTimestampFromTimezone(item[dateFieldName])?.split(' ')[0]?.split('-')?.reverse()?.join('-');
    itemDate = new Date(itemDate);
    return itemDate >= startDate && itemDate <= endDate;
  });
};

export const getVerifiedDateAuto = (dateGroup) => {
  if (Object.values(dateGroup).every((val) => val !== null)) {
    return [convertDate(dateGroup?.from), convertDate(dateGroup?.to)];
  } else if (dateGroup?.from !== null && dateGroup?.to == null) {
    return [convertDate(dateGroup?.from), convertDate(new Date())];
  } else {
    return [];
  }
};

export const findMatchingQuery = (data, query) => {
  return data.filter((item) => {
    for (let key in query) {
      if (query[key]?.length > 0 && !query[key]?.includes(item[key])) {
        return false;
      }
    }
    return true;
  });
};
/**
 * Common comparator function to sort in ascending order based on numeric value in the string
 */
export const customComparator = (a, b) => {
  const numA = parseInt(a.match(/\d+/)[0], 10);
  const numB = parseInt(b.match(/\d+/)[0], 10);
  if (numA < numB) {
    return -1;
  }
  if (numA > numB) {
    return 1;
  }
  return 0;
};

export const getMaskedString = (value = '') => '*****';

export const getScriptTypes = (data, previousData) => {
  let scriptTypes = previousData || [];
  data?.forEach((module) => {
    if (module?.children && module?.children?.length) {
      getScriptTypes(module?.children, scriptTypes);
    } else {
      scriptTypes.push(module?.scriptType);
    }
  });
  return scriptTypes;
};

export const getNumberOfRuns = (executionType, projType, isOnlyMobileTypeScript) => {
  if (
    (projType === 'mobile' && ['parallel', 'manual distribution']?.includes(executionType)) ||
    (isOnlyMobileTypeScript && executionType !== 'sequential')
  ) {
    // for mobile type of projects or mobile type of scripts
    return 1;
  } else {
    return 35;
  }
};

export const getFinalMachineObject = (machine) => {
  let machines = { ...machine };
  let selectedMachines = machines?.selectedMachines?.map((machine) => {
    let selectedMachine = { ...machine };
    let machineInstances = [];
    if (machine?.machineInstances?.length) {
      machine?.machineInstances?.map((instance) => {
        if (instance?.machineInstances?.length) {
          machineInstances = [...instance?.machineInstances];
        } else {
          machineInstances = [...machineInstances, instance];
        }
        return null;
      });
    }
    selectedMachine.machineInstances = machineInstances;
    return selectedMachine;
  });
  machines.selectedMachines = selectedMachines || [];
  return machines;
};

export const addMachineInstancesIfNotExist = (selectedMachines) => {
  selectedMachines?.forEach((machine) => {
    let selectedMachine = { ...machine };
    let machineInstances = [];
    if (machine?.machineInstances?.length) {
      return;
    }
    machineInstances = [selectedMachine];
    machine.machineInstances = machineInstances;
  });
};

export function getConfigurationTabs() {
  return JSON.parse(
    localStorage.getItem('allowedProjects')
  )?.features?.configuration?.menu?.allProjects?.subFeatures.find((item) => item.name === 'integrations')?.subFeatures;
}
export const splitedScriptNameAndID = (title) => {
  const titleArr = title?.split(' - ');
  const id = titleArr.shift();
  const name = titleArr.join(' - ');
  return [id, name];
};

export const regexValidator = (regex) => (text) => new RegExp(regex).test(text);

export async function fetchMachineData(user, getUserAllAvailableMachineReq, getAllMachineListReq) {
  let allMacData;
  if (user?.privilege === 'User') {
    allMacData = await getUserAllAvailableMachineReq(user?.id);
    return allMacData?.data?.responseMap?.userSystems;
  } else if (['Super Admin', 'Admin']?.includes(user?.privilege)) {
    allMacData = await getAllMachineListReq();
    return allMacData?.data?.responseObject?.clientSystems;
  }
}

export function delay() {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
      resolve(true);
    }, 100);
  });
}

export const getWaitTimeValidationCondition = (waitTime) => {
  const {
    delayBetweenStepsUnit,
    delayBetweenSteps,
    implicitWaitUnit,
    implicitWait,
    explicitlyWaitUnit,
    explicitlyWait,
  } = { ...waitTime };
  return (
    (delayBetweenStepsUnit === 'Secs' && delayBetweenSteps > 99) ||
    (delayBetweenStepsUnit === 'Msecs' && delayBetweenSteps > 99000) ||
    (implicitWaitUnit === 'Secs' && implicitWait > 999) ||
    (implicitWaitUnit === 'Msecs' && implicitWait > 999000) ||
    (explicitlyWaitUnit === 'Secs' && explicitlyWait > 999) ||
    (explicitlyWaitUnit === 'Msecs' && explicitlyWait > 999000)
  );
};

export function getCurrentProjectTimeZone() {
  return localStorage['selected-project'] && JSON.parse(localStorage['selected-project'])?.timeZoneValue;
}

export function getCurrentProjectTimeZoneName() {
  return localStorage['selected-project'] && JSON.parse(localStorage['selected-project'])?.timeZoneName;
}

export function compareDates(expiryDate, scheduledDate, scheduleTime) {
  const expiryDateString = JSON.stringify(expiryDate)?.substring(0, 11);
  const scheduledDateString = JSON.stringify(scheduledDate)?.substring(0, 11);

  if (expiryDateString === scheduledDateString) {
    const hours = parseInt(new Date(scheduleTime).toString().slice(16, 18));

    return new Date(0, 0, 0, hours);
  } else {
    return null;
  }
}

function licenseLevelTimeZone() {
  return localStorage['license-level-timezone'] && localStorage['license-level-timezone'];
}

function convertTimestampToLocaleString(timestamp, timeZone) {
  if (timestamp && timeZone) {
    const formattedTime = moment(timestamp, 'DD-MM-YYYY HH:mm').tz(timeZone).format('DD MMM YYYY hh:mm a');
    return formattedTime;
  } else {
    return moment(timestamp).format('DD MMM YYYY hh:mm a');
  }
}

const validateInvalidDateFormat = (value, additionalInvalidValues = []) => {
  const invalidValues = ['--', '', null, ...additionalInvalidValues];
  return invalidValues.includes(value);
};

export const timeZoneConversion = (response) => {
  let responseObject = { ...response };
  let timeZoneChangeReq = responseObject?.headers['allow-timezone-conversion'];

  if (typeof responseObject.data.responseObject === 'string') {
    if (responseObject.data.responseObject.startsWith('"{') && responseObject.data.responseObject.endsWith('}"')) {
      try {
        responseObject.data.responseObject = JSON.parse(responseObject.data.responseObject);
      } catch (error) {
        console.error('Error parsing responseObject.data.responseObject:', error);
        return responseObject;
      }
    }
  }

  if (timeZoneChangeReq) {
    if (Array.isArray(responseObject.data.responseObject)) {
      if (responseObject && responseObject?.data?.responseObject?.length > 0) {
        responseObject?.data?.responseObject?.map((isCreatedModifiedOn) => {
          if (
            isCreatedModifiedOn?.createdOn ||
            isCreatedModifiedOn?.modifiedOn ||
            isCreatedModifiedOn?.executionStartTime
          ) {
            let initialTimeZoneName = getCurrentProjectTimeZoneName();
            let licenseLevelTimeZoneName = licenseLevelTimeZone();
            if (
              !validateInvalidDateFormat(isCreatedModifiedOn?.modifiedOn) &&
              !validateInvalidDateFormat(isCreatedModifiedOn?.executionStartTime, ['N/A'])
            ) {
              if (!isEmptyValue(initialTimeZoneName)) {
                isCreatedModifiedOn.createdOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn?.createdOn,
                  initialTimeZoneName
                );
                isCreatedModifiedOn.modifiedOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn?.modifiedOn,
                  initialTimeZoneName
                );
                isCreatedModifiedOn.executionStartTime = convertTimestampToLocaleString(
                  isCreatedModifiedOn?.executionStartTime,
                  initialTimeZoneName
                );
              } else if (!isEmptyValue(licenseLevelTimeZoneName)) {
                isCreatedModifiedOn.createdOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn.createdOn,
                  licenseLevelTimeZoneName
                );
                isCreatedModifiedOn.modifiedOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn.modifiedOn,
                  licenseLevelTimeZoneName
                );
              } else {
                isCreatedModifiedOn.createdOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn?.createdOn,
                  isCreatedModifiedOn?.timeZoneName
                );
                isCreatedModifiedOn.modifiedOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn?.modifiedOn,
                  isCreatedModifiedOn?.timeZoneName
                );
              }
            } else {
              return isCreatedModifiedOn;
            }
          } else if (isCreatedModifiedOn?.createdOn || isCreatedModifiedOn?.modifiedOn) {
            let initialTimeZoneName = getCurrentProjectTimeZoneName();
            let licenseLevelTimeZoneName = licenseLevelTimeZone();
            if (!validateInvalidDateFormat(isCreatedModifiedOn?.modifiedOn)) {
              if (!isEmptyValue(initialTimeZoneName)) {
                isCreatedModifiedOn.createdOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn?.createdOn,
                  initialTimeZoneName
                );
                isCreatedModifiedOn.modifiedOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn?.modifiedOn,
                  initialTimeZoneName
                );
              } else if (!isEmptyValue(licenseLevelTimeZoneName)) {
                isCreatedModifiedOn.createdOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn.createdOn,
                  licenseLevelTimeZoneName
                );
                isCreatedModifiedOn.modifiedOn = convertTimestampToLocaleString(
                  isCreatedModifiedOn.modifiedOn,
                  licenseLevelTimeZoneName
                );
              }
            } else {
              return isCreatedModifiedOn;
            }
          }
          return isCreatedModifiedOn;
        });
      }
    }

    if (responseObject?.data?.responseObject?.createdOn || responseObject?.data?.responseObject?.modifiedOn) {
      if (!validateInvalidDateFormat(responseObject?.data?.responseObject?.modifiedOn)) {
        let initialTimeZoneName = getCurrentProjectTimeZoneName();
        let licenseLevelTimeZoneName = licenseLevelTimeZone();
        if (!isEmptyValue(initialTimeZoneName)) {
          responseObject.data.responseObject.createdOn = convertTimestampToLocaleString(
            responseObject?.data?.responseObject?.createdOn,
            initialTimeZoneName
          );
          responseObject.data.responseObject.modifiedOn = convertTimestampToLocaleString(
            responseObject?.data?.responseObject?.modifiedOn,
            initialTimeZoneName
          );
        } else if (!isEmptyValue(licenseLevelTimeZoneName)) {
          responseObject.data.responseObject.createdOn = convertTimestampToLocaleString(
            responseObject?.data?.responseObject?.createdOn,
            licenseLevelTimeZoneName
          );
          responseObject.data.responseObject.modifiedOn = convertTimestampToLocaleString(
            responseObject?.data?.responseObject?.modifiedOn,
            licenseLevelTimeZoneName
          );
        } else {
          responseObject.data.responseObject.createdOn = convertTimestampToLocaleString(
            responseObject?.data?.responseObject?.createdOn,
            responseObject?.data?.responseObject.timeZoneName
          );
          responseObject.data.responseObject.modifiedOn = convertTimestampToLocaleString(
            responseObject?.data?.responseObject?.modifiedOn,
            responseObject?.data?.responseObject.timeZoneName
          );
        }
      } else {
        return responseObject;
      }
    } else if (responseObject?.license?.createdOn || responseObject?.license?.modifiedOn) {
      if (!validateInvalidDateFormat(responseObject?.license?.modifiedOn)) {
        let initialTimeZoneName = getCurrentProjectTimeZoneName();
        responseObject.license.createdOn = convertTimestampToLocaleString(
          responseObject?.license?.createdOn,
          initialTimeZoneName
        );
        responseObject.license.modifiedOn = convertTimestampToLocaleString(
          responseObject?.license?.modifiedOn,
          initialTimeZoneName
        );
      } else {
        return responseObject;
      }
    }
  }

  return responseObject;
};

export function getAuthTypeName(parentAuthData) {
  let authTypeName;
  switch (parentAuthData?.authorizationInfo?.authType) {
    case 'Basic':
      authTypeName = 'Basic Auth';
      break;
    case 'Oauth1':
      authTypeName = 'OAuth1.0';
      break;
    case 'Oauth2':
      authTypeName = 'OAuth2.0';
      break;
    case 'Digest':
      authTypeName = 'Digest Auth';
      break;
    case 'NTLM':
      authTypeName = 'Microsoft NTLM';
      break;
    default:
      authTypeName = parentAuthData?.authorizationInfo?.authType;
  }
  return authTypeName;
}

export const pathName = (data) => {
  let path = data?.split('/');
  let rootIndex = path.lastIndexOf('Root');
  let result = path.slice(rootIndex).join('/');
  let finalResult = result.substring(0, result.lastIndexOf('/'));
  return result;
};
export const getArchiveFlag = () => {
  if (window.location.pathname.includes(DASHBOARD_CONSTANTS.ANALYTICS_DASHBOARD_URL)) {
    return '';
  }
  return window.location.pathname.includes(PROJECT_TABPERMISSION_SECTIONS.TEST_DEVELOPMENT.toLowerCase())
    ? archive.testDevFlags?.[JSON.parse(localStorage.getItem('selected-project'))?.id] || false // development dashboard flag
    : archive.suiteFlags?.[localStorage.getItem('urlSuiteId')] || false; // suite dashboard flag
};

export const archive = {
  testDevFlags: JSON.parse(localStorage.getItem('testDevFlags')),
  suiteFlags: JSON.parse(localStorage.getItem('suiteFlags')),
};

export const forLoopScript = (script, selectedRowIndex, currentStepMarginLeft) => {
  if (script?.steps[selectedRowIndex]?.type === '_startforloop') {
    return script?.steps[selectedRowIndex];
  } else {
    for (let i = selectedRowIndex; i >= 0; i--) {
      const step = script.steps[i];
      if (step && (step.specialNlpId || step.parentSpecialNlpId)) {
        if (!step?.parentSpecialNlpId && step?.type !== '_startforloop') {
          break;
        }
        if (step.type === '_startforloop' && step.marginLeft < currentStepMarginLeft) {
          return step;
        }
      }
    }
  }
  return null;
};
export const dataProviderScript = (script, selectedRowIndex, currentStepMarginLeft) => {
  if (script?.steps[selectedRowIndex]?.type === '_startiteration') {
    return script?.steps[selectedRowIndex];
  } else {
    for (let i = selectedRowIndex + 1; i >= 0; i--) {
      const step = script.steps[i];
      if (step && (step.specialNlpId || step.parentSpecialNlpId)) {
        if (step.marginLeft < currentStepMarginLeft) {
          if (step.type === '_startforloop' && !step.parentSpecialNlpId) {
            break;
          } else {
            if (step.type === '_startiteration' && (step?.parentSpecialNlpId || step?.specialNlpId.includes('itr_'))) {
              return step;
            }
          }
        }
      }
    }
  }
  return null;
};

export const ifCondition_ParentCheck = (script, selectedRowIndex, currentStepMarginLeft) => {
  let setBlockMarginLeft = currentStepMarginLeft;
  if (
    script?.steps[selectedRowIndex] &&
    NLP_CONDITION_START_ITERATION.includes(script?.steps[selectedRowIndex]?.displayName)
  ) {
    return script?.steps[selectedRowIndex];
  } else {
    for (let i = selectedRowIndex; i >= 0; i--) {
      const step = script.steps[i];
      if (setBlockMarginLeft > step.marginLeft && step?.name && !NLP_CONDITION_START_ITERATION.includes(step?.name)) {
        setBlockMarginLeft = step.marginLeft;
      }
      if (
        step &&
        NLP_CONDITION_START_ITERATION.some((iteration) => step.name.includes(iteration)) &&
        Number((currentStepMarginLeft - step.marginLeft).toFixed(1)) === NLP_CONDITIONAL_STEPS.MARGIN_LEFT &&
        setBlockMarginLeft >= step.marginLeft
      ) {
        return step;
      }
    }
  }
  return null;
};

export const generateConditionalOptionalParam = (
  executionOrder,
  parentConditionalStep,
  parentIterationId,
  currentStep
) => {
  const forLoopValues = parentConditionalStep?.stepInputs?.[0];
  const specialNlpId = parentConditionalStep?.specialNlpId;
  const forLoopValue = forLoopValues
    ? {
        forLoop: [
          {
            value: forLoopValues.value,
            name: WEBSERVICE.FOR_LOOP_INITIAL_VALUE,
            parentSpecialNlpId: specialNlpId || parentIterationId,
            ...(forLoopValues.reference && { reference: forLoopValues.reference }),
          },
        ],
      }
    : null;

  const conditionalOptionalParam = {
    executionOrder: executionOrder || 0,
    marginLeft: currentStep?.marginLeft,
    ...(parentIterationId && { parentSpecialNlpId: parentIterationId }),
  };
  if (specialNlpId?.includes('forloop_') || parentIterationId?.includes('forloop_')) {
    conditionalOptionalParam.values = forLoopValue;
  }
  if (currentStep?.type?.startsWith('_start') || currentStep?.name.includes('Start If Condition')) {
    conditionalOptionalParam.marginLeft += NLP_CONDITIONAL_STEPS.MARGIN_LEFT;
  }
  return conditionalOptionalParam;
};

export const addPropsForIfCondition = (conditionalOptionalParam, conditionId, conditionSearchKey, MARGIN_LEFT) => {
  conditionalOptionalParam.marginLeft = MARGIN_LEFT;
  conditionalOptionalParam.conditionId = conditionId;
  conditionalOptionalParam.conditionSearchKey = conditionSearchKey;
};
export function calculatedScriptNumber(obj) {
  if (obj?.children?.length) {
    let subCount = 0;
    let scriptCount = 0;
    obj?.children.forEach((childObj) => {
      if (childObj?.folder && childObj.calculatedScriptCount > 0) {
        subCount = childObj.calculatedScriptCount + subCount;
      } else if (childObj?.type === 'Script') {
        scriptCount++;
      }
    });
    obj['calculatedScriptCount'] = scriptCount + subCount;
  }
}
export function truncateRequestNameForHistory(str, maxLength = MAX_REQUEST_NAME_LENGTH) {
  return str.length > maxLength ? str.slice(0, maxLength - 4) + '... ' : str;
}
export function truncateURLForHistory(str, maxLength = MAX_URL_LENGTH) {
  return str.length > maxLength ? str.slice(0, maxLength - 3) + '...' : str;
}

export const filterTooltipTitle = (data, filterStatus) => {
  return isEmptyValue(data)
    ? FILTER_STATUS_CONSTANT.FILTER_DISABLED
    : filterStatus !== 'ALL'
    ? FILTER_STATUS_CONSTANT.CHANGE_FILTER
    : FILTER_STATUS_CONSTANT.FILTER;
};
export const _formatDate = (dateFormat) => {
  switch (dateFormat) {
    case 'dd-mm-yyyy':
      return 'dd MMMM yyyy';
    case 'mm-yyyy-dd':
      return 'MMMM yyyy dd';
    case 'yyyy-dd-mm':
      return 'yyyy dd MMMM';
    default:
      return 'dd MMMM yyyy';
  }
};
export const DATE_FORMATS = {
  DATE_WITH_MONTH_NAME: 'dd MMMM yyyy',
  DATE_WITH_MONTH_NUMBER: 'DD-MM-YYYY',
};
export const getFromUrl = (data) => {
  const search = window.location.search;
  return new URLSearchParams(search).get(data);
};
export const getUpdatedHistoryPath = (history) => {
  const scriptId = getFromUrl(HISTORY_SCRIPT_ID);
  const moduleId = getFromUrl(HISTORY_MODULE_ID);
  const updatedPath = history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/'));
  return `${updatedPath}/webservices/restapi?moduleId=${moduleId}&scriptId=${scriptId}`;
};
export const historyDetails = (execution_id) => ({ executionId: execution_id });
export const replaceVarIdToVarName = (urlData, contextData) => {
  const getAllVariables = [
    ...contextData.values.allContextGlobalVariables,
    ...contextData.values.allContextProjectEnvVariables,
    ...contextData.values.allContextLocalVariables,
  ];
  const variableMap = getAllVariables.reduce((acc, { id, name, type }) => {
    const prefixMap = {
      GLOBAL: GLOBAL_VARIABLE_PREFIX,
      LOCAL: LOCAL_VARIABLE_PREFIX,
      PROJECT_ENVIRONMENT: PROJECT_ENV_VARIABLE_PREFIX,
    };
    acc[id] = `${prefixMap[type] || ''}${name}`;
    return acc;
  }, {});

  return urlData ? urlData.replace(/\$\{(\w+)\}/g, (_, id) => `\${${variableMap[id] || id}}`) : urlData;
};

const dropdownWidth = (typeOfModal) =>
  typeOfModal === HASH_LABELS.MODAL_TYPE_DROPDOWN
    ? 'w-64'
    : typeOfModal === HASH_LABELS.DRAWER_TYPE_DROPDOWN
    ? 'w-420v'
    : typeOfModal === HASH_LABELS.WEB_SERVICE_TYPE_DROPDOWN
    ? 'w-380v'
    : '';

export const renderFilePath = (fileArray, handleOptionSelect, typeOfModal = '') => {
  if (!fileArray?.length) {
    return (
      <div className={`empty-dropdown-style ${dropdownWidth(typeOfModal)}`} id="journal-scroll">
        <p className="file-options truncate">{HASH_LABELS?.NO_OPTION}</p>
      </div>
    );
  }

  return (
    <div className={`filepath-dropdown ${dropdownWidth(typeOfModal)}`} id="journal-scroll">
      {fileArray.map((option, index) => (
        <Tooltip key={index} title={option?.actualPath} placement="top" className="tooltip-style">
          <div className="option-wrapper">
            <p onClick={() => handleOptionSelect(option)} className="file-options truncate">
              {option?.path}
            </p>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
export const renderFileAndFolderPath = (fileAndFolderArray, handleOptionSelect, typeOfModal = '') => {
  if (!fileAndFolderArray?.length) {
    return (
      <div className={`empty-dropdown-style ${dropdownWidth(typeOfModal)}`} id="journal-scroll">
        <p className="file-options truncate">{HASH_LABELS?.NO_OPTION}</p>
      </div>
    );
  }
  return (
    <div className={`filepath-dropdown ${dropdownWidth(typeOfModal)}`} id="journal-scroll">
      {fileAndFolderArray.map((option, index) => (
        <Tooltip key={index} title={option?.actualPath} placement="top" className="tooltip-style">
          <div className="option-wrapper">
            <p onClick={() => handleOptionSelect(option)} className="file-options truncate">
              {option?.path}
            </p>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
export const searchFilePathBasedOnHash = (event, filePathArray, allFilePathArray) => {
  const inputValue = event.target.value;
  const searchQuery = inputValue.slice(1).toLowerCase();
  const fileArrayOption = filePathArray.find((option) => option.path.toLowerCase() === searchQuery);
  const filteredArray = allFilePathArray.filter((file) => file.path.toLowerCase().includes(searchQuery));
  return { inputValue, fileArrayOption, filteredArray };
};

export const TimeZoneDates = (timeZone, timeZoneName) => {
  const minDate = new Date().toLocaleString(timeZone, {
    timeZone: timeZoneName,
  });
  return dayjs(minDate);
};

export const getDateFormateForCreateSuite = (timeZoneDate) => {
  var dd = String(timeZoneDate.getDate()).padStart(2, '0');
  var mm = String(timeZoneDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = timeZoneDate.getFullYear();
  var time = timeZoneDate.getHours() + ':' + timeZoneDate.getMinutes();
  let todayDate = dd + '/' + mm + '/' + yyyy + ', ' + time;
  return todayDate;
};

export const getTimeZoneFormateDate = (createOnDate, operation) => {
  if (operation === 'create') {
    const inputDateStr = createOnDate;
    const inputDate = moment(inputDateStr, 'DD/MM/YYYY, HH:mm');
    const targetDate = moment(inputDate, 'DD MMM YYYY hh:mm A');
    const formattedDate = targetDate.format('DD MMM YYYY hh:mm A');
    return formattedDate;
  } else {
    return createOnDate;
  }
};

export const findMaxNumberOfRuns = (machines, maxNumber = UI_VALIDATIONS.MAX_NUMBER_OF_RUNS_SHOULD_SELECT) => {
  let sumOfNumOfRuns = 0;
  for (const data of machines) {
    sumOfNumOfRuns += Number(data?.numberOfRuns);
  }
  if (sumOfNumOfRuns > maxNumber) {
    return false;
  }
  return true;
};
export const maxNumberOfRunsAlert = (machines, type, maxNumber) => {
  if (type === 'multipleMachine') {
    let machineData = [];
    for (const data of machines) {
      let machineArray = data?.flat(1);
      let status = findMaxNumberOfRuns(machineArray, maxNumber);
      !status && machineData.push(status);
    }
    return !machineData?.length ? true : false;
  } else {
    let status = findMaxNumberOfRuns(machines, maxNumber);
    return !status ? false : true;
  }
};

// this is used for time conversion in payload schedule Instance
export const convertGivenTimeToGMT = (selectedDate) => {
  const dateObj = moment(selectedDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const formattedDate = dateObj.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  return formattedDate;
};
export const convertGMTTimeToUserGivenTime = (selectedDate) => {
  const dateObj = moment(selectedDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  const formattedDate = dateObj.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  return formattedDate;
};

// File exetension validation fun
export const validateFileExtension = (file, allowedFileTypes) => {
  const selectedFile = file?.target?.files[0] || file;
  const fileExtension = '.' + selectedFile?.name?.split('.')?.pop()?.toLowerCase();
  const fileType = selectedFile?.type;
  if (Object.keys(allowedFileTypes)?.includes(fileExtension)) {
    return true;
  }
  return allowedFileTypes[fileExtension] && fileType === allowedFileTypes[fileExtension];
};

export const validateZipFileExtension = (event) => {
  const selectedFile = event?.target?.files[0] || event;
  // List of valid MIME types
  const validMimeTypes = ['application/zip', 'application/x-zip-compressed'];
  // Validating the file extension and MIME type
  return (
    selectedFile && validMimeTypes?.includes(selectedFile?.type) && selectedFile?.name?.toLowerCase()?.endsWith('.zip')
  );
};
export const calculatePosition = (dollarPosition, isInput) => {
  const { top, left } = dollarPosition;
  const LEFT_SCREEN_SIZE = window.innerWidth < left + DROPDOWN_WIDTH ? window.innerWidth - DROPDOWN_WIDTH : left;
  return {
    top: isInput ? top : top > BOTTOM_SCREEN_SIZE ? top - OPEN_UP_SIZE : top + OPEN_DOWN_SIZE,
    left: Math.min(left, LEFT_SCREEN_SIZE),
    position: 'fixed',
  };
};
export const getScreenPosition = (editorRef) => {
  if (!editorRef) return null;
  const { row, column } = editorRef?.editor.getCursorPosition();
  return editorRef?.editor?.renderer.textToScreenCoordinates(row, column);
};

export const escapeHtml = (str) => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/\//g, '&#x2F;')
    .replace(/`/g, '&#x60;')
    .replace(/=/g, '&#x3D;');
};
export const convertStringToBoolean = (value) => {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' ? true : false;
  }
  return value;
};
export const booleanToString = (isChecked) => {
  return isChecked ? 'true' : 'false';
};
export const getReportTypes = (data) => {
  const parseBoolean = (value) => {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }
    return Boolean(value);
  };

  let isPDFReport = parseBoolean(data?.attachPdfReport) || parseBoolean(data?.pdfAttachment);
  let isHTMLReport = parseBoolean(data?.attachHtmlReport) || parseBoolean(data?.htmlAttachment);

  return [isPDFReport && 'PDF', isHTMLReport && 'HTML'].filter(Boolean).join(', ');
};

export const getLocatorsType = (dataType) => {
  if (VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(dataType)) {
    return dataType === 'svg+xml' ? 'SVG' : dataType?.toUpperCase();
  } else {
    return dataType === 'dynamic' ? 'Dynamic' : 'Static';
  }
};

export const getDeviceData = (deviceData) => {
  return Array.isArray(deviceData) && !isEmptyValue(deviceData) && deviceData[0]?.label ? deviceData : null;
};

export const getFileExtension = (fileName) => fileName?.split('.').pop();

export const handleExportClick = async (selectedProject, setIsInfoPopupOpen, MyAlert) => {
  try {
    const { id, name } = selectedProject;
    setIsInfoPopupOpen(false);
    const response = await exportProjectV2(id);
    if (response.status === 200) {
      saveFileFromBlob(response?.data, `${name}.zip`);
      MyAlert.success(`${name} ${IMPORT_EXPORT_CONSTANTS.PROJECT_EXPORTED_SUCCESSFULLY}`);
    }
  } catch (err) {
    console.error('API FAILURE:', err);
  }
};
